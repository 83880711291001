import React from 'react';
import styled from 'styled-components';

import { IonInput, IonLabel, IonItem, IonButton, IonIcon } from '@ionic/react';
import { eye, eyeOff } from 'ionicons/icons';

import { Spacing } from '../../../foundations';

const Field = styled.div`
  background: transparent;
  margin-bottom: ${Spacing.xxs};
`;

const Input = ({ label, value, name, onChange, type, ...props }: any) => {
  const [passwordType, setPasswordType] = React.useState(type);
  const [eyeIcon, setEyeIcon] = React.useState(eyeOff);

  const handlePasswordVisibility = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      setEyeIcon(eye);
      return;
    }

    setPasswordType('password');
    setEyeIcon(eyeOff);
  };

  return (
    <Field>
      <IonItem color="white">
        <IonLabel position="floating">{label}</IonLabel>
        <IonInput
          name={name}
          value={value}
          onIonChange={onChange}
          color={'dark'}
          type={type === 'password' ? passwordType : type}
          {...props}
        ></IonInput>
        {type === 'password' && (
          <IonButton
            fill="clear"
            color="dark"
            slot="end"
            className="input-icon"
            onClick={() => handlePasswordVisibility()}
          >
            <IonIcon id="eyeicon" icon={eyeIcon} />
          </IonButton>
        )}
      </IonItem>
    </Field>
  );
};

export default Input;
