import React from 'react';
import Text from '../Text';
import { Colors } from '../../../foundations';

const getFormattedDistance = (distance: number) => {
  return Math.round(distance) > 0 ? distance.toFixed(2) + 'km' : Math.round(distance * 1000) + 'm';
};

const FormattedDistance = ({ distance }: any) => {
  return (
    <Text config={'secondary-text'} color={Colors.black}>
      {getFormattedDistance(distance)}
    </Text>
  );
};

export default FormattedDistance;
