import { useQuery } from 'react-query';

import { axios } from '../../../lib/axios';

export const generateQrAuth = (): Promise<any> => {
  return axios.get('/v1/auth/qr/generate');
};

export const useQRAuth = (config: any | undefined) => {
  return useQuery({
    queryKey: ['QRAuth'],
    queryFn: () => generateQrAuth(),
    ...config,
  });
};
