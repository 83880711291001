import ApiCaller from '../lib/ApiCaller';
import Restaurant from '../lib/common/models/restaurant';
import RestaurantsCollection from '../lib/common/models/restaurantsCollection';
import { ENV } from '../env/env';
import { Coords } from '@/features/shared';

class RestaurantApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  async add(restaurant: Restaurant, password: string): Promise<any> {
    return this.apiCaller
      .call('/v2/restaurant', 'POST', {
        name: restaurant.name,
        email: restaurant.email,
        password,
        address: restaurant.address,
        lat: restaurant.lat,
        displayed: restaurant.displayed,
        lng: restaurant.lng,
        placeid: restaurant.placeid,
        phone: restaurant.phone,
        hours: restaurant.hours,
        photoUrl: restaurant.photoUrl,
        rating: restaurant.rating,
        website: restaurant.website,
        type: restaurant.type,
        productTypeList: restaurant.productTypeList,
        productGroupTypeList: restaurant.productGroupTypeList,
      })
      .catch((err) => {
        console.error(err);
        alert('Error creating user, please try again');
      });
  }

  getRestaurantInfo(id: string): Promise<void | undefined> {
    return this.apiCaller
      .call('/v1/restaurant/getRestaurant', 'POST', {
        id,
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getActiveRestaurants(): Promise<void | RestaurantsCollection> {
    return this.apiCaller.call('/v1/restaurant/getActive', 'GET').then((data) => {
      const restaurantArray = data?.restaurants?.map(
        (restaurant: any) => new Restaurant(restaurant)
      );

      return new RestaurantsCollection(restaurantArray);
    });
  }

  getRestaurants(
    from = 0,
    limit = 10,
    query: string | null = null,
    containers?: boolean,
    lends?: boolean,
    active?: boolean,
    distance: Coords | null = null
  ): Promise<any> {
    const params = {
      ...(from ? { from } : {}),
      ...(limit ? { limit } : {}),
      ...(query ? { query } : {}),
      ...(distance ? { distance } : {}),
      ...(containers === true || containers === false ? { containers } : {}),
      ...(lends === true || lends === false ? { lends } : {}),
      ...(active === true || active === false ? { active } : {}),
    };
    return this.apiCaller.call(`/v2/restaurant`, 'GET', params);
  }

  updateData(restaurant: Restaurant): Promise<any> {
    return this.apiCaller.call(`/v2/restaurant/${restaurant.id}`, 'PUT', {
      name: restaurant.name,
      address: restaurant.address,
      lat: restaurant.lat,
      lng: restaurant.lng,
      displayed: restaurant.displayed,
      placeid: restaurant.placeid,
      phone: restaurant.phone,
      hours: restaurant.hours,
      photoUrl: restaurant.photoUrl,
      rating: restaurant.rating,
      website: restaurant.website,
      active: restaurant.active,
      type: restaurant.type,
      productTypeList: restaurant.productTypeList,
      productGroupTypeList: restaurant.productGroupTypeList,
    });
  }

  updateLendCount(): Promise<any> {
    return this.apiCaller
      .call('/v1/restaurant/updateLendCount', 'GET')
      .then((data: any) => {
        console.log(data);
      })
      .catch((err: any) => console.log(err));
  }

  uploadPictures({
    restaurantName,
    base64,
    format,
    url,
  }: {
    restaurantName: string;
    base64?: string;
    format?: string;
    url?: string;
  }): Promise<any> {
    return this.apiCaller
      .call('/v1/restaurant/uploadPictures', 'POST', {
        restaurantName,
        base64,
        format,
        url,
      })
      .catch((err) => {
        console.log(err);
      });
  }

  suggestRestaurant({
    restaurantName,
    restaurantWeb,
  }: {
    restaurantName: string;
    restaurantWeb: string;
  }): Promise<any> {
    return this.apiCaller.call(
      'https://api.airtable.com/v0/apptmhjRtvNP8NeWq/Sugerencias',
      'POST',
      {
        fields: { Name: restaurantName, Web: restaurantWeb },
      },
      { Authorization: `Bearer ${ENV.AIRTABLE_API_KEY}` }
    );
  }
}

export default RestaurantApi;
