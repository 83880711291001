import React, { useRef } from 'react';
import styled from 'styled-components';
import { Carrousel, Dots, ImgSlide } from '../../index';
import { Spacing } from '../../../foundations';
import { Icon } from '../../atoms';

interface CarrouselProps {
  admin?: boolean;
  imgList: any | Array<any>;
  onAdd?: void;
  onArrowClick?: any;
}

const StyledDiv = styled.div`
  position: relative;
  height: auto;
  width: 100%;
`;

const IconContainer = styled.div`
  position: absolute;
  top: ${Spacing.m};
  right: ${Spacing.s};
`;

const settings = {
  appendDots: (dots: any) => <Dots dots={dots} position="right" />,
};

const RestaurantCarrousel = ({ admin: isAdmin, imgList, onArrowClick }: CarrouselProps) => {
  const sliderRef = useRef<any>(null);

  const emptyImageURL =
    'https://bumerangphotos.s3.eu-west-3.amazonaws.com/assets/big_default_restaurant.png';
  return (
    <StyledDiv>
      <Carrousel reference={sliderRef} overrideSettings={settings}>
        {imgList && imgList.length !== 0 ? (
          imgList.map((image: any, index: number) => <ImgSlide key={index} imgUrl={image} />)
        ) : (
          <ImgSlide imgUrl={emptyImageURL} />
        )}
      </Carrousel>
      {isAdmin && (
        <IconContainer onClick={() => onArrowClick()}>
          <Icon name="arrow" />
        </IconContainer>
      )}
    </StyledDiv>
  );
};
export default RestaurantCarrousel;
