import React, { useEffect, useState } from 'react';
import { ENV } from '@/env/env';
import styled from 'styled-components';
import { StatusBar } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { useQRAuth } from '../../../auth/api/generateQRAuth';
import { useLendsHistory } from '../../../logistics/api/getLendsHistory';
import useNetwork from '../../../../hooks/useNetwork';
import { Container, HorizontalScroll } from '@bumerang-kit/layouts';
import { Accountant, Icon, LoadingHome, PartnerCard } from '@bumerang-kit/components';
import { Colors } from '@bumerang-kit/foundations';
import Settings from '../../../../lib/settings';
import { getCurrentSession } from '../../../../helpers/session';
import Offline from '../../../../pages/Offline';
import { useGetLastActivePartners, useGetMostPopularPartners } from '@/features/logistics';
import { NavigationWrapper, Partner, useGetPaymentStatus } from '@/features/shared';
import { useGetCurrentLocation } from '@/features/explorer';
import { useTranslation } from 'react-i18next';
import useNotifications from '@/hooks/useNotifications';
import { useHistory } from 'react-router';
import ApiLambda from '@/lib/ApiLambda';
import { usePlatform } from '@bumerang-kit/helpers';
import { language } from '@/locales/languageDetector';
import HomeModal from '../../components/HomeModal/HomeModal';
import AuthApi from '@/api/AuthApi';
import ApiCaller from '@/lib/ApiCaller';
import NewsLetterModal from '../../components/NewsLetterModal/NewsLetterModal';
import useUserEdit from '@bumerang-kit/helpers/Hooks/useEditUser';

const StyledContainer = styled(Container)`
  position: relative;
  padding: 0;
`;
const accountantTransformer = (totalLendsNotFinished: any) =>
  totalLendsNotFinished.map((lendNotFinished: any) => {
    const lentAt = new Date(lendNotFinished?.lent_at);
    const returnAt = lentAt.setDate(lentAt.getDate() + Settings.getNumDaysToReturn());

    return {
      name: lendNotFinished?.container?.name || lendNotFinished.id,
      expirationDate: returnAt,
      type: lendNotFinished?.container?.product_type?.group?.toLowerCase() || 'bowl',
    };
  });

const Home: React.FC = () => {
  const [isQRModalOpen, setIsQRModalOpen] = React.useState<boolean>(false);
  const navigate = useHistory();
  const { t } = useTranslation();
  const currentUser = getCurrentSession();
  const { isConnected } = useNetwork();
  const { position } = useGetCurrentLocation();
  const { data: platform } = usePlatform();
  const productApi = new ApiLambda();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [showNewsLetterModal, setShowNewsLetterModal] = useState<boolean>(false);
  const [logedInWithGoogle, setLogedInWithGoogle] = useState<boolean>(false);
  const [logedInWithApple, setLogedInWithApple] = useState<boolean>(false);
  const [socialLogin, setSocialLogin] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalImage, setModalImage] = useState<string>('');
  const [checkStatus, setCheckStatus] = useState<boolean>(false);
  const [staging, setStaging] = useState<boolean>(false);
  const { getPaymentStatus, paymentStatus } = useGetPaymentStatus(currentUser.status, {});
  const getUser = new AuthApi(new ApiCaller(currentUser.token));
  const editUserMutation = useUserEdit();

  useEffect(() => {
    if (ENV.CURRENT_ENV === 'staging') {
      setStaging(true);
    }
  }, []);

  async function getUserNewsLetterCheck() {
    try {
      const user: any = await getUser.getCurrentUser();
      if (user && user.data.newsletter === null) {
        setShowNewsLetterModal(true);
        if (user.data.provider === 'Google') {
          setSocialLogin(true);
          setLogedInWithGoogle(true);
        } else if (user.data.provider !== 'Google' && user.data.provider !== 'Email') {
          setSocialLogin(true);
          setLogedInWithApple(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const generateQRAuth: any = useQRAuth({
    enabled: getPaymentStatus.data && getPaymentStatus.data === 'configured',
  });

  const getLendsHistory: any = useLendsHistory({
    enabled: generateQRAuth.data && !!generateQRAuth?.data?.id,
  });

  const getLastThreeActivePartners: Partner[] = useGetLastActivePartners();
  const getThreeMostPopularPartners: Partner[] = useGetMostPopularPartners({ position });
  const userHasEmailValidated = currentUser.status === 'confirmed';

  useNotifications(currentUser.id, () => getLendsHistory.refetch());
  //Not supported on ios
  if (Capacitor.isPluginAvailable('StatusBar'))
    StatusBar.setBackgroundColor({ color: Colors.mainColor });

  async function checkAppStatus() {
    try {
      const id = localStorage.getItem('warning id');
      if (id) {
        return;
      }
      const response = await productApi.call(
        'https://cp6iwu2ba3.execute-api.eu-west-3.amazonaws.com/Prod'
      );
      if (response && response.length > 0) {
        const data = response[0];
        console.log(data);
        localStorage.setItem('warning id', data.id);
        const theTitle = (): string => {
          switch (language) {
            case 'en':
              return data.titleEN;
            case 'es':
              return data.titleES;
            default:
              return data.title;
          }
        };

        const theText = (): string => {
          switch (language) {
            case 'en':
              return data.textEN;
            case 'es':
              return data.textES;
            default:
              return data.text;
          }
        };
        setModalImage(data.icon);
        setModalText(theText);
        setModalTitle(theTitle);
        if (
          (data.platform === platform || data.platform === 'all') &&
          (data.app === 'customer' || data.app === 'all')
        ) {
          setShowModal(true);
          if (
            data.flag === 'warning_custom' ||
            data.flag === 'warning_maintenance' ||
            data.flag === 'warning_update'
          ) {
            setShowButton(true);
          }
          if (data.flag === 'warning_update' || data.flag === 'update') {
            setShowUpdate(true);
          }
        }
        setCheckStatus(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    if (!checkStatus) checkAppStatus();
  });

  React.useEffect(() => {
    getUserNewsLetterCheck();
  }, []);

  React.useEffect(() => {
    if (!userHasEmailValidated) {
      setIsQRModalOpen(true);
    }
  }, [userHasEmailValidated]);

  if (!isConnected) {
    return (
      <NavigationWrapper
        active="customer"
        type="customer"
        isQRModalOpen={isQRModalOpen}
        onOpenQRModal={(isOpen: boolean) => setIsQRModalOpen(isOpen)}
      >
        <Offline />
      </NavigationWrapper>
    );
  }

  if (generateQRAuth.isLoading || getLendsHistory.isLoading || getPaymentStatus.isLoading) {
    return (
      <NavigationWrapper
        active="customer"
        type="customer"
        isQRModalOpen={isQRModalOpen}
        onOpenQRModal={(isOpen: boolean) => setIsQRModalOpen(isOpen)}
      >
        <LoadingHome />
      </NavigationWrapper>
    );
  }

  const totalLendsNotFinished = accountantTransformer(
    getLendsHistory.data.data.history.not_finished
  );

  const Row = (partner: Partner, cardDirection: 'horizontal' | 'vertical', index: number) => {
    const partnerDetailsUrl = `/partner/${partner.id}/details`;

    return (
      <PartnerCard
        onClick={() => navigate.push(partnerDetailsUrl)}
        partner={partner}
        direction={cardDirection}
        itemId={index}
        className={`${cardDirection === 'vertical' ? 'mr-2 ' : 'mr-0'} mb-4 min-w-[270px]`}
        currentPosition={position}
        key={index}
      />
    );
  };

  return (
    <>
      {checkStatus && (
        <HomeModal
          isOpen={showModal}
          setIsOpen={setShowModal}
          modalImage={modalImage}
          modalText={modalText}
          modalTitle={modalTitle}
          showButton={showButton}
          showUpdate={showUpdate}
        />
      )}
      {showNewsLetterModal && (
        <NewsLetterModal
          isOpen={showNewsLetterModal}
          setIsOpen={setShowNewsLetterModal}
          socialLogin={socialLogin}
          logedInWithGoogle={logedInWithGoogle}
          appleLogin={logedInWithApple}
          editUser={editUserMutation}
          userData={currentUser}
        />
      )}

      <NavigationWrapper
        active="customer"
        type="customer"
        isQRModalOpen={isQRModalOpen}
        onOpenQRModal={(isOpen: boolean) => setIsQRModalOpen(isOpen)}
      >
        <StyledContainer backgroundColor={Colors.backgroundBlue}>
          <header className="bg-main px-4 pt-12 pb-16">
            {staging && (
              <p color={Colors.backgroundBlue} className="text-lg text-red-600 font-bold">
                STAGING VERSION
              </p>
            )}
            <div className=" flex items-center justify-between">
              <p className="text-lg text-white">{t('HomeWelcome', { name: currentUser.name })},</p>
              <button onClick={() => navigate.replace('how-it-works')}>
                <Icon name="info" color="white" size="24" />
              </button>
            </div>
          </header>
          <div className="-mt-8 px-4">
            <Accountant
              total={totalLendsNotFinished}
              paymentStatus={paymentStatus}
              setupAction={() => setIsQRModalOpen(!isQRModalOpen)}
            />
          </div>

          <header className="my-4">
            <div className="px-4 mx-auto">
              <h1 className="text-xl text-main font-bold">{t('HomeTitle')}</h1>
            </div>
          </header>

          <section className="mb-8">
            <div className="mx-auto">
              <header className="flex items-baseline justify-between mt-8 mb-4 px-4">
                <h2 className="text-base text-main font-bold">{t('PopularPartnersTitle')}</h2>
                <a
                  className="underline text-xs text-main font-bold block text-center cursor-pointer"
                  onClick={() => navigate.push('/populars')}
                >
                  {t('SeeAll')}
                </a>
              </header>

              <HorizontalScroll>
                {getThreeMostPopularPartners &&
                  getThreeMostPopularPartners
                    .slice(0, 6)
                    .map((partner: Partner, index: number) => Row(partner, 'vertical', index))}
              </HorizontalScroll>
            </div>
          </section>

          <section className="mb-8">
            <div className="mx-auto">
              <header className="flex items-baseline justify-between mt-8 mb-4 px-4">
                <h2 className="text-base text-main font-bold">{t('NewPartnersTitle')}</h2>
                <a
                  className="underline text-xs text-main font-bold block text-center cursor-pointer"
                  onClick={() => navigate.push('/news')}
                >
                  {t('SeeAll')}
                </a>
              </header>

              <HorizontalScroll>
                {getLastThreeActivePartners &&
                  getLastThreeActivePartners
                    .slice(0, 6)
                    .map((partner: Partner, index: number) => Row(partner, 'vertical', index))}
              </HorizontalScroll>
            </div>
          </section>
        </StyledContainer>
      </NavigationWrapper>
    </>
  );
};

export default Home;
