import React, { useEffect, useState } from 'react';
import { IonSpinner } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import QrReader from 'react-qr-reader';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useQuery } from 'react-query';
import { format } from 'date-fns';
import LogisticsApi from '../../apiClient/LogisticsApi';
import ProductApi from '../../apiClient/ProductApi';
import { Container, Page } from '@bumerang-kit/layouts';
import { Toaster } from '@bumerang-kit/helpers';
import {
  Card,
  Modal,
  MultipleStatCard,
  Navigation,
  Placeholder,
  Text,
} from '@bumerang-kit/components';
import { Colors } from '@bumerang-kit/foundations';
import { getCurrentSession } from '@/helpers/session';
import UserApi from '../../api/UserApi';
import ApiCaller from '../../lib/ApiCaller';

const toaster = new Toaster();

const Scan = styled(QrReader)`
  width: 100%;
  display: block;
  margin: 0;
  height: 324px;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;
`;

const DateContainer = styled.div`
  width: 30%;
`;

const Movements = styled.div`
  width: 70%;
`;

interface ScannerDelayState {
  lastScanDetected?: number;
  qrContent?: string;
}

const StyledCard = styled(Card)`
  width: 100%;
  height: auto;
  max-height: 100px;
  display: flex;
`;

const GetInfoContainer = () => {
  const navigate = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const user = getCurrentSession();
  const [isScanning, updateScanning] = useState(true);
  const productApi = new ProductApi(user.token);
  const logisticsApi = new LogisticsApi(user.token);
  const userApi = new UserApi(new ApiCaller(user.token));
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [lastScanDetected, setLastScanDetected] = useState<ScannerDelayState>({});
  const [container, setContainer] = useState<any>(null);
  const [containerUser, setContainerUser] = useState<any>(null);
  const [containerJourney, setContainerJourney] = useState<any>(null);

  const isValid = !!container && !!container?.id;
  const getJourney = useQuery(
    'journey',
    async () => await logisticsApi.getContainerJourney(container?.id),
    {
      onSuccess: (response: any) => setContainerJourney(response.data),
      enabled: isValid,
    }
  );

  useEffect(() => {
    if (isValid) {
      getJourney.refetch();
    }
  }, [container]);

  useEffect(() => {
    const pathname = location?.pathname;
    if (pathname === '/admin/container/info') {
      updateScanning(true);
    }
  }, [location]);

  const handleScan = async (qrContent: string | null) => {
    if (!qrContent) return;

    const now: number = Date.now();

    if (lastScanDetected?.lastScanDetected !== undefined) {
      if (
        now - lastScanDetected?.lastScanDetected < 3000 &&
        lastScanDetected?.qrContent === qrContent
      ) {
        return;
      }
    }

    setLastScanDetected({ lastScanDetected: now, qrContent });

    const containerResponse = await productApi.getContainerDetails(qrContent);

    if (containerResponse && containerResponse.success) {
      const userResponse = await userApi.getUserInfo(containerResponse.data.current_owner_id);
      if (userResponse && userResponse.success) {
        setContainerUser(userResponse.data);
        setContainer(containerResponse.data);
      } else {
        if (userResponse?.data?.error) {
          toaster.flashError(userResponse.data.error);
          return;
        }
        toaster.flashError(t('GenericNegativeFeedbackTryAgain'));
      }
    } else {
      if (containerResponse?.data?.error) {
        toaster.flashError(containerResponse.data.error);
        return;
      }

      toaster.flashError(t('GenericNegativeFeedbackTryAgain'));
    }
  };

  const handleError = () => {
    toaster.flashError(t('GenericNegativeFeedback'));
    updateScanning(false);
    navigate.push('/admin', { replace: true });
  };

  return (
    <Page>
      <Navigation
        onBack={() => {
          updateScanning(false);
          navigate.go(-1);
        }}
      >
        {t('GetInfoContainerTitle')}
      </Navigation>

      {isScanning ? (
        <>
          <Scan showViewFinder={false} delay={300} onError={handleError} onScan={handleScan} />
          <Container>
            {container ? (
              <>
                <MultipleStatCard
                  sameLine={false}
                  title={t('ContainerDetails')}
                  stats={[
                    {
                      name: t('Name'),
                      metric: container.name,
                      highlighted: false,
                      metricTextConfig: 'main-text',
                    },
                    {
                      name: t('Status'),
                      metric: container.status,
                      highlighted: false,
                      metricTextConfig: 'main-text',
                    },
                    {
                      name: t('ProductCode'),
                      metric: container.product_type.code,
                      highlighted: false,
                      metricTextConfig: 'main-text',
                    },
                  ]}
                />
                <MultipleStatCard
                  sameLine={false}
                  title={t('CurrentOwnerDetails')}
                  stats={[
                    {
                      name: t('Name'),
                      metric: containerUser.name,
                      highlighted: false,
                      metricTextConfig: 'main-text',
                    },
                    {
                      name: t('Email'),
                      metric: containerUser.email,
                      highlighted: false,
                      metricTextConfig: 'main-text',
                    },
                    {
                      name: t('UserType'),
                      metric: containerUser.type,
                      highlighted: false,
                      metricTextConfig: 'main-text',
                    },
                  ]}
                />
                <Text
                  config="main-text-link"
                  isLink
                  align="center"
                  onClick={() => setIsModalVisible(true)}
                >
                  {t('ViewContainerJourney')}
                </Text>
              </>
            ) : (
              <Placeholder
                icon="assets/scanCode.svg"
                title={t('GetInfoContainerTitle')}
                description={t('GetInfoContainerDescription')}
              />
            )}
          </Container>
        </>
      ) : (
        <SpinnerContainer>
          <IonSpinner color={Colors.mainColor} />
        </SpinnerContainer>
      )}
      <Modal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        title={container ? container.name : t('ContainerJourney')}
      >
        {getJourney.isLoading || !isValid ? (
          <SpinnerContainer>
            <IonSpinner color={Colors.mainColor} />
          </SpinnerContainer>
        ) : (
          <>
            {containerJourney !== null && containerJourney.length > 0 ? (
              containerJourney.map((journey: any, index: number) => {
                return (
                  <StyledCard key={index} isListItem full>
                    <DateContainer>
                      <Text config="secondary-text-title" align="left">
                        {format(new Date(journey.created_at), 'dd/MM/yyyy')}
                      </Text>
                      <Text config="secondary-text-title" align="left">
                        {format(new Date(journey.created_at), 'HH:mm')}
                      </Text>
                    </DateContainer>
                    <Movements>
                      <Text config="secondary-text">
                        <strong>De: </strong>
                        {journey.giver.name}
                      </Text>
                      <Text config="secondary-text">
                        <strong>A: </strong>
                        {journey.receiver.name}
                      </Text>
                    </Movements>
                  </StyledCard>
                );
              })
            ) : (
              <StyledCard listItem isFull={false}>
                <Text confi="secondary-text-title" align="center">
                  {t('ContainerJourney.noJourney')}
                </Text>
              </StyledCard>
            )}
          </>
        )}
      </Modal>
    </Page>
  );
};

export default GetInfoContainer;
