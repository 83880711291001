import ApiCaller from '../lib/ApiCaller';

class OauthApplicationAuthorizationApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getApplicationInfo(clientId: string, redirectUri: string): Promise<any> {
    const url = `/v2/oauth/info?client_id=${clientId}&redirect_uri=${redirectUri}`;
    return this.apiCaller.call(url, 'GET');
  }

  getAuthorizationStatus(clientId: string, redirectUri: string): Promise<any> {
    const url = `/v2/oauth/status?client_id=${clientId}&redirect_uri=${redirectUri}`;
    return this.apiCaller.call(url, 'GET');
  }

  authorizeApplication(clientId: string, redirectUri: string): Promise<any> {
    const data = {
      client_id: clientId,
      redirect_uri: redirectUri,
    };

    return this.apiCaller.call(`/v2/oauth/authorize`, 'POST', data);
  }
}

export default OauthApplicationAuthorizationApi;
