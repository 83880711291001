import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { isPlatform } from '@ionic/react';
import { setupConfig } from '@ionic/core';
import { AppTrackingTransparency } from 'capacitor-ios-app-tracking';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { ENV } from '@/env/env';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Theme variables */
import '@/theme/variables.css';
import '@/theme/tailwind.css';

import * as serviceWorker from '@/serviceWorker';

import ServiceApi from '@/api/ServiceApi';

import ApiCaller from '@/lib/ApiCaller';
import Settings from '@/lib/settings';
import { queryClient } from '@/lib/react-query';
import { AppWithContext } from '@/components';

setupConfig({
  mode: 'md',
  swipeBackEnabled: true,
  animated: false,
});

type AppProviderProps = {
  store: any;
  children: React.ReactNode;
};

const stripePromise = loadStripe(ENV.PUBLIC_STRIPE_KEY, {
  stripeAccount: ENV.STRIPE_ACCOUNT_ID,
});

export const AppProvider = ({ children, store }: AppProviderProps) => {
  const [response, setResponse] = React.useState<any>();

  React.useEffect(() => {
    if (isPlatform('ios') && !isPlatform('mobileweb')) {
      AppTrackingTransparency.getTrackingStatus().then((res: any) => setResponse(res));
    }
  }, []);

  React.useEffect(() => {
    if (
      response &&
      response.status === 'unrequested' &&
      isPlatform('ios') &&
      !isPlatform('mobileweb')
    ) {
      AppTrackingTransparency.requestPermission();
    }
  }, [response]);

  React.useEffect(() => {
    const serviceApi = new ServiceApi(new ApiCaller());

    serviceApi
      .getVersion()
      .then((data: any) => {
        if (data.version !== Settings.getVersion()) {
          serviceWorker.unregister();
          document.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Elements stripe={stripePromise}>
          <AppWithContext>
            <ToastContainer
              style={{
                top: 'var(--ion-safe-area-top, 24px)',
              }}
            />
            {children}
          </AppWithContext>
        </Elements>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
