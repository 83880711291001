import { Suspense } from 'react';
import { IonReactRouter } from '@ionic/react-router';
import { IonRouterOutlet } from '@ionic/react';

import { LastLocationProvider } from 'react-router-last-location';

import Pages from '@/pages';

import { PrivateOauthRoute, PrivateRoute, PublicOauthRoute, PublicRoute } from '@/components';

import LinkListener from '../LinkListener';

import { HowItWorksModal, Loading } from '@/features/shared';

// The screens now comes from their specific features
import { PopularPartners, NewPartners, Home } from '@/features/home';
import { Explorer, PartnerProfile } from '@/features/explorer';

const AppRouter = () => {
  return (
    <Suspense fallback={() => <Loading />}>
      <IonReactRouter>
        <LastLocationProvider>
          <LinkListener />
          <IonRouterOutlet>
            <PublicRoute path="/" component={Pages.Splash} exact />
            <PublicOauthRoute as="customer" path="/oauth" component={Pages.OauthLogin} exact />
            <PublicOauthRoute
              as="customer"
              path="/oauth/register"
              component={Pages.OauthRegister}
              exact
            />
            <PublicOauthRoute
              as="customer"
              path="/oauth/forgotpassword"
              component={Pages.OauthForgotPass}
              exact
            />
            <PrivateOauthRoute
              as="customer"
              path="/oauth/authorize"
              component={Pages.OauthAuthorize}
              exact
            />
            <PublicRoute path="/oauth/error" component={Pages.OauthError} exact />
            <PublicRoute path="/guestMap" component={Pages.GuestMap} exact />
            <PublicRoute path="/tutorial" component={Pages.Tutorial} exact />
            <PublicRoute path="/login" component={Pages.Login} exact />
            <PublicRoute path="/register" component={Pages.Register} exact />
            <PublicRoute path="/forgotpassword" component={Pages.ForgotPass} exact />
            <PublicRoute path="/resetpassword/:token" component={Pages.ResetPass} exact />
            <PublicRoute path="/guestMap" component={Pages.GuestMap} exact />
            <PrivateRoute as="customer" path="/customer" component={Home} exact />
            <PrivateRoute
              as="customer"
              path="/self-assign"
              component={Pages.ConfirmationSelfAssign}
              exact
            />
            <PrivateRoute as="customer" path="/populars" component={PopularPartners} exact />
            <PrivateRoute as="customer" path="/news" component={NewPartners} exact />
            <PrivateRoute as="customer" path="/map" component={Explorer} exact />
            <PrivateRoute
              as="customer"
              path="/how-it-works/:section"
              component={HowItWorksModal}
              exact
            />
            <PrivateRoute as="customer" path="/how-it-works" component={HowItWorksModal} exact />
            <PrivateRoute as="customer" path="/profile" component={Pages.Profile} exact />
            <PrivateRoute
              as="customer"
              path="/verify/:email/:token"
              component={Pages.Verify}
              exact
            />
            <PrivateRoute
              as="customer"
              path="/partner/:id/details"
              component={PartnerProfile}
              exact
            />
            <PrivateRoute as="customer" path="/mytuppers" component={Pages.MyTuppers} exact />
            <PrivateRoute as="customer" path="/payment" component={Pages.AddCard} exact />
            <PrivateRoute
              as="customer"
              path="/changePayment"
              component={Pages.ChangePayment}
              exact
            />
            <PrivateRoute as="customer" path="/impact" component={Pages.Impact} exact />
            <PrivateRoute as="customer" path="/profile/edit" component={Pages.EditProfile} exact />
            <PrivateRoute as="restaurant" path="/typeqr" component={Pages.TypeQr} exact />
            <PrivateRoute as="restaurant" path="/qrscan" component={Pages.QrScan} exact />
            <PrivateRoute
              as="restaurant"
              path="/takeaway/scan"
              component={Pages.PartnerScanner}
              exact
            />
            <PrivateRoute
              as="restaurant"
              path="/delivery/lend/select"
              component={Pages.SelectLend}
              exact
            />
            <PrivateRoute
              as="restaurant"
              path="/takeaway/client/type"
              component={Pages.ManualClient}
              exact
            />
            <PrivateRoute
              as="restaurant"
              path="/takeaway/summary"
              component={Pages.ScanSummary}
              exact
            />
            <PrivateRoute
              as="restaurant"
              path="/delivery/summary"
              component={Pages.ScanSummary}
              exact
            />
            <PrivateRoute as="restaurant" path="/restaurant" component={Pages.Restaurant} exact />
            <PrivateRoute as="restaurant" path="/restoptions" component={Pages.RestOptions} exact />
            <PrivateRoute
              as="restaurant"
              path="/restaurant/profile"
              component={Pages.RestProfile}
              exact
            />
            <PrivateRoute
              as="restaurant"
              path="/restaurant/impact"
              component={Pages.RestImpact}
              exact
            />
            <PrivateRoute as="admin" path="/admin/loginAs" component={Pages.LoginAs} exact />
            <PrivateRoute as="admin" path="/admin/billing/sepa" component={Pages.AddSepa} exact />
            <PrivateRoute as="admin" path="/admin/profile" component={Pages.AdminProfile} exact />

            <PrivateRoute
              as="admin"
              path="/admin/restaurantList"
              component={Pages.RestaurantList}
              exact
            />
            <PrivateRoute as="admin" path="/admin/userList" component={Pages.UserList} exact />
            <PrivateRoute
              as="admin"
              path="/admin/productList"
              component={Pages.ProductList}
              exact
            />
            <PrivateRoute as="admin" path="/admin/planList" component={Pages.PlanList} exact />
            <PrivateRoute
              as="admin"
              path="/admin/productTypeList"
              component={Pages.RetrieveProductTypeList}
              exact
            />
            <PrivateRoute
              as="admin"
              path="/admin/subscriptionList"
              component={Pages.SubscriptionList}
              exact
            />
            <PrivateRoute
              as="admin"
              path="/admin/subscriptionItemList"
              component={Pages.SubscriptionItemList}
              exact
            />
            <PrivateRoute
              as="admin"
              path="/admin/selectUserType"
              component={Pages.SelectUserTypeAdmin}
              exact
            />
            <PrivateRoute as="admin" path="/admin/billing" component={Pages.AdminBilling} exact />
            <PrivateRoute
              as="admin"
              path="/admin/billing/summary"
              component={Pages.AdminBillingSummary}
              exact
            />
            <PrivateRoute as="admin" path="/restaurant/add" component={Pages.AddRestaurant} exact />
            <PrivateRoute
              as="admin"
              path="/admin/restaurant/edit"
              component={Pages.EditRestaurant}
              exact
            />
            <PrivateRoute
              as="admin"
              path="/admin/restaurant/containers/assign"
              component={Pages.AssignContainers}
              exact
            />
            <PrivateRoute
              as="admin"
              path="/admin/user/containers/collect"
              component={Pages.AssignContainers}
              exact
            />
            <PrivateRoute
              as="admin"
              path="/admin/user/containers/lend"
              component={Pages.AssignContainers}
              exact
            />
            <PrivateRoute
              as="admin"
              path="/admin/restaurant/containers/retrieve"
              component={Pages.RetrieveContainers}
              exact
            />
            <PrivateRoute
              as="admin"
              path="/admin/user/containers/retrieve"
              component={Pages.RetrieveContainers}
              exact
            />
            <PrivateRoute
              as="admin"
              path="/admin/user/containers/assign"
              component={Pages.RetrieveContainers}
              exact
            />
            <PrivateRoute
              as="admin"
              path="/admin/restaurant/summary"
              component={Pages.MovementSummary}
              exact
            />
            <PrivateRoute
              as="admin"
              path="/admin/user/summary"
              component={Pages.AdminLendSummary}
              exact
            />
            <PrivateRoute
              as="admin"
              path="/admin/container/info"
              component={Pages.GetInfoContainer}
              exact
            />
            <PrivateRoute
              as="admin"
              path="/admin/restaurant/movements"
              component={Pages.MovementHistory}
              exact
            />

            <PrivateRoute as="admin" path="/admin" component={Pages.Admin} exact />
          </IonRouterOutlet>
        </LastLocationProvider>
      </IonReactRouter>
    </Suspense>
  );
};

export default AppRouter;
