import React, { useRef } from 'react';
import styled from 'styled-components';
import { IonFabButton, IonIcon } from '@ionic/react';
import { useHistory } from 'react-router';
import Settings from '../lib/settings';
import { arrowForward } from 'ionicons/icons';
import { StatusBar } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';

import { Slide, Carrousel, Text, Button } from '@bumerang-kit/components';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import { Page } from '@bumerang-kit/layouts';
import Analyzer from '../lib/common/helpers';
import { getCurrentSession } from '@/helpers/session';

const analyzer = new Analyzer();
const NextButtonContainer = styled.div`
  position: absolute;
  bottom: var(--ion-safe-area-bottom, 16px);
  right: ${Spacing.s};
  z-index: 10;
`;

const StyledButton = styled(Button)`
  min-width: 160px;
`;

const StyledPage = styled(Page)`
  padding-top: var(--ion-safe-area-top);
  padding-bottom: var(--ion-safe-area-bottom);
`;

const Tutorial: React.FC = () => {
  const { t } = useTranslation();
  if (Capacitor.isPluginAvailable('StatusBar'))
    StatusBar.setBackgroundColor({ color: Colors.white });

  const currentUser = getCurrentSession();
  const sliderRef = useRef<any>(null);
  const navigate = useHistory();

  return (
    <StyledPage style={{ overflow: 'auto' }}>
      <Carrousel reference={sliderRef}>
        <Slide
          onSkip={() => {
            Settings.markTutorialAsSeen();
            analyzer.trackSkippedOnboarding(currentUser.id, 1);
          }}
          onSkipLink="/customer"
          imgUrl="/assets/tutorial/onboarding_1.png"
        >
          <>
            <Text color={Colors.mainColor} config="onboarding-title">
              {t('TutorialTitle')}
            </Text>
            <Text config="main-text">{t('TutorialSubtitle')}</Text>
            <NextButtonContainer>
              <IonFabButton
                color="primary"
                onClick={() => {
                  sliderRef?.current?.slickNext();
                }}
              >
                <IonIcon size="medium" icon={arrowForward} />
              </IonFabButton>
            </NextButtonContainer>
          </>
        </Slide>
        <Slide
          onSkip={() => {
            Settings.markTutorialAsSeen();
            analyzer.trackSkippedOnboarding(currentUser.id, 2);
          }}
          onSkipLink="/customer"
          imgUrl="/assets/tutorial/onboarding_2.png"
        >
          <>
            <Text color={Colors.mainColor} config="onboarding-title">
              {t('TutorialShowQr')}
            </Text>
            <Text config="main-text">{t('TutorialShowQrExplanation')}</Text>
            <NextButtonContainer>
              <IonFabButton
                color="primary"
                onClick={() => {
                  sliderRef?.current?.slickNext();
                }}
              >
                <IonIcon size="medium" icon={arrowForward} />
              </IonFabButton>
            </NextButtonContainer>
          </>
        </Slide>
        <Slide
          onSkip={() => {
            Settings.markTutorialAsSeen();
            analyzer.trackSkippedOnboarding(currentUser.id, 3);
          }}
          onSkipLink="/customer"
          imgUrl="/assets/tutorial/onboarding_3.png"
        >
          <>
            <Text color={Colors.mainColor} config="onboarding-title">
              {t('TutorialReturn')}
            </Text>
            <Text config="main-text">{t('TutorialReturnExplanation')}</Text>
            <NextButtonContainer>
              <StyledButton
                onClick={() => {
                  Settings.markTutorialAsSeen();
                  navigate.push('/customer');
                }}
              >
                {t('Start')}
              </StyledButton>
            </NextButtonContainer>
          </>
        </Slide>
      </Carrousel>
    </StyledPage>
  );
};

export default Tutorial;
