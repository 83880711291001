import * as React from 'react';
import Icon from '@bumerang-kit/components/Icon';
import { Deliveries } from '@/features/shared';

export type DeliveryIconProps = {
  name: Deliveries;
  onClick: () => void;
};

const getIconName = (name: Deliveries) => {
  return name.replace(' ', '_').toLowerCase();
};

const DeliveryIcon = (props: DeliveryIconProps) => {
  const { name, onClick } = props;

  return <Icon type={'none'} isRounded name={getIconName(name)} onClick={() => onClick()} />;
};

export default DeliveryIcon;
