import Distance from '../helpers/Distance';

interface RestaurantInfo {
  id?: string;
  email: string;
  userId?: string;
  address: string;
  name: string;
  lat: number;
  lng: number;
  placeid?: string;
  phone?: string;
  hours?: Array<any>;
  photoUrl: Array<any>;
  rating?: number;
  website?: string;
  discount?: boolean;
  displayed: boolean;
  type?: Array<any>;
  description?: string;
  active?: boolean;
  delivery?: any;
  currentDistanceInKm?: number;
  productTypeList?: Array<any>;
  productGroupTypeList?: Array<any>;

  assigned_containers?: Array<any>;
}

class Restaurant {
  id?: string;
  email: string;
  userId?: string;
  address: string;
  displayed: boolean;
  name: string;
  lat: number;
  lng: number;
  phone?: string;
  hours?: Array<any>;
  photoUrl: Array<any>;
  rating?: number;
  website?: string;
  placeid?: string;
  active?: boolean;
  discount?: boolean;
  type?: Array<any>;
  description?: string;
  delivery?: any;
  productTypeList?: Array<any>;
  productGroupTypeList?: Array<any>;
  assigned_containers?: Array<any>;

  constructor(resInfo: RestaurantInfo) {
    this.id = resInfo.id;
    this.userId = resInfo.userId;
    this.email = resInfo.email;
    this.address = resInfo.address;
    this.displayed = resInfo.displayed;
    this.name = resInfo.name;
    this.lat = resInfo.lat;
    this.lng = resInfo.lng;
    this.placeid = resInfo.placeid;
    this.phone = resInfo.phone;
    this.hours = resInfo.hours;
    this.photoUrl = resInfo.photoUrl;
    this.rating = resInfo.rating;
    this.website = resInfo.website;
    this.active = resInfo.active;
    this.type = resInfo.type;
    this.description = resInfo.description;
    this.discount = resInfo.discount;
    this.delivery = resInfo.delivery;
    this.productTypeList = resInfo.productTypeList;
    this.productGroupTypeList = resInfo.productGroupTypeList;
    this.assigned_containers = resInfo.assigned_containers;
  }

  toObject(): RestaurantInfo {
    return {
      id: this.id,
      email: this.email,
      userId: this.userId,
      address: this.address,
      displayed: this.displayed,
      name: this.name,
      lat: this.lat,
      lng: this.lng,
      placeid: this.placeid,
      phone: this.phone,
      hours: this.hours,
      photoUrl: this.photoUrl,
      rating: this.rating,
      website: this.website,
      type: this.type,
      description: this.description,
      discount: this.discount,
      delivery: this.delivery,
      productTypeList: this.productTypeList,
      productGroupTypeList: this.productGroupTypeList,
      assigned_containers: this.assigned_containers,
    };
  }

  getCurrentDistanceInKm(distance: Distance, currentLat: number, currentLng: number): number {
    return distance!.getDistanceFromLatLonInKm({
      lat1: currentLat,
      lon1: currentLng,
      lat2: this.lat,
      lon2: this.lng,
    });
  }
}

export default Restaurant;
