import React from 'react';
import { IonRouterLink } from '@ionic/react';
import styled from 'styled-components';

import { Text, Icon } from '../../atoms';
import { Colors, Spacing } from '../../../foundations';

const StyledIonRouterLink = styled(IonRouterLink)`
  width: 100%;
`;

const TabButton = styled.div`
  padding: ${Spacing.xs} ${Spacing.xxs};
  flex: 1;
  min-height: 48px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  ${(props: { isActive?: boolean; position?: string }) =>
    props.isActive && props.position === 'top'
      ? `
       border-bottom: ${Colors.mainColorActive} 2px solid;`
      : null}
`;

const StyledIcon = styled(Icon)`
  margin-bottom: ${Spacing.xxs};
`;

const Tab = ({ children, icon, link, isActive, onClick, position }: any) => (
  <StyledIonRouterLink routerLink={link}>
    <TabButton isActive={isActive} position={position} onClick={() => (onClick ? onClick() : null)}>
      {icon && (
        <StyledIcon color={isActive ? Colors.mainColor : Colors.mainColorDisabled} name={icon} />
      )}
      <Text
        align="center"
        color={isActive ? Colors.mainColor : Colors.mainColorDisabled}
        config={position === 'top' ? 'main-text-title' : 'small-text-title'}
      >
        {children}
      </Text>
    </TabButton>
  </StyledIonRouterLink>
);

export default Tab;
