import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from '@bumerang-kit/components';

type ScheduleProps = {
  hours?: Array<any>;
};

const getFormattedHours = (hours: Array<any>): Array<any> => {
  return hours.map((hour: string) => {
    const upperCasedHours = hour.charAt(0).toUpperCase() + hour.slice(1);
    const days = upperCasedHours.split(':').shift();
    const formattedHours = upperCasedHours.substring(
      upperCasedHours.indexOf(':') + 1,
      upperCasedHours.length + 1
    );
    return [days, formattedHours];
  });
};

const Schedule = (props: ScheduleProps) => {
  const { hours } = props;
  const { t } = useTranslation();
  const hasHours = hours && Object.keys(hours).length > 0;

  if (!hours || !hasHours) {
    return null;
  }

  const hoursList = getFormattedHours(hours);

  return (
    <div>
      <Line />
      <header className="text-m font-montserrat font-bold mb-1">
        {t('PartnerProfile.Schedule')}{' '}
      </header>
      <div className="font-montserrat text-s ">
        {hoursList.map((day: any) => {
          const hours = day[1].split(',');
          return (
            <div key={day[0]} className="flex my-1.5 justify-between">
              <p className="text-s font-montserrat">{t(day[0].toLowerCase())}:</p>
              <p className="text-s font-montserrat">
                {hours[0]}
                <br />
                {hours[1] && hours[1]}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Schedule;
