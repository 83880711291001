import React from 'react';
import styled from 'styled-components';
import './styles/General.css';
import './styles/ForgotPass.css';
import AuthApi from '../api/AuthApi';
import ApiCaller from '../lib/ApiCaller';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Input, Navigation, Button } from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import { Spacing, Colors } from '@bumerang-kit/foundations';
import { Toaster } from '@bumerang-kit/helpers';

const StyledForm = styled.form`
  margin-top: ${Spacing.l};
  margin-bottom: ${Spacing.xxxl};
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.s};
`;

const StyledPage = styled(Page)`
  background-color: ${Colors.backgroundBlue};
`;

const toaster = new Toaster();

const ForgotPass = () => {
  const [email, setEmail] = React.useState<string>();
  const { t } = useTranslation();
  const navigate = useHistory();

  const isFormValid = !email;

  const remember = (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      return;
    }
    const authApi = new AuthApi(new ApiCaller());
    authApi
      .resetPassword(email)
      .then((response: any) => {
        const { success, data } = response;

        if (!success) {
          toaster.flashError(data.error);
          return;
        }

        toaster.flashSuccess(t('ForgotPasswordPositiveFeedback'));

        navigate.push('/login');
      })
      .catch(() => {
        toaster.flashError(t('ForgotPasswordNegativeFeedback'));
      });
  };

  return (
    <StyledPage>
      <Navigation backgroundColor={Colors.backgroundBlue} onBack={() => navigate.go(-1)}>
        {t('ForgotPasswordPageTitle')}
      </Navigation>
      <Container backgroundColor={Colors.backgroundBlue}>
        <StyledForm onSubmit={remember}>
          <Input
            value={email}
            label="Email"
            name="email"
            onChange={(e: any) => setEmail(e.detail.value!)}
          />
          <StyledButton disabled={isFormValid} type="submit">
            {t('Send')}
          </StyledButton>
        </StyledForm>
      </Container>
    </StyledPage>
  );
};

export default ForgotPass;
