import React from 'react';
import styled from 'styled-components';
import { Icon, Line, Modal, Text } from '../../atoms';
import { Sizes, Spacing } from '../../../foundations';
import { Trans, useTranslation } from 'react-i18next';
import { ENV } from '../../../../env/env';
import { HelpFooter } from '../index';
import Settings from '../../../../lib/settings';

const StyledItem = styled.li`
  display: flex;
  width: 100%;
  align-content: center;
`;

const IconContainer = styled.div`
  height: ${Sizes.xl};
  width: ${Sizes.xl};
  margin-right: ${Spacing.l};
`;

const List = styled.ul`
  padding: 0;
  list-style-type: none;
  justify-content: space-between;
`;

const StyledImg = styled.img`
  margin: ${Spacing.m} auto;
`;

const Wrapper = styled.div`
  display: flex;
  align-content: center;
`;

const HelpModal = ({ isVisible, onClose, currentUser }: any) => {
  const { t } = useTranslation();
  return (
    <Modal title={t('HowDoesItWork')} isVisible={isVisible} onClose={() => onClose()}>
      <Wrapper>
        <StyledImg src={'assets/show-qr.svg'} />
      </Wrapper>
      <Text config={'screen-title'}>{t('HelpModalFirstTitle')}</Text>
      <hr />
      <Text config={'main-text-title'}>{t('HelpModalForTakeaway')}</Text>
      <Text config={'main-text'}>{t('HelpModalFirstSubtitle')}</Text>
      <hr />
      <Text config={'main-text-title'}>{t('HelpModalForDelivery')}</Text>
      <Text config={'main-text'}>
        <Trans
          i18nKey={'HelpModalFirstParagraph'}
          defaults={
            'Copia y envía al restaurante el código que verás debajo del código QR ya sea para pedir online o si necesitas que alguien recoja un pedido por ti. <a target="_blank" rel="noopener noreferrer" href="https://www.notion.so/C-mo-hacer-un-pedido-delivery-con-B-merang-c32b882630cc4f78924e5e09c667a822" > ¿Cómo funciona el delivery? </a>'
          }
          components={{ a: <a /> }}
        />
      </Text>
      <hr />
      <Wrapper>
        <StyledImg src={'assets/framed-bowlWithBackground.svg'} />
      </Wrapper>
      <Text config={'screen-title'}>{t('HelpModalSecondTitle')}</Text>
      <hr />
      <Text config={'main-text'}>{t('HelpModalSecondSubtitle')}</Text>
      <br />
      <List>
        <StyledItem>
          <IconContainer>
            <Icon name={'microwave'} />
          </IconContainer>
          <Text>{t('HelpModalFirstListItem')}</Text>
        </StyledItem>
        <hr />
        <StyledItem>
          <IconContainer>
            <Icon name={'cutlery'} />
          </IconContainer>
          <Text>{t('HelpModalSecondListItem')}</Text>
        </StyledItem>
        <hr />
        <StyledItem>
          <IconContainer>
            <Icon name={'no-water'} />
          </IconContainer>
          <Text>{t('HelpModalThirdListItem')}</Text>
        </StyledItem>
        <hr />
        <StyledItem>
          <IconContainer>
            <Icon name={'backpack'} />
          </IconContainer>
          <Text>{t('HelpModalForthListItem')}</Text>
        </StyledItem>
      </List>
      <Wrapper>
        <StyledImg src={'assets/framed-ok-bowls.svg'} />
      </Wrapper>
      <Text config={'screen-title'}>{t('HelpModalThirdTitle')}</Text>
      <hr />
      <Text config={'main-text'}>
        <Trans
          i18nKey={'HelpModalThirdParagraph'}
          defaults={
            '· Puedes devolver los envases en cualquier punto de la red Bûmerang <line /> · Puedes usar el servicio sin pagar nada durante 15 días. <line/>· Si pasados 15 días no has devuelto el envase cobramos un depósito de {{penaltyPrice}}€ por envase que será devuelto en el momento que el envase vuelve. No te preocupes que te lo recordaremos.'
          }
          components={{ line: <hr /> }}
          values={{
            penaltyPrice: Settings.getPenaltyPrice(),
          }}
        />
      </Text>
      <hr />
      <Line />
      <hr />

      <HelpFooter crispId={ENV.CRISP_ID} user={currentUser} />
    </Modal>
  );
};

export default HelpModal;
