import ApiCaller from '../lib/ApiCaller';

class PaymentApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getClientSecret(): Promise<string> {
    return this.apiCaller
      .call('/v1/payment/intent', 'GET')
      .then((data) => {
        if (!data || data.error) {
          return;
        }

        return data.client_secret;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getPayment(): Promise<any> {
    return this.apiCaller.call('/v1/payment/getPayment', 'GET');
  }

  async getPlans(productId: string): Promise<any> {
    const response = await this.apiCaller.call(`/v2/payment/products/${productId}/plans`, 'GET');
    if (!response?.success) {
      throw new Error(response?.data?.error);
    }
    return response;
  }

  async getProducts(): Promise<any> {
    const response = await this.apiCaller.call('/v2/payment/products', 'GET');
    if (!response?.success) {
      throw new Error(response?.data?.error);
    }
    return response;
  }

  async getSubscriptions(customerId: string): Promise<any> {
    const response = await this.apiCaller.call(
      `/v2/payment/customers/${customerId}/subscriptions`,
      'GET'
    );
    if (!response?.success) {
      throw new Error(response?.data ? response?.data?.error : null);
    }
    return response;
  }

  async getSubscriptionItems(customerId: string, subscriptionId: string): Promise<any> {
    const response = await this.apiCaller.call(
      `/v2/payment/customers/${customerId}/subscriptions/${subscriptionId}/items`,
      'GET'
    );
    if (!response?.success) {
      throw new Error(response?.data?.error);
    }
    return response;
  }

  getPaymentStatus(): Promise<string> {
    return this.apiCaller
      .call('/v1/payment/status', 'GET')
      .then((data) => {
        if (data.error) {
          alert('Server error');
        } else {
          return data.status;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getClientSecretWithoutUser(customerStripeId: any): Promise<any> {
    return this.apiCaller
      .call(`/v1/payment/getClientSecret/${customerStripeId}`, 'GET')
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  async setupSepa(customerStripeId: string, defaultPaymentMethod: string): Promise<any> {
    const response = await this.apiCaller.call('/v1/payment/sepa', 'POST', {
      customerStripeId,
      defaultPaymentMethod,
    });
    if (!response?.success) {
      throw new Error(response?.data?.error);
    }
    return response;
  }

  updatePaymentStatus(status: string): Promise<any> {
    return this.apiCaller
      .call('/v1/payment/updateStatus', 'POST', { status })
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  }

  removeCard(): Promise<any> {
    return this.apiCaller.call('/v1/payment/removeCard', 'GET');
  }

  async addProductSubscription(
    userId: string,
    productTypeId: string,
    stripePlanId?: string,
    stripeSubscriptionItemId?: string
  ): Promise<any> {
    const response = await this.apiCaller.call('/v2/payment/product_subscriptions', 'POST', {
      user_id: userId,
      product_type_id: productTypeId,
      stripe_plan_id: stripePlanId,
      stripe_subscription_item_id: stripeSubscriptionItemId,
    });
    if (!response?.success) {
      throw new Error(response?.data?.error);
    }
    return response;
  }
}

export default PaymentApi;
