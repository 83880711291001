import { useRef, useState } from 'react';
import useInView from 'react-cool-inview';

const useInfiniteLoading = (props: { getItems: any; limit: number; initialEnable?: boolean }) => {
  const { getItems, limit, initialEnable } = props;
  const [items, setItems] = useState([] as any[]);
  const fromToLoad = useRef(0);
  const initialFromLoaded = useRef(false);
  const [hasNext, setHasNext] = useState(true);
  const [enable, setEnable] = useState(initialEnable);
  const [isLoading, setIsLoading] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(() => fromToLoad.current !== 0);

  const loadItems = async (from: any, itemCombineMethod: any) => {
    if (!enable) return;
    setIsLoading(true);
    const data = await getItems({ from: fromToLoad.current });
    fromToLoad.current = from;
    setHasNext(data.pagination.from + data.pagination.length < data.pagination.total); /* 3 */
    setHasPrevious(data.pagination.from > 0); /* 4 */
    setItems((prevItems: any[]) => {
      /* 5 */
      return itemCombineMethod === 'prepend'
        ? [...data.items, ...prevItems]
        : [...prevItems, ...data.items];
    });
    setIsLoading(false);
  };

  const loadNext = async () => {
    await loadItems(Number(fromToLoad.current) + limit, 'append');
  };

  const loadPrevious = async () => {
    await loadItems(Number(fromToLoad.current) - limit, 'prepend');
  };

  const { observe, unobserve } = useInView({
    threshold: 0.25, // Default is 0
    onEnter: async () => {
      if (!fromToLoad.current) return;
      unobserve();
      await loadNext();
      observe();
    },
  });

  const resetPagination = async () => {
    unobserve();
    fromToLoad.current = 0;
    initialFromLoaded.current = false;
    setItems([]);
    setHasNext(true);
    setHasPrevious(false);
    await loadNext();
    observe();
  };

  const setEnableTrigger = async (value: boolean) => {
    setEnable(value);
    if (value) {
      observe();
    } else {
      unobserve();
    }
  };

  return {
    items,
    hasNext,
    hasPrevious,
    loadNext,
    loadPrevious,
    loadMoreRef: observe,
    resetPagination,
    isLoading,
    setEnable: setEnableTrigger,
    enable,
  };
};
export default useInfiniteLoading;
