import * as React from 'react';
import Icon from '@bumerang-kit/components/Icon';
import { ProductTypeGroup } from '../../types';

type ProductIconProps = {
  productGroup: Array<ProductTypeGroup>;
  isRounded?: boolean;
  isPointer?: boolean;
  className?: string;
  onClick?: () => void;
};

const getIconProperties = (productGroup: Array<ProductTypeGroup>): any => {
  if (productGroup.length > 1) {
    return {
      name: 'mixWithBackground',
    };
  }

  if (productGroup[0] === 'Bowl')
    return {
      name: 'bowlWithBackground',
    };

  if (productGroup[0] === 'Cup')
    return {
      name: 'cupWithBackground',
    };
};

const ProductIcon = (props: ProductIconProps) => {
  const { productGroup, className, onClick } = props;

  if (productGroup.length === 0 || !productGroup) return null;

  const { name } = getIconProperties(productGroup);

  return (
    <div className={`flex justify-center align-middle items-center ${className}`}>
      <Icon onClick={onClick} name={name} type={'none'} size={'40px'} />
    </div>
  );
};

export default ProductIcon;
