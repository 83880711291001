import * as React from 'react';
import ReactDOM from 'react-dom';
import { useMediaQuery } from 'react-responsive';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';

import App from './App';
import './i18n';

import * as serviceWorker from './serviceWorker';

import reportWebVitals from './reportWebVitals';
import configureStore from './store/store';

import { Layout, Wrapper } from '@bumerang-kit/layouts';
import { Colors } from '@bumerang-kit/foundations';

const store = configureStore();

const AppWithLayout = () => {
  if (Capacitor.isPluginAvailable('StatusBar'))
    StatusBar.setBackgroundColor({ color: Colors.backgroundBlue });

  const isMobile = useMediaQuery({ query: '(max-width: 823px)' });

  return (
    <React.StrictMode>
      <Layout>
        <Wrapper isResponsive={isMobile}>
          <App store={store} />
        </Wrapper>
      </Layout>
    </React.StrictMode>
  );
};

ReactDOM.render(<AppWithLayout />, document.getElementById('root'));

reportWebVitals();
serviceWorker.register();
