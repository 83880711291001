import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IonContent } from '@ionic/react';
import { useQuery } from 'react-query';
import format from 'date-fns/format';
import { Spacing } from '@bumerang-kit/foundations';
import { Card, Navigation, Text } from '@bumerang-kit/components';
import { Page } from '@bumerang-kit/layouts';
import LogisticsApi from '../../apiClient/LogisticsApi';
import Settings from '../../lib/settings';
import { getCurrentSession } from '@/helpers/session';
import MovementV2 from '../../lib/common/models/movementV2';
import ContainerV2Collection from '../../lib/common/models/containerV2Collection';

const StyledCard = styled(Card)`
  height: 100px;
  display: flex;
  padding: ${Spacing.s};
  position: relative;
`;

const ContainerCount = styled.div`
  float: left;
`;

const DateContainer = styled(Text)`
  position: absolute;
  top: ${Spacing.s};
  right: ${Spacing.s};
`;
const Count = styled.div`
  display: flex;
`;

const Status = styled(Text)`
  position: absolute;
  bottom: ${Spacing.s};
  right: ${Spacing.s};
`;

const MovementHistory = ({ lendConfig, setLendConfig }: any) => {
  const navigate = useHistory();
  const currentUser = getCurrentSession();
  const logisticsApi = new LogisticsApi(currentUser.token);
  const [restaurant, setRestaurant] = useState<any>();
  const [movements, setMovements] = useState<MovementV2[]>([]);

  const { t } = useTranslation();

  const setLendConfigStorage = (state: any) => {
    Settings.saveStateInSession(state);
    setLendConfig(state);
  };

  useQuery('paymentStatus', async () => await logisticsApi.getMovementHistory(restaurant?.userId), {
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
    onSuccess: (response: any) => setMovements(response.data),
    enabled: restaurant !== undefined,
  });

  useEffect(() => {
    setRestaurant(lendConfig?.restaurant);
  }, [lendConfig]);

  return (
    <Page>
      <Navigation
        onBack={() => {
          setLendConfigStorage({
            ...lendConfig,
            adminAction: 'movement_history',
            restaurant: undefined,
          });
          navigate.go(-1);
        }}
      >
        {restaurant ? restaurant?.name : t('MovementHistory')}
      </Navigation>
      <IonContent>
        {movements.map((movement: any, index: number) => {
          const productTypeCount = new ContainerV2Collection(
            movement.container_movements
          ).getCountByProductType();
          return (
            <StyledCard key={index} full listItem>
              <ContainerCount>
                {Object.keys(productTypeCount).map((productCode: any, index: number) => (
                  <Count key={index}>
                    <Text bold align="left" config="secondary-text-title">
                      {productCode}:&nbsp;
                    </Text>
                    <Text> {productTypeCount[productCode]}</Text>
                  </Count>
                ))}
              </ContainerCount>
              <DateContainer bold={false} config="small-text">
                {format(new Date(movement.created_at), 'dd/MM/yyyy')}
              </DateContainer>
              <Status config="secondary-text-title">{movement.status}</Status>
            </StyledCard>
          );
        })}
      </IonContent>
    </Page>
  );
};

export default MovementHistory;
