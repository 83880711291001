import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IonContent, IonSpinner } from '@ionic/react';
import styled from 'styled-components';
import { Page } from '@bumerang-kit/layouts';

import { Card, Icon, Navigation, Text } from '@bumerang-kit/components';
import ApiCaller from '../../lib/ApiCaller';
import PaymentApi from '../../api/PaymentApi';
import { getCurrentSession } from '@/helpers/session';
import Settings from '../../lib/settings';
import { Spacing, Colors } from '@bumerang-kit/foundations';
import { Toaster } from '@bumerang-kit/helpers';
import UserApi from '../../api/UserApi';

const StyledCard = styled(Card)`
  display: flex;
  position: relative;
  max-width: 100%;
`;

const StyledDiv = styled.div`
  padding: ${Spacing.xs};
  flex-grow: 2;
`;

const ArrowContainer = styled.div`
  padding: ${Spacing.xs};
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const StyledSpinner = styled(IonSpinner)`
  margin: 50% auto;
`;

/* eslint-disable camelcase */
interface Subscription {
  subscription_id: string;
  customer_id: string;
  current_period_end: Date;
  current_period_start: Date;
  description: string;
  status: string;
  items: number;
}
/* eslint-enable camelcase */

const toaster = new Toaster();

const SubscriptionList = ({ lendConfig, setLendConfig }: any) => {
  const currentUser = getCurrentSession();
  const navigate = useHistory();
  const location = useLocation();
  const apiCaller = new ApiCaller(currentUser.token);
  const userApi = new UserApi(apiCaller);
  const paymentApi = new PaymentApi(apiCaller);
  const { t } = useTranslation();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const setLendConfigStorage = (state: any) => {
    Settings.saveStateInSession(state);
    setLendConfig(state);
  };

  useEffect(() => {
    const pathname = location?.pathname;
    if (pathname === '/admin/subscriptionList') {
      if (!lendConfig.adminAction) {
        const status = Settings.getStateInSession();
        if (status?.adminAction === 'billing_assign_subscription' && status?.restaurant) {
          setLendConfigStorage({
            ...lendConfig,
            ...status,
          });
        } else {
          setLendConfigStorage({
            ...lendConfig,
            adminAction: null,
          });
          return navigate.replace('/admin');
        }
      }
    }
  }, [location]);

  const onBackButton = async () => {
    setLendConfigStorage({
      ...lendConfig,
      restaurant: null,
    });
    navigate.replace('/admin/restaurantList');
  };

  useEffect(() => {
    (async () => {
      const pathname = location?.pathname;
      if (
        !lendConfig.adminAction ||
        !lendConfig.restaurant ||
        pathname !== '/admin/subscriptionList' ||
        isLoading
      )
        return;

      setIsLoading(true);
      try {
        let userResponse = await userApi.getUserInfo(lendConfig?.restaurant?.userId);
        if (!userResponse.data?.stripeId) {
          userResponse = await userApi.resetStripe(lendConfig?.restaurant?.userId);
        }
        const subscriptionResponse = await paymentApi.getSubscriptions(userResponse.data.stripeId);
        setLendConfigStorage({
          ...lendConfig,
          user: userResponse.data,
        });
        setSubscriptions(subscriptionResponse.data?.subscriptions);
      } catch (error: any) {
        if (error?.message) {
          toaster.flashError(error.message);
        } else toaster.flashError(t('GenericNegativeFeedbackTryAgain'));
        await onBackButton();
      } finally {
        setIsLoading(false);
      }
    })();
  }, [lendConfig, location]);

  const openAction = async (subscription: any) => {
    setLendConfigStorage({
      ...lendConfig,
      subscription,
    });
    navigate.replace('/admin/subscriptionItemList');
  };

  return (
    <Page>
      <Navigation onBack={() => onBackButton()} iconName="sync">
        <Text align="center" config="secondary-screen-title">
          {t('SelectSubscriptionTitle')}
        </Text>
      </Navigation>
      <IonContent>
        {!isLoading ? (
          subscriptions.map((subscription: Subscription) => {
            return (
              <StyledCard
                key={subscription.subscription_id}
                onClick={() => openAction(subscription)}
                full
                listItem
              >
                <StyledDiv>
                  <Text config="main-text-link"> {subscription.description}</Text>
                </StyledDiv>
                <ArrowContainer>
                  <Icon name="arrow" />
                </ArrowContainer>
              </StyledCard>
            );
          })
        ) : (
          <StyledSpinner color={Colors.mainColor} />
        )}
      </IonContent>
    </Page>
  );
};

export default SubscriptionList;
