import React from 'react';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { Trans } from 'react-i18next';

import format from 'date-fns/format';

import { Card } from '../../atoms';
import { Text } from '../../index';
import { Colors } from '../../../foundations';

const StyledCard = styled(Card)`
  display: flex;
  max-height: 64px;
  padding: 10px 20px 13px 16px;
  cursor: pointer;
`;

const StyledDiv = styled.div`
  flex-grow: 1;
  border-right: 1px solid ${Colors.mainColorDisabled};
`;

const StyledIcon = styled(IonIcon)`
  margin-left: 4px;
  width: 27.9px;
  height: 16px;
`;

const StyledStrong = styled.strong`
  font-family: 'Montserrat';
  font-size: 20px;
`;

const FlexedDiv = styled.div`
  display: flex;
  flex-grow: 2;
  justify-content: space-around;
`;

const InfoCard = ({ pending, hasOrdered, toReturn, beforeDate, onClick }: any) => {
  const iconPath = hasOrdered ? 'assets/fullBowl.svg' : 'assets/emptyBowl.svg';

  return (
    <StyledCard isFull={hasOrdered} onClick={() => onClick()}>
      <StyledDiv>
        <Text config="main-text">
          <Trans
            i18nKey={'InfoCardYouHave'}
            defaults={'Tienes <line/> <bold>{{pending}}</bold>'}
            components={{ line: <br />, bold: <StyledStrong /> }}
            values={{ pending: pending }}
          />
          <StyledIcon size="small" icon={iconPath} />
        </Text>
      </StyledDiv>
      {hasOrdered ? (
        <FlexedDiv>
          <Text>
            <Trans
              i18nKey={'InfoCardReturn'}
              defaults={
                "Devuelve <line /> <bold style={{ fontFamily: 'Montserrat' }}> {{toReturn}} </bold>"
              }
              components={{ line: <br />, bold: <StyledStrong /> }}
              values={{ toReturn: toReturn }}
            />
            <StyledIcon size="small" icon={iconPath} />
          </Text>
          <Text>
            <Trans
              i18nKey={'InfoCardBefore'}
              defaults={
                "Antes de <line /> <bold style={{ fontFamily: 'Montserrat' }}> {{date}} </bold>"
              }
              components={{ line: <br />, bold: <StyledStrong /> }}
              values={{ date: format(new Date(beforeDate), 'dd/MM') }}
            />
          </Text>
        </FlexedDiv>
      ) : (
        <FlexedDiv>
          <Text>
            <Trans
              i18nKey={'InfoCardEmpty'}
              defaults={'No tienes Bûmerangs <line /> por devolver'}
              components={{ line: <br /> }}
            />
          </Text>
        </FlexedDiv>
      )}
    </StyledCard>
  );
};

export default InfoCard;
