import React from 'react';
import styled from 'styled-components';
import { Text, Card, Button } from '../../index';
import { Spacing } from '../../../foundations';
import { IonImg } from '@ionic/react';

const EmptyStateMain = styled.main`
  text-align: center;
`;

const EmptyStateTitle = styled(Text)`
  margin: ${Spacing.s} auto;
`;

const StyledCard = styled(Card)`
  position: relative;
  padding: ${Spacing.l};
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.m};
`;

const EmptyState = ({ title, subtitle, buttonText, onClick, src }: any) => {
  return (
    <StyledCard>
      <EmptyStateMain>
        <IonImg className="mx-auto w-20 h-20" src={src} />
        <EmptyStateTitle align="center" config="screen-title">
          {title}
        </EmptyStateTitle>
        {subtitle && (
          <Text align="center" config="main-text">
            {subtitle}
          </Text>
        )}
        {buttonText && (
          <StyledButton onClick={() => onClick()} expand="full">
            {buttonText}
          </StyledButton>
        )}
      </EmptyStateMain>
    </StyledCard>
  );
};

export default EmptyState;
