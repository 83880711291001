export const ENV = {
  VERSION: '1.0',
  API_VERSION: '2.0',
  PUBLIC_STRIPE_KEY: 'pk_test_t1WHSW3dlbCyWaJe8vStOrxf00Gl3m9aWM',
  ENDPOINT: 'https://api-staging.youbumerang.com',
  CURRENT_ENV: 'staging',
  CRISP_ID: 'ed9a8769-ce9b-402c-849d-02d52da57337',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBTjxOuFMQ5di7Cz24GzPDF28wBBUyKKQA',
  ONE_SIGNAL_APP_ID: '08040071-5741-48b3-b40e-3d6890fd2d17',
  ONE_SIGNAL_API_KEY: 'NGIwZjU1NTgtZWNkZS00YjZlLWI3MzgtNzdlYzcyOWRlNmIy',
  FIREBASE_SENDER_ID: '191951374251',
  SEGMENT_WRITE_KEY: '2FlcYL7kAP2mnzlJ2sDmJLMO9vl39RSd',
  AIRTABLE_API_KEY: 'keyHNAlTsIIIX6BIo',
  STRIPE_ACCOUNT_ID: 'acct_1FKnueF03L74ac1E',
  FIREBASE_API_KEY: 'eyJhcGlLZXkiOiJBSXphU3lDRVVUMzR5TzdzZkhGZFBGSW1xbGNkbFhKSEhVV0FaNDgiLCJhdXRoRG9tYWluIjoiYnVtZXJhbmctYXBwLXN0YWdpbmcuZmlyZWJhc2VhcHAuY29tIiwiZGF0YWJhc2VVUkwiOiJodHRwczovL2J1bWVyYW5nLWFwcC1zdGFnaW5nLWRlZmF1bHQtcnRkYi5maXJlYmFzZWlvLmNvbSIsInByb2plY3RJZCI6ImJ1bWVyYW5nLWFwcC1zdGFnaW5nIiwic3RvcmFnZUJ1Y2tldCI6ImJ1bWVyYW5nLWFwcC1zdGFnaW5nLmFwcHNwb3QuY29tIiwibWVzc2FnaW5nU2VuZGVySWQiOiIyMzc0ODQ4NDMxMDQiLCJhcHBJZCI6IjE6MjM3NDg0ODQzMTA0OndlYjowYmViZDBiNzExZGRjYzYxYTY2MzA1IiwibWVhc3VyZW1lbnRJZCI6IkctS1ZEVFNDWERXRCJ9Cg=='
};
