import * as React from 'react';

import { toast } from 'react-toastify';
import { Icon } from '@bumerang-kit/components';

import 'react-toastify/dist/ReactToastify.css';
import './Toaster.css';

class Toaster {
  options: object;
  toast: string;

  constructor() {
    this.toast = 'toast';
    this.options = {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    };
  }

  flashError(message: string): void {
    toast.error(message, { ...this.options, ...{ icon: <Icon name="confused-emoji" /> } });
  }

  flashSuccess(message: string): void {
    toast.success(message, { ...this.options, ...{ icon: <Icon name="thumb-up" /> } });
  }
}

export default Toaster;
