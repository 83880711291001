import { Button } from '@bumerang-kit/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type CTAFooterProps = {
  onDirections: () => void;
  onOrder: () => void;
  hasDelivery: boolean;
};

const CTAFooter = (props: CTAFooterProps) => {
  const { onDirections, onOrder, hasDelivery } = props;
  const { t } = useTranslation();

  return (
    <footer className="absolute bottom-1 bg-gradient-to-t from-white via-white w-full p-1 pb-safe z-40 h-auto pt-12">
      <div className="flex justify-between gap-1 bg-white">
        <Button onClick={() => onDirections()}>{t('Directions')}</Button>
        {hasDelivery && (
          <Button secondary onClick={() => onOrder()}>
            {t('PartnerProfile.DeliveryButtonText')}
          </Button>
        )}
      </div>
    </footer>
  );
};

export default CTAFooter;
