import { useQuery } from 'react-query';
import { Geolocation } from '@capacitor/geolocation';
import { queryClient } from '@/lib/react-query';

const getCurrentLocation = async (): Promise<any> => {
  const result = await Geolocation.checkPermissions();
  if (result.location === 'granted') return Geolocation.getCurrentPosition();
  if (result.location === 'denied') return undefined;
  else
    return Geolocation.requestPermissions().then(() => {
      getCurrentLocation();
    });
};

export default function useCurrentLocation(config: any | undefined) {
  return useQuery({
    queryKey: ['position'],
    queryFn: getCurrentLocation,
    ...config,
    onError: () => queryClient.setQueryData(['position'], undefined),
  });
}
