import React from 'react';
import styled from 'styled-components';
import './styles/General.css';
import './styles/Register.css';
import { useHistory } from 'react-router';
import { IonFooter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import AuthApi from '../api/AuthApi';
import ApiCaller from '../lib/ApiCaller';
import {
  Button,
  Checkbox,
  Input,
  Line,
  LoginButtons,
  Navigation,
  Text,
} from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import { Toaster, useApple, useGoogle, useLogin, usePlatform } from '@bumerang-kit/helpers';

import { Keyboard } from '@capacitor/keyboard';
import CheckBoxBewsLetter from '../bumerang-kit/components/molecules/Checkbox/CheckBoxNewsLetter';

const toaster = new Toaster();

const StyledForm = styled.form`
  margin-top: ${Spacing.xs};
  margin-bottom: ${Spacing.xxxxxl};
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.s};
`;

const LoginWrapper = styled(IonFooter)`
  display: flex;
  width: 95%;
  justify-content: center;
  margin-top: ${Spacing.m};
  margin-bottom: 30px;
  position: absolute;
  bottom: ${Spacing.xs};
`;

const StyledText = styled(Text)`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
`;

const StyledPage = styled(Page)`
  background-color: ${Colors.backgroundBlue};
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const RegisterLink = styled(Text)`
  margin-left: ${Spacing.xxs};
`;

const Register = () => {
  const [name, setName] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();
  const [newsLetter, setNewsLetter] = React.useState<boolean>(false);
  const [password, setPassword] = React.useState<string>();
  const [tycStatus, setTycStatus] = React.useState<boolean>(false);
  const [isFooterShown, setIsFooterShown] = React.useState(true);
  const { t } = useTranslation();
  const { data: platform } = usePlatform();
  const navigate = useHistory();
  const isFormDisabled = !name || !email || !password || !tycStatus;
  const authApi = new AuthApi(new ApiCaller());
  const { refetch: getGoogle } = useGoogle();
  const { refetch: getApple } = useApple();
  const loginMutation = useLogin();

  const login = async (provider: string) => {
    const { data } = provider === 'Google' ? await getGoogle() : await getApple();

    loginMutation.mutate({ data, provider, platform });
  };

  React.useEffect(() => {
    if (Keyboard) {
      Keyboard.addListener('keyboardWillShow', function () {
        setIsFooterShown(false);
      });

      Keyboard.addListener('keyboardWillHide', function () {
        setIsFooterShown(true);
      });
      return () => {
        Keyboard.removeAllListeners();
      };
    }
  });

  const handleRegisterForm = async (event: React.FormEvent) => {
    try {
      event.preventDefault();

      if (!name || !email || !password) {
        return;
      }

      const response: any = await authApi.register(email, password, name, newsLetter, platform);
      //TODO: remove console.log
      console.log(response);

      if (!response.success) {
        if (response.data.error) {
          throw new Error(response.data.error);
        }

        throw new Error(t('GeneralError'));
      }

      loginMutation.mutate({
        data: { email, password },
        provider: 'Email',
        platform,
      });
    } catch (e: any) {
      //TODO: remove console.log
      console.log(e);
      toaster.flashError(e.message);
    }
  };

  return (
    <StyledPage>
      <Navigation backgroundColor={Colors.backgroundBlue} onBack={() => navigate.go(-1)}>
        {t('RegisterTitle')}
      </Navigation>
      <StyledContainer backgroundColor={Colors.backgroundBlue}>
        <LoginButtons onGoogle={() => login('Google')} onApple={() => login('Apple')}>
          <Line
            isOption
            backgroundColor={Colors.backgroundBlue}
            color={Colors.black}
            yMargin={Spacing.l}
          />
        </LoginButtons>
        <StyledForm onSubmit={handleRegisterForm}>
          <Input
            label={t('RegisterNameLabel')}
            name="name"
            id="name"
            value={name}
            onChange={(e: any) => setName(e.detail.value!)}
            required
          />
          <Input
            label="Email"
            name="email"
            id="email"
            type="email"
            value={email}
            onChange={(e: any) => setEmail(e.detail.value!)}
            required
          />
          <Input
            label={t('PasswordLabel')}
            id="password"
            name="password"
            required
            type="password"
            value={password}
            onChange={(e: any) => setPassword(e.detail.value)}
            clearInput
          />

          <Checkbox
            name="tyc"
            checked={tycStatus}
            onCheck={(status: boolean) => setTycStatus(status)}
          />

          <CheckBoxBewsLetter
            name="newsletter"
            onCheck={(status: any) => setNewsLetter(status)}
            checked={newsLetter}
          />

          <StyledButton disabled={isFormDisabled} type="submit">
            {t('CreateAccount')}
          </StyledButton>

          <input type="submit" className="submit-enter" />
        </StyledForm>
        {isFooterShown && (
          <LoginWrapper className="ion-no-border">
            <StyledText config="main-text" align="center">
              {t('RegisterSignInSuggestion')}
              <RegisterLink config="main-text-link" link="/login" isLink>
                {t('RegisterSingInLink')}
              </RegisterLink>
            </StyledText>
          </LoginWrapper>
        )}
      </StyledContainer>
    </StyledPage>
  );
};

export default Register;
