import { IonImg } from '@ionic/react';
import queryString from 'query-string';
import React from 'react';
import styled from 'styled-components';

import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Container, Page } from '@bumerang-kit/layouts';
import { Text } from '@bumerang-kit/components';
import { Spacing, Colors } from '@bumerang-kit/foundations';
import { decodeOAuthError } from '../helpers/oauth';

const StyledContainer = styled(Container)`
  padding: ${Spacing.s} ${Spacing.m};
`;

const Logo = styled(IonImg)`
  width: 55px;
  margin: 0 auto;
`;

const Header = styled.header`
  padding: ${Spacing.s};
  border-bottom: 1px solid ${Colors.mainColorDisabled};
`;

const StyledText = styled(Text)`
  margin: ${Spacing.s} auto;
`;

const OauthAuthorize = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const context = queryString.parse(location.search);
  let decodedErrorMessage = { errorMessage: 'Bad Request' };
  if (context?.authError && typeof context?.authError === 'string') {
    decodedErrorMessage = decodeOAuthError(t, context?.authError);
  }
  return (
    <Page>
      <Header>
        <Logo src="assets/logo-con-nombre.svg" />
      </Header>
      <StyledContainer>
        <StyledText config="onboarding-title" color={Colors.mainColor} align="center">
          {t('ErrorOnAuthorization')}
        </StyledText>
        <StyledText align="center">{decodedErrorMessage.errorMessage}</StyledText>
      </StyledContainer>
    </Page>
  );
};

export default OauthAuthorize;
