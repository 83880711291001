import * as React from 'react';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';

// eslint-disable-next-line @typescript-eslint/ban-types
export type IconProps = {
  isRounded?: boolean;
  isPointer?: boolean;
  name: string;
  color?: string;
  size?: string;
  transform?: string;
  type?: 'primary' | 'secondary' | 'none';
  onClick?: (event: any) => void;
};

const getColorProperties = (type = 'primary') => {
  switch (type) {
    case 'primary':
    default:
      return {
        background: 'bg-details-blue',
        borderTop: 'after:border-t-details-blue',
      };
    case 'secondary':
      return {
        background: 'bg-main',
        borderTop: 'after:border-t-main',
      };
    case 'none': {
      return {
        background: 'transparent',
        borderTop: 'after:border-t-transparent',
      };
    }
  }
};

const StyledSVGIcon = styled(ReactSVG)`
  svg {
    ${({ size }: any) =>
      size &&
      css`
        width: ${size};
        height: ${size};
      `}
    ${({ transform }: any) =>
      transform &&
      css`
        transform: ${transform};
      `}
    path {
      ${({ color }: any) =>
        color &&
        css`
          fill: ${color};
        `}
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Icon = (props: IconProps) => {
  const colorProperties = getColorProperties(props.type);

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const svgPath = require(`./icons/${props.name}.svg`);

  if (props.isRounded) {
    return (
      <div
        {...props}
        onClick={(event: any) => props.onClick && props.onClick(event)}
        className={`block rounded-full ${colorProperties.background} w-[60px] h-[60px] flex justify-center items-center`}
      >
        <StyledSVGIcon
          src={svgPath}
          color={props.color}
          size={props.size}
          transform={props.transform}
        />
      </div>
    );
  }

  if (props.isPointer) {
    return (
      <div
        {...props}
        onClick={(event: any) => props.onClick && props.onClick(event)}
        className={`block relative rounded-full ${colorProperties.background} w-12 h-12 flex justify-center items-center after:pseudo-content after:left-1/2 after:absolute after:-bottom-2 after:-ml-2 ${colorProperties.borderTop} after:border-l-transparent after:border-r-transparent after:border-b-transparent after:bg-transparent after:border-t-12 after:border-r-8 after:border-b-0 after:border-l-8 after:w-0 after:h-0`}
      >
        <StyledSVGIcon
          src={svgPath}
          color={props.color}
          size={props.size}
          transform={props.transform}
        />
      </div>
    );
  }

  return (
    <div
      {...props}
      onClick={(event: any) => props.onClick && props.onClick(event)}
      className="block"
    >
      <StyledSVGIcon
        src={svgPath}
        color={props.color}
        size={props.size}
        transform={props.transform}
      />
    </div>
  );
};

export default Icon;
