import { IonFooter, IonImg } from '@ionic/react';
import { Route, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Keyboard } from '@capacitor/keyboard';

import { useQuery } from 'react-query';
import { Button, Input, LoadingHome, Text } from '@bumerang-kit/components';
import { Page, Container } from '@bumerang-kit/layouts';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import { useLoginOauth, usePlatform } from '@bumerang-kit/helpers';

import './styles/General.css';
import './styles/OauthLogin.css';
import OauthApplicationAuthorizationApi from '../api/OauthApplicationAuthorizationApi';
import ApiCaller from '../lib/ApiCaller';
import { encodeOAuthError } from '../helpers/oauth';

const StyledForm = styled.form`
  margin-top: ${Spacing.xs};
  margin-bottom: ${Spacing.xxxxxl};
`;

const CreateAccountWrapper = styled(IonFooter)`
  display: flex;
  width: 95%;
  justify-content: center;
  margin-top: ${Spacing.m};
  margin-bottom: 30px;
  position: absolute;
  bottom: ${Spacing.xs};
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.s};
`;

const StyledText = styled(Text)`
  align-items: center;
  display: flex;
`;

const StyledPage = styled(Page)`
  background-color: ${Colors.backgroundBlue};
`;

const StyledContainer = styled(Container)`
  position: relative;
  margin-top: ${Spacing.s};
`;

const RegisterLink = styled(Text)`
  margin-left: ${Spacing.xxs};
`;

const Logo = styled(IonImg)`
  width: 55px;
  margin: 0 auto;
`;

const Header = styled.header`
  padding: ${Spacing.s};
  border-bottom: 1px solid ${Colors.mainColorDisabled};
`;

const OauthLogin = () => {
  const [applicationInfo, setApplicationInfo] = useState<any>();
  const { data: platform } = usePlatform();
  const loginOauthMutation = useLoginOauth();
  const { t } = useTranslation();
  const [email, setEmail] = React.useState<string>();
  const [isFooterShown, setIsFooterShown] = React.useState(true);
  const [password, setPassword] = React.useState<string>();
  const isFormValid = !email || !password;
  const location = useLocation();
  const context = queryString.parse(location.search);
  const clientId: string = context.client_id as string;
  const redirectUri: string = context.redirect_uri as string;
  const currentQuery = queryString.stringify(context);

  const doAuth = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!email || !password) {
      return;
    }

    loginOauthMutation.mutate({
      data: { email, password },
      provider: 'Email',
      platform,
    });
  };

  React.useEffect(() => {
    if (Keyboard) {
      Keyboard.addListener('keyboardWillShow', function () {
        setIsFooterShown(false);
      });

      Keyboard.addListener('keyboardWillHide', function () {
        setIsFooterShown(true);
      });
      return () => {
        Keyboard.removeAllListeners();
      };
    }
  });

  const oauthApplicationAuthorizationApi = new OauthApplicationAuthorizationApi(new ApiCaller());

  const getAuthorizationStatus = useQuery(
    'getAuthorizationStatus',
    async () => await oauthApplicationAuthorizationApi.getApplicationInfo(clientId, redirectUri),
    {
      onSuccess: (data: any) => {
        return setApplicationInfo(data);
      },
    }
  );

  if (getAuthorizationStatus.isLoading) {
    return (
      <Page>
        <LoadingHome />
      </Page>
    );
  }
  if (
    applicationInfo &&
    !applicationInfo?.success &&
    typeof applicationInfo.data.error === 'string'
  ) {
    return (
      <Route
        render={() => {
          window.location.replace(
            `/oauth/error?authError=${encodeOAuthError(applicationInfo.data.error)}`
          );
          return null;
        }}
      />
    );
  }

  return (
    <StyledPage>
      <Header>
        <Logo src="assets/logo-con-nombre.svg" />
      </Header>
      <StyledContainer backgroundColor={Colors.backgroundBlue}>
        {
          //  <LoginButtons
          //    onGoogle={() => login('Google')}
          //    onApple={() => login('Apple')}
          //  >
          //    <Line
          //      isOption
          //      backgroundColor={Colors.backgroundBlue}
          //      color={Colors.black}
          //      yMargin={Spacing.l}
          //    />
          //  </LoginButtons>
        }

        <StyledForm onSubmit={(event) => doAuth(event)}>
          <Input
            label="Email"
            name="email"
            id="email"
            type="email"
            value={email}
            onChange={(e: any) => setEmail(e.detail.value!)}
            required
          />
          <Input
            label={t('Password')}
            id="password"
            name="password"
            required
            type="password"
            value={password}
            onChange={(e: any) => setPassword(e.detail.value!)}
            clearInput
          />
          <StyledButton type="submit" expand="full" disabled={isFormValid}>
            {t('ContinueWithEmail')}
          </StyledButton>
          <Text
            align="center"
            config="main-text-link"
            link={`/oauth/forgotpassword?${currentQuery}`}
            isLink
          >
            {t('LoginForgotPassword')}
          </Text>
          <input type="submit" className="submit-enter" />
        </StyledForm>
        {isFooterShown && (
          <CreateAccountWrapper className="ion-no-border">
            <StyledText config="main-text" align="center">
              {t('LoginSignUpSuggestion')}
              <RegisterLink config="main-text-link" link={`/oauth/register?${currentQuery}`} isLink>
                {t('CreateAccount')}
              </RegisterLink>
            </StyledText>
          </CreateAccountWrapper>
        )}
      </StyledContainer>
    </StyledPage>
  );
};

export default OauthLogin;
