import React from 'react';
import styled from 'styled-components';
import Colors from '../../../foundations/colors';
import Spacing from '../../../foundations/spacing';

const getCardState = (isFull: boolean) => {
  return isFull
    ? `border: solid 1px ${Colors.mainColorDisabled};
         background-color: ${Colors.white};`
    : `border: dashed 1px ${Colors.detailsBlue};`;
};

const StyledCard = styled.div`
  ${(props: { listItem: boolean }) => {
    return props.listItem
      ? `width: 100%;`
      : `border-radius: 6px;
  margin: ${Spacing.xs} auto;
  padding: ${Spacing.xs};
  min-height: 64px;`;
  }}
  ${({ isFull }: any): any => getCardState(isFull)};
`;
const Card = ({ full: isFull, listItem: isListItem, children, ...props }: any) => {
  return (
    <StyledCard isFull={isFull} listItem={isListItem} {...props}>
      {children}
    </StyledCard>
  );
};
export default Card;
