import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { IonItem, IonLabel, IonSelect, IonSelectOption, IonSpinner } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import Restaurant from '../lib/common/models/restaurant';
import RestaurantApi from '../api/RestaurantApi';
import ApiCaller from '../lib/ApiCaller';
import LocationInput from '../components/LocationInput';
import { Container, Page } from '@bumerang-kit/layouts';
import { Button, Input, LoadingModal, Navigation } from '@bumerang-kit/components';
import styled from 'styled-components';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import { Toaster } from '@bumerang-kit/helpers';

import { getCurrentSession } from '@/helpers/session';
import ProductApi from '../apiClient/ProductApi';

const toaster = new Toaster();

const StyledButton = styled(Button)`
  position: absolute;
  bottom: ${Spacing.s};
  left: 50%;
  transform: translate(-50%);
  width: 85%;
`;

const StyledSpinner = styled(IonSpinner)`
  margin: 50% auto;
`;

const Field = styled.div`
  background: transparent;
  margin-bottom: ${Spacing.xxs};
`;

/* eslint-disable camelcase */
interface ProductType {
  id: string;
  name: string;
  code: string;
  group: string;
  picture: string;
  created_at: Date;
  updated_at: Date;
}
interface BusinessType {
  name: string;
  code: string;
}
/* eslint-enable camelcase */

const AddRestaurant: React.FC = () => {
  const currentUser = getCurrentSession();
  const [email, setEmail] = React.useState<string>();
  const [name, setName] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();
  const [addr, setAddr] = React.useState<string>();
  const [lat, setLat] = React.useState<number>();
  const [lng, setLng] = React.useState<number>();
  const [display, setDisplayed] = React.useState<boolean>(false);
  const [placeId, setPlaceId] = React.useState<string>();
  const navigate = useHistory();
  const { t } = useTranslation();
  const [businessType, setBusinessType] = useState<string[]>([]);
  const [productGroupType, setProductGroupType] = useState<string[]>([]);
  const [allProductGroupTypes, setAllProductGroupTypes] = useState<string[]>([]);
  const [messageLoading, setMessageLoading] = React.useState('');
  const [totalPhotos, setTotalPhotos] = React.useState(0);
  const [currentPhoto, setCurrentPhoto] = React.useState(0);
  const [showLoadingToUploadPhotos, setShowLoadingToUploadPhotos] = React.useState(false);
  const [showLoadingToCreateRestaurant, setShowLoadingToCreateRestaurant] = React.useState(false);

  const [allBusinessTypes] = useState<BusinessType[]>([
    {
      name: 'Restaurant',
      code: 'restaurant',
    },
    {
      name: 'Delivery Service',
      code: 'delivery_service',
    },
    {
      name: 'Supermarket',
      code: 'supermarket',
    },
    {
      name: 'Canteen',
      code: 'canteen',
    },
    {
      name: 'Cafeteria',
      code: 'cafeteria',
    },
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const restaurantApi = new RestaurantApi(new ApiCaller(currentUser.token));
  const productApi = new ProductApi(currentUser.token);

  const Spain = new google.maps.LatLng(40.416775, -3.70379);
  const map = new google.maps.Map(document.createElement('div'), {
    center: Spain,
    zoom: 15,
  });
  const service = new google.maps.places.PlacesService(map);
  const isFormValid = !email || !name || !password || !addr || !lat || !lng || !placeId;

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      try {
        const response = await productApi.getProductTypeList();
        if (!(response && response?.success)) {
          throw new Error(response.data.error);
        }
        setAllProductGroupTypes(
          response.data?.product_types
            .map((productType: ProductType) => {
              return productType.group;
            })
            .reduce((acc: string[], curr: string) => {
              if (!acc.includes(curr)) {
                acc.push(curr);
              }
              return acc;
            }, [])
        );
      } catch (error: any) {
        if (error?.message) {
          toaster.flashError(error.message);
        } else toaster.flashError(t('GenericNegativeFeedbackTryAgain'));
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const addRestaurant = async (e: React.FormEvent) => {
    e.preventDefault();

    const request = {
      language: 'en',
      placeId: placeId!,
      fields: [
        'name',
        'rating',
        'formatted_phone_number',
        'opening_hours',
        'website',
        'price_level',
        'photo',
      ],
    };

    service.getDetails(request, async (place: any, status: any) => {
      const uploadedPhotos = [];

      if (status === google.maps.places.PlacesServiceStatus.OK) {
        if (place.photos) {
          setTotalPhotos(place.photos.length);
          setShowLoadingToUploadPhotos(true);
          try {
            for (let x = 0; x < place.photos.length; x += 1) {
              setCurrentPhoto(x);
              const imageUrl = await restaurantApi.uploadPictures({
                restaurantName: name! ? name : email!,
                url: place.photos[x].getUrl(),
              });
              uploadedPhotos.push(imageUrl.Location);
            }
            setCurrentPhoto(place.photos.length);
          } catch (error) {
            console.log(error);
          } finally {
            setShowLoadingToUploadPhotos(false);
          }
        }

        const hoursFormatted = place.opening_hours?.weekday_text;
        const restaurant = new Restaurant({
          email: email!,
          address: addr!,
          name: name!,
          lat: lat!,
          displayed: display,
          lng: lng!,
          placeid: placeId!,
          phone: place.formatted_phone_number,
          hours: hoursFormatted,
          photoUrl: uploadedPhotos,
          type: businessType,
          productGroupTypeList: productGroupType,
          rating: place.rating,
          website: place.website,
        });

        try {
          setShowLoadingToCreateRestaurant(true);
          const response = await restaurantApi.add(restaurant, password!);
          if (!(response && response?.success)) {
            throw new Error(response.data.error);
          }
          toaster.flashSuccess(t('RestaurantAdded'));
          navigate.replace('/admin');
        } catch (error: any) {
          if (error?.message) {
            toaster.flashError(error.message);
          } else toaster.flashError(t('GenericNegativeFeedbackTryAgain'));
        } finally {
          setShowLoadingToCreateRestaurant(false);
        }
      }
    });
  };

  useEffect(() => {
    if (totalPhotos === 1) {
      setMessageLoading(t('AddRestaurantUploading'));
    } else {
      setMessageLoading(
        t('AddRestaurantUploadingMultiple', {
          current: currentPhoto,
          total: totalPhotos,
        })
      );
    }
  }, [currentPhoto, totalPhotos]);

  return (
    <Page>
      <Navigation onBack={() => navigate.replace('/admin')}>{t('AddRestaurantTitle')}</Navigation>
      <LoadingModal isOpen={showLoadingToUploadPhotos} message={messageLoading} />
      <LoadingModal isOpen={showLoadingToCreateRestaurant} message={t('AddRestaurantCreating')} />

      <Container>
        {!isLoading ? (
          <form onSubmit={addRestaurant}>
            <Input
              label={t('Name')}
              name="name"
              id="name"
              type="name"
              value={name}
              onChange={(e: any) => setName(e.detail.value!)}
              required
            />

            <Input
              label="Email"
              name="email"
              id="email"
              type="email"
              value={email}
              onChange={(e: any) => setEmail(e.detail.value!)}
              required
            />

            <Input
              label={t('Password')}
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={(e: any) => setPassword(e.detail.value!)}
              clearInput
              required
            />
            <Input
              label={t('Display')}
              id="showed"
              name="showed"
              type="checkbox"
              value={display}
              onChange={(e: any) => setDisplayed(e.detail.checked)}
              clearInput
              required
            />
            <Field>
              <IonItem color="white">
                <IonLabel position="floating">{t('BusinessType')}</IonLabel>
                <IonSelect
                  color="dark"
                  value={businessType}
                  multiple
                  cancelText={t('Cancel')}
                  okText={t('Confirm')}
                  onIonChange={(e) => setBusinessType(e.detail.value)}
                >
                  {allBusinessTypes.map((business: any) => {
                    return (
                      <IonSelectOption key={business.code} value={business.code}>
                        {business.name}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
            </Field>
            <Field>
              <IonItem color="white">
                <IonLabel position="floating">{t('ProductGroupType')}</IonLabel>
                <IonSelect
                  color="dark"
                  value={productGroupType}
                  multiple
                  cancelText={t('Cancel')}
                  okText={t('Confirm')}
                  onIonChange={(e) => setProductGroupType(e.detail.value)}
                >
                  {allProductGroupTypes.map((group: string) => {
                    return (
                      <IonSelectOption key={group} value={group}>
                        {group}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
            </Field>

            <LocationInput
              setAddress={(addr: any) => {
                setAddr(addr);
              }}
              setLat={setLat}
              setLng={setLng}
              setPlaceId={(id: string) => setPlaceId(id)}
            />

            <StyledButton
              type="submit"
              expand="full"
              disabled={isFormValid || showLoadingToCreateRestaurant || showLoadingToUploadPhotos}
            >
              {t('Save')}
            </StyledButton>
          </form>
        ) : (
          <StyledSpinner color={Colors.mainColor} />
        )}
      </Container>
    </Page>
  );
};

export default AddRestaurant;
