import React, { useEffect, useState } from 'react';
import { IonApp } from '@ionic/react';
import { useQuery } from 'react-query';
import { getCurrentSession } from '../../helpers/session';

export const CameraPermissionsContext = React.createContext<any>(null);

export function AppWithContext({ children }: any) {
  const [isPartner, setIsPartner] = React.useState(false);
  const [isCustomer, setIsCustomer] = React.useState(false);
  const [hasPermissions, setHasPermissions] = useState<boolean | null>(null);

  const getCurrentSessionQuery = useQuery({
    queryKey: 'getCurrentSessionQuery',
    queryFn: getCurrentSession,
    onSuccess: (data: any) => {
      if (data.type === 'restaurant') setIsPartner(true);
      if (data.type === 'customer') setIsCustomer(true);
    },
  });

  const checkIfHasPermissions = async () => {
    try {
      const result = await navigator.mediaDevices.getUserMedia({ video: true });
      if (result) {
        result.getVideoTracks().forEach((track: MediaStreamTrack) => track.stop());
        setHasPermissions(true);
      }
    } catch (err) {
      return setHasPermissions(false);
    }
  };

  useEffect(() => {
    if ((isPartner && !isCustomer) || (isCustomer && !isPartner)) checkIfHasPermissions();
  }, [isCustomer, isPartner]);

  if (getCurrentSessionQuery.isLoading) return <></>;

  return isPartner || isCustomer ? (
    <CameraPermissionsContext.Provider value={{ hasPermissions, setHasPermissions }}>
      <IonApp>{children}</IonApp>
    </CameraPermissionsContext.Provider>
  ) : (
    <IonApp>{children}</IonApp>
  );
}
