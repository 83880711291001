import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { getCurrentSession } from '@/helpers/session';
import ApiCaller from '../../lib/ApiCaller';
import { Button, Input, Navigation, Text } from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import { Colors, Spacing } from '@bumerang-kit/foundations';

import CustomerApi from '../../api/CustomerApi';
import { Toaster } from '@bumerang-kit/helpers';

const toaster = new Toaster();

const Redirect = styled(Text)`
  margin-top: ${Spacing.xs};
`;

const StyledButton = styled(Button)`
  margin: ${Spacing.m} auto;
`;

const ManualClient: React.FC = ({ lendConfig, setLendConfig }: any) => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const [qr, setQR] = useState<string>('B-');
  const currentUser = getCurrentSession();
  const isQRValid = !(qr.length >= 9);
  const apiCaller = new ApiCaller(currentUser.token);
  const customerApi = new CustomerApi(apiCaller);

  const handleConfirm = async (event: any) => {
    event.preventDefault();
    if (!qr) return;

    const response = await customerApi.getCustomerByUsername(qr);

    if (!response.success) {
      toaster.flashError(response.data.error);
      return;
    }

    await setLendConfig({
      ...lendConfig,
      user: { ...response.data, user_id: response.data.id },
    });

    setQR('B-');
    navigate.push('/takeaway/summary');
  };

  const handlePaste = async (event: any) => {
    event.preventDefault();
    const pastedContent = event.clipboardData.getData('Text');

    if (pastedContent.includes('B-')) {
      const qrCode = pastedContent.slice(pastedContent.indexOf('B-'));
      setQR(qrCode);
    } else {
      setQR(pastedContent);
    }
  };

  const handleBack = () => {
    setQR('B-');
    navigate.push('/takeaway/scan?target=client');
  };

  document.addEventListener('ionBackButton', (ev: any) => {
    ev.preventDefault();
    ev.detail.register(10, () => handleBack());
  });

  return (
    <Page>
      <Navigation backgroundColor={Colors.white} onBack={() => handleBack()}>
        {t('TypeQrTitle')}
      </Navigation>
      <Container>
        <form onSubmit={handleConfirm}>
          <Input
            value={qr}
            onChange={(e: CustomEvent) => setQR(e.detail.value.toUpperCase())}
            label={t('TypeQrLabel')}
            onPaste={(e: any) => handlePaste(e)}
          />
          <StyledButton disabled={isQRValid} type="submit">
            {t('TypeQrSubmit')}
          </StyledButton>
        </form>
        <Redirect
          align="center"
          isLink
          config="small-text"
          onClick={() => navigate.push('/takeaway/scan?target=client')}
        >
          {t('QRScannerTitle')}
        </Redirect>
      </Container>
    </Page>
  );
};

export default ManualClient;
