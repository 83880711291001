import ApiCaller from '../lib/ApiCaller';

export default class LogisticsApi {
  private apiCaller: ApiCaller;

  constructor(userToken: string) {
    this.apiCaller = new ApiCaller(userToken);
  }

  //  initLend(id: string): Promise<any> {
  //    return this.apiCaller.call(`/v2/logistic/lend/init`);
  //  }

  startLend(lendee_id: string, container_ids: string[], lender_id?: string): Promise<any> {
    return this.apiCaller.call('/v2/logistics/lend/start', 'POST', {
      lendee_id,
      container_ids,
      ...(lender_id ? { lender_id } : {}),
    });
  }

  selfAssign(container_ids: string[]): Promise<any> {
    return this.apiCaller.call('/v2/logistics/lend/self', 'POST', {
      container_ids,
    });
  }

  collect(container_ids: string[], collector_id?: string): Promise<any> {
    return this.apiCaller.call('/v2/logistics/lend/collect', 'POST', {
      container_ids,
      ...(collector_id ? { collector_id } : {}),
    });
  }

  assign(containerIds: string[], partnerId: string): Promise<any> {
    return this.apiCaller.call('/v2/logistics/container/assign', 'PUT', {
      partner_id: partnerId,
      container_ids: containerIds,
    });
  }

  retrieve(containerIds: string[], partnerId: string): Promise<any> {
    return this.apiCaller.call('/v2/logistics/container/retrieve', 'PUT', {
      partner_id: partnerId,
      container_ids: containerIds,
    });
  }

  getContainerJourney(container_id: string): Promise<any> {
    return this.apiCaller.call(`/v2/logistics/container/journey/${container_id}`);
  }

  getMovementHistory(userId: string): Promise<any> {
    return this.apiCaller.call('/v2/logistics/movement/history', 'POST', {
      user_id: userId,
    });
  }
}
