import queryString from 'query-string';
import jwt from 'jwt-simple';
import { TFunction } from 'react-i18next';
import { getCurrentSession, isLogin } from './session';

const getSecret = (): string => {
  return '7d02e052-b177-4e35-98b0-15010f1cbb21';
};

const isValidOauth = (search: string, as: string): boolean => {
  const context = queryString.parse(search);
  const isLoginResult = isLogin();
  const currentUser = getCurrentSession();

  return (
    !!context &&
    !!context.client_id &&
    typeof context.client_id === 'string' &&
    !!context.redirect_uri &&
    typeof context.redirect_uri === 'string' &&
    ((isLoginResult && currentUser.type === as) || !isLoginResult)
  );
};

const encodeOAuthError = (error: string): string => {
  return jwt.encode({ success: false, errorMessage: error }, getSecret());
};

const generateOAuthErrorFromUrl = (
  t: TFunction<'translation'>,
  search: string,
  as: string
): string => {
  const context = queryString.parse(search);
  const isLoginResult = isLogin();
  let errorMessage = t('BadRequest');
  if (context && !(!!context.client_id && typeof context.client_id === 'string')) {
    errorMessage = t('MissingClientId');
  } else if (context && !(!!context.redirect_uri && typeof context.redirect_uri === 'string')) {
    errorMessage = t('MissingRedirectUri');
  } else if (context && !!context.client_id) {
    errorMessage = t('InvalidClientId');
  } else if (context && !!context.redirect_uri) {
    errorMessage = t('InvalidRedirectUri');
  } else if (isLoginResult) {
    const currentUser = getCurrentSession();
    if (currentUser.type !== as) {
      errorMessage = t('InvalidUserType');
    }
  }
  return encodeOAuthError(errorMessage);
};

const decodeOAuthError = (t: TFunction<'translation'>, encodedError: string): any => {
  const error = { success: false, errorMessage: t('BadRequest') };
  if (encodedError && typeof encodedError === 'string') {
    const decodedError: {
      success?: boolean;
      errorMessage?: string;
    } = jwt.decode(encodedError, getSecret());
    if (
      typeof decodedError.success === 'boolean' &&
      typeof decodedError.errorMessage === 'string'
    ) {
      return decodedError;
    }
  }
  return error;
};

const getOauthQueryAttributes = (search: string): string => {
  const context = queryString.parse(search);
  const params = new URLSearchParams();

  if (context) {
    params.set('redirect_uri', context.redirect_uri as string);
    params.set('client_id', context.client_id as string);
  }
  return params.toString();
};

export {
  isValidOauth,
  generateOAuthErrorFromUrl,
  decodeOAuthError,
  getOauthQueryAttributes,
  encodeOAuthError,
};
