import React, { useState } from 'react';
import styled from 'styled-components';
import './styles/General.css';
import './styles/OauthForgotPass.css';
import { useHistory, Route, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import AuthApi from '../api/AuthApi';
import ApiCaller from '../lib/ApiCaller';

import { Input, Navigation, Button, LoadingHome } from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import { Spacing, Colors } from '@bumerang-kit/foundations';
import { Toaster } from '@bumerang-kit/helpers';
import OauthApplicationAuthorizationApi from '../api/OauthApplicationAuthorizationApi';
import { encodeOAuthError } from '../helpers/oauth';

const StyledForm = styled.form`
  margin-top: ${Spacing.l};
  margin-bottom: ${Spacing.xxxl};
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.s};
`;

const StyledPage = styled(Page)`
  background-color: ${Colors.backgroundBlue};
`;

const toaster = new Toaster();

const OauthForgotPass = () => {
  const [applicationInfo, setApplicationInfo] = useState<any>();
  const [email, setEmail] = React.useState<string>();
  const { t } = useTranslation();
  const navigate = useHistory();
  const location = useLocation();
  const context = queryString.parse(location.search);
  const clientId: string = context.client_id as string;
  const redirectUri: string = context.redirect_uri as string;
  const currentQuery = queryString.stringify(context);

  const isFormValid = !email;

  const remember = (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      return;
    }
    const authApi = new AuthApi(new ApiCaller());
    authApi
      .resetPassword(email)
      .then((response: any) => {
        const { success, data } = response;

        if (!success) {
          toaster.flashError(data.error);
          return;
        }

        toaster.flashSuccess(t('ForgotPasswordPositiveFeedback'));

        navigate.push(`/oauth/login?${currentQuery}`);
      })
      .catch(() => {
        toaster.flashError(t('ForgotPasswordNegativeFeedback'));
      });
  };

  const oauthApplicationAuthorizationApi = new OauthApplicationAuthorizationApi(new ApiCaller());

  const getAuthorizationStatus = useQuery(
    'getAuthorizationStatus',
    async () => await oauthApplicationAuthorizationApi.getApplicationInfo(clientId, redirectUri),
    {
      onSuccess: (data: any) => {
        return setApplicationInfo(data);
      },
    }
  );

  if (getAuthorizationStatus.isLoading) {
    return (
      <Page>
        <LoadingHome />
      </Page>
    );
  }
  if (
    applicationInfo &&
    !applicationInfo?.success &&
    typeof applicationInfo.data.error === 'string'
  ) {
    return (
      <Route
        render={() => {
          window.location.replace(
            `/oauth/error?authError=${encodeOAuthError(applicationInfo.data.error)}`
          );
          return null;
        }}
      />
    );
  }
  return (
    <StyledPage>
      <Navigation backgroundColor={Colors.backgroundBlue} onBack={() => navigate.go(-1)}>
        {t('ForgotPasswordPageTitle')}
      </Navigation>
      <Container backgroundColor={Colors.backgroundBlue}>
        <StyledForm onSubmit={remember}>
          <Input
            value={email}
            label="Email"
            name="email"
            onChange={(e: any) => setEmail(e.detail.value!)}
          />
          <StyledButton disabled={isFormValid} type="submit">
            {t('Send')}
          </StyledButton>
        </StyledForm>
      </Container>
    </StyledPage>
  );
};

export default OauthForgotPass;
