import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IonIcon, IonItem, IonLabel, IonList, IonMenuToggle } from '@ionic/react';
import {
  chatbubblesOutline,
  helpCircleOutline,
  logoInstagram,
  logOutOutline,
} from 'ionicons/icons';

import './styles/Profile.css';

import { Container, Page } from '@bumerang-kit/layouts';
import { Spacing, Colors } from '@bumerang-kit/foundations';
import { Tabs, Text } from '@bumerang-kit/components';
import { Chat } from '@bumerang-kit/helpers';

import { ENV } from '../env/env';

import AuthApi from '../api/AuthApi';
import ApiCaller from '../lib/ApiCaller';
import { useHistory } from 'react-router';

import { getCurrentSession, removeCurrentSession } from '../helpers/session';

const Separator = styled.div`
  height: 1px;
  background-color: ${Colors.mainColorDisabled};
  margin: ${Spacing.m} 0;
`;

const Header = styled(Text)`
  margin-bottom: ${Spacing.m};
`;

const AdminProfile: React.FC = () => {
  const currentUser = getCurrentSession();
  const navigate = useHistory();
  const { t } = useTranslation();

  const logout = () => {
    const authApi = new AuthApi(new ApiCaller());
    authApi
      .logout()
      .then(() => {
        navigate.push('/');
      })
      .catch((e) => console.log(e));

    removeCurrentSession();
    document.location.href = '/';
  };

  return (
    <Page>
      <Container>
        <Header align="center" config="secondary-screen-title">
          {t('ProfileMyAccount')}
        </Header>
        <IonList>
          <IonMenuToggle key={1} autoHide={false}></IonMenuToggle>
          <Separator />
          <IonMenuToggle key={2} autoHide={false}></IonMenuToggle>
          <IonMenuToggle key={3} autoHide={false}>
            <a
              className="no-underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youbumerang.com/faqs"
            >
              <IonItem routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" icon={helpCircleOutline} />
                <IonLabel>{t('ProfileFAQ')}</IonLabel>
              </IonItem>
            </a>
          </IonMenuToggle>
          <IonMenuToggle key={4} autoHide={false}>
            <IonItem
              onClick={() =>
                Chat.open(ENV.CRISP_ID, currentUser.email, currentUser.name, currentUser.token)
              }
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" icon={chatbubblesOutline} />
              <IonLabel>{t('ProfileContactUs')}</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle key={5} autoHide={false}>
            <a
              className="no-underline"
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.instagram.com/youbumerang"
            >
              <IonItem routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" icon={logoInstagram} />
                <IonLabel>{t('ProfileFollow')}</IonLabel>
              </IonItem>
            </a>
          </IonMenuToggle>
          <Separator />
          <IonMenuToggle key={6} autoHide={false}>
            <IonItem onClick={() => logout()} routerDirection="none" lines="none" detail={false}>
              <IonIcon slot="start" icon={logOutOutline} />
              <IonLabel>{t('ProfileCloseSession')}</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </Container>
      <Tabs type="admin" active="profile" />
    </Page>
  );
};

export default AdminProfile;
