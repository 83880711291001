import styled from 'styled-components';

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  -webkit-appearance: none;

  video::webkit-media-controls {
    display: none !important;
  }

  video::-webkit-media-controls-start-playback-button {
    display: none !important;
  }

  video::-webkit-media-controls-panel {
    display: none !important;
  }

  video::-webkit-media-controls-play-button {
    display: none !important;
  }

  video::-webkit-media-controls-volume-slider-container {
    display: none !important;
  }

  video::-webkit-media-controls-volume-slider {
    display: none !important;
  }

  video::-webkit-media-controls-mute-button {
    display: none !important;
  }

  video::-webkit-media-controls-timeline {
    display: none !important;
  }

  video::-webkit-media-controls-current-time-display {
    display: none !important;
  }

  video::-webkit-full-page-media::-webkit-media-controls-panel {
    display: none !important;
  }

  video::-webkit-media-controls-timeline-container {
    display: none !important;
  }

  video::-webkit-media-controls-time-remaining-display {
    display: none !important;
  }

  video::-webkit-media-controls-seek-back-button {
    display: none !important;
  }

  video::-webkit-media-controls-seek-forward-button {
    display: none !important;
  }

  video::-webkit-media-controls-fullscreen-button {
    display: none !important;
  }

  video::-webkit-media-controls-rewind-button {
    display: none !important;
  }

  video::-webkit-media-controls-return-to-realtime-button {
    display: none !important;
  }

  video::-webkit-media-controls-toggle-closed-captions-button {
    display: none !important;
  }
`;

export default StyledVideo;
