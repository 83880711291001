import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useHistory, useLocation } from 'react-router';
import { Container, Page } from '@bumerang-kit/layouts';
import { Navigation, Button, Text, Line, Icon } from '@bumerang-kit/components';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import Settings from '../../lib/settings';
import { getCurrentSession } from '@/helpers/session';
import PaymentApi from '../../api/PaymentApi';
import ApiCaller from '../../lib/ApiCaller';
import { Toaster } from '@bumerang-kit/helpers';

const toaster = new Toaster();

const StyledLine = styled(Line)`
  margin: ${Spacing.s} 0;
`;

const Wrapper = styled.div``;

const Block = styled.div`
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
  padding: ${Spacing.xxs} 0;
`;

const FixedBlock = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${Spacing.m} ${Spacing.s};
  background-color: ${Colors.white};
`;

const Link = styled(Text)`
  margin-top: ${Spacing.xs};
`;

const StyledContainer = styled(Container)`
  padding: ${Spacing.s} ${Spacing.s};
`;

const StyledIcon = styled(Icon)`
  width: 65px;
  height: 65px;
  margin-bottom: ${Spacing.s};
`;

const BillingSummary = ({ lendConfig, setLendConfig }: any) => {
  const navigate = useHistory();
  const location = useLocation();
  const currentUser = getCurrentSession();
  const apiCaller = new ApiCaller(currentUser.token);
  const paymentApi = new PaymentApi(apiCaller);
  const [isFocus, setIsFocus] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { t } = useTranslation();

  const setLendConfigStorage = (state: any) => {
    Settings.saveStateInSession(state);
    setLendConfig(state);
  };

  useEffect(() => {
    const pathname = location?.pathname;
    if (pathname === '/admin/billing/summary') {
      if (!lendConfig.adminAction) {
        const status = Settings.getStateInSession();

        if (
          status?.restaurant &&
          status?.productTypes &&
          status?.productTypes.length > 0 &&
          ((status?.adminAction === 'billing_create_subscription' &&
            status?.product &&
            status?.plan) ||
            (status?.adminAction === 'billing_assign_subscription' &&
              status?.subscriptionItem &&
              status?.subscription))
        ) {
          setLendConfigStorage({
            ...lendConfig,
            ...status,
          });
        } else {
          setLendConfigStorage({
            ...lendConfig,
            adminAction: null,
          });
          return navigate.replace('/admin');
        }
      }
      setIsFocus(true);
    } else {
      setIsFocus(false);
    }
  }, [location]);

  const handleConfirmation = async () => {
    setIsButtonDisabled(true);
    try {
      if (
        lendConfig.adminAction === 'billing_create_subscription' &&
        lendConfig.productTypes.length > 1
      ) {
        const productSubscription = await paymentApi.addProductSubscription(
          lendConfig.restaurant.userId,
          lendConfig.productTypes[0].id,
          lendConfig.plan?.plan_id,
          lendConfig.subscriptionItem?.subscription_item_id
        );
        if (productSubscription) {
          await Promise.all(
            lendConfig.productTypes.slice(1).map(async (productType: any) => {
              await paymentApi.addProductSubscription(
                lendConfig.restaurant.userId,
                productType.id,
                lendConfig.plan?.plan_id,
                productSubscription.data.product_subscription.stripe_subscription_item_id
              );
            })
          );
        }
      } else if (
        lendConfig.adminAction === 'billing_create_subscription' ||
        lendConfig.adminAction === 'billing_assign_subscription'
      ) {
        await Promise.all(
          lendConfig.productTypes.map(async (productType: any) => {
            await paymentApi.addProductSubscription(
              lendConfig.restaurant.userId,
              productType.id,
              lendConfig.plan?.plan_id,
              lendConfig.subscriptionItem?.subscription_item_id
            );
          })
        );
      }

      toaster.flashSuccess(t('SuccessfulAction'));
    } catch (error: any) {
      if (error?.message) {
        toaster.flashError(error.message);
      } else toaster.flashError(t('GenericNegativeFeedbackTryAgain'));
    }
    await setLendConfigStorage({
      ...lendConfig,
      adminAction: 'billing',
    });

    navigate.replace(`/admin/billing`);
    setIsButtonDisabled(false);
  };

  const onBackButton = async () => {
    setLendConfigStorage({
      ...lendConfig,
      adminAction: null,
    });
    navigate.replace('/admin');
  };
  const openChangeAction = (type: string) => {
    switch (type) {
      case 'product':
        navigate.replace('/admin/productList');
        break;
      case 'productType':
        navigate.replace('/admin/productTypeList');
        break;
      case 'subscription':
        navigate.replace('/admin/subscriptionList');
        break;
      case 'subscriptionItem':
        navigate.replace('/admin/subscriptionItemList');
        break;
      case 'restaurant':
        navigate.replace('/admin/restaurantList');
        break;
      case 'plan':
        navigate.replace('/admin/planList');
        break;
    }
  };

  const isNotValid = false;

  return (
    <Page>
      <Navigation onBack={() => onBackButton()}>
        {lendConfig.adminAction === 'billing_assign_subscription'
          ? t('AssignAnExistingSubscriptionTitle')
          : t('CreateASubscriptionTitle')}
      </Navigation>
      <StyledContainer>
        {isFocus && (
          <>
            <Wrapper>
              {lendConfig.adminAction === 'billing_assign_subscription' && (
                <>
                  <Block>
                    <Text align="center" config="screen-title-regular">
                      {t('SubscriptionSelected')}
                    </Text>
                    <Text align="center" config="secondary-screen-title">
                      {lendConfig.subscription.description}
                    </Text>
                    <Link
                      config="small-text-title"
                      align="center"
                      isLink
                      onClick={() => openChangeAction('subscription')}
                    >
                      {t('Change')}
                    </Link>
                  </Block>
                  <Block>
                    <Text align="center" config="screen-title-regular">
                      {t('SubscriptionItemSelected')}
                    </Text>
                    <Text align="center" config="secondary-screen-title">
                      {lendConfig.subscriptionItem.plan_name}
                    </Text>
                    <Link
                      config="small-text-title"
                      align="center"
                      isLink
                      onClick={() => openChangeAction('subscriptionItem')}
                    >
                      {t('Change')}
                    </Link>
                  </Block>
                  <Block>
                    <Text align="center" config="screen-title-regular">
                      {lendConfig.productTypes.length > 1
                        ? t('ProductTypeListSelected')
                        : t('ProductTypeSelected')}
                    </Text>

                    <Text align="center" config="secondary-screen-title">
                      {lendConfig.productTypes
                        .map((productType: any) => productType.code)
                        .join(', ')}
                    </Text>

                    <Link
                      config="small-text-title"
                      align="center"
                      isLink
                      onClick={() => openChangeAction('productType')}
                    >
                      {t('Change')}
                    </Link>
                  </Block>
                </>
              )}
              {lendConfig.adminAction === 'billing_create_subscription' && (
                <>
                  <Block>
                    <Text align="center" config="screen-title-regular">
                      {t('ProductSelected')}
                    </Text>
                    <Text align="center" config="secondary-screen-title">
                      {lendConfig.product.name}
                    </Text>

                    <Link
                      config="small-text-title"
                      align="center"
                      isLink
                      onClick={() => openChangeAction('product')}
                    >
                      {t('Change')}
                    </Link>
                  </Block>
                  <Block>
                    <Text align="center" config="screen-title-regular">
                      {t('PlanSelected')}
                    </Text>
                    <Text align="center" config="secondary-screen-title">
                      {lendConfig.plan.name}
                    </Text>
                    <Link
                      config="small-text-title"
                      align="center"
                      isLink
                      onClick={() => openChangeAction('plan')}
                    >
                      {t('Change')}
                    </Link>
                  </Block>
                  <Block>
                    <Text align="center" config="screen-title-regular">
                      {lendConfig.productTypes.length > 1
                        ? t('ProductTypeListSelected')
                        : t('ProductTypeSelected')}
                    </Text>
                    <Text align="center" config="secondary-screen-title">
                      {lendConfig.productTypes
                        .map((productType: any) => productType.code)
                        .join(', ')}
                    </Text>
                    <Link
                      config="small-text-title"
                      align="center"
                      isLink
                      onClick={() => openChangeAction('productType')}
                    >
                      {t('Change')}
                    </Link>
                  </Block>
                </>
              )}
              <StyledLine />
              <Block>
                <StyledIcon name="tick" />
                {!lendConfig.restaurant ? (
                  <Text align="center" config="screen-title-regular">
                    {t('SummaryClientPlaceholder')}
                  </Text>
                ) : (
                  <Text align="center" config="screen-title-regular">
                    {lendConfig.restaurant.name}
                  </Text>
                )}
                <Link
                  config="small-text-title"
                  align="center"
                  isLink
                  onClick={() => openChangeAction('restaurant')}
                >
                  {t('Change')}
                </Link>
              </Block>
            </Wrapper>
          </>
        )}
        <FixedBlock>
          <Button
            disabled={isNotValid || isButtonDisabled}
            expand="full"
            onClick={handleConfirmation}
          >
            {t('Confirm')}
          </Button>
        </FixedBlock>
      </StyledContainer>
    </Page>
  );
};

export default BillingSummary;
