import useActivePartners, { getActivePartners } from '@/hooks/useActivePartners';

import { Partner } from '@/features/shared';
import { queryClient } from '@/lib/react-query';
import { useQuery } from 'react-query';

const storePartnersInCache = (partners: Partner[]) => {
  if (partners && partners.length > 0)
    partners.map((partner: Partner) => {
      queryClient.setQueryData(['partner', partner.id], partner);
    });
};

export const useGetActivePartners = (config = {}): any[] => {
  const getActivePartners: any = useActivePartners(config);
  const partners = getActivePartners?.data?.restaurants as Partner[];
  storePartnersInCache(partners);

  return partners ? partners : [];
};

export const useGetLastActivePartners = (props: any = {}): any[] => {
  const { requestParams, config } = props;

  const getMostPopularPartners: any = useQuery({
    queryKey: ['getLastActivePartners'],
    queryFn: () => getActivePartners({ limit: 15, order: '-createdAt', ...requestParams }),
    ...config,
  });
  const partners = getMostPopularPartners?.data?.data as Partner[];
  storePartnersInCache(partners);

  return partners ? partners : [];
};

export const useGetMostPopularPartners = (props: any = {}): any[] => {
  const { requestParams, config, position } = props;

  const getMostPopularPartners: any = useQuery({
    queryKey: ['getMostPopularPartners'],
    queryFn: () =>
      getActivePartners({
        distance: position,
        lends: false,
        limit: 15,
        order: '-weekLends',
        ...requestParams,
      }),
    ...config,
  });
  const partners = getMostPopularPartners?.data?.data as Partner[];

  storePartnersInCache(partners);

  return partners ? partners : [];
};
