import types from './types';

export interface LendConfig {
  containers: Array<any>;
  user?: any;
  restaurant?: any;
  adminAction: undefined;
  product?: any;
  plan?: any;
  productType?: any;
  subscription?: any;
  subscriptionItem?: any;
}

const setLendConfig = (config: LendConfig) => ({
  type: types.SET_LEND_CONFIG,
  payload: config,
});

export default { setLendConfig };
