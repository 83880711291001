import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeliveryOptions } from '@/features/shared';
import { Button } from '@bumerang-kit/components';
import NoCodeDialog from './NoCodeDialog';
import Icon from '@bumerang-kit/components/Icon';

type DeliveryRedirectDialogProps = {
  open: boolean;
  code?: string;
  deliveries: DeliveryOptions;
  onClose: () => void;
  onConfirm: (url: any) => void;
  selected?: string;
};

const DeliveryRedirectDialog = (props: DeliveryRedirectDialogProps) => {
  const { open, onClose, onConfirm, code, deliveries, selected } = props;
  const deliveryKeys = deliveries ? Object.keys(deliveries) : [];
  const [chosenDelivery, setChosenDelivery] = React.useState<string | undefined>(selected);
  const [isSingleOption] = React.useState(deliveryKeys ? deliveryKeys.length === 1 : false);
  const { t } = useTranslation();

  if (!open || deliveryKeys.length === 0) {
    return null;
  }

  if (open && selected) {
    return (
      <div className="absolute h-full w-full">
        <div
          onClick={() => onClose()}
          className="absolute h-full w-full bg-black opacity-50 z-50  border-2 border-red-50"
        />
        <div className="absolute pt-8 flex flex-col top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-auto z-50 w-[90%] bg-white rounded-lg p-3">
          <div className="absolute top-3 right-4 cursor-pointer">
            <Icon name={'cross'} onClick={() => onClose()} />
          </div>
          <h1 className="text-main font-montserrat text-3xl font-bold leading-9 text-center mb-2">
            {t('PartnerProfile.DialogHeader')}
          </h1>
          <h3 className="text-let text-xs font-montserrat font-bold text-main mb-2">
            {t(
              `DeliveryRedirectDialog.PasteInstructions_${selected
                ?.trim()
                .replace(/ /g, '')
                .toLowerCase()}`
            )}
          </h3>
          <div className="w-full  h-auto border-[0.5px] border-gray-400 rounded-lg p-2 bg-background-blue mb-4">
            <p className="text-left text-xs text-main">
              {
                //This text is left untranslated since a spanish restaurant will read it
              }
              &quot;
              <strong className="whitespace-nowrap">Código Bûmerang: {`${code}`}</strong> - <br />{' '}
              Por favor, entregue mi comida en un envase Bûmerang.
            </p>
          </div>
          <Button
            onClick={() => {
              if (selected) onConfirm((deliveries as any)[selected]);
            }}
          >
            {selected === 'Delivery propio'
              ? t('PartnerProfile.DialogButton', { deliveryName: selected })
              : t('PartnerProfile.DialogButton', { deliveryName: selected })}
          </Button>
        </div>
      </div>
    );
  }
  if (open && !code) return <NoCodeDialog onClose={() => onClose()} />;

  return (
    <>
      <div onClick={() => onClose()} className="absolute h-full w-full bg-black opacity-50 z-50 " />
      <div className="absolute pt-8 flex flex-col top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-auto z-50 w-[90%] bg-white rounded-lg p-3">
        <div className="absolute top-3 right-4 cursor-pointer">
          <Icon name={'cross'} onClick={() => onClose()} />
        </div>
        <h1 className="text-main font-montserrat text-3xl font-bold leading-9 text-center ">
          {t('PartnerProfile.DialogHeader')}
        </h1>
        {!isSingleOption && (
          <div className="my-4">
            <h3 className="text-let text-xs font-montserrat font-bold text-main">
              {t('PartnerProfile.FirstDialogInstruction')}
            </h3>
            <ul
              onChange={(event: any) => {
                const key = event.target.value;
                setChosenDelivery(key);
              }}
              className="mt-2"
            >
              {deliveryKeys.map((key) => (
                <li
                  key={key}
                  className="flex justify-between h-6 text-xs font-montserrat items-center text-main"
                >
                  <label>{key}</label>
                  <input
                    className="accent-main"
                    checked={key === chosenDelivery}
                    type="radio"
                    value={key}
                    name="delivery"
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
        <h3 className="text-let text-xs font-montserrat font-bold text-main mb-2">
          {`${isSingleOption ? '' : '2. '} ${t(
            `DeliveryRedirectDialog.PasteInstructions_${selected?.trim().toLowerCase()}`
          )}`}
        </h3>
        <div className="w-full  h-auto border-[0.5px] border-gray-400 rounded-lg p-2 bg-background-blue mb-4">
          <p className="text-left text-xs text-main">
            {
              //This text is left untranslated since a spanish restaurant will read it
            }
            &quot;
            <strong className="whitespace-nowrap">Código Bûmerang: {`${code}`}</strong> - <br /> Por
            favor, entregue mi comida en un envase Bûmerang.
          </p>
        </div>
        <Button
          onClick={() => {
            if (chosenDelivery) onConfirm((deliveries as any)[chosenDelivery]);
          }}
          disabled={!isSingleOption && !chosenDelivery}
        >
          {isSingleOption
            ? t('PartnerProfile.DialogButton', { deliveryName: deliveryKeys[0] })
            : t('PartnerProfile.GenericDialogButton')}
        </Button>
      </div>
    </>
  );
};
export default DeliveryRedirectDialog;
