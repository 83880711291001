import * as React from 'react';
import { BottomHalfModal } from '@bumerang-kit/components';
import ResendEmail from '@/pages/ResendEmail';
import AddCard from '@/pages/AddCard';
import QRModalSliders from './QrModalSlidersContainers';

const UserStatusIsPending = ({ onDismiss }: any) => <ResendEmail onDismiss={onDismiss} />;

const UserHasNotConfiguredPayment = ({ onDismiss }: any) => <AddCard onDismiss={onDismiss} />;

const QRModal = ({
  isOpen,
  qr,
  onDismiss,
  onCopy,
  onChat,
  currentUser,
  paymentStatus,
}: {
  isOpen: boolean;
  qr: string;
  onDismiss: () => void;
  onCopy: any;
  onChat: () => void;
  currentUser: any;
  paymentStatus: any;
}) => {
  const getStatusView = () => {
    if (currentUser.status === 'pending') return <UserStatusIsPending onDismiss={onDismiss} />;
    if (currentUser.status === 'confirmed' && paymentStatus !== 'configured')
      return <UserHasNotConfiguredPayment onDismiss={onDismiss} />;
    return (
      <QRModalSliders
        onDismiss={onDismiss}
        currentUser={currentUser}
        onCopy={onCopy}
        onChat={onChat}
        qr={qr}
      />
    );
  };

  return (
    <React.Fragment>
      <BottomHalfModal isOpen={isOpen} onDismiss={onDismiss}>
        {getStatusView()}
      </BottomHalfModal>
    </React.Fragment>
  );
};

export default QRModal;
