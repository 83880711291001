import React, { useRef, useState } from 'react';
import { ENV } from '../../../../../../env/env';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import ClusterMarker from '../ClusterMarker';
import { Button, Icon, MapMarker } from '@bumerang-kit/components';
import CustomerMarker from '../CustomerMarker';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import styled from 'styled-components';

import { Coords, Partner } from '@/features/shared';

const coordinatesFromBarcelona = {
  lat: 41.3851,
  lng: 2.1734,
};

const StyledButton = styled(Button)`
  margin-bottom: ${Spacing.xs};
  bottom: max(75px, calc(var(--ion-safe-area-bottom) + 75px));
`;

const getMapOptions = () => {
  return {
    clickableIcons: false,
    disableDefaultUI: true,
    streetViewControl: false,
    gestureHandling: 'greedy',
    minZoom: 6,
    maxZoom: 20,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }],
      },
    ],
  };
};

const formatPartners = (partners: Partner[]) => {
  return partners.map((partner) => ({
    type: 'Feature',
    properties: { cluster: false, ...partner },
    geometry: {
      type: 'Point',
      coordinates: [partner.lng, partner.lat],
    },
  }));
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type MapProps = {
  currentPosition?: Coords;
  partners: Partner[];
  onMarkerClick: (restaurant: Partner) => void;
  onDismiss: () => void;
};

const CustomMap = (props: MapProps) => {
  const { currentPosition, partners, onMarkerClick, onDismiss } = props;
  const mapRef: any = useRef();
  const [bounds, setBounds] = useState<number[] | null>(null);
  const [zoom, setZoom] = useState<number>(10);
  const [selected, setSelected] = useState<Partner | undefined>();
  const points = formatPartners(partners);
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 80, maxZoom: 20 },
  });
  const handleClusterClick = (cluster: any, latitude: number, longitude: number) => {
    const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
    mapRef.current.setZoom(expansionZoom);
    mapRef.current.panTo({ lat: latitude, lng: longitude });
  };

  return (
    <div className="h-full w-full">
      <GoogleMapReact
        onClick={() => {
          onDismiss();
          setSelected(undefined);
        }}
        bootstrapURLKeys={{
          key: ENV.GOOGLE_MAPS_API_KEY,
          language: 'es',
          region: 'es',
        }}
        yesIWantToUseGoogleMapApiInternals
        defaultCenter={coordinatesFromBarcelona}
        center={currentPosition ? currentPosition : coordinatesFromBarcelona}
        zoom={10}
        options={getMapOptions}
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
        }}
      >
        {currentPosition && (
          <CustomerMarker key={'customer'} lat={currentPosition.lat} lng={currentPosition.lng} />
        )}

        {clusters.map((cluster: any) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } = cluster.properties;

          return isCluster ? (
            <ClusterMarker
              key={cluster.properties.id}
              lat={latitude}
              lng={longitude}
              pointCount={pointCount}
              onClick={() => handleClusterClick(cluster, latitude, longitude)}
            />
          ) : (
            <MapMarker
              selected={selected?.id === cluster.properties.id}
              key={cluster.properties.id}
              lat={latitude}
              lng={longitude}
              productGroup={cluster.properties.productGroupTypeList}
              onClick={(event: any) => {
                event.stopPropagation();
                onMarkerClick(cluster.properties);
                setSelected(cluster.properties);
              }}
            />
          );
        })}
      </GoogleMapReact>

      <div className="absolute right-m top-m">
        <StyledButton fab onClick={() => mapRef.current.setCenter(currentPosition)}>
          <Icon color={Colors.white} name="gps" />
        </StyledButton>
      </div>
    </div>
  );
};

export default CustomMap;
