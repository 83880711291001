import Settings from './settings';

class ApiCaller {
  token: string;

  constructor(token?: string) {
    this.token = token || '';
  }

  call(url: string, method = 'GET', params?: object, headers?: object): Promise<any> {
    const fetchParams: any = {
      method,
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    };

    if (this.token) {
      fetchParams.headers.Authorization = `Bearer ${this.token}`;
    }

    const finalUrl = new URL(Settings.getApiURL() + url);
    const apiVersion = Settings.getAPIVersion();

    finalUrl.searchParams.append('version', apiVersion);
    if (this.token) {
      finalUrl.searchParams.append('token', this.token);
    }

    if (params) {
      if (method !== 'GET') {
        fetchParams.body = JSON.stringify(params);
      } else {
        const queryParams: { [key: string]: string } = params as {
          [key: string]: string;
        };
        Object.keys(queryParams).forEach((key) =>
          finalUrl.searchParams.append(key, queryParams[key])
        );
      }
    }
    return fetch(finalUrl.toString(), fetchParams)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  }
}

export default ApiCaller;
