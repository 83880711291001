import { PartnerCard } from '@bumerang-kit/components';
import React from 'react';

import { Partner } from '@/features/shared';

export type PartnerCardMapProps = {
  partner: Partner | null;
  onClick: () => void;
};

const PartnerCardMap = (props: PartnerCardMapProps) => {
  const { partner, onClick } = props;

  return !partner ? null : (
    <div className="pointer-events-none fixed left-0 bottom-0 h-full w-full ">
      <PartnerCard
        className="fixed pointer-events-auto bottom-20 pb-safe m-0 left-[50%] translate-x-[-50%] w-[95%] "
        direction="horizontal"
        partner={partner}
        onClick={onClick}
      />
    </div>
  );
};
export default PartnerCardMap;
