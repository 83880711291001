import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Trans, useTranslation } from 'react-i18next';
import { NoticeModal, Text, Button } from '@bumerang-kit/components';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import { useFeatures } from '@bumerang-kit/helpers';

const StyledButton = styled(Button)`
  margin-top: ${Spacing.m};
`;

const StyledText = styled(Text)`
  padding: ${Spacing.m};
`;

const SystemChangeNoticeModal = ({ onChat, isRestaurant = false }: any) => {
  const feature: any = useFeatures('system_change_modal');
  const [isActive, updateIsActive] = useState(false);
  const [affectedDays, setAffectedDays] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    if (feature) {
      updateIsActive(feature.is_activated);

      if (feature?.config['affected_days']) setAffectedDays(feature?.config['affected_days']);
    }
  }, [feature]);

  if (!isActive) return null;

  return (
    <NoticeModal
      content={
        <>
          <StyledText config="screen-title-regular" align="center" color={Colors.mainColor}>
            <Trans i18nKey="ServiceDownMainNotice" values={{ affectedDays }} />
            <br />
            <br />
            {isRestaurant ? (
              <Trans i18nKey="IfQuestions" />
            ) : (
              <Trans i18nKey="ContactUsIfContainers" />
            )}
          </StyledText>
          <StyledButton secondary onClick={() => onChat()}>
            {t('ContactThroughChat')}
          </StyledButton>
        </>
      }
    />
  );
};

export default SystemChangeNoticeModal;
