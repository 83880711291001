import * as React from 'react';
import Icon from '@bumerang-kit/components/Icon';
import { Colors } from '@bumerang-kit/foundations';

// eslint-disable-next-line @typescript-eslint/ban-types
export type FabButtonProps = {
  name: string;
  onClick: () => void;
  className?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FabButton = (props: FabButtonProps) => {
  const { name, onClick, className } = props;

  return (
    <div
      onClick={onClick}
      className={`drop-shadow-lg bg-white h-[50px] w-[50px] rounded-full flex flex-col p-4 justify-center align-middle items-center ${className}`}
    >
      <Icon color={Colors.mainColor} name={name} />
    </div>
  );
};

export default FabButton;
