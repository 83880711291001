import React from 'react';
import { IonImg } from '@ionic/react';
import styled from 'styled-components';
import { Button } from '../../atoms';
import { Spacing } from '../../../foundations';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(Button)`
  margin-bottom: ${Spacing.xs};
`;

const SocialButton = ({ onClick, provider }: any) => {
  const assetsUrl = `assets/${provider.toLowerCase()}-logo-social-login.png`;
  const { t } = useTranslation();
  return (
    <StyledButton social onClick={() => onClick()}>
      <IonImg slot="start" className="social-login-button-logo" src={assetsUrl} />
      {t('SocialButtonTitle', { provider })}
    </StyledButton>
  );
};

export default SocialButton;
