import React from 'react';
import { isPlatform } from '@ionic/react';
import styled from 'styled-components';
import { Spacing } from '../../../foundations';
import SocialButton from './SocialButton';

const SocialButtonWrapper = styled.div`
  margin-bottom: ${Spacing.xs};
`;

const LoginButtons = ({ onGoogle, onApple, children }: any) => (
  <SocialButtonWrapper>
    <SocialButton provider={'Google'} onClick={onGoogle} />
    {isPlatform('ios') && !isPlatform('mobileweb') && (
      <SocialButton provider={'Apple'} onClick={onApple} />
    )}
    {children}
  </SocialButtonWrapper>
);

export default LoginButtons;
