import React from 'react';
import styled from 'styled-components';

import { Colors, Spacing } from '../../../../foundations';
import { Text } from '../../../index';

const Item: any = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.backgroundBlue};
  padding: ${Spacing.s} ${Spacing.m};

  ${(props: any) => props.selected && `background-color: ${Colors.backgroundBlue}`}
`;

const Radius: any = styled.div`
  border: 1px solid white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  box-shadow: 0 0 0 1px ${Colors.black};

  ${(props: any) =>
    props.selected &&
    `
    background-color: ${Colors.mainColor};
    box-shadow: 0 0 0 1px ${Colors.mainColor};
    `}
`;

const ListItem = ({ title, description, value, selected, onClick }: any) => (
  <Item selected={selected} key={value} onClick={() => onClick(value)}>
    <div>
      <Text config="small-text">{title}</Text>
      <Text config="small-text">{description}</Text>
    </div>
    <Radius selected={selected} />
  </Item>
);

export default ListItem;
