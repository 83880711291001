import React from 'react';
import { IonIcon } from '@ionic/react';
import styled from 'styled-components';

import Text from '../Text';
import { Colors, Spacing } from '../../../foundations';

const Section = styled.div`
  text-align: center;
`;

const StyledIcon = styled(IonIcon)`
  width: 100%;
  height: 120px;
  margin-bottom: ${Spacing.xs};
`;

const Title = styled(Text)`
  margin-bottom: ${Spacing.xs};
  color: #322e8f;
`;

const Placeholder = ({
  title,
  description,
  icon,
  selfAssignConfirmation,
}: {
  title: string;
  description?: string;
  icon: string;
  selfAssignConfirmation?: boolean;
}) => (
  <Section>
    <StyledIcon icon={icon}></StyledIcon>
    {selfAssignConfirmation ? (
      <Title config="onboarding-title" align="center">
        {title}
      </Title>
    ) : (
      <Title config="secondary-screen-title" align="center">
        {title}
      </Title>
    )}
    {description && (
      <Text color={Colors.mainColor} config="secondary-text" align="center">
        {description}
      </Text>
    )}
  </Section>
);

export default Placeholder;
