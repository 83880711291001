import * as React from 'react';
import { Line } from '@bumerang-kit/components';
import Icon from '@bumerang-kit/components/Icon';
import { useTranslation } from 'react-i18next';

type ContactInfoProps = {
  phone?: string;
  website?: string;
  onPhone?: () => void;
  onWebsite?: () => void;
};

const ContactInfo = (props: ContactInfoProps) => {
  const { phone, website, onPhone, onWebsite } = props;
  const { t } = useTranslation();

  if (!phone && !website) {
    return null;
  }

  return (
    <>
      <Line />
      <section>
        <main className="flex items-center justify-center align-middle gap-11 mt-3 mb-1">
          {phone && (
            <div className="flex flex-col justify-center content-center items-center">
              <div className="flex flex-col rounded-full h-8 w-8 justify-center content-center items-center border-[1px] border-main">
                <Icon
                  type={'primary'}
                  name={'phone'}
                  size={'20px'}
                  onClick={() => onPhone && onPhone()}
                />
              </div>
              <p className="text-xs font-bold text-main text-center">{t('Call')}</p>
            </div>
          )}
          {website && (
            <div className="flex flex-col ">
              <div className="rounded-full h-8 w-8 flex justify-center items-center  border-[1px] border-main">
                <Icon
                  isRounded
                  type={'none'}
                  name={'web'}
                  size={'24px'}
                  onClick={() => onWebsite && onWebsite()}
                />
              </div>
              <p className="text-xs font-bold text-main text-center">{t('Web')}</p>
            </div>
          )}
        </main>
      </section>
    </>
  );
};

export default ContactInfo;
