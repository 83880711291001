import * as React from 'react';
import { Redirect, Route } from 'react-router';
import { getCurrentSession, isLogin } from '../helpers/session';

const PrivateRoute = ({ component: Component, as, ...rest }: any) => {
  const currentUser = getCurrentSession();

  return (
    <Route {...rest}>
      {isLogin() && currentUser.type === as ? <Component /> : <Redirect to="/" />}
    </Route>
  );
};

export default React.memo(PrivateRoute);
