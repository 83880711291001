import * as React from 'react';
import CustomMap from './components/CustomMap';
import PartnerCardMap from './components/PartnerCardMap';
import { Container } from '@bumerang-kit/layouts';

import { Partner } from '@/features/shared';

type coords = {
  lat: number;
  lng: number;
};

type PartnerListTabProps = {
  partners: Partner[];
  currentPosition?: coords;
  onPartnerCardClick: (partner: Partner | null) => void;
};

const MapTab = (props: PartnerListTabProps) => {
  const { partners, currentPosition, onPartnerCardClick } = props;
  const [selected, setSelected] = React.useState<Partner | null>(null);

  return (
    <Container noPadding>
      <CustomMap
        currentPosition={currentPosition}
        partners={partners}
        onMarkerClick={(partner: Partner) => setSelected(partner)}
        onDismiss={() => setSelected(null)}
      />

      <PartnerCardMap partner={selected} onClick={() => onPartnerCardClick(selected)} />
    </Container>
  );
};

export default MapTab;
