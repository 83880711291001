import * as React from 'react';
import { useHistory } from 'react-router';
import { Navigation, PartnerCard } from '@bumerang-kit/components';
import { Container } from '@bumerang-kit/layouts';

import { useGetMostPopularPartners } from '@/features/logistics';
import { NavigationWrapper, Partner } from '@/features/shared';
import { useGetCurrentLocation } from '@/features/explorer';
import { useTranslation } from 'react-i18next';

const PopularPartners: React.FC = () => {
  const [isQRModalOpen, setIsQRModalOpen] = React.useState<boolean>(false);
  const navigate = useHistory();
  const { t } = useTranslation();
  const getThreeMostPopularPartners: Partner[] = useGetMostPopularPartners();
  const { position } = useGetCurrentLocation();

  const Row = (partner: Partner, cardDirection: 'horizontal' | 'vertical', index: number) => {
    return (
      <PartnerCard
        onClick={() => {
          navigate.push(`/partner/${partner.id}/details`);
        }}
        partner={partner}
        direction={cardDirection}
        itemId={index}
        className={`${cardDirection === 'vertical' ? 'mr-2 ' : 'mr-0'} mb-4 min-w-[270px]`}
        currentPosition={position}
        key={index}
      />
    );
  };

  return (
    <NavigationWrapper
      active="customer"
      type="customer"
      isQRModalOpen={isQRModalOpen}
      onOpenQRModal={(isOpen: boolean) => setIsQRModalOpen(isOpen)}
    >
      <Navigation onClose={() => navigate.go(-1)}>{t('PopularPartnersTitle')}</Navigation>
      <Container>
        <section className="relative mb-10">
          {getThreeMostPopularPartners
            .slice(0, 15)
            .map((partner: Partner, index: number) => Row(partner, 'horizontal', index))}
        </section>
      </Container>
    </NavigationWrapper>
  );
};

export default PopularPartners;
