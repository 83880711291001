import React from 'react';
import styled from 'styled-components';
import { Text } from '../../atoms';
import { Spacing, Colors, Sizes } from '../../../foundations';
import StarRatings from 'react-star-ratings';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;
const NumberRating = styled(Text)`
  margin-right: ${Spacing.xs};
  background-image: url(${({ color }: any) => color});
`;
const Rating = ({ rating, starsColor, ...props }: { rating: any; starsColor: string }) => {
  return (
    <StyledDiv {...props}>
      <NumberRating color={starsColor} config="secondary-text-title">
        {rating || 0}
      </NumberRating>
      <StarRatings
        rating={rating || 0}
        starRatedColor={starsColor}
        starEmptyColor={Colors.mainColorDisabled}
        starDimension={Sizes.s}
        numberOfStars={5}
        starSpacing="1.6px"
      ></StarRatings>
    </StyledDiv>
  );
};

export default Rating;
