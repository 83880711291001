import { BottomSheet } from '@bumerang-kit/components';
import { ContactInfo, DeliveryList, Description, Header, Schedule } from './components';
import React from 'react';
import { Coords, Partner } from '@/features/shared';
import ProductIcon from '../../../../../../features/shared/components/ProductIcon';

type PartnerSheetProps = {
  partner: Partner;
  onDirections: () => void;
  onPhone: () => void;
  onWeb: () => void;
  onHowItWorks: () => void;
  onDelivery: (key: string) => void;
  currentPosition?: Coords;
};

const PartnerSheet = ({
  partner,
  onDirections,
  onPhone,
  onWeb,
  onHowItWorks,
  onDelivery,
  currentPosition,
}: PartnerSheetProps) => {
  return (
    <div className="-mt-3 h-full pb-12 pb-safe w-full overflow-scroll rounded-lg bg-transparent scrollbar-hide ">
      <ProductIcon
        className="absolute right-4 z-50 -mt-4 rounded-full border-4 border-white"
        productGroup={partner.productGroupTypeList}
      />
      <BottomSheet>
        <Header partner={partner} position={currentPosition} onDirections={() => onDirections()} />

        <DeliveryList
          deliveries={partner.delivery}
          onHowItWorksClick={() => onHowItWorks()}
          onClick={(key) => {
            onDelivery(key);
          }}
        />

        <Description description={partner.description || ''} />

        <ContactInfo
          phone={partner.phone}
          website={partner.website}
          onWebsite={() => onWeb()}
          onPhone={() => onPhone()}
        />

        <Schedule hours={partner.hours} />
      </BottomSheet>
    </div>
  );
};
export default PartnerSheet;
