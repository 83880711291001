import React from 'react';
import { IbanElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import './IbanFormStyles.css';

import { Input, Button } from '@bumerang-kit/components';
import { Spacing, Colors } from '@bumerang-kit/foundations';

const FormRow = styled.div`
  margin-top: ${Spacing.s};
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.m};
`;

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
  base: {
    color: Colors.mainColor,
    fontSize: '16px',
    '::placeholder': {
      color: Colors.actionableGrey,
    },
    ':-webkit-autofill': {
      color: Colors.mainColor,
    },
  },
  invalid: {
    color: Colors.error,
    iconColor: Colors.error,
    ':-webkit-autofill': {
      color: Colors.error,
    },
  },
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  placeholderCountry: 'ES',
  style: IBAN_STYLE,
};

export default function IbanForm({
  onSubmit,
  disabled,
  value,
}: {
  onSubmit: any;
  disabled: boolean;
  value?: string;
}) {
  return (
    <form onSubmit={onSubmit}>
      <FormRow>
        <label>
          Customer ID
          {value ? (
            <Input
              name="accountholder-id"
              placeholder="cus_Je8vG3m5YNFvor"
              required
              value={value}
              readonly
            />
          ) : (
            <Input name="accountholder-id" placeholder="cus_Je8vG3m5YNFvor" required />
          )}
        </label>
      </FormRow>

      <FormRow>
        <label htmlFor="iban">
          IBAN
          <IbanElement options={IBAN_ELEMENT_OPTIONS} />
        </label>
      </FormRow>

      <StyledButton type="submit" disabled={disabled}>
        Set up SEPA Direct Debit
      </StyledButton>
    </form>
  );
}
