import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IonContent } from '@ionic/react';
import styled from 'styled-components';
import { Page } from '@bumerang-kit/layouts';

import { Restaurant } from '../../lib/common/models';
import { Input, Navigation, Text } from '@bumerang-kit/components';
import RestaurantApi from '../../api/RestaurantApi';
import ApiCaller from '../../lib/ApiCaller';
import { ListCard } from '@bumerang-kit/components/molecules';
import { getCurrentSession } from '@/helpers/session';
import Settings from '../../lib/settings';
import { Spacing } from '@bumerang-kit/foundations';
import useInfiniteLoading from '@bumerang-kit/helpers/Hooks/useInfiniteLoading';

const Container = styled.div`
  padding-left: ${Spacing.xs};
  padding-right: ${Spacing.xs};
  border: 0 solid white;
  border-bottom: 0px;
  justify-content: center;
  align-items: center;
`;

const StyledForm = styled.form`
  margin-top: ${Spacing.xs};
  margin-bottom: ${Spacing.xs};
`;

const RestaurantList = ({ lendConfig, setLendConfig }: any) => {
  const navigate = useHistory();
  const location = useLocation();
  const currentUser = getCurrentSession();
  const restaurantApi = new RestaurantApi(new ApiCaller(currentUser.token));
  const { t } = useTranslation();

  const [searchText, setSearchText] = React.useState<string | null>('');
  const [previousSearchText, setPreviousSearchText] = React.useState<string | null>('');
  const [showTags, setShowTags] = React.useState<boolean>(true);
  const [query, setQuery] = React.useState('');
  const pagination = useInfiniteLoading({
    limit: 20,
    initialEnable: true,
    getItems: async ({ from }: { from: number }) => {
      return restaurantApi
        .getRestaurants(
          from,
          20,
          searchText !== '' ? searchText : null,
          lendConfig.adminAction !== 'edit'
            ? lendConfig.adminAction === 'retrieve_containers_restaurant' ||
                lendConfig.adminAction === 'assign_containers_final_customer'
            : undefined
        )
        .then((data: any) => {
          return {
            items: data.data,
            pagination: data.pagination,
          };
        });
    },
  });

  const setLendConfigStorage = (state: any) => {
    Settings.saveStateInSession(state);
    setLendConfig(state);
  };
  useEffect(() => {
    const pathname = location?.pathname;
    if (pathname === '/admin/restaurantList') {
      if (!lendConfig.adminAction) {
        const state = Settings.getStateInSession();
        if (state?.adminAction) {
          setLendConfigStorage({
            ...lendConfig,
            ...state,
          });
        } else {
          setLendConfigStorage({
            ...lendConfig,
            adminAction: 'edit',
          });
        }
      }
      setPreviousSearchText('');
      setSearchText('');
      pagination.resetPagination().then(() => console.log('reset'));
    }
  }, [location]);

  useEffect(() => {
    lendConfig.adminAction === 'edit' ? setShowTags(true) : setShowTags(false);
  }, [location]);

  useEffect(() => {
    if (pagination.isLoading) return;
    if (searchText !== previousSearchText) {
      setPreviousSearchText(searchText);
      pagination.resetPagination().then(() => console.log('reset'));
    }
  }, [searchText, pagination.isLoading]);

  const openAction = async (restaurant: any) => {
    setLendConfigStorage({
      ...lendConfig,
      restaurant,
    });
    switch (lendConfig.adminAction) {
      case 'assign_containers_restaurant':
        navigate.replace('/admin/restaurant/containers/assign');
        break;
      case 'retrieve_containers_restaurant':
        navigate.replace('/admin/restaurant/containers/retrieve');
        break;
      case 'assign_containers_final_customer':
        navigate.replace('/admin/userList');
        break;
      case 'retrieve_containers_final_customer':
        navigate.replace('/admin/user/containers/collect');
        break;
      case 'billing_sepa':
        navigate.replace('/admin/billing/sepa');
        break;
      case 'billing_assign_subscription':
        navigate.replace('/admin/subscriptionList');
        break;
      case 'billing_create_subscription':
        navigate.replace('/admin/productList');
        break;
      case 'movement_history':
        navigate.replace('/admin/restaurant/movements');
        break;
      default:
        navigate.replace('/admin/restaurant/edit');
        break;
    }
    setPreviousSearchText('');
    setSearchText('');
    setQuery('');
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchText(query), 250);
    return () => clearTimeout(timeOutId);
  }, [query]);

  const onBackButton = async () => {
    switch (lendConfig.adminAction) {
      case 'assign_containers_restaurant':
      case 'assign_containers_final_customer':
        setLendConfigStorage({
          ...lendConfig,
          adminAction: 'assign_containers',
        });
        navigate.replace('/admin/selectUserType');
        break;
      case 'retrieve_containers_restaurant':
      case 'retrieve_containers_final_customer':
        setLendConfigStorage({
          ...lendConfig,
          adminAction: 'retrieve_containers',
        });
        navigate.replace('/admin/selectUserType');
        break;
      case 'billing_sepa':
      case 'billing_assign_subscription':
      case 'billing_create_subscription':
        setLendConfigStorage({
          ...lendConfig,
          adminAction: 'billing',
        });
        navigate.replace('/admin/billing');
        break;
      default:
        setLendConfigStorage({
          ...lendConfig,
          adminAction: null,
        });
        navigate.replace('/admin');
    }
    setSearchText('');
    setPreviousSearchText('');
    setQuery('');
  };
  const finalRestaurantList = pagination.items || [];

  return (
    <Page>
      <Navigation
        onBack={() => onBackButton()}
        iconAction={async () => restaurantApi.updateLendCount()}
        iconName="sync"
      >
        <Text align="center" config="secondary-screen-title">
          {lendConfig.adminAction !== 'retrieve_containers_final_customer'
            ? t('SelectRestaurantTitle')
            : t('SelectDestinationTitle')}
        </Text>
      </Navigation>
      <Container>
        <StyledForm>
          <Input
            label={t('Search')}
            name="search"
            id="search"
            type="text"
            value={query}
            onChange={(event: any) => setQuery(event.target.value)}
          />
        </StyledForm>
      </Container>
      <IonContent>
        {finalRestaurantList.map((restaurant: Restaurant, index: number) => {
          return (
            <ListCard
              key={index}
              showTags={showTags}
              restaurant={restaurant}
              onClick={() => openAction(restaurant)}
            />
          );
        })}
        {pagination.hasNext && (
          <button ref={pagination.loadMoreRef} onClick={() => pagination.loadNext()} />
        )}
      </IonContent>
    </Page>
  );
};

export default RestaurantList;
