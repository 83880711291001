import {
  IonIcon,
  IonImg,
  IonItem,
  IonItemSliding,
  IonReorder,
  IonReorderGroup,
} from '@ionic/react';
import { trash } from 'ionicons/icons';
import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../index';

interface ReorderProps {
  isVisible: boolean;
  onClose: any;
  itemList?: Array<any>;
  onReorder: (event: any) => void;
  onRemove: (index: number) => void;
}

const StyledImg = styled(IonImg)`
  max-width: 90px;
  max-height: 90px;
  border-radius: 10px;
`;
const ReorderModal = ({ isVisible, onClose, itemList, onReorder, onRemove }: ReorderProps) => {
  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <IonReorderGroup onIonItemReorder={(e: any) => onReorder(e)} disabled={false}>
        {itemList?.map((object, index) => (
          <IonItemSliding key={index}>
            <IonItem>
              <StyledImg src={object} slot="start" />
              <IonIcon icon={trash} slot="end" onClick={() => onRemove(index)} />
              <IonReorder slot="end" />
            </IonItem>
          </IonItemSliding>
        ))}
      </IonReorderGroup>
    </Modal>
  );
};
export default ReorderModal;
