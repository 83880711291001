import React from 'react';
import { Icon, Text } from '../../atoms';
import styled from 'styled-components';
import { Colors, Spacing } from '../../../foundations';
import { useTranslation } from 'react-i18next';

const StarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const IconContainer = styled.div`
  height: 40px;
  width: 40px;
  margin: 0 auto ${Spacing.xs} auto;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const getConfig = (ranking: string, t: any) => {
  switch (ranking) {
    case 'high':
      return [t('ImpactHigh'), t('ImpactHighRequirements'), '36'];
    case 'half':
      return [t('ImpactMedium'), t('ImpactMediumRequirements'), '26'];
    default:
      return [t('ImpactLow'), t('ImpactLowRequirements'), '16'];
  }
};
const RankingStart = ({ ranking, selected }: { ranking: string; selected: boolean }) => {
  const { t } = useTranslation();
  const [title, subtitle, size] = getConfig(ranking, t);
  const iconName = selected ? 'selected-star' : 'disabled-star';
  const titleColor = selected ? Colors.mainColorActive : Colors.mainColorDisabled;
  const subtitleColor = selected ? Colors.black : Colors.mainColorDisabled;

  return (
    <StarContainer>
      <IconContainer>
        <Icon name={iconName} size={size} />
      </IconContainer>
      <Text config={'main-text-title'} align={'center'} color={titleColor}>
        {title}
      </Text>
      <Text config={'small-text'} align={'center'} color={subtitleColor}>
        {subtitle}
      </Text>
    </StarContainer>
  );
};

export default RankingStart;
