import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IonSpinner, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import CustomerApi from '../../api/CustomerApi';
import Scanner from '@bumerang-kit/components/organism/QRScanner';
import ProductApi from '../../apiClient/ProductApi';
import { Container, Page } from '@bumerang-kit/layouts';
import { Toaster } from '@bumerang-kit/helpers';
import { Cart, Navigation, Placeholder, Text } from '@bumerang-kit/components';
import { getCurrentSession } from '@/helpers/session';
import Settings from '../../lib/settings';
import ApiCaller from '../../lib/ApiCaller';
import { Colors } from '@bumerang-kit/foundations';

const toaster = new Toaster();

const StyledContainer = styled(Container)`
  overflow: hidden;
`;
const Scan = styled(Scanner)`
  border: 4px green solid;
  width: 100%;
  height: 50%;
  display: block;
  margin: 0;
`;

const Link = styled(Text)`
  text-align: center;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;
`;

/* eslint-disable camelcase */
interface ContainerDetailsData {
  id: string;
  current_owner: {
    id: string;
    name: string;
    username: string;
    type: string;
  } | null;
  name: string;
  product_type_id: string;
  status: string;
  current_owner_id: string | null;
  created_at: Date;
  updated_at: Date;
  model: {
    id: string;
    name: string;
    code: string;
    picture: string;
    created_at: Date;
    updated_at: Date;
  };
  timestamp?: Date;
}

/* eslint-enable camelcase */

const PartnerScanner: React.FC = ({ lendConfig, setLendConfig }: any) => {
  const navigate = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const user = getCurrentSession();
  const productApi = new ProductApi(user.token);
  const { mode, target } = queryString.parse(location.search);
  const currentUser = getCurrentSession();
  const apiCaller = new ApiCaller(currentUser.token);
  const customerApi = new CustomerApi(apiCaller);
  const [containers, updateContainers] = useState<ContainerDetailsData[]>([]);
  const [isScanning, updateScanning] = useState(true);
  const [isContainerScan, updateIsContainerScan] = useState<boolean>(true);

  useEffect(() => {
    const containersFromSession: any = Settings.getContainersInSession();
    if (containersFromSession) {
      const sessionContainers = JSON.parse(containersFromSession);
      if (sessionContainers) {
        updateContainers(sessionContainers);
        setLendConfig({
          ...lendConfig,
          containers,
        });
      }
    } else {
      updateContainers([]);
      updateIsContainerScan(true);
    }
  }, [location]);

  useEffect(() => {
    return target === 'client' ? updateIsContainerScan(false) : updateIsContainerScan(true);
  }, [target]);

  const removeContainer = async (id: string) => {
    const removedItem = containers.filter((container) => container.id === id);
    const filteredArray = containers.filter((container) => container.id !== id);

    await setLendConfig({
      ...lendConfig,
      containers: filteredArray,
    });

    updateContainers([...filteredArray]);
    toaster.flashSuccess(
      t('SuccessfullyRemoved', {
        containerName: removedItem[0].name,
      })
    );
  };

  const cleanStateAndCloseScanner = async () => {
    await Settings.removeContainersInSession();

    await setLendConfig({
      user: null,
      containers: [],
    });
    updateContainers([]);
    updateScanning(false);
  };

  const handleConfirm = async () => {
    try {
      await setLendConfig({
        ...lendConfig,
        containers,
      });

      await Settings.saveContainersInSession(containers);
      const isCollect = containers.every((container: any) => container.status === 'lent');

      if (lendConfig.user || isCollect) {
        navigate.replace('/takeaway/summary');
        return;
      }

      if (mode && mode === 'manual') {
        navigate.replace('/takeaway/client/type', { replace: true });
        return;
      }

      updateIsContainerScan(false);
      return;
    } catch (error) {
      updateScanning(true);
      toaster.flashError('Something went wrong, try again!');
    }
  };

  const handleContainerScan = async (qrContent: string | null) => {
    if (!qrContent) return;

    const isClientId = qrContent.includes('B-');

    if (isClientId) {
      toaster.flashError(t('IsNotAValidContainer'));
      return;
    }

    const isAlreadyScanned = containers.some((container) => container.id === qrContent);

    if (!isAlreadyScanned) {
      const response = await productApi.getContainerDetails(qrContent);

      if (response && response.success) {
        if (
          response.data.current_owner.type !== 'restaurant' &&
          response.data.current_owner.type !== 'customer'
        ) {
          toaster.flashError(t('UserIsNotRestaurantOrCustomer'));
          return;
        }

        if (
          response.data.current_owner.type === 'restaurant' &&
          response.data.current_owner_id !== user.id
        ) {
          toaster.flashError(t('ContainerBelongsToAnotherRestaurant'));
          return;
        }

        if (
          response.data.current_owner.type === 'customer' &&
          response.data.status === 'not_refundable'
        ) {
          toaster.flashError(t('ContainerCouldNotBeCollected'));
          return;
        }

        const finalArray: any = containers.concat([
          { ...response.data, timestamp: new Date().getTime() },
        ]);

        updateContainers(finalArray);

        toaster.flashSuccess(
          t('SuccessfullyAdded', {
            containerName: response.data.name,
          })
        );
      } else {
        if (response?.data?.error) {
          toaster.flashError(response.data.error);
          return;
        }

        toaster.flashError(t('ContainerIsIncorrect'));
      }
    } else {
      toaster.flashError(t('ContainerAlreadyScanned'));
    }
  };

  const handleClientScan = async (clientId: string | null) => {
    try {
      if (clientId) {
        const isClientId = clientId.includes('B-');

        if (!isClientId) {
          toaster.flashError(t('IsNotAValidClientId'));
          return;
        }

        updateScanning(false);

        const response = await customerApi.getCustomerByUsername(clientId);
        if (!response.success) {
          toaster.flashError(response.data.error);
          updateScanning(true);
          return;
        }

        await setLendConfig({
          ...lendConfig,
          user: { ...response.data, user_id: response.data.id },
        });

        navigate.replace('/takeaway/summary');
        return;
      }

      updateScanning(true);
      return;
    } catch (error) {
      updateScanning(true);
    }
  };

  const handleError = () => {
    toaster.flashError(t('QrScanSomethingWentWrongFeedback'));
    updateScanning(false);
    navigate.replace('/restaurant');
  };

  useIonViewWillLeave(() => updateScanning(false));
  useIonViewWillEnter(() => updateScanning(true));

  const handleBack = async () => {
    if (isContainerScan) {
      await cleanStateAndCloseScanner();
      navigate.replace('/restaurant');
    } else updateIsContainerScan(true);
  };

  document.addEventListener('ionBackButton', (ev: any) => {
    ev.preventDefault();
    ev.detail.register(10, async () => {
      await handleBack();
    });
  });

  return (
    <Page>
      <Navigation onBack={handleBack}>
        {isContainerScan ? t('ScanContainerInstructions') : t('ScanClient')}
      </Navigation>
      {isScanning ? (
        <>
          <Scan
            onScan={isContainerScan ? handleContainerScan : handleClientScan}
            onError={handleError}
          />
          <StyledContainer>
            {isContainerScan ? (
              <Cart
                key={containers}
                items={containers}
                onConfirm={handleConfirm}
                onRemoveItem={(item: any) => removeContainer(item)}
                options={{
                  placeholderDescription: t('ScanContainerDescription'),
                  confirmSelectionButton: t('Next'),
                }}
              />
            ) : (
              <>
                <Placeholder icon="assets/scanCode.svg" title={t('ScanClientInstructions')} />
                <Link
                  isLink
                  config="small-text"
                  onClick={() => navigate.replace('/takeaway/client/type')}
                >
                  {t('IDoNotHaveQrCode')}
                </Link>
              </>
            )}
          </StyledContainer>
        </>
      ) : (
        <SpinnerContainer>
          <IonSpinner color={Colors.mainColor} />
        </SpinnerContainer>
      )}
    </Page>
  );
};
export default PartnerScanner;
