const Divider = () => {
  return (
    <div className="flex items-center my-8">
      <div className="h-px w-full bg-main"></div>
      <div className="h-px w-full bg-main"></div>
    </div>
  );
};

export default Divider;
