import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';

import { useTranslation } from 'react-i18next';
import AuthApi from '../../../api/AuthApiV2';
import ApiCaller from '../../../lib/ApiCaller';
import User from '../../../lib/common/models/user';

import { Toaster } from '../index';
import { setCurrentSession2 } from '../../../helpers/session';

const toaster = new Toaster();
const authApi = new AuthApi(new ApiCaller());

const logUserOauth = async ({ data, provider, platform }: any): Promise<User> => {
  return authApi.login({
    email: data.email,
    password: data.password,
    provider,
    platform,
  });
  //  return provider === 'Email'
  //    ? authApi.login({
  //        email: data.email,
  //        password: data.password,
  //        provider,
  //        platform,
  //      })
  //    : authApi.socialLogin({
  //        email: data.email,
  //        name: data.fullName,
  //        appleToken: data.appleToken,
  //        provider,
  //        platform,
  //      });
};

export default function useLoginOauth() {
  const navigate = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const context = queryString.parse(location.search);
  const isOauth = !!context && !!context.client_id && typeof context.client_id === 'string';
  const currentQuery = queryString.stringify(context);

  return useMutation(
    'logUserOauth',
    ({ data, provider, platform }: any) => logUserOauth({ data, provider, platform }),
    {
      onSuccess: async (response: any) => {
        if (response?.success && response?.data) {
          setCurrentSession2(response.data.token);
          if (isOauth) navigate.push(`/oauth/authorize?${currentQuery}`);
          else navigate.push(`/${response.data.type}`);
          return;
        }

        throw new Error(response.data.error);
      },
      onError: (error: any) => {
        if (error.message) {
          toaster.flashError(error.message);
          return;
        }

        toaster.flashError(t('GeneralError'));
      },
      retry: false,
    }
  );
}
