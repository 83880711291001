import React from 'react';
import styled from 'styled-components';

const StyledUl = styled.ul`
  position: absolute;
  ${({ position }: { position?: string }) => getPosition(position)}
`;
const getPosition = (position?: string) => {
  switch (position) {
    case 'right':
      return `right: 0;
             bottom: -2px;`;
    default:
      return `left: 0`;
  }
};

const Dots = ({ dots, position }: { dots: Array<object>; position?: string }) => {
  return (
    <div>
      <StyledUl className="slick-dots" position={position}>
        {' '}
        {dots.map((dot) => dot)}{' '}
      </StyledUl>
    </div>
  );
};

export default Dots;
