import React, { useState } from 'react';
import './styles/AddCard.css';
import { Card, Icon, Navigation, Text } from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import { getCurrentSession } from '@/helpers/session';
import { useHistory } from 'react-router';
import PaymentApi from '../api/PaymentApi';
import ApiCaller from '../lib/ApiCaller';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import AddCard from './AddCard';
import { IonAlert, IonSpinner } from '@ionic/react';
import { Toaster } from '@bumerang-kit/helpers';
import { Spacing } from '@bumerang-kit/foundations';
import { useTranslation } from 'react-i18next';
import { useLendsHistory } from '@/features/logistics';
import { useGetPaymentStatus } from '@/features/shared';

const toaster = new Toaster();

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  height: fit-content;
  width: fit-content;
  margin-right: ${Spacing.xs};
`;
const StyledSpinner = styled(IonSpinner)`
  height: 100%;
  margin: 0 auto;
`;

const CardInfo = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChangePayment: React.FC = () => {
  const [showWarning, setShowWarning] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [hasTuppers, setHasTuppers] = useState(false);
  const [card, setCard] = useState<any>();
  const navigate = useHistory();
  const currentUser = getCurrentSession();
  const paymentApi = new PaymentApi(new ApiCaller(currentUser.token));
  const { t } = useTranslation();
  const { getPaymentStatus, paymentStatus } = useGetPaymentStatus(currentUser.status, {});

  const getLends = useLendsHistory({
    onSuccess: (data: any) => setHasTuppers(data.data?.next_lends_to_expire !== 0),
    refetchOnWindowFocus: true,
  });

  useQuery('getPayment', async () => await paymentApi.getPayment(), {
    onSuccess: (data: any) => setCard(data.result),
    enabled: paymentStatus === 'configured',
  });

  const removeCard = async () => {
    paymentApi
      .removeCard()
      .then(() => {
        toaster.flashSuccess(t('ChangePaymentPositiveFeedback'));
        setCard({});
        setShowDialog(false);
        getPaymentStatus.refetch();
      })
      .catch((err) => {
        toaster.flashError(t('GenericNegativeFeedback'));
        console.log(err);
      });
  };

  const getContent = () => {
    if (getPaymentStatus.isLoading || getPaymentStatus.isLoading || getLends.isLoading)
      return <StyledSpinner name={'dots'} color={'tertiary'} />;

    if (paymentStatus === 'configured' && card)
      return (
        <Container>
          <Text config="main-text">{t('ChangePaymentHeader')}</Text>
          <StyledCard full>
            <CardInfo>
              {' '}
              <IconContainer>
                <Icon
                  name={
                    card.brand === 'visa' || card.brand === 'mastercard' ? card.brand : 'stripe'
                  }
                />
              </IconContainer>
              <Text config={'main-text'}>...{card.last4!}</Text>
            </CardInfo>
            <IconContainer>
              <Icon name={'tick'} />
            </IconContainer>
          </StyledCard>
          <Text
            config="main-text"
            isLink={true}
            onClick={() => (hasTuppers ? setShowWarning(true) : setShowDialog(true))}
          >
            {t('ChangePaymentDialogTitle')}
          </Text>
          <IonAlert
            isOpen={showDialog}
            header={t('ChangePaymentAlertHeader')}
            message={t('ChangePaymentAlertMessage')}
            buttons={[
              {
                text: t('Cancel'),
                handler: () => setShowDialog(false),
              },
              {
                text: t('Remove'),
                handler: removeCard,
              },
            ]}
          />
          <IonAlert
            isOpen={showWarning}
            header={t('ChangePaymentForbiddenHeader')}
            buttons={[
              {
                text: t('Understood'),
                handler: () => setShowWarning(false),
              },
            ]}
          />
        </Container>
      );

    if (paymentStatus === 'pending' || paymentStatus === 'removed') return <AddCard />;
  };

  return (
    <Page>
      <Navigation onBack={() => navigate.go(-1)}>{t('ChangePaymentTitle')}</Navigation>
      {getContent()}
    </Page>
  );
};
export default ChangePayment;
