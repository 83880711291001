import { off, onValue, ref, set } from 'firebase/database';
import { database } from '@/lib/firebase';
import { Toaster } from '@bumerang-kit/helpers';
import { useEffect } from 'react';
import useGetNotificationToken from '@/hooks/useGetNotificationToken';

export const useNotifications = (userId: string, onNotificationAction?: any): void => {
  const HALF_MINUTE = 30 * 1000;
  const toaster = new Toaster();
  const userNotificationsPath = `users/notifications/${userId}`;
  const userRef = ref(database, userNotificationsPath);
  const token = useGetNotificationToken();

  const markAsRead = (data: any, key: string) => {
    return set(ref(database, `${userNotificationsPath}/${key}`), {
      ...data[key],
      read: true,
    });
  };

  const onNewNotification = (data: any) => {
    if (data)
      Object.keys(data).map(async (key: string) => {
        const { message, type, read, sent_at: sentAt } = data[key];
        const isHalfAMinuteOld = new Date().getTime() - Date.parse(sentAt) <= HALF_MINUTE;

        if (read === false && isHalfAMinuteOld) {
          type === 'success' ? toaster.flashSuccess(message) : toaster.flashError(message);
          onNotificationAction && onNotificationAction().then(() => markAsRead(data, key));
        }
      });
  };

  useEffect(() => {
    userId && token && onValue(userRef, (snapshot: any) => onNewNotification(snapshot.val()));

    return () => off(userRef);
  }, [userId, token]);
};

export default useNotifications;
