import React, { useState } from 'react';

import { useHistory } from 'react-router';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { EmptyState, Navigation, TuppersHistory } from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import LendApi from '../api/LendApi';
import ApiCaller from '../lib/ApiCaller';
import { LoadingList } from '@bumerang-kit/components/molecules';
import { getCurrentSession } from '@/helpers/session';
import { Toaster } from '@bumerang-kit/helpers';

const toaster = new Toaster();

const MyTuppers: React.FC = () => {
  const navigate = useHistory();
  const { t } = useTranslation();
  const [lendsGroup, setLends] = useState<any>({
    finished: [],
    notFinished: [],
  });

  const currentUser = getCurrentSession();
  const lendApi = new LendApi(new ApiCaller(currentUser.token));
  const getLends = useQuery('getLends', async () => await lendApi.getMyLends(), {
    onSuccess: (response: any) => {
      if (response && !response.success) {
        toaster.flashError(response.data.error);
        return;
      }

      setLends({
        finished: response.data.history.finished,
        notFinished: response.data.history.not_finished,
      });
    },
    onError: (err: any) => console.error(err),
  });

  const hasNoLends = lendsGroup.finished.length === 0 && lendsGroup.notFinished.length === 0;

  if (getLends.isLoading)
    return (
      <Page>
        <LoadingList />
      </Page>
    );

  return (
    <Page>
      <Navigation onBack={() => navigate.go(-1)}>{t('MyTuppersPageTitle')}</Navigation>

      {hasNoLends ? (
        <Container>
          <EmptyState
            src="assets/emptyBowl.svg"
            title={t('ImpactNeverUsedEmptyStateTitle')}
            subtitle={t('ImpactNeverUsedEmptyStateSubtitle')}
            buttonText={t('ImpactNeverUsedEmptyStateBottom')}
            onClick={() => navigate.push('/map')}
          />
        </Container>
      ) : (
        <TuppersHistory lends={lendsGroup} />
      )}
    </Page>
  );
};

export default MyTuppers;
