import React from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Container } from '../../../layouts';
import { EmptyState, HistoryCard } from '../../index';

const StyledDiv = styled.div`
  padding: 20px, 0;
`;

const PendingHistory = ({ list }: { list: any }) => {
  const { t } = useTranslation();

  return (
    <StyledDiv>
      {list.length === 0 ? (
        <Container>
          <EmptyState src="assets/emptyBowlCorrect.svg" title={t('PendingHistoryTitle')} />
        </Container>
      ) : (
        <div>
          {list
            .map((lend: any, index: number) => <HistoryCard key={index} lend={lend} />)
            .reverse()}
        </div>
      )}
    </StyledDiv>
  );
};
export default PendingHistory;
