import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { Toaster } from '@bumerang-kit/helpers';
import { Button, Icon, Line, Navigation, Text } from '@bumerang-kit/components';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import { Container, Page } from '@bumerang-kit/layouts';
import LogisticsApi from '../../apiClient/LogisticsApi';
import Settings from '../../lib/settings';
import { getCurrentSession } from '@/helpers/session';

const toaster = new Toaster();

const StyledIcon = styled(Icon)`
  width: 65px;
  height: 65px;
  margin-bottom: ${Spacing.s};
`;

const StyledLine = styled(Line)`
  margin-top: ${Spacing.l};
  margin-bottom: ${Spacing.l};
`;

const Wrapper = styled.div`
  margin-top: ${Spacing.l};
`;

const Block = styled.div`
  text-align: center;
  max-width: 65%;
  margin: 0 auto;
`;

const FixedBlock = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${Spacing.m} ${Spacing.s};
  background-color: ${Colors.white};
`;

const Redirect = styled(Text)`
  margin-top: ${Spacing.xs};
`;

const ScanSummary = ({ lendConfig, setLendConfig }: any) => {
  const navigate = useHistory();
  const currentUser = getCurrentSession();
  const logisticsApi = new LogisticsApi(currentUser.token);
  const [toCollect, setToCollect] = useState([]);
  const [toLend, setToLend] = useState<any>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const collect: any = [];
    const lend: any = [];

    lendConfig.containers.map((container: any) => {
      return container.status === 'available'
        ? lend.push(container.id)
        : collect.push(container.id);
    });
    setToCollect(collect);
    setToLend(lend);
  }, [lendConfig]);

  const cleanStateAndCloseScanner = async () => {
    await Settings.removeContainersInSession();
    await setLendConfig({
      containers: [],
      user: null,
    });
  };

  const handleConfirmation = async () => {
    setIsButtonDisabled(true);
    const calls = [];

    try {
      if (toCollect.length > 0) {
        calls.push(logisticsApi.collect(toCollect));
      }
      if (toLend.length > 0) {
        calls.push(logisticsApi.startLend(lendConfig.user.user_id, toLend));
      }
      const responses = await Promise.all(calls);

      const notSuccessfulResponses = responses.filter((response) => response.success === false);

      if (notSuccessfulResponses.length > 0) {
        const firstErrorMessage = notSuccessfulResponses[0].data.error;
        toaster.flashError(firstErrorMessage);
        setIsButtonDisabled(false);
      } else {
        await cleanStateAndCloseScanner();
        toaster.flashSuccess(t('SuccessfulAction'));
        navigate.replace(`/restaurant`);
      }
    } catch {
      (e: any) => console.log(e);
    }
    setIsButtonDisabled(false);
  };

  const resetState = () => {
    setToCollect([]);
    setToLend([]);
  };
  const isNotValid = lendConfig.containers.length === 0 || (toLend.length > 0 && !lendConfig.user);

  return (
    <Page>
      <Navigation
        onBack={() => {
          resetState();
          navigate.replace('/takeaway/scan?target=client');
        }}
      >
        {t('ScanSummary.PageTitle')}
      </Navigation>
      <Container>
        <Wrapper>
          <Block>
            <StyledIcon name="bowl" />

            {toLend.length + toCollect.length === 0 ? (
              <Text align="center" config="screen-title-regular">
                {t('SummaryQRPlaceholder')}
              </Text>
            ) : (
              <>
                <Text align="center" config="secondary-screen-title">
                  {t('ScannedContainers')}
                </Text>
                <Text align="center" config="secondary-screen-title">
                  {toCollect.length !== 0 && t('ScanSummary.Returns', { total: toCollect.length })}
                  <br />
                  {toLend.length !== 0 && t('ScanSummary.Takes', { total: toLend.length })}
                </Text>
              </>
            )}

            <Redirect
              config="small-text-title"
              align="center"
              isLink
              onClick={() => {
                resetState();
                navigate.replace('/takeaway/scan?target=containers');
              }}
            >
              {t('Change')}
            </Redirect>
          </Block>
          <StyledLine />
          {toLend.length > 0 && (
            <Block>
              <StyledIcon name="tick" />
              {!lendConfig.user ? (
                <Text align="center" config="screen-title-regular">
                  {t('SummaryClientPlaceholder')}
                </Text>
              ) : (
                <Text align="center" config="screen-title-regular">
                  <Trans
                    i18nKey="QRCodeOf"
                    components={{
                      bold: <strong />,
                    }}
                    values={{
                      user: lendConfig.user.name,
                    }}
                  />
                </Text>
              )}
              <Text
                config="small-text-title"
                align="center"
                isLink
                onClick={() => navigate.replace('/takeaway/scan?target=client')}
              >
                {t('Change')}
              </Text>
            </Block>
          )}
        </Wrapper>
        <FixedBlock>
          <Button
            disabled={isNotValid || isButtonDisabled}
            expand="full"
            onClick={handleConfirmation}
          >
            {t('Confirm')}
          </Button>
        </FixedBlock>
      </Container>
    </Page>
  );
};

export default ScanSummary;
