import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Colors, Spacing } from '../../../foundations';

import Tab from './Tab';

const TabBar = styled.div`
  position: static;
  bottom: 0;
  width: 100%;
  padding-left: ${Spacing.xs};
  padding-right: ${Spacing.xs};
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  border-top: 1px solid ${Colors.mainColorDisabled};
  border-bottom: 1px solid ${Colors.mainColorDisabled};
  background-color: ${Colors.white};
  z-index: 49;
  ${(props: { position?: string }) =>
    props.position === 'top'
      ? `
         box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
         `
      : `padding-bottom: var(--ion-safe-area-bottom);`}
`;

const getTabs = ({
  type,
  active,
  onClick,
  t,
}: {
  type: string;
  active: string;
  onClick?: (name: string) => void;
  t: any;
}) => {
  switch (type) {
    case 'admin':
      return (
        <TabBar>
          <Tab icon="my-bumerang" isActive={active === 'admin'} link="/admin">
            {t('TabAdmin')}
          </Tab>
          <Tab icon="my-account" isActive={active === 'profile'} link="/admin/profile">
            {t('TabAccount')}
          </Tab>
        </TabBar>
      );
    case 'map':
      return (
        <TabBar position="top">
          <Tab
            onClick={() => (onClick ? onClick('list') : null)}
            isActive={active === 'list'}
            position="top"
          >
            {t('TabList')}
          </Tab>
          <Tab
            onClick={() => (onClick ? onClick('map') : null)}
            isActive={active === 'map'}
            position="top"
          >
            {t('TabMap')}
          </Tab>
        </TabBar>
      );
    case 'tuppers':
      return (
        <TabBar position="top">
          <Tab
            onClick={() => (onClick ? onClick('pending') : null)}
            isActive={active === 'pending'}
            position="top"
          >
            {t('TabPending')}
          </Tab>
          <Tab
            onClick={() => (onClick ? onClick('returned') : null)}
            isActive={active === 'returned'}
            position="top"
          >
            {t('TabReturned')}
          </Tab>
        </TabBar>
      );
    case 'restaurant':
      return (
        <TabBar>
          <Tab icon="my-bumerang" isActive={active === 'scan'} link="/restaurant">
            {t('TabScan')}
          </Tab>
          <Tab icon="world" isActive={active === 'impact'} link="/restaurant/impact">
            {t('TabImpact')}
          </Tab>
          <Tab icon="my-account" isActive={active === 'profile'} link="/restaurant/profile">
            {t('TabAccount')}
          </Tab>
        </TabBar>
      );
    default:
      return (
        <TabBar>
          <Tab icon="my-bumerang" isActive={active === 'customer'} link="/customer">
            {t('TabCode')}
          </Tab>
          <Tab icon="restaurants" isActive={active === 'map'} link="/map">
            {t('TabRestaurants')}
          </Tab>
          <Tab icon="world" isActive={active === 'impact'} link="/impact">
            {t('TabImpact')}
          </Tab>
          <Tab icon="my-account" isActive={active === 'profile'} link="/profile">
            {t('TabAccount')}
          </Tab>
        </TabBar>
      );
  }
};
const Tabs = ({
  active,
  type,
  onClick,
}: {
  active: string;
  type: string;
  onClick?: (name: any) => void;
}) => {
  const { t } = useTranslation();
  return getTabs({ type, active, onClick, t });
};
export default Tabs;
