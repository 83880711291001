import React from 'react';
import { Trans } from 'react-i18next';
import format from 'date-fns/format';

import Settings from '../../../../lib/settings';
import styled from 'styled-components';

const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  height: 30px;
  width: 190px;
  border-radius: 7px;

  strong {
    margin-left: 3px;
  }
`;

const getBackgroundColor = (days: number) => {
  if (days < 1) return `bg-secondary-green`;
  if (days > 0 && days < 7) return `bg-coral`;
  if (days >= 7 && days < 10) return `bg-orange`;
  if (days >= 15) return `bg-cream`;
  return `bg-cream`;
};

const getDaysLeftToReturn = (lentAt: any) => {
  const lentAtDate = new Date(lentAt);
  const now = new Date();

  lentAtDate.setDate(lentAtDate.getDate() + Settings.getNumDaysToReturn());

  return Math.floor((lentAtDate.getTime() - now.getTime()) / (1000 * 3600 * 24));
};

const TimedTag = ({ time }: { time: any }) => {
  const days = getDaysLeftToReturn(time);

  const daysLeft = getDaysLeftToReturn(time);
  const isDeposit = daysLeft <= 0;
  return (
    <StyledSpan
      className={`py-1 px-4 flex justify-center text-xs ${getBackgroundColor(
        days
      )} rounded-xl text-center`}
    >
      {isDeposit ? (
        <Trans i18nKey={'DepositOf'} values={{ price: '6' }} components={{ bold: <strong /> }} />
      ) : (
        <Trans
          i18nKey={'ReturnBefore'}
          components={{ strong: <strong /> }}
          values={{ time: format(new Date(time), 'dd/MM') }}
        />
      )}
    </StyledSpan>
  );
};
export default TimedTag;
