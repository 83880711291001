import React from 'react';
import styled from 'styled-components';

import { Colors, Spacing } from '../../../foundations';

import Icon from '../Icon';

const Container = styled.div`
  padding: ${Spacing.xs};
  background-color: ${Colors.tagCream};
  display: flex;
  justify-content: center;
  flex-flow: nowrap column;
  text-align: center;
  align-items: center;
  height: 100%;
  padding: ${Spacing.xxl};
`;

const StyledIcon = styled(Icon)`
  width: 120px;
  margin-bottom: ${Spacing.s};
`;

const NoticeModal = ({ content }: any) => (
  <Container>
    <StyledIcon name="notice" />
    {content}
  </Container>
);

export default NoticeModal;
