import Lend from './lend';

export interface LendsGroup {
  finished: LendsCollection;
  notfinished: LendsCollection;
}

class LendsCollection {
  lends: Array<Lend>;

  constructor(lends: Array<Lend>) {
    this.lends = lends;
  }

  length() {
    return this.lends ? this.lends.length : 0;
  }

  getSoonerReturn() {
    if (this.lends && this.lends.length === 0) {
      return;
    }
    return (
      this.lends &&
      this.lends.reduce(
        (min, p) =>
          new Date(p.lendedAt).getTime() < new Date(min.lendedAt).getTime()
            ? p
            : min,
        this.lends[0]
      )
    );
  }

  getLendsSameDayReturn(lend: Lend) {
    //No pilla el type correctamente
    const daysLeft = new Lend(lend).getLeftDaysToReturn(15);
    return new LendsCollection(
      this.lends.filter(
        (lend) => new Lend(lend).getLeftDaysToReturn(15) === daysLeft
      )
    );
  }

  orderedByReturnDate(): LendsCollection {
    return new LendsCollection(
      this.lends.sort(
        (a, b) =>
          new Date(a.collectedAt).getTime() - new Date(b.collectedAt).getTime()
      )
    );
  }

  orderedByLendedAt(): LendsCollection {
    return new LendsCollection(
      this.lends.sort(
        (a, b) =>
          new Date(a.lendedAt).getTime() - new Date(b.lendedAt).getTime()
      )
    );
  }

  reverseOrder(): LendsCollection {
    return new LendsCollection(this.lends.reverse());
  }

  getLends() {
    return this.lends;
  }
}

export default LendsCollection;
