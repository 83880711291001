import AddCard from './AddCard';
import AddRestaurant from './AddRestaurant';
import AddSepa from './AddSepa';
import Admin from './Admin';
import AdminBilling from './AdminBilling';
import AdminBillingSummary from './AdminBillingSummary';
import AdminLendSummary from './AdminLendSummary';
import AdminProfile from './AdminProfile';
import ApiHome from './ApiHome';
import AssignContainers from './AssignContainers';
import ChangePayment from './ChangePayment';
import EditProfile from './EditProfile';
import EditRestaurant from './EditRestaurant';
import Faq from './Faq';
import ForgotPass from './ForgotPass';
import GetInfoContainer from './GetInfoContainer';
import GuestMap from './GuestMap';
import Impact from './Impact';
import Login from './Login';
import LoginAs from './LoginAs';
import ManualClient from './ManualClient';
import MovementHistory from './MovementHistory';
import MovementSummary from './MovementSummary';
import MyTuppers from './MyTuppers';
import Oauth from './Oauth';
import OauthAuthorize from './OauthAuthorize';
import OauthError from './OauthError';
import OauthForgotPass from './OauthForgotPass';
import OauthLogin from './OauthLogin';
import OauthRegister from './OauthRegister';
import Offline from './Offline';
import PartnerScanner from './PartnerScanner';
import PlanList from './PlanList';
import ProductList from './ProductList';
import RetrieveProductTypeList from '@/pages/RetrieveProductTypeList';
import Profile from './Profile';
import QrScan from './QrScan';
import Register from './Register';
import Registered from './Registered';
import ResetPass from './ResetPass';
import RestImpact from './RestImpact';
import RestOptions from './RestOptions';
import RestProfile from './RestProfile';
import Restaurant from './Restaurant/Restaurant';
import RestaurantList from './RestaurantList';
import RetrieveContainers from './RetrieveContainers';
import ScanSummary from './ScanSummary';
import SelectLend from './SelectLend';
import SelectUserTypeAdmin from './SelectUserTypeAdmin';
import ShareQr from './ShareQr';
import Splash from './Splash';
import SubscriptionItemList from './SubscriptionItemList';
import SubscriptionList from './SubscriptionList';
import Tutorial from './Tutorial';
import TypeQr from './TypeQr';
import UserList from './UserList';
import UserQr from './UserQr';
import Verify from './Verify';
import ViewQr from './ViewQr';
import ConfirmationSelfAssign from '@/pages/ConfirmationContainer';
import Home from '../../src/features/home/screens/Home/HomeContainer';

export default {
  AddCard,
  AddRestaurant,
  AddSepa,
  Admin,
  AdminBilling,
  AdminBillingSummary,
  AdminLendSummary,
  AdminProfile,
  ApiHome,
  AssignContainers,
  ChangePayment,
  EditProfile,
  EditRestaurant,
  Faq,
  ForgotPass,
  GetInfoContainer,
  GuestMap,
  Home,
  Impact,
  Login,
  LoginAs,
  ManualClient,
  Map,
  MovementHistory,
  MovementSummary,
  MyTuppers,
  Oauth,
  OauthAuthorize,
  OauthError,
  OauthForgotPass,
  OauthLogin,
  OauthRegister,
  Offline,
  PartnerScanner,
  PlanList,
  ProductList,
  RetrieveProductTypeList,
  Profile,
  QrScan,
  Register,
  Registered,
  ResetPass,
  RestImpact,
  RestOptions,
  RestProfile,
  Restaurant,
  RestaurantList,
  RetrieveContainers,
  ScanSummary,
  SelectLend,
  SelectUserTypeAdmin,
  ShareQr,
  Splash,
  SubscriptionItemList,
  SubscriptionList,
  Tutorial,
  TypeQr,
  UserList,
  UserQr,
  Verify,
  ViewQr,
  ConfirmationSelfAssign,
};
