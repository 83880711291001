import { combineReducers } from 'redux';
import types from './types';
import { LendConfig } from './actions';

interface ActionInterface {
  type: string;
  payload: LendConfig;
}

const defaultLendConfig = {
  containers: [],
  user: undefined,
  restaurant: undefined,
  adminAction: undefined,
};

const lendConfig = (state: LendConfig = defaultLendConfig, action: ActionInterface) => {
  switch (action.type) {
    case types.SET_LEND_CONFIG:
      return action.payload;
    default:
      return state;
  }
};

const reducer = combineReducers({
  lendConfig,
});

export default reducer;
