import React from 'react';
import styled from 'styled-components';
import { IonRouterLink } from '@ionic/react';

import { Colors } from '../../../foundations';

const generalStyles = `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-family: 'Montserrat Regular', sans-serif;
    text-transform: none;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.7px;
    border-radius: 40px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
    width: 100%;
    outline: 0;
    cursor: pointer;
`;

const getButtonColor = (secondary: boolean, social: boolean, fab: boolean) => {
  if (social) {
    return `
      border: solid 2px ${Colors.black};
      background-color: transparent;
      color: ${Colors.black};
      
      &:disabled {
        border: solid 2px ${Colors.detailsBlue};
        color: ${Colors.detailsBlue};
      }
    `;
  }

  if (secondary)
    return `
    border: solid 2px ${Colors.mainColor};
    background-color: transparent;
    color: ${Colors.mainColor};

    &:disabled {
      border: solid 2px ${Colors.mainColorDisabled};
      color: ${Colors.mainColorDisabled};
    }

    &:hover:{
      border: solid 2px ${Colors.mainColorActive};
      background-color: transparent;
      color: ${Colors.mainColorActive};
    }
    
    &:active{
      border: solid 2px ${Colors.mainColorActive};
      background-color: transparent;
      color: ${Colors.mainColorActive};
    }
    `;

  if (fab)
    return `
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background-color: ${Colors.mainColor};
    color: ${Colors.white};
    `;

  return `
    border: solid 2px ${Colors.mainColor};
    background-color: ${Colors.mainColor};
    color: ${Colors.white};

    &:disabled {
      border: solid 2px ${Colors.mainColorDisabled};
      background-color: ${Colors.mainColorDisabled};
    }

    &:hover:not([disabled]) {
      border: solid 2px ${Colors.mainColorActive};
      background-color: ${Colors.mainColorActive};
    }
    `;
};

const StyledLink = styled.a`
  ${generalStyles}
  ${({ secondary, social, fab }: any) => getButtonColor(secondary, social, fab)}
  text-decoration: none;
`;

const StyledButton = styled.button`
  ${generalStyles}
  ${({ secondary, social, fab }: any) => getButtonColor(secondary, social, fab)}
`;

const ConditionalWrapper = ({ condition, wrapper, children }: any): any =>
  condition ? wrapper(children) : children;

const Button = ({
  children,
  secondary,
  routerLink,
  link,
  social,
  disabled,
  fab,
  ...props
}: any) => (
  <ConditionalWrapper
    condition={routerLink}
    wrapper={(children: any) => <IonRouterLink routerLink={routerLink}>{children}</IonRouterLink>}
  >
    {link ? (
      <StyledLink
        href={link}
        secondary={secondary}
        disabled={disabled}
        social={social}
        cirucular={fab}
        {...props}
      >
        {children}
      </StyledLink>
    ) : (
      <StyledButton secondary={secondary} disabled={disabled} social={social} fab={fab} {...props}>
        {children}
      </StyledButton>
    )}
  </ConditionalWrapper>
);

export default Button;
