import { Browser } from '@capacitor/browser';
import { v4 as uuidv4 } from 'uuid';

const open = async (crispId: string, email: string, name: string, token: string) => {
  if (token === '') token = uuidv4();
  await Browser.open({
    url: `https://go.crisp.chat/chat/embed/?website_id=${crispId}&user_email=${encodeURI(
      email
    )}&user_nickname=${encodeURI(name)}&token_id=${encodeURI(token)}`,
  });
};

export default { open };
