import React from 'react';
import styled from 'styled-components';
import { IonCol, IonImg, IonRow } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Trans, useTranslation } from 'react-i18next';
import './styles/General.css';
import './styles/Oauth.css';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { LoginButtons, Button, Text } from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import { useApple, useGoogle, useLoginOauth, usePlatform } from '@bumerang-kit/helpers';

const ButtonsWrapper = styled.div`
  margin-top: ${Spacing.xxxxl};
`;

const Logo = styled(IonImg)`
  margin-bottom: ${Spacing.l};
`;

const StyledTitle = styled(Text)`
  margin-bottom: ${Spacing.xs};
`;

const StyledPage = styled(Page)`
  background-color: ${Colors.backgroundBlue};
`;

const Oauth = () => {
  const { data: platform } = usePlatform();
  const { refetch: getGoogle } = useGoogle();
  const { refetch: getApple } = useApple();
  const { t } = useTranslation();
  const loginOauthMutation = useLoginOauth();
  const location = useLocation();
  const context = queryString.parse(location.search);

  if (Capacitor.isPluginAvailable('StatusBar')) {
    StatusBar.setStyle({ style: Style.Light });
    StatusBar.setBackgroundColor({ color: Colors.backgroundBlue });
  }

  const login = async (provider: string) => {
    const { data } = provider === 'Google' ? await getGoogle() : await getApple();
    loginOauthMutation.mutate({ data, provider, platform });
  };

  return (
    <StyledPage>
      <Container backgroundColor={Colors.backgroundBlue}>
        <IonRow>
          <IonCol size="12">
            <Logo className="centered-image splash-logo" src="assets/logo-con-nombre.svg" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <StyledTitle align="center" config="app-title" color={Colors.mainColor}>
              {t('SplashTitle')}
            </StyledTitle>
            <Text align="center" config="main-text" color={Colors.mainColor}>
              <Trans
                i18nKey="SplashSubtitle"
                defaults="Envases reutilizables para tu comida takeaway o delivery.<bold> 100% gratuito.</bold>"
                components={{ bold: <strong /> }}
              />
            </Text>
          </IonCol>
        </IonRow>
        <ButtonsWrapper>
          <LoginButtons onGoogle={() => login('Google')} onApple={() => login('Apple')}>
            <Button secondary expand="full" routerLink={`/oauth/login?token=${context.token}`}>
              {t('ContinueWithEmail')}
            </Button>
          </LoginButtons>
        </ButtonsWrapper>
      </Container>
    </StyledPage>
  );
};

export default Oauth;
