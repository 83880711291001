import * as React from 'react';
import { useTranslation } from 'react-i18next';

export type PartnerScheduleProps = {
  hours?: any;
};

const removeDayOfTheWeek = (hoursOfTheDay: string, currentDayOfTheWeek: number) => {
  const daysOfTheWeek = [
    { spanish: 'lunes', english: 'Monday' },
    { spanish: 'martes', english: 'Tuesday' },
    { spanish: 'miércoles', english: 'Wednesday' },
    { spanish: 'jueves', english: 'Thursday' },
    { spanish: 'viernes', english: 'Friday' },
    { spanish: 'sábado', english: 'Saturday' },
    { spanish: 'domingo', english: 'Sunday' },
  ];

  return hoursOfTheDay
    .replace(daysOfTheWeek[currentDayOfTheWeek - 1].spanish, '')
    .replace(daysOfTheWeek[currentDayOfTheWeek - 1].english, '')
    .replace(':', '');
};

const closedTexts = ['cerrado', 'closed'];

const PartnerSchedule = (props: PartnerScheduleProps) => {
  const { hours } = props;
  const { t } = useTranslation();
  const currentDayOfTheWeek = new Date().getDay();
  const hoursOfTheDay = hours && props.hours[currentDayOfTheWeek - 1];

  const isClosed: boolean = closedTexts.some(
    (string) => hoursOfTheDay && hoursOfTheDay.toLowerCase().includes(string)
  );

  return hoursOfTheDay && !isClosed ? (
    <p className="text-xxs mt-1">
      <strong className="text-green-600">{t('Open')}:</strong>{' '}
      {removeDayOfTheWeek(hoursOfTheDay, currentDayOfTheWeek)}
    </p>
  ) : (
    <p className="text-xxs mt-1">
      <strong className="text-red-600">{t('Close')}</strong>
    </p>
  );
};

export default PartnerSchedule;
