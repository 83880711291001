import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import ProductApi from '../../apiClient/ProductApi';

import { Container, Page } from '@bumerang-kit/layouts';
import { Toaster, useInfiniteLoading } from '@bumerang-kit/helpers';
import { Navigation } from '@bumerang-kit/components';

import { getCurrentSession } from '@/helpers/session';

import Settings from '../../lib/settings';
import ProductTypeList from '../../bumerang-kit/components/molecules/ProductTypeList';

const toaster = new Toaster();

/* eslint-disable camelcase */
interface GetProductTypeDetailsResponse {
  id: string;
  name: string;
  code: string;
  picture: string;
  created_at: Date;
  updated_at: Date;
}

/* eslint-enable camelcase */

const RetrieveProductTypes = ({ lendConfig, setLendConfig }: any) => {
  const navigate = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const user = getCurrentSession();
  const productApi = new ProductApi(user.token);

  const [searchText, setSearchText] = React.useState<string | null>('');
  const [previousSearchText, setPreviousSearchText] = React.useState<string | null>('');
  const [query, setQuery] = React.useState('');
  const [focus, setFocus] = React.useState(false);
  const [selectedProductTypes, setSelectedProductTypes] = useState<{
    [key: string]: GetProductTypeDetailsResponse;
  }>({});
  const [productTypes, setProductTypes] = useState<any>([]);
  const [productCount, setProductCount] = useState({});
  const pagination = useInfiniteLoading({
    limit: 20,
    initialEnable: true,
    getItems: async ({ from }: { from: number }) => {
      if (location?.pathname !== '/admin/productTypeList') {
        return {
          items: [],
          pagination: {
            limit: 20,
            from: 0,
            total: 0,
            length: 0,
          },
        };
      }
      return productApi
        .getProductTypeList(from, 20, searchText !== '' ? searchText : null)
        .then((response: any) => {
          return {
            items: response.data?.product_types,
            pagination: response.pagination,
          };
        });
    },
  });

  const setLendConfigStorage = (state: any) => {
    Settings.saveStateInSession(state);
    setLendConfig(state);
  };

  useEffect(() => {
    setFocus(false);
    const pathname = location?.pathname;
    if (pathname === '/admin/productTypeList') {
      if (!lendConfig.adminAction) {
        const status = Settings.getStateInSession();
        if (
          (status?.adminAction === 'billing_assign_subscription' ||
            status?.adminAction === 'billing_create_subscription') &&
          status?.restaurant
        ) {
          setLendConfigStorage({
            ...lendConfig,
            ...status,
          });
        } else {
          setLendConfigStorage({
            ...lendConfig,
            adminAction: null,
            restaurant: null,
            user: null,
            containers: [],
          });
          return navigate.replace('/admin');
        }
      }
    }
    setFocus(true);
  }, [location]);

  useEffect(() => {
    if (focus) {
      setPreviousSearchText('');
      setSearchText('');
      pagination.resetPagination().then(() => console.log('reset'));
    }
  }, [focus]);

  useEffect(() => {
    if (pagination.isLoading) return;
    if (searchText !== previousSearchText) {
      setPreviousSearchText(searchText);
      pagination.resetPagination().then(() => console.log('reset'));
    }
  }, [searchText, pagination.isLoading]);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchText(query), 250);
    return () => clearTimeout(timeOutId);
  }, [query]);

  useEffect(() => {
    const calculateProductCount: any = {};
    const selectedProductTypeList = Object.values(selectedProductTypes);
    for (let i = 0; i < selectedProductTypeList.length; i += 1) {
      const productType = selectedProductTypeList[i];
      if (calculateProductCount[productType.name] === undefined) {
        calculateProductCount[productType.name] = 1;
      } else {
        calculateProductCount[productType.name] += 1;
      }
    }
    setProductCount(calculateProductCount);
  }, [selectedProductTypes]);

  useEffect(() => {
    const calculateProductTypes: any = [];
    for (let i = 0; i < pagination.items.length; i += 1) {
      const productType = pagination.items[i];
      calculateProductTypes.push({
        productType,
        selected: !!selectedProductTypes[productType.id],
      });
    }
    setProductTypes([...calculateProductTypes]);
  }, [pagination.items]);

  const handleUnselectItem = (id: string) => {
    if (id in selectedProductTypes) {
      delete selectedProductTypes[id];
      setSelectedProductTypes({ ...selectedProductTypes });
    }
    for (let i = 0; i < productTypes.length; i += 1) {
      if (productTypes[i].productType.id === id) {
        productTypes[i].selected = false;
        setProductTypes([...productTypes]);
        break;
      }
    }
  };

  const handleSelectItem = (id: string) => {
    for (let i = 0; i < productTypes.length; i += 1) {
      if (productTypes[i].productType.id === id) {
        selectedProductTypes[id] = productTypes[i].productType;
        productTypes[i].selected = true;
        setSelectedProductTypes({ ...selectedProductTypes });
        setProductTypes([...productTypes]);
        break;
      }
    }
  };

  const resetState = () => {
    setProductTypes([]);
    setSelectedProductTypes({});
    setProductCount({});
    setPreviousSearchText('');
    setSearchText('');
    setQuery('');
  };

  const handleConfirm = async () => {
    try {
      setLendConfigStorage({
        ...lendConfig,
        productTypes: Object.values(selectedProductTypes),
      });
      navigate.replace('/admin/billing/summary');
      resetState();
    } catch (error) {
      toaster.flashError(t('GenericNegativeFeedbackTryAgain'));
    }
  };

  const onBack = () => {
    resetState();
    switch (lendConfig?.adminAction) {
      case 'billing_create_subscription':
        setLendConfigStorage({
          ...lendConfig,
          plan: null,
        });
        navigate.replace('/admin/planList');
        break;
      case 'billing_assign_subscription':
        setLendConfigStorage({
          ...lendConfig,
          subscriptionItem: null,
        });
        navigate.replace('/admin/subscriptionItemList');
        break;
      default:
        setLendConfigStorage({
          ...lendConfig,
          adminAction: null,
        });
        navigate.replace('/admin');
        break;
    }
  };

  return (
    <Page>
      <Navigation onBack={() => onBack()}>{t('SelectedProductTypesTitle')}</Navigation>
      <Container>
        <ProductTypeList
          query={query}
          setQuery={setQuery}
          items={productTypes}
          productCount={productCount}
          onConfirm={handleConfirm}
          onSelectedItem={handleSelectItem}
          onUnselectedItem={handleUnselectItem}
          hasNextPage={pagination.hasNext}
          loadMore={pagination.loadNext}
          loadMoreRef={pagination.loadMoreRef}
          options={{
            placeholderTitle: t('NoContainersForThatPartnerDescription', {
              restaurant: lendConfig.restaurant?.name,
            }),
            placeholderDescription: query
              ? t('NoContainersForThatPartnerWithThatSearchParamsTitle', {
                  restaurant: lendConfig.restaurant?.name,
                })
              : t('NoContainersForThatPartnerTitle', {
                  restaurant: lendConfig.restaurant?.name,
                }),
            confirmSelectionButton: t('Next'),
          }}
        />
      </Container>
    </Page>
  );
};

export default RetrieveProductTypes;
