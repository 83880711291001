import * as React from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';

import './styles.css';

import { Container } from '@bumerang-kit/layouts';
import Icon from '../Icon';
import { Colors } from '../../foundations';
import useWindowDimensions from '@/hooks/useWindowDimensions';

export type BottomHalfModalProps = {
  children?: JSX.Element;
  isOpen: boolean;
  onDismiss: () => void;
  backgroundColor?: string;
  heightAuto?: boolean;
};

const BottomHalfModal = (props: BottomHalfModalProps) => {
  const { isOpen, children, onDismiss, backgroundColor, heightAuto } = props;
  const { height } = useWindowDimensions();

  const bottomHalfModalStyles = heightAuto ? {} : { height: height - 0.05 * height };

  return (
    <BottomSheet expandOnContentDrag open={isOpen} onDismiss={onDismiss}>
      <Container noOverflow noPadding backgroundColor={Colors.transparent}>
        <div
          className={`shadow rounded-t-xl px-2 ${
            backgroundColor ? `bg-${backgroundColor}` : 'bg-background-blue'
          }  mx-auto`}
          style={bottomHalfModalStyles}
        >
          <header className="p-4 flex items-center justify-between">
            <div style={{ width: '32px', height: '3px' }} />
            <div
              className="bg-main-disabled rounded-full"
              style={{ width: '32px', height: '3px' }}
            />
            <button onClick={onDismiss}>
              <Icon color={Colors.mainColor} name="close" />
            </button>
          </header>

          {children}
        </div>
      </Container>
    </BottomSheet>
  );
};

export default BottomHalfModal;
