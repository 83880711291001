export type Partner = {
  id?: string;
  email: string;
  userId?: string;
  address: string;
  distance?: number;
  name: string;
  lat: number;
  lng: number;
  phone?: string;
  hours?: Array<any>;
  photoUrl: Array<any>;
  rating?: number;
  website?: string;
  placeid?: string;
  active?: boolean;
  discount?: boolean;
  productTypeList?: Array<ProductTypeCode>;
  productGroupTypeList: Array<ProductTypeGroup>;
  type?: Array<any>;
  description?: string;
  delivery?: any;
  weekLends?: number;
  assigned_containers?: Array<any>;
};

enum ProductTypeCode {
  BOWL750 = 'Bowl750',
  BOWL1250 = 'Bowl1250',
  CUP = 'Cup',
}

enum ProductTypeGroup {
  BOWL = 'Bowl',
  CUP = 'Cup',
}

export type Coords = {
  lat: number;
  lng: number;
};

export enum Deliveries {
  GLOVO = 'Glovo',
  DELIVEROO = 'Deliveroo',
  JUST_EAT = 'Just Eat',
  TAKEAWAY_MR_NOOW = 'Takeaway MrNoow',
  TAKEAWAY_ULABOX = 'Takeaway Ulabox',
  UBER_EATS = 'Uber Eats',
  DELIVERY_PROPIO = 'Delivery propio',
}

export type DeliveryOptions = {
  [key in Deliveries]: string;
};

export { ProductTypeCode, ProductTypeGroup };
