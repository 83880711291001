import * as React from 'react';
import Icon from '../Icon';
import { Colors } from '../../foundations';
import { useTranslation } from 'react-i18next';

export type ClipboardProps = {
  onCopy: (text: string) => void;
  text: string;
};

const Clipboard = (props: ClipboardProps) => {
  const [isCopied, setIsCopied] = React.useState<boolean>(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  }, [isCopied]);

  return (
    <div
      className="flex items-center bg-white justify-center shadow py-2 px-4 mx-auto mt-s rounded-md w-auto"
      style={{ maxWidth: '260px' }}
    >
      <span className="whitespace-nowrap font-montserrat font-bold text-base text-main mr-4">
        {props.text}
      </span>
      <button
        onClick={() => {
          props.onCopy(props.text);
          setIsCopied(true);
        }}
        className="flex items-center"
      >
        {isCopied ? (
          <>
            <Icon name="check" size="24px" />
            <span className="ml-1 text-xs text-main">{t('Copied')}</span>
          </>
        ) : (
          <>
            <Icon color={Colors.mainColor} name="copy" size="24px" />
            <span className="ml-1 text-xs text-main">{t('Copy')}</span>
          </>
        )}
      </button>
    </div>
  );
};

export default Clipboard;
