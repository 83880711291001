import { useQuery } from 'react-query';

import { axios } from '../../../lib/axios';

const getPaymentStatus = (): Promise<any> => {
  return axios.get('/v1/payment/status');
};

export const usePaymentStatus = (config: any) => {
  return useQuery({
    queryKey: ['paymentStatus'],
    queryFn: () => getPaymentStatus(),
    ...config,
  });
};
