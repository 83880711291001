import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from '@bumerang-kit/components';

type ContactInfoProps = {
  description: string;
};

const ContactInfo = (props: ContactInfoProps) => {
  const { description } = props;
  const { t } = useTranslation();

  if (!description) {
    return null;
  }

  return (
    <div>
      <Line />
      <header className="text-m font-montserrat font-bold">
        {t('PartnerProfile.DescriptionHeader')}{' '}
      </header>
      <p className="font-montserrat text-s ">{description}</p>
    </div>
  );
};

export default ContactInfo;
