class ApiLambda {
  token: any;

  constructor() {
    this.token = null;
  }

  call(url: string, method = 'GET', params?: string, headers?: object): Promise<any> {
    const fetchParams: any = {
      method,
      headers: {
        ...headers,
        'Content-Type': 'Application/json',
      },
    };

    return fetch(url, fetchParams)
      .then((response) => response.json())
      .catch((error) => console.error(error));
  }
}

export default ApiLambda;
