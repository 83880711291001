import { Page } from '@bumerang-kit/layouts';
import { LoadingHome } from '@bumerang-kit/components';

const Loading = () => (
  <Page>
    <LoadingHome />
  </Page>
);

export default Loading;
