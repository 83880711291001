import AuthApi from '@/api/AuthApi';
import ApiCaller from '@/lib/ApiCaller';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import Toaster from '../Toaster';

const toaster = new Toaster();

const editUser = async ({ token, name, email, newsletter }: any): Promise<any> => {
  const authApi = new AuthApi(new ApiCaller(token));
  return authApi.editUser(name, email, newsletter);
};

export default function useUserEdit() {
  const { t } = useTranslation();
  return useMutation(
    'editUser',
    ({ token, name, email, newsletter }: any) => editUser({ token, name, email, newsletter }),
    {
      onSuccess: (response: any) => {
        if (response.success) {
          toaster.flashSuccess(t('RestOptionsGenericPositiveFeedback'));
          return;
        }
      },
      onError: () => {
        toaster.flashError(t('ResendEmailSomethingWentWrong'));
      },
      retry: false,
    }
  );
}
