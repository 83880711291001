import React from 'react';
import styled from 'styled-components';
import { Text } from '../../index';

const StyledDiv = styled.div`
  padding: 6px 11px 6px 10px;
  border-radius: 7px;
  width: 190px;
  white-space: nowrap;
  background-color: ${(props: { color: string }) => props.color};
  display: flex;
  justify-content: center;
`;

const Tag = ({ title, color, ...props }: { title: string; color: string }) => {
  return (
    <StyledDiv {...props} color={color}>
      <Text config="tag-text">{title}</Text>
    </StyledDiv>
  );
};
export default Tag;
