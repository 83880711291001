import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { ENV } from '@/env/env';
import { Buffer } from 'buffer';

const firebaseConfig = JSON.parse(Buffer.from(ENV.FIREBASE_API_KEY, 'base64').toString('ascii'));

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { app, database };
