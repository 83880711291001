import { connect } from 'react-redux';

import PlanList from './PlanList';

import actions from '@bumerang-kit/store/actions';

const mapStateToProps = (state: any) => ({
  lendConfig: state.config.lendConfig,
});

const mapDispatchToProps = (dispatch: any) => ({
  setLendConfig: (config: any) => dispatch(actions.setLendConfig(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanList);
