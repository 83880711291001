import React from 'react';
import styled from 'styled-components';
import { Card, Icon, Text } from '../../atoms';
import { Colors, Spacing } from '../../../foundations';
import Tag from '../../atoms/Tag';

const StyledCard = styled(Card)`
  display: flex;
  position: relative;
  max-width: 100%;
`;

const StyledDiv = styled.div`
  padding: ${Spacing.xs};
  flex-grow: 2;
`;

const PropertiesContainer = styled.div`
  bottom: ${Spacing.xs};
  width: 160px;
  display: flex;
  justify-content: space-between;
  margin-left: 0;
`;

const ArrowContainer = styled.div`
  padding: ${Spacing.xs};
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const ContainerCount = styled.div`
  padding: ${Spacing.xs};
  position: absolute;
  right: 0;
  bottom: 0;
`;

const ListUserCard = ({ user, showTags = true, onClick }: any) => {
  return (
    <StyledCard onClick={() => onClick(user)} full listItem>
      <StyledDiv>
        <Text config="main-text-link">{user.name}</Text>
        <Text config="secondary-text-title">{user.email}</Text>
        {showTags && (
          <PropertiesContainer>
            {user.type === 'admin' && <Tag title="Admin" color={Colors.tagCream} />}
            {user.type === 'customer' && <Tag title="Customer" color={Colors.tagYellow} />}
            {user.type === 'restaurant' && <Tag title="Restaurant" color={Colors.tagOrange} />}
          </PropertiesContainer>
        )}
      </StyledDiv>
      <ArrowContainer>
        <Icon name="arrow" />
      </ArrowContainer>

      {user.containersAssigned && user.containersAssigned[0] !== null && (
        <ContainerCount>
          {user.containersAssigned.map((product: any, index: number) => (
            <Text key={index} limit config="small-text-title" align="center">
              {`${product.code}: ${product.count}`}
            </Text>
          ))}
        </ContainerCount>
      )}
    </StyledCard>
  );
};

export default ListUserCard;
