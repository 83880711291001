import React, { useState } from 'react';
import styled from 'styled-components';

import { Modal, Text } from '../../index';
import { Spacing } from '../../../foundations';
import { useTranslation } from 'react-i18next';

const List = styled.ul`
  list-style: none;
  padding: ${Spacing.s};
`;

const StyledItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-content: center;
  list-style: none;
  white-space: nowrap;
`;

const getToday = () => {
  const dayOfTheWeek = new Date().getDay();
  if (dayOfTheWeek === 0) return 6;
  return dayOfTheWeek - 1;
};

/**
 *
 * NOTE: Popover only works in a mobile device (web, android and ios)
 */

const Hours = ({ hoursList }: { hoursList: any[] }) => {
  const today = getToday();
  const { t } = useTranslation();
  const [showPopover, setShowPopover] = useState(false);

  hoursList = hoursList.map((dayHours: string) => {
    const upperCasedHours = dayHours.charAt(0).toUpperCase() + dayHours.slice(1);
    const days = upperCasedHours.split(':').shift();
    const hours = upperCasedHours.substring(
      upperCasedHours.indexOf(':') + 1,
      upperCasedHours.length + 1
    );
    return [days, hours];
  });

  return (
    <>
      {hoursList && (
        <Text config="main-text" onClick={() => setShowPopover(true)}>
          <strong>{hoursList[today][0]}</strong>: {hoursList[today][1]}
          <Text config="small-text" isLink={true}>
            {t('HoursSeeSchedule')}
          </Text>
        </Text>
      )}

      <Modal size="small" isVisible={showPopover} onClose={() => setShowPopover(false)}>
        <List>
          {hoursList.map((days, index: number) => {
            const hours = days[1].split(',');
            return (
              <StyledItem key={index}>
                <Text align="left" config={'secondary-text'}>
                  <strong>{days[0]}</strong>:{' '}
                </Text>
                <Text align={'right'} config={'secondary-text'}>
                  {'   '}
                  {hours[0]}
                  <br />
                  {hours[1] && hours[1]}
                </Text>
              </StyledItem>
            );
          })}
        </List>
      </Modal>
    </>
  );
};

export default Hours;
