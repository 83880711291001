import React from 'react';
import { IonAlert } from '@ionic/react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

interface ConfirmationAlertProps {
  showAlert: boolean;
  setShowAlert1: (state: boolean) => void;
}

export const ConfirmationAlert = ({ showAlert, setShowAlert1 }: ConfirmationAlertProps) => {
  const navigate = useHistory();
  const { t } = useTranslation();
  return (
    <IonAlert
      isOpen={showAlert}
      onDidDismiss={() => setShowAlert1(false)}
      cssClass="my-custom-class"
      header={t('CloseConfirmation')}
      message={t('ShowSelfAssignConfirmation')}
      buttons={[
        {
          text: 'Aún no',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            setShowAlert1(false);
          },
        },
        {
          text: 'Si',
          handler: () => {
            setShowAlert1(false);
            navigate.push('/customer');
          },
        },
      ]}
    />
  );
};

export default ConfirmationAlert;
