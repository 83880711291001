import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IonContent } from '@ionic/react';
import styled from 'styled-components';
import { Page } from '@bumerang-kit/layouts';

import { User } from '../../lib/common/models';
import { Input, Navigation, Text } from '@bumerang-kit/components';
import UserApi from '../../api/UserApi';
import ApiCaller from '../../lib/ApiCaller';
import { ListUserCard } from '@bumerang-kit/components/molecules';
import { getCurrentSession } from '@/helpers/session';
import Settings from '../../lib/settings';
import { Spacing } from '@bumerang-kit/foundations';
import useInfiniteLoading from '@bumerang-kit/helpers/Hooks/useInfiniteLoading';

const Container = styled.div`
  padding-left: ${Spacing.xs};
  padding-right: ${Spacing.xs};
  border: 0 solid white;
  border-bottom: 0px;
  justify-content: center;
  align-items: center;
`;

const StyledForm = styled.form`
  margin-top: ${Spacing.xs};
  margin-bottom: ${Spacing.xs};
`;

const UserList = ({ lendConfig, setLendConfig }: any) => {
  const getType = () => {
    switch (lendConfig.adminAction) {
      case 'assign_containers_final_customer':
      case 'retrieve_containers_final_customer':
        return 'customer';
      default:
        return null;
    }
  };
  const navigate = useHistory();
  const location = useLocation();
  const currentUser = getCurrentSession();
  const userApi = new UserApi(new ApiCaller(currentUser.token));
  const { t } = useTranslation();

  const [searchText, setSearchText] = React.useState<string | null>('');
  const [previousSearchText, setPreviousSearchText] = React.useState<string | null>('');
  const [showTags, setShowTags] = React.useState<boolean>(true);
  const type: string | null = getType();
  const [query, setQuery] = React.useState('');
  const pagination = useInfiniteLoading({
    limit: 20,
    initialEnable: true,
    getItems: async ({ from }: { from: number }) => {
      return userApi
        .getUsers(
          type,
          from,
          20,
          searchText !== '' ? searchText : null,
          lendConfig.adminAction !== 'user_edit'
            ? lendConfig.adminAction === 'retrieve_containers_final_customer'
            : undefined
        )
        .then((data: any) => {
          return {
            items: data.data,
            pagination: data.pagination,
          };
        });
    },
  });

  const setLendConfigStorage = (lendState: any) => {
    Settings.saveStateInSession(lendState);
    setLendConfig(lendState);
  };

  useEffect(() => {
    const pathname = location?.pathname;
    if (pathname === '/admin/userList') {
      if (!lendConfig.adminAction) {
        const status = Settings.getStateInSession();
        if (status?.adminAction) {
          setLendConfigStorage({
            ...lendConfig,
            ...status,
          });
        } else {
          setLendConfigStorage({
            ...lendConfig,
            adminAction: 'user_edit',
          });
        }
      }
      setPreviousSearchText('');
      setSearchText('');
      pagination.resetPagination().then(() => console.log('reset'));
    }
  }, [location]);

  useEffect(() => {
    lendConfig.adminAction === 'user_edit' ? setShowTags(true) : setShowTags(false);
  }, [location]);

  useEffect(() => {
    if (pagination.isLoading) return;
    if (searchText !== previousSearchText) {
      setPreviousSearchText(searchText);
      pagination.resetPagination().then(() => console.log('reset'));
    }
  }, [searchText, pagination.isLoading]);

  const openAction = async (user: any) => {
    setLendConfigStorage({
      ...lendConfig,
      user,
    });
    switch (lendConfig.adminAction) {
      case 'assign_containers_final_customer':
        navigate.replace('/admin/user/containers/lend');
        break;
      case 'retrieve_containers_final_customer':
        navigate.replace('/admin/user/containers/retrieve');
        break;
      case 'user_edit':
      default:
        navigate.replace('/admin/user/edit');
        break;
    }
    setPreviousSearchText('');
    setSearchText('');
    setQuery('');
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchText(query), 250);
    return () => clearTimeout(timeOutId);
  }, [query]);

  const onBackButton = async () => {
    switch (lendConfig.adminAction) {
      case 'assign_containers_final_customer':
        setLendConfigStorage({
          ...lendConfig,
          restaurant: null,
        });
        navigate.replace('/admin/restaurantList');
        break;
      case 'retrieve_containers_final_customer':
        navigate.replace('/admin/user/containers/collect');
        break;
      case 'user_edit':
      default:
        navigate.replace('/admin/user/edit');
        break;
    }
    setPreviousSearchText('');
    setSearchText('');
    setQuery('');
  };

  const finalUserList = pagination.items || [];
  return (
    <Page>
      <Navigation onBack={() => onBackButton()} iconName="sync">
        <Text align="center" config="secondary-screen-title">
          {t('SelectUserTitle')}
        </Text>
      </Navigation>
      <Container>
        <StyledForm>
          <Input
            label={t('Search')}
            name="search"
            id="search"
            type="text"
            value={query}
            onChange={(event: any) => setQuery(event.target.value)}
          />
        </StyledForm>
      </Container>
      <IonContent>
        {finalUserList.map((user: User, index: number) => {
          return (
            <ListUserCard
              key={index}
              showTags={showTags}
              user={user}
              onClick={() => openAction(user)}
            />
          );
        })}
        {pagination.hasNext && (
          <button ref={pagination.loadMoreRef} onClick={() => pagination.loadNext()} />
        )}
      </IonContent>
    </Page>
  );
};

export default UserList;
