import React from 'react';
import styled from 'styled-components';
import { Spacing } from '../../../foundations';
import { Card, Text, Icon } from '../../atoms';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.s};
`;

const HeaderMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const IconContainer = styled.div`
  margin-right: ${Spacing.xs};
`;

const Metric = styled.div`
  white-space: nowrap;
`;

const SingleStatCard = ({
  iconName,
  title,
  metric,
  unit,
}: {
  iconName: string;
  title: any;
  metric: any;
  unit?: string;
}) => (
  <StyledCard full>
    <HeaderMain>
      <IconContainer>
        <Icon name={iconName} />
      </IconContainer>
      <Text config="main-text">{title}</Text>
    </HeaderMain>
    <Metric>
      <Text config="screen-title" align="right">
        {metric}
      </Text>
      {unit && (
        <Text config="small-text" align="right">
          {unit}
        </Text>
      )}
    </Metric>
  </StyledCard>
);

export default SingleStatCard;
