import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import { useTranslation } from 'react-i18next';
import AuthApi from '../../../api/AuthApi';
import ApiCaller from '../../../lib/ApiCaller';
import User from '../../../lib/common/models/user';

import { Toaster } from '../index';
import { setCurrentSession } from '../../../helpers/session';

const toaster = new Toaster();

const logUser = async ({ data, provider, platform, token }: any): Promise<User> => {
  const authApi = new AuthApi(new ApiCaller(token));
  return authApi.loginAs({
    email: data.email,
    provider,
    platform,
  });
};

export default function useLoginAs() {
  const navigate = useHistory();
  const { t } = useTranslation();

  return useMutation(
    'logUser',
    ({ token, data, provider, platform }: any) => logUser({ token, data, provider, platform }),
    {
      onSuccess: async (response: any) => {
        if (response.success === undefined) {
          const user = new User(response);
          const goodUser = user.load(response);

          setCurrentSession(goodUser.token);
          navigate.push(`/${goodUser.type}`);
        } else {
          if (response.success === false) {
            throw new Error(response.data.error);
          }

          const user = new User(response.data);
          const goodUser = user.load(response.data);

          setCurrentSession(goodUser.token);
          navigate.push(`/${goodUser.type}`);
        }
      },
      onError: (error: any) => {
        if (error.message) {
          toaster.flashError(error.message);
          return;
        }

        toaster.flashError(t('GeneralError'));
      },
      retry: false,
    }
  );
}
