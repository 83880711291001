import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { Spacing, Colors } from '../foundations';

import HorizontalScroll from './HorizontalScroll';

export const Layout = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: auto;
  background-color: ${Colors.backgroundBlue};
`;

export const Wrapper = styled.div<{ isResponsive?: boolean }>`
  background-color: white;
  width: 375px;
  min-height: 670px;
  overflow-y: auto;

  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  ${(props: { isResponsive?: boolean }) =>
    props.isResponsive
      ? `
    min-height: 100vh;
    width: 100%;
  `
      : `
  position: relative;
  `}
`;

export const Page = styled(IonPage)`
  padding-top: var(--ion-safe-area-top, 24px);
`;

type ContainerProps = {
  noPadding?: boolean;
  backgroundColor?: string;
  noOverflow?: boolean;
};

export const Container = styled.div`
  position: relative;
  padding: ${({ noPadding = false }: ContainerProps) =>
    noPadding ? '0' : `${Spacing.m} ${Spacing.s}`};
  max-width: 480px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  background-color: ${({ backgroundColor }: ContainerProps) => backgroundColor || 'white'};

  ${({ noOverflow = false }: ContainerProps) => !noOverflow && `overflow: auto;`}

  @media (max-width: 823px) {
    max-width: 100%;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

export { HorizontalScroll };
