import { useState } from 'react';
import useActivePartners from '../../../hooks/useActivePartners';
import { Coords, Partner } from '@/features/shared';
import { queryClient } from '@/lib/react-query';

type PaginationOptions = {
  position?: Coords;
  limit: number;
  order?: string;
};

export const useGetActiveRestaurants = (paginationOptions: PaginationOptions): any => {
  const [from, setFrom] = useState<number>(0);
  const { position, limit, order } = paginationOptions;

  const finalConfig = {
    onSuccess: (data: any) => {
      if (data) {
        data.pages[0].data.map((partner: Partner) => {
          queryClient.setQueryData(['partner', partner.id], partner);
        });
      }
    },
  };

  const getActivePartners: any = useActivePartners({
    requestParams: {
      distance: position,
      limit,
      from,
      order,
    },
    config: finalConfig,
  });

  const totalPartners =
    getActivePartners.data && getActivePartners?.data?.pages[0]?.pagination?.total;

  const handleNextPage = () => {
    if (from > totalPartners) return;
    setFrom(from + limit);
  };

  return {
    activePartners: getActivePartners.data?.pages[0]?.data,
    handleNextPage: handleNextPage,
    isLoading: getActivePartners.isLoading,
    isFetchingNextPage: getActivePartners.isFetchingNextPage,
  };
};
