export interface UserInfo {
  id: string;
  name: string;
  email: string;
  token: string;
  type: string;
  status: string;
  stripeId?: string;
  appleToken?: string;
  provider: string;
  username?: string;
  newsletter?: boolean;
}

class User {
  id: string;
  name: string;
  email: string;
  token: string;
  type: string;
  status: string;
  stripeId?: string;
  appleToken?: string;
  provider: string;
  username?: string;
  newsletter?: boolean;

  constructor(userInfo: UserInfo) {
    this.id = userInfo.id;
    this.name = userInfo.name;
    this.email = userInfo.email;
    this.token = userInfo.token ? userInfo.token : '';
    this.type = userInfo.type;
    this.status = userInfo.status;
    this.stripeId = userInfo.stripeId;
    this.appleToken = userInfo.appleToken;
    this.provider = userInfo.provider || 'email';
    this.username = userInfo.username;
  }

  load(data: UserInfo): User {
    return new User(data);
  }

  toObject(): any {
    return {
      id: this.id,
      name: this.name,
      email: this.email,
      type: this.type,
      stripeId: this.stripeId,
      status: this.status,
      appleToken: this.appleToken,
      provider: this.provider,
      username: this.username,
    };
  }

  isEmpty(): boolean {
    return this.email === undefined;
  }
}

export default User;
