import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Trans, useTranslation } from 'react-i18next';

import './styles/AddCard.css';
import Settings from '../lib/settings';
import { Container } from '@bumerang-kit/layouts';
import { Colors } from '@bumerang-kit/foundations';

import Icon from '@bumerang-kit/components/Icon';
import Ilustration from '../features/shared/components/ContainerDepositModal/images/ilustration.png';

import { Elements } from '@stripe/react-stripe-js';
import { CardSetupForm } from '@/components';

const stripePromise = loadStripe(Settings.getStripeKey());

const AddCard = ({ onDismiss }: any) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <Container backgroundColor={Colors.backgroundBlue} hidden={showModal}>
        <div className="flex justify-center mb-2">
          <Icon name="payment_method" />
        </div>
        <h1 className="text-3xl text-main font-bold text-center mb-4">{t('AddCardTitle')}</h1>
        <div
          className="text-base text-main text-center"
          onClick={() => {
            setShowModal(true);
          }}
        >
          {t('AddCardSubtitle')}
          <a className="underline font-bold">{t('AddCard.ModalRedirect')}</a>
        </div>
        <Elements stripe={stripePromise}>
          <CardSetupForm onDismiss={onDismiss} />
        </Elements>
      </Container>
      <Container backgroundColor={Colors.backgroundBlue} hidden={!showModal}>
        <Icon name={'back_arrow'} onClick={() => setShowModal(false)} />
        <br />

        <h1 className="text-main text-3xl mb-4">¿Qué pasa después de los 15 días?</h1>
        <p className="text-base mb-4">
          <Trans
            i18nKey={'AddCardModal.explanation'}
            components={{ bold: <strong />, break: <br /> }}
          />
        </p>
        <img src={Ilustration} alt="ilustration" />
      </Container>
    </>
  );
};

export default AddCard;
