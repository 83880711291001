import ApiCaller from '../lib/ApiCaller';

class ServiceApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getVersion(): Promise<string> {
    return this.apiCaller.call('/v1/service/version', 'GET');
  }

  getFeatures(): Promise<any> {
    return this.apiCaller.call('/v1/service/features', 'GET');
  }
}

export default ServiceApi;
