import { usePaymentStatus } from '../../payment/api/getPaymentStatus';

export const useGetPaymentStatus = (userStatus: string, config: any) => {
  const getPaymentStatus: any = usePaymentStatus({
    ...config,
    enabled: userStatus === 'confirmed',
  });
  const paymentStatus =
    (getPaymentStatus.data?.status as string) || (getPaymentStatus.data as string) || 'pending';

  return { paymentStatus, getPaymentStatus };
};

export default useGetPaymentStatus;
