import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IonContent, IonSpinner } from '@ionic/react';
import styled from 'styled-components';
import { Page } from '@bumerang-kit/layouts';

import { Card, Icon, Navigation, Text } from '@bumerang-kit/components';
import ApiCaller from '../../lib/ApiCaller';
import PaymentApi from '../../api/PaymentApi';
import { getCurrentSession } from '@/helpers/session';
import Settings from '../../lib/settings';
import { Spacing, Colors } from '@bumerang-kit/foundations';
import { Toaster } from '@bumerang-kit/helpers';

const StyledCard = styled(Card)`
  display: flex;
  position: relative;
  max-width: 100%;
`;

const StyledDiv = styled.div`
  padding: ${Spacing.xs};
  flex-grow: 2;
`;

const ArrowContainer = styled.div`
  padding: ${Spacing.xs};
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const StyledSpinner = styled(IonSpinner)`
  margin: 50% auto;
`;

/* eslint-disable camelcase */
interface Plan {
  plan_id: string;
  product_id: string;
  name: string;
  interval: string;
  interval_count: number;
  tiers_mode?: string;
  billing_scheme: string;
  amount?: number;
}
/* eslint-enable camelcase */

const toaster = new Toaster();

const PlanList = ({ lendConfig, setLendConfig }: any) => {
  const currentUser = getCurrentSession();
  const navigate = useHistory();
  const location = useLocation();
  const paymentApi = new PaymentApi(new ApiCaller(currentUser.token));
  const { t } = useTranslation();
  const [plans, setPlans] = useState<Plan[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const setLendConfigStorage = (state: any) => {
    Settings.saveStateInSession(state);
    setLendConfig(state);
  };

  useEffect(() => {
    const pathname = location?.pathname;
    if (pathname === '/admin/planList') {
      if (!lendConfig.adminAction) {
        const status = Settings.getStateInSession();
        if (
          status?.adminAction === 'billing_create_subscription' &&
          status?.restaurant &&
          status?.product
        ) {
          setLendConfigStorage({
            ...lendConfig,
            ...status,
          });
        } else {
          setLendConfigStorage({
            adminAction: null,
          });
          return navigate.replace('/admin');
        }
      }
    }
  }, [location]);

  const onBackButton = async () => {
    setLendConfigStorage({
      ...lendConfig,
      product: null,
    });
    navigate.replace('/admin/productList');
  };

  useEffect(() => {
    (async () => {
      const pathname = location?.pathname;
      if (
        !lendConfig.adminAction ||
        !lendConfig.product ||
        !lendConfig.restaurant ||
        pathname !== '/admin/planList' ||
        isLoading
      )
        return;

      setIsLoading(true);
      try {
        const response = await paymentApi.getPlans(lendConfig.product.product_id);
        setPlans(response.data.plans);
      } catch (error: any) {
        if (error?.message) {
          toaster.flashError(error.message);
        } else {
          toaster.flashError(t('GenericNegativeFeedbackTryAgain'));
        }
        await onBackButton();
      } finally {
        setIsLoading(false);
      }
    })();
  }, [lendConfig, location]);

  const openAction = async (plan: any) => {
    setLendConfigStorage({
      ...lendConfig,
      plan,
    });
    navigate.replace('/admin/productTypeList');
  };

  return (
    <Page>
      <Navigation onBack={() => onBackButton()} iconName="sync">
        <Text align="center" config="secondary-screen-title">
          {t('SelectPlanTitle')}
        </Text>
      </Navigation>
      <IonContent>
        {!isLoading ? (
          plans.map((plan: Plan) => {
            return (
              <StyledCard key={plan.plan_id} onClick={() => openAction(plan)} full listItem>
                <StyledDiv>
                  <Text config="main-text-link">{plan.name}</Text>
                </StyledDiv>
                <ArrowContainer>
                  <Icon name="arrow" />
                </ArrowContainer>
              </StyledCard>
            );
          })
        ) : (
          <StyledSpinner color={Colors.mainColor} />
        )}
      </IonContent>
    </Page>
  );
};

export default PlanList;
