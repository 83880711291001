import ApiCaller from '../lib/ApiCaller';

export default class ProductApi {
  private apiCaller: ApiCaller;

  constructor(userToken: string) {
    this.apiCaller = new ApiCaller(userToken);
  }

  getContainerDetails(id: string): Promise<any> {
    return this.apiCaller.call(`/v2/product/container/${id}`);
  }

  getContainerList(
    userId?: string,
    from = 0,
    limit = 10,
    query: string | null = null
  ): Promise<any> {
    const params = {
      ...(from ? { from } : {}),
      ...(limit ? { limit } : {}),
      ...(userId ? { user_id: userId } : {}),
      ...(query ? { query } : {}),
    };
    return this.apiCaller.call(`/v2/product/container`, 'GET', params);
  }

  getProductTypeList(from = 0, limit = 10, query: string | null = null): Promise<any> {
    const params = {
      ...(from ? { from } : {}),
      ...(limit ? { limit } : {}),
      ...(query ? { query } : {}),
    };
    return this.apiCaller.call(`/v2/product/product_types`, 'GET', params);
  }
}
