import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import QrScanner from 'qr-scanner';
import { useTranslation } from 'react-i18next';
import { CameraPermissionsContext } from '@/components';
import { Button, Text } from '@bumerang-kit/components/atoms';
import { Colors, Spacing } from '../../../foundations';
import StyledVideo from './StyledVideo';
import { toast } from 'react-toastify';

QrScanner.WORKER_PATH = 'qr-scanner/qr-scanner-worker.min.js';

interface QrReaderProps {
  onScan: (data: string | null) => void;
  onError: (err: any) => void;
}

interface QrScannerState {
  time?: number;
  text?: string | null;
}

export const NoPermissionsPlaceHolder = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 100;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: ${Spacing.l};
  align-items: center;
`;

export const StyledButton = styled(Button)`
  border-color: ${Colors.white};
  color: ${Colors.white};
  margin-top: ${Spacing.l};
`;

const ScannedAreaOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
  border: 1px solid ${Colors.white};
  z-index: 40;
  width: 230px;
  height: 230px;
`;

const QRReader = (props: QrReaderProps) => {
  const { onScan, onError } = props;
  const [toScan, setToScan] = useState<QrScannerState>({});
  const [alreadyScanned, setAlreadyScanned] = useState<QrScannerState>({});
  const [scanner, setScanner] = useState<QrScanner | null>(null);
  const [video, setVideo] = useState<HTMLVideoElement | null>(null);
  const { hasPermissions, setHasPermissions } = useContext(CameraPermissionsContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (video && hasPermissions) {
      try {
        const qrScanner = new QrScanner(
          video,
          (qrText: string | null) =>
            qrText ? setToScan({ time: Date.now(), text: qrText }) : null,
          300
        );

        setScanner(qrScanner);
      } catch (e) {
        console.log(e);
      }
    }
  }, [video, hasPermissions]);

  useEffect(() => {
    if (scanner) {
      scanner.start();
    }

    return () => {
      if (scanner) scanner.stop();
    };
  }, [scanner]);

  useEffect(() => {
    try {
      if (
        alreadyScanned.time &&
        Date.now() - alreadyScanned.time < 2000 &&
        toScan.text === alreadyScanned.text
      ) {
        return;
      }
      if (toScan.text) {
        onScan(toScan.text);
        setAlreadyScanned(toScan);
      }
    } catch (e) {
      onError(e);
    }
  }, [toScan]);

  const handleClick = async () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream) => {
        const newVideo = document.createElement('video');
        newVideo.srcObject = stream;
        setVideo(newVideo);
        setHasPermissions(true);
      })
      .catch((err) => {
        toast.error('Something went wrong.');
        history.go(0);
        console.log('el err', err);
      });
  };

  if (hasPermissions === false)
    return (
      <NoPermissionsPlaceHolder>
        <Text color={Colors.white} align="center" config="small-screen-title">
          {t('VideoStream.CameraPermissionsDenied')}
        </Text>
        <StyledButton secondary color={Colors.white} onClick={handleClick}>
          {t('Settings')}
        </StyledButton>
      </NoPermissionsPlaceHolder>
    );

  return (
    <>
      <ScannedAreaOverlay />
      <StyledVideo
        poster="assets/spinner-icon-gif-19.jpg"
        autoPlay
        muted
        playsInline
        ref={setVideo}
      />
    </>
  );
};
export default QRReader;
