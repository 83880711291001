import { combineReducers, createStore } from 'redux';
import { lendsReducer } from './lendsStore';

import configReducer from '@bumerang-kit/store';

const appReducer = combineReducers({
  lends: lendsReducer,
  config: configReducer,
});

export type AppState = ReturnType<typeof appReducer>;

export default function configureStore() {
  const store = createStore(appReducer);
  return store;
}
