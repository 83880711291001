import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { App as CapApp } from '@capacitor/app';

const LinkListener: React.FC<any> = () => {
  const navigate = useHistory();

  useEffect(() => {
    CapApp.addListener('appUrlOpen', (data: any) => {
      const slug = data.url.substring(data.url.indexOf('.com') + 4, data.url.length + 1);

      if (
        slug.includes('/resetpassword') ||
        slug.includes('/customer') ||
        slug.includes('/verify') ||
        slug.includes('/map') ||
        slug.includes('/restaurant') ||
        slug === '/'
      ) {
        navigate.push(slug);
      }
    });
  }, []);

  return null;
};

export default LinkListener;
