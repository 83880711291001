import * as React from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import styled from 'styled-components';

import CardSection from './CardSection';
import PaymentApi from '../api/PaymentApi';
import ApiCaller from '../lib/ApiCaller';
import Analyzer from '../lib/common/helpers';

import { Button, Text } from '@bumerang-kit/components';
import { Spacing } from '@bumerang-kit/foundations';
import { Toaster } from '@bumerang-kit/helpers';
import { getCurrentSession } from '../helpers/session';
import { useTranslation } from 'react-i18next';

const toaster = new Toaster();
const analyzer = new Analyzer();
const StyledForm = styled.form`
  margin-top: ${Spacing.l};
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.l};
  margin-bottom: ${Spacing.xs};
`;

export default function CardSetupForm({ onDismiss }: any) {
  const stripe = useStripe();

  const elements = useElements();
  const currentUser = getCurrentSession();
  const { t } = useTranslation();
  const [isSettingUpTheCard, setIsSettingUpTheCard] = React.useState<boolean>(false);
  const [cardInputComplete, setCardInputComplete] = React.useState<boolean>(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsSettingUpTheCard(true);

    const card = elements && elements.getElement(CardElement);

    if (!stripe || !card) {
      return;
    }

    const paymentApi = new PaymentApi(new ApiCaller(currentUser.token));

    paymentApi
      .getClientSecret()
      .then((secret: any) => {
        stripe
          .confirmCardSetup(secret, {
            payment_method: {
              card: card,
              billing_details: {
                name: currentUser.name,
              },
            },
          })
          .then(async (result) => {
            if (result.error) {
              setIsSettingUpTheCard(false);
              onDismiss ? onDismiss() : null;
              toaster.flashError(t('CardSetupNegativeFeedback'));
              return;
            }

            setIsSettingUpTheCard(false);
            if (onDismiss !== undefined) {
              onDismiss();
            }

            toaster.flashSuccess(t('CardSetupPositiveFeedback'));

            await paymentApi.updatePaymentStatus('configured');
            analyzer.trackAddedCard(currentUser.id);
            setTimeout(() => {
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            setIsSettingUpTheCard(false);
          });
      })
      .catch(() => {
        setIsSettingUpTheCard(false);
      });
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <CardSection setCard={setCardInputComplete} />
      <StyledButton type="submit" disabled={!cardInputComplete || isSettingUpTheCard}>
        {isSettingUpTheCard ? t('AddCardLoading') : t('AddCard')}
      </StyledButton>
      <div className="mt-4">
        <Text config="small-text" align="center">
          <span role="img" aria-label="locker">
            🔒
          </span>{' '}
          Secured by Stripe
        </Text>
      </div>
    </StyledForm>
  );
}
