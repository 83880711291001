/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { LoadingHome, Tabs } from '@bumerang-kit/components';
import { usePlatform } from '@bumerang-kit/helpers';
import Analyzer from '@/lib/common/helpers';
import { getCurrentSession } from '@/helpers/session';
import { useGetActiveRestaurants } from '@/features/logistics';
import { useGetCurrentLocation } from '../../hooks';
import { MapTab, PartnerListTab } from '../../components';
import { NavigationWrapper, Partner } from '@/features/shared';
import { IonContent, IonLoading } from '@ionic/react';

const analyzer = new Analyzer();

const StyledTabs = styled(Tabs)`
  position: absolute;
  top: var(--ion-safe-area-top, 0);
`;

const Explorer: React.FC = () => {
  const navigate = useHistory();
  const currentUser = getCurrentSession();
  const { data: platform } = usePlatform();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const currentTabFromSearch = query.get('tab') || 'list';
  const [activeTab, setActiveTab] = useState<string>(currentTabFromSearch);
  const { position, isLoading: getCurrentLocationIsLoading } = useGetCurrentLocation();
  const [isQRModalOpen, setIsQRModalOpen] = React.useState<boolean>(false);
  /* const [loading, setLoading] = React.useState<boolean>(false); */
  const limit = 1000;

  const {
    activePartners,
    isLoading: getActiveRestaurantsIsLoading,
    handleNextPage,
  } = useGetActiveRestaurants({ position, limit, order: position ? 'distance' : 'name' });

  const isLoading = getCurrentLocationIsLoading || getActiveRestaurantsIsLoading;

  const openDetailsHandler = async (partner: Partner | null) => {
    if (!partner) return;
    analyzer.trackRestaurantVisited(currentUser.id, partner.name);
    if (partner) navigate.push(`/partner/${partner.id}/details`);
  };

  React.useEffect(() => {
    analyzer.trackOpenMap(currentUser.id, platform);
  }, [currentUser.id, platform]);

  if (isLoading)
    return (
      <NavigationWrapper
        active="map"
        type="customer"
        isQRModalOpen={isQRModalOpen}
        onOpenQRModal={(isOpen: boolean) => setIsQRModalOpen(isOpen)}
      >
        <LoadingHome />
      </NavigationWrapper>
    );

  return (
    <IonContent /* onLoad={() => setLoading(true)} */>
      {/* <IonLoading
        onDidDismiss={() => setLoading(false)}
        isOpen={loading}
        animated
        duration={3000}
      ></IonLoading> */}
      <NavigationWrapper
        active="map"
        type="customer"
        isQRModalOpen={isQRModalOpen}
        onOpenQRModal={(isOpen: boolean) => setIsQRModalOpen(isOpen)}
      >
        <StyledTabs
          onClick={(tabName: string) => {
            setActiveTab(tabName);
          }}
          type="map"
          active={activeTab}
        />
        {activeTab === 'map' ? (
          <MapTab
            currentPosition={position}
            partners={activePartners}
            onPartnerCardClick={openDetailsHandler}
          />
        ) : (
          <PartnerListTab
            partners={activePartners}
            onFetchNextPage={() => {
              handleNextPage();
            }}
            currentPosition={position}
          />
        )}
      </NavigationWrapper>
    </IonContent>
  );
};

export default Explorer;
