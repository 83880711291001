const Sizes = {
  xxs: '10px',
  xs: '12px',
  s: '14px',
  m: '16px',
  l: '18px',
  xl: '20px',
  xxl: '30px',
  xxxl: '40px',
};

export default Sizes;
