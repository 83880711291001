import React from 'react';
import { useHistory } from 'react-router';
import { LoadingHome, Navigation } from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import { useTranslation } from 'react-i18next';
import { useGetActiveRestaurants } from '@/features/logistics';
import { Partner } from '@/features/shared';
import { CustomMap } from '../features/explorer/components/MapTab/components';
import PartnerCardMap from '../features/explorer/components/MapTab/components/PartnerCardMap';
import RegisterDialog from '@/components/RegisterDialog';
import { useGetCurrentLocation } from '@/features/explorer';

//TODO: improve imports and reuse the map instead of duplicating it
const GuestMap: React.FC = () => {
  const [selected, setSelected] = React.useState<Partner | null>(null);
  const navigate = useHistory();
  const { activePartners, isLoading: getActiveRestaurantsIsLoading } = useGetActiveRestaurants({
    limit: 2000,
  });
  const [showRegisterDialog, setRegisterDialog] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const { position, isLoading: getCurrentLocationIsLoading } = useGetCurrentLocation();

  const isLoading = getCurrentLocationIsLoading || getActiveRestaurantsIsLoading;

  if (isLoading)
    return (
      <Page>
        <Navigation onBack={() => navigate.push('/')}>{t('GuestMapPageTitle')}</Navigation>
        <LoadingHome />
      </Page>
    );

  return (
    <Page>
      <Navigation onBack={() => navigate.push('/')}>{t('GuestMapPageTitle')}</Navigation>
      <Container noPadding>
        <CustomMap
          currentPosition={position}
          partners={activePartners}
          onMarkerClick={(partner: Partner) => setSelected(partner)}
          onDismiss={() => setSelected(null)}
        />
        {showRegisterDialog && <RegisterDialog onClose={() => setRegisterDialog(false)} />}
        <PartnerCardMap
          partner={selected}
          onClick={() => {
            setSelected(null);
            setRegisterDialog(true);
          }}
        />
      </Container>
    </Page>
  );
};

export default GuestMap;
