import * as React from 'react';

export type TagProps = {
  children: JSX.Element;
};

const Tag = (props: TagProps) => {
  return (
    <div className="py-1 px-4 text-xs font-bold flex justify-center bg-tag-yellow text-main rounded-2xl text-center">
      {props.children}
    </div>
  );
};

export default Tag;
