import { Deliveries, DeliveryOptions } from '@/features/shared';
import * as React from 'react';
import DeliveryIcon from '@bumerang-kit/components/DeliveryIcon';
import { Line } from '@bumerang-kit/components';
import { useTranslation } from 'react-i18next';

type DeliveryListProps = {
  deliveries: DeliveryOptions;
  onHowItWorksClick: () => void;
  onClick: (key: string) => void;
};

const getProps = (props: DeliveryListProps) => {
  return {
    hasDelivery: Object.keys(props.deliveries).length > 0,
    keys: Object.keys(props.deliveries) as Deliveries[],
    onClick: props.onClick,
    onHowItWorksClick: props.onHowItWorksClick,
  };
};

const DeliveryList = (props: DeliveryListProps) => {
  const { keys, hasDelivery, onClick, onHowItWorksClick } = getProps(props);
  const { t } = useTranslation();

  if (!hasDelivery) {
    return null;
  }

  return (
    <>
      <Line />
      <section>
        <header className="flex justify-between align-middle">
          <h3 className="m-0 font-montserrat text-m font-bold text-black">
            {t('PartnerProfile.DeliveryHeading')}
          </h3>
          <a onClick={() => onHowItWorksClick()} className="text-xs text-main underline">
            {t('HowDoesItWork')}
          </a>
        </header>
        <main className="mt-3 flex justify-center gap-3">
          {keys.map((key: Deliveries) => {
            return <DeliveryIcon key={key} name={key} onClick={() => onClick(key)} />;
          })}
        </main>
      </section>
    </>
  );
};

export default DeliveryList;
