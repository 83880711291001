import React, { useEffect, useState } from 'react';

import ListItem from './ListItem';

const SelectorList = ({ values, onSelect }: any) => {
  const [currentValue, setCurrentValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    onSelect(currentValue);
  }, [currentValue]);

  return (
    <>
      {values &&
        values.map((value: any, index: number) => (
          <ListItem
            key={index}
            selected={value.value === currentValue}
            onClick={(lend: string) => setCurrentValue(lend)}
            {...value}
          />
        ))}
    </>
  );
};

export default SelectorList;
