import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-http-backend';
import translationEN from './locales/en/translationEN.json';
import translationES from './locales/es/translationES.json';

const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: [
        'navigator',
        'cookie',
        'querystring',
        'sessionStorage',
        'localStorage',
        'htmlTag',
        'path',
        'subdomain',
      ],
    },
    lng: navigator.language,
    resources,
    fallbackLng: 'es',
    supportedLngs: ['es', 'en'],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
