import React from 'react';
import { Network } from '@capacitor/network';

export const useNetwork = (initial = true) => {
  const [isConnected, setIsConnected] = React.useState<boolean>(initial);

  React.useEffect(() => {
    const getCurrentNetworkStatus = async () => {
      const currentStatus = await Network.getStatus();
      if (currentStatus) setIsConnected(currentStatus.connected);
    };

    Network.addListener('networkStatusChange', (status: any) => {
      setIsConnected(status.connected);
    });

    getCurrentNetworkStatus();

    return () => {
      Network.removeAllListeners();
    };
  }, []);

  return { isConnected };
};

export default useNetwork;
