import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import AuthApi from '../api/AuthApi';
import ApiCaller from '../lib/ApiCaller';
import { Button, Input, Navigation } from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import LendApi from '../api/LendApi';
import { Toaster } from '@bumerang-kit/helpers';
import { getCurrentSession } from '@/helpers/session';

const toaster = new Toaster();

const StyledButton = styled(Button)`
  margin: ${Spacing.m} auto;
`;
const TypeQr: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const location = useLocation();
  const [qr, setQR] = useState<string>('B-');
  const currentUser = getCurrentSession();
  const isQRValid = !(qr.length >= 9);

  const handleError = (error: any) => {
    if (error === 'TypeError: Failed to fetch') toaster.flashError(t('TypeQrNoResponse'));
    else toaster.flashError(error.result);
    navigate.push('/restaurant', { state: { isSuccess: false } });
  };

  const handlePaste = (event: any) => {
    event.preventDefault();
    const pastedContent = event.clipboardData.getData('Text');

    if (pastedContent.includes('B-')) {
      const qrCode = pastedContent.slice(pastedContent.indexOf('B-'));
      setQR(qrCode);
    } else {
      setQR(pastedContent);
    }
  };

  const changeHandler = (e: React.FormEvent) => {
    e.preventDefault();

    const lendApi = new LendApi(new ApiCaller(currentUser.token));
    const authApi = new AuthApi(new ApiCaller(currentUser.token));
    const pathname = location?.pathname;

    authApi
      .getTokenById(qr)
      .then((token: any) => {
        lendApi
          .isUsed(token.token)
          .then((e) => {
            if (e.result === null && pathname === '/typeqr') {
              navigate.push('/restoptions', {
                state: {
                  token,
                  inputMethod: 'typed',
                },
              });
            } else {
              toaster.flashError(e.result);
            }
          })
          .catch((e) => handleError(e));
      })
      .catch((e) => handleError(e));
  };

  return (
    <Page>
      <Navigation backgroundColor={Colors.white} onBack={() => navigate.go(-1)}>
        {t('TypeQrTitle')}
      </Navigation>
      <Container>
        <form onSubmit={changeHandler}>
          <Input
            value={qr}
            onChange={(e: CustomEvent) => setQR(e.detail.value.toUpperCase())}
            label={t('TypeQrLabel')}
            onPaste={(e: any) => handlePaste(e)}
          />
          <StyledButton disabled={isQRValid} type="submit">
            {t('TypeQrSubmit')}
          </StyledButton>
        </form>
      </Container>
    </Page>
  );
};

export default TypeQr;
