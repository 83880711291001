import AuthApi from '@/api/AuthApi';
import { getCurrentSession, setCurrentSession } from '@/helpers/session';
import ApiCaller from '@/lib/ApiCaller';
import Settings from '@/lib/settings';
import { Button } from '@bumerang-kit/components';
import { Toaster } from '@bumerang-kit/helpers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const StyledError = styled.div`
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 13px;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.004em;
  color: #ef6c65;
`;

const StyledFeedback = styled.div`
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 17px;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.004em;
  color: #1ab420;
`;

const StyledReactInputVerificationCode = styled.div<StyleProps>`
  display: flex;
  justify-content: center;

  --ReactInputVerificationCode-itemWidth: 40px;
  --ReactInputVerificationCode-itemHeight: 48px;
  --ReactInputVerificationCode-itemSpacing: 8px;

  .ReactInputVerificationCode__item {
    font-size: 20px;
    font-weight: 500;
    color: #322e8f;
    font-family: 'Montserrat';

    border-bottom: 3px solid ${({ isInvalid }) => (isInvalid ? '#EF6C65' : '#322e8f')};

    box-shadow: none;
  }

  .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
    border: 1px solid #322e8f;
    border-bottom: 3px solid #322e8f;
  }
`;
interface StyleProps {
  className?: string;
  fontSize?: any;
  color?: string;
  isInvalid?: boolean;
}
interface Props {
  className?: string;
  fontSize?: any;
  color?: string;
  isInvalid?: boolean;
  onDismiss: () => void;
  feedback: string;
}

const toaster = new Toaster();

export default function VerificationCode({ className, onDismiss, feedback }: Props): JSX.Element {
  const currentUser = getCurrentSession();
  const [value, setValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const authApi = new AuthApi(new ApiCaller(currentUser.token));
  const history = useHistory();

  return (
    <div className={className}>
      <StyledReactInputVerificationCode isInvalid={isInvalid} className="mb-8">
        <ReactInputVerificationCode
          value={value}
          length={6}
          placeholder={''}
          onChange={(newValue: string) => {
            setValue(newValue);
            if (newValue.length === 6) {
              setIsDisabled(false);
            } else {
              setIsDisabled(true);
            }
          }}
        />
      </StyledReactInputVerificationCode>

      {error && <StyledError>{error}</StyledError>}

      {feedback && <StyledFeedback>{feedback}</StyledFeedback>}

      <Button
        disabled={isDisabled || isInvalid}
        onClick={() => {
          setValue('');

          authApi
            .verify(value)
            .then((response: any) => {
              if (response.success) {
                setCurrentSession(response.data.token);
                Settings.setEmailAsValidated();
                onDismiss();
                history.push(`/${response.data.type}`);
                toaster.flashSuccess(t('EmailVerifiedPositiveFeedback'));
              } else {
                setError(response.data.error);
                setTimeout(() => {
                  setError(null);
                }, 3000);
                setIsInvalid(true);
                setTimeout(() => {
                  setIsInvalid(false);
                }, 3000);
              }
            })
            .catch((error) => {
              if (error.message) {
                toaster.flashError(error.message);
              } else {
                toaster.flashError(t('GeneralError'));
              }
              history.push('/');
            });
        }}
      >
        {t('ResendEmailButton')}
      </Button>
    </div>
  );
}
