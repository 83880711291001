import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../../../layouts';
import { EmptyState } from '../../index';
import { HistoryCard } from '../../molecules';
import Settings from '../../../../lib/settings';
import styled from 'styled-components';

const ReturnedHistory = ({ list }: { list: any }) => {
  const { t } = useTranslation();
  const StyledDiv = styled.div`
    padding-bottom: 3vh;
  `;

  return (
    <StyledDiv>
      {list.length === 0 ? (
        <Container>
          <EmptyState
            src="assets/emptyBowlIncorrect.svg"
            title={t('ReturnedHistoryEmptyStateTitle')}
            subtitle={t('ReturnedHistoryEmptyStateSubtitle', {
              daysLeft: Settings.getNumDaysToReturn(),
              penaltyPrice: Settings.getPenaltyPrice(),
            })}
          />
        </Container>
      ) : (
        <div>
          {list
            .map((lend: any, index: number) => {
              return <HistoryCard key={index} returned lend={lend} />;
            })
            .sort(list.collected_at > list.collected_at ? list.collected_at : list.collected_at)}
        </div>
      )}
    </StyledDiv>
  );
};
export default ReturnedHistory;
