import React from 'react';
import styled from 'styled-components';

import { Colors, Spacing } from '../../../foundations';

import Icon from '../Icon';

const Container = styled.div`
  padding: ${Spacing.xs};
  border: ${Spacing.m} solid white;
  border-bottom: 0px;
  background-color: ${Colors.tagCream};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  width: 80px;
  height: auto;
  margin-right: ${Spacing.s};
`;

const Notice = ({ content }: any) => (
  <Container>
    <StyledIcon name="notice" />
    {content}
  </Container>
);

export default Notice;
