import { Button, Checkbox } from '@bumerang-kit/components';
import CheckBoxBewsLetter from '@bumerang-kit/components/molecules/Checkbox/CheckBoxNewsLetter';
import { Colors } from '@bumerang-kit/foundations';
import { IonModal } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface HomeModalProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  socialLogin: boolean;
  logedInWithGoogle: boolean;
  appleLogin: boolean;
  editUser: any;
  userData: any;
}

const StyledCheckBox = styled(Checkbox)`
  font-size: 12px;
`;

const HomeModalStyle = styled(IonModal)`
  .ion-page {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 95px;
    height: 95px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 10px;
    margin: 10px, 0;

    input {
      background-color: ${Colors.backgroundBlue};
      padding: 0;
      font-size: 14px;
      width: 100%;
      height: 25px;
      margin: 5px 0;
      border-bottom: solid 2px ${Colors.mainColor};
    }
  }

  div .container-modal {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: ${Colors.paleBlue};
    border-radius: 20px;

    .checkboxes {
      align-items: flex-start;
    }
  }
`;

const NewsLetterModal = ({
  isOpen,
  setIsOpen,
  socialLogin,
  logedInWithGoogle,
  appleLogin,
  userData,
  editUser,
}: HomeModalProps) => {
  const [tycStatus, setTycStatus] = React.useState<boolean>(false);
  const { token, name, email } = userData;
  const [newsLetter, setNewsLetter] = React.useState<boolean>();
  const emptyForm: any = {
    name: '',
    email: '',
  };

  const updateInput = (event: any) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = () => {
    if (logedInWithGoogle) {
      if (newsLetter === null || newsLetter === undefined) {
        editUser.mutate({ token, name, email, newsletter: false });
        setIsOpen(false);
      } else {
        editUser.mutate({ token, name, email, newsletter: true });
        setIsOpen(false);
      }
    } else if (appleLogin) {
      if (newsLetter === null || newsLetter === undefined) {
        editUser.mutate({ token, name, email, newsletter: false });
        setIsOpen(false);
      } else {
        editUser.mutate({ token, name: formName, email: formEmail, newsletter: true });
        setIsOpen(false);
      }
    } else {
      if (newsLetter === null || newsLetter === undefined) {
        editUser.mutate({ token, name, email, newsletter: false });
        setIsOpen(false);
      } else {
        editUser.mutate({ token, name, email, newsletter: true });
        setIsOpen(false);
      }
    }
  };

  const [formData, setFormData] = React.useState(emptyForm);
  const formName = formData.name;
  const formEmail = formData.email;
  const invalidFormData = formData.name === '' || formData.email === '';
  const { t } = useTranslation();
  return (
    <HomeModalStyle isOpen={isOpen} cssClass="my-custom-class">
      <div className="container-modal px-3">
        {socialLogin ? (
          <>
            {logedInWithGoogle ? (
              <>
                <img src="/assets/security-logo.svg" alt="Security logo" />
                <h1 className="text-[20px] text-center text-main font-montserrat font-bold">
                  {t('PrivacyAndSecurity')}
                </h1>
                <div className="checkboxes">
                  <StyledCheckBox
                    name="tyc"
                    checked={tycStatus}
                    onCheck={(status: boolean) => setTycStatus(status)}
                  />
                  <CheckBoxBewsLetter
                    name="newsletter"
                    onCheck={(status: any) => setNewsLetter(status)}
                    checked={newsLetter}
                  />
                </div>
                <Button
                  className="mt-2"
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={!tycStatus}
                  primary
                >
                  {t('Continue')}
                </Button>
              </>
            ) : (
              <>
                <img src="/assets/newsletter-mail.svg" alt="Newsletters mail logo" />
                <h1 className="text-[20px] text-center text-main font-montserrat font-bold">
                  {t('SubscribeNewsletter')}
                </h1>
                <p className="text-xs text-center m-3">{t('NewsletterDescription')}</p>
                <form className="newsletter-form" onSubmit={() => handleSubmit()}>
                  <label htmlFor="name" className="name-label text-xs font-montserrat">
                    {t('Name')}
                  </label>
                  <input
                    className="name-input"
                    name="name"
                    id="name"
                    type="text"
                    value={formData.name}
                    required
                    onChange={updateInput}
                  />
                  <label htmlFor="email" className="email-label text-xs font-montserrat">
                    E-mail
                  </label>
                  <input
                    className="email-input"
                    name="email"
                    id="email"
                    type="email"
                    value={formData.email}
                    required
                    onChange={updateInput}
                  />
                </form>
                <Button
                  onClick={() => {
                    setNewsLetter(true);
                    handleSubmit();
                  }}
                  disabled={invalidFormData}
                  primary
                >
                  {t('Subscribe')}
                </Button>
                <a
                  className="text-main font-bold font-montserrat m-2 text-decoration-line: underline cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setNewsLetter(false);
                    handleSubmit();
                  }}
                >
                  {t('NotNow')}
                </a>
              </>
            )}
          </>
        ) : (
          <>
            <img src="/assets/newsletter-mail.svg" alt="Newsletters mail logo" />
            <h1 className="text-[20px] text-center text-main font-montserrat font-bold">
              {t('SubscribeNewsletter')}
            </h1>
            <p className="text-xs text-center m-3">{t('NewsletterDescription')}</p>
            <Button
              onClick={() => {
                setNewsLetter(true);
                handleSubmit();
              }}
              primary
            >
              {t('Subscribe')}
            </Button>
            <a
              className="text-main font-bold font-montserrat m-2 text-decoration-line: underline cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                setNewsLetter(false);
                handleSubmit();
              }}
            >
              {t('NotNow')}
            </a>
          </>
        )}
      </div>
    </HomeModalStyle>
  );
};

export default NewsLetterModal;
