import { IonContent } from '@ionic/react';
import React from 'react';
import { Page } from '@bumerang-kit/layouts';

const Faq: React.FC = () => {
  return (
    <Page>
      <IonContent scrollY={false}>FAQ</IonContent>
    </Page>
  );
};

export default Faq;
