import React from 'react';
import styled from 'styled-components';
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonPopover,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import './LoadingModal.css';

interface ReorderProps {
  isOpen: boolean;
  message: string;
}

const StyledIonCard = styled(IonCard)`
  margin: 0;
  padding-top: 24px;
  padding-bottom: 24px;
`;

const LoadingModal = ({ isOpen, message }: ReorderProps) => {
  return (
    <IonPopover isOpen={isOpen} cssClass="bigger-popover">
      <IonContent class="ion-text-center modal-content">
        <StyledIonCard>
          <IonGrid>
            <IonRow>
              <IonCol size="2" text-center>
                <IonCardContent className="text-center">
                  <IonSpinner name="crescent" color="primary"></IonSpinner>
                </IonCardContent>
              </IonCol>

              <IonCol size="10" text-center>
                <IonCardContent className="text-center">{message}</IonCardContent>
              </IonCol>
            </IonRow>
          </IonGrid>
        </StyledIonCard>
      </IonContent>
    </IonPopover>
  );
};
export default LoadingModal;
