import Restaurant from './restaurant';
import Distance from '../helpers/Distance';

class RestaurantsCollection {
  restaurants: Array<Restaurant>;
  distance?: Distance;

  constructor(restaurants: Array<Restaurant>, distance?: Distance) {
    this.restaurants = restaurants;
    this.distance = distance;
  }

  length(): number {
    return this?.restaurants?.length || 0;
  }

  search(text: string): RestaurantsCollection {
    return new RestaurantsCollection(
      this.restaurants.filter(
        (restaurant: any) =>
          restaurant &&
          (restaurant.name.toLowerCase().includes(text.toLowerCase()) ||
            restaurant.email.toLowerCase().includes(text.toLowerCase()))
      )
    );
  }

  getRestaurantsWithContainers(): RestaurantsCollection {
    return new RestaurantsCollection(
      this.restaurants.filter(
        (restaurant: any) =>
          restaurant && restaurant.assigned_containers[0] !== null
      )
    );
  }

  getSortedByDistance({
    lat,
    lng,
  }: {
    lat: number;
    lng: number;
  }): RestaurantsCollection {
    return this.distance !== undefined
      ? new RestaurantsCollection(
          this.restaurants.sort(
            (previousRestaurant: Restaurant, nextRestaurant: Restaurant) => {
              const distanceA = previousRestaurant.getCurrentDistanceInKm(
                this.distance!,
                lat,
                lng
              );

              const distanceB = nextRestaurant.getCurrentDistanceInKm(
                this.distance!,
                lat,
                lng
              );

              return distanceA - distanceB;
            }
          ),

          this.distance
        )
      : new RestaurantsCollection(this.restaurants);
  }

  setDistanceObject(distance: Distance): void {
    this.distance = distance;
  }
}

export default RestaurantsCollection;
