import { IonImg } from '@ionic/react';
import { Route, useLocation } from 'react-router';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { useState } from 'react';
import styled from 'styled-components';

import { Container, Page } from '@bumerang-kit/layouts';
import { LoadingHome, Button, Text } from '@bumerang-kit/components';
import { Spacing, Colors } from '@bumerang-kit/foundations';
import SingleStatCard from '@bumerang-kit/components/molecules/SingleStatCard';
import { getCurrentSession } from '@/helpers/session';
import OauthApplicationAuthorizationApi from '../api/OauthApplicationAuthorizationApi';
import ApiCaller from '../lib/ApiCaller';
import { encodeOAuthError } from '../helpers/oauth';

const StyledButton = styled(Button)`
  margin-top: ${Spacing.l};
  margin-bottom: ${Spacing.s};
`;

const StyledContainer = styled(Container)`
  padding: ${Spacing.s} ${Spacing.m};
`;

const WrapperContainer = styled(Container)`
  padding: 0;
  margin-top: ${Spacing.s};
  margin-bottom: ${Spacing.m};
`;

const Logo = styled(IonImg)`
  width: 55px;
  margin: 0 auto;
`;

const Header = styled.header`
  padding: ${Spacing.s};
  border-bottom: 1px solid ${Colors.mainColorDisabled};
`;

const BumerangPayment = styled(Text)`
  margin-top: ${Spacing.s};
`;

const OauthAuthorize = () => {
  const [authorizationStatus, setAuthorizationStatus] = useState<any>();
  const location = useLocation();
  const context = queryString.parse(location.search);
  const clientId: string = context.client_id as string;
  const redirectUri: string = context.redirect_uri as string;
  const currentUser = getCurrentSession();
  const oauthApplicationAuthorizationApi = new OauthApplicationAuthorizationApi(
    new ApiCaller(currentUser.token)
  );
  const getAuthorizationStatus = useQuery(
    'getAuthorizationStatus',
    async () =>
      await oauthApplicationAuthorizationApi.getAuthorizationStatus(clientId, redirectUri),
    {
      onSuccess: (data: any) => setAuthorizationStatus(data),
      onError: () => setAuthorizationStatus({ success: false }),
    }
  );

  const authorizeApplication = async () => {
    oauthApplicationAuthorizationApi
      .authorizeApplication(clientId, redirectUri)
      .then((data: any) => setAuthorizationStatus(data))
      .catch(() => setAuthorizationStatus({ success: false }));
  };

  if (getAuthorizationStatus.isLoading)
    return (
      <Page>
        <LoadingHome />
      </Page>
    );

  if (authorizationStatus && authorizationStatus?.success) {
    if (!authorizationStatus?.data.authorized) {
      return (
        <Page>
          <Header>
            <Logo src="assets/logo-con-nombre.svg" />
          </Header>
          <StyledContainer>
            <Text config="screen-title" align="center">
              <strong>{authorizationStatus?.data.name} quiere acceder a tu cuenta:</strong>
            </Text>
            <WrapperContainer>
              <SingleStatCard
                iconName="full-account"
                title="Podrá acceder a tu email y a tu nombre"
                metric=""
              />
              <SingleStatCard
                iconName="bowl"
                title={`Podrá gestionar los envases Bûmerangs que utilices en la plataforma ${authorizationStatus?.data.name}`}
                metric=""
              />

              <BumerangPayment config="secondary-text">
                El pago de los envases seguirá siendo gestionado desde nuestra plataforma Bûmerang
              </BumerangPayment>

              <StyledButton type="button" expand="full" onClick={() => authorizeApplication()}>
                Autorizar
              </StyledButton>
            </WrapperContainer>
          </StyledContainer>
        </Page>
      );
    }

    if (authorizationStatus?.data.redirect_uri === 'urn:ietf:wg:oauth:2.0:oob') {
      return (
        <Page>
          <Container>
            <Text config="screen-title" align="center">
              Success code: {authorizationStatus?.data.code}
            </Text>
          </Container>
        </Page>
      );
    }

    if (authorizationStatus?.data.redirect_uri === 'urn:ietf:wg:oauth:2.0:oob:auto') {
      return (
        <Page>
          <Container>
            <Text config="screen-title" align="center">
              Close the current tab
            </Text>
          </Container>
        </Page>
      );
    }

    return (
      <Route
        render={() => {
          const url = new URL(authorizationStatus?.data.redirect_uri);
          const params = new URLSearchParams(url.search.slice(1));
          params.set('code', authorizationStatus?.data.code);

          window.location.replace(`${authorizationStatus?.data.redirect_uri}?${params.toString()}`);
          return null;
        }}
      />
    );
  }

  if (authorizationStatus && !authorizationStatus?.success) {
    if (typeof authorizationStatus?.data.errorCode === 'string') {
      if (authorizationStatus?.data.redirect_uri === 'urn:ietf:wg:oauth:2.0:oob:auto') {
        return (
          <Page>
            <Container>
              <Text config="screen-title" align="center">
                Close the current tab
              </Text>
            </Container>
          </Page>
        );
      }

      return (
        <Route
          render={() => {
            const url = new URL(authorizationStatus?.data.redirect_uri);
            const params = new URLSearchParams(url.search.slice(1));
            params.set('error', authorizationStatus?.data.errorCode);

            window.location.replace(
              `${authorizationStatus?.data.redirect_uri}?${params.toString()}`
            );
            return null;
          }}
        />
      );
    }

    return (
      <Route
        render={() => {
          if (authorizationStatus.data.error) {
            window.location.replace(
              `/oauth/error?authError=${encodeOAuthError(authorizationStatus.data.error)}`
            );
          } else {
            window.location.replace(`/oauth/error`);
          }
          return null;
        }}
      />
    );
  }
  return (
    <Page>
      <LoadingHome />
    </Page>
  );
};

export default OauthAuthorize;
