import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Input, Placeholder, Text } from '../../../components';
import { Colors, Spacing } from '../../../foundations';

const Section = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 98px;
`;

const ProductType = styled.div`
  background-color: white;
  width: 100%;
  position: fixed;
  margin: -${Spacing.m} -${Spacing.s};
  padding: ${Spacing.xs};
`;

const ProductCount = styled.div`
  height: 60px;
  margin-bottom: ${Spacing.xs};
`;

const List = styled.div`
  padding-top: 131px;
`;

const ProductTypeListItem = styled.div`
  display: flex;
  vertical-align: center;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.backgroundBlue};
  padding: ${Spacing.xs} 0;
  margin-bottom: ${Spacing.xxs};

  &:last-child {
    margin-bottom: ${Spacing.xxl};
  }
`;

const FixedBlock = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${Spacing.m} ${Spacing.s};
  background-color: ${Colors.white};
`;

const StyledForm = styled.form`
  margin-top: ${Spacing.xs};
  margin-bottom: ${Spacing.xs};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-right: ${Spacing.xxs};
`;

const StyledText = styled(Text)`
  margin-left: ${Spacing.s};
`;

const ProductTypeList = ({
  items,
  productCount,
  onConfirm,
  onSelectedItem,
  onUnselectedItem,
  options,
  query,
  setQuery,
  hasNextPage,
  loadMore,
  loadMoreRef,
}: any) => {
  const { t } = useTranslation();
  const placeholderDescription: string = options?.placeholderDescription
    ? options.placeholderDescription
    : t('ScanProductTypeDescription');
  const placeholderTitle: string = options?.placeholderTitle
    ? options.placeholderTitle
    : t('ScanProductTypeInstructions');
  const confirmSelectionButton: string = options?.confirmSelectionButton
    ? options.confirmSelectionButton
    : t('ConfirmSelection');
  const selectedProductTypes: string = options?.selectedProductTypes
    ? options.selectedProductTypesTitle
    : t('SelectedProductTypes');

  return (
    <>
      <Section>
        <ProductType>
          {query !== undefined && setQuery !== undefined && (
            <StyledForm>
              <Input
                label={t('Search')}
                name="search"
                id="search"
                type="text"
                value={query}
                onChange={(event: any) => setQuery(event.target.value)}
              />
            </StyledForm>
          )}
          <ProductCount>
            <Text config="secondary-text-title" align="center">
              {selectedProductTypes}:{' '}
              {(Object.values(productCount) as number[]).reduce(
                (current, item) => current + item,
                0
              )}
            </Text>
            {Object.keys(productCount).map((key) => (
              <Text config="secondary-text-title" align="center" key={key}>
                {`${key}: ${productCount[key]}`}
              </Text>
            ))}
          </ProductCount>
        </ProductType>
        <List>
          {items && items.length > 0 ? (
            <>
              {items.map((item: any) => (
                <ProductTypeListItem key={item.productType.id}>
                  <IconContainer>
                    {item.productType.group === 'Cup' ? (
                      <StyledIcon name="cup" size="24" />
                    ) : (
                      <StyledIcon name="bowl" size="24" />
                    )}
                    <StyledText>{item.productType.code}</StyledText>
                  </IconContainer>
                  {item.selected === true ? (
                    <div
                      onClick={() => {
                        return onUnselectedItem(item.productType.id);
                      }}
                    >
                      <Icon name="checkbox" size="24" />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        return onSelectedItem(item.productType.id);
                      }}
                    >
                      <Icon name="box" size="24" />
                    </div>
                  )}
                </ProductTypeListItem>
              ))}
              {hasNextPage && <button ref={loadMoreRef} onClick={() => loadMore()} />}
            </>
          ) : (
            <Placeholder
              icon="assets/scanCode.svg"
              title={placeholderTitle}
              description={placeholderDescription}
            />
          )}
        </List>
        <FixedBlock>
          <Button
            expand="full"
            disabled={
              !items ||
              items?.length === 0 ||
              items?.filter((item: any) => item.selected).length === 0
            }
            onClick={onConfirm}
          >
            {confirmSelectionButton}
          </Button>
        </FixedBlock>
      </Section>
    </>
  );
};

export default ProductTypeList;
