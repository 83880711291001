/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useHistory, useParams } from 'react-router';
import Analyzer from '@/lib/common/helpers';
import { getCurrentSession } from '@/helpers/session';
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';
import { IonPage } from '@ionic/react';
import { CallService } from '@bumerang-kit/helpers';
import { Clipboard } from '@capacitor/clipboard';
import { useQRAuth } from '@/features/auth';
import { FabButton, RestaurantCarrousel } from '@bumerang-kit/components';
import {
  CTAFooter,
  DeliveryRedirectDialog,
  NonIntendedResponse,
  PartnerSheet,
  useGetCurrentLocation,
  useGetPartnerDetails,
} from '@/features/explorer/';
import { useGetPaymentStatus } from '@/features/shared';

const analyzer = new Analyzer();

const PartnerProfile = () => {
  const navigate = useHistory();
  const { id } = useParams() as any;
  const currentUser = getCurrentSession();
  const { t } = useTranslation();
  const { position } = useGetCurrentLocation();
  const { paymentStatus } = useGetPaymentStatus(currentUser.status, {});
  const { data: currentQrCode }: any = useQRAuth({ enabled: paymentStatus === 'configured' });
  const [showRedirectionDialog, setShowRedirectionDialog] = React.useState(false);
  const [chosenDelivery, setChosenDelivery] = React.useState<string | undefined>(undefined);
  const { partner, isLoading, isError }: any = useGetPartnerDetails(id);

  const handleGoogleMapRedirect = async () => {
    const googleMapsUrl = `https://maps.google.com/?q=${encodeURIComponent(
      partner?.address || ''
    )}`;

    await Browser.open({
      url: googleMapsUrl,
    });
  };

  const handleDeliveryConfirm = (url: string) => {
    if (currentQrCode) {
      const contentToCopy = t('PartnerProfile.RedirectComment', {
        qrCode: currentQrCode.id,
      });
      Clipboard.write({
        string: contentToCopy,
      });

      return url.match(/^[0-9]/m) || url.match(/^[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/m)
        ? Browser.open({ url: partner.website })
        : Browser.open({ url });
    }
  };

  const handlePhoneClick = async () => {
    const phoneNumber = partner?.phone || '';
    await CallService.call(phoneNumber);
  };

  return partner ? (
    <IonPage className="pt-safe">
      <FabButton
        className="absolute mt-safe top-m left-m z-10"
        name="back_arrow"
        onClick={() => navigate.go(-1)}
      />

      <RestaurantCarrousel imgList={partner.photoUrl} />
      <PartnerSheet
        partner={partner}
        onDirections={handleGoogleMapRedirect}
        onPhone={handlePhoneClick}
        onWeb={() => Browser.open({ url: partner.website || '' })}
        onHowItWorks={() => navigate.push('/how-it-works/delivery')}
        onDelivery={(props) => {
          paymentStatus === 'configured'
            ? setShowRedirectionDialog(true)
            : navigate.push('/changePayment');
          setChosenDelivery(props);
          analyzer.trackGoButtonSubmit(currentUser.id, partner.name);
        }}
        currentPosition={position}
      />

      <CTAFooter
        onDirections={handleGoogleMapRedirect}
        onOrder={() =>
          paymentStatus === 'configured'
            ? setShowRedirectionDialog(true)
            : navigate.push('/changePayment')
        }
        hasDelivery={partner.delivery && Object.keys(partner.delivery).length > 0}
      />

      <DeliveryRedirectDialog
        open={showRedirectionDialog}
        code={paymentStatus === 'configured' ? currentQrCode.id : undefined}
        deliveries={partner.delivery}
        onClose={() => {
          setShowRedirectionDialog(false);
          setChosenDelivery(undefined);
        }}
        selected={chosenDelivery}
        onConfirm={(url: any) => handleDeliveryConfirm(url)}
      />
    </IonPage>
  ) : (
    <NonIntendedResponse isLoading={isLoading} isError={isError} />
  );
};

export default PartnerProfile;
