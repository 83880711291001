import * as React from 'react';
import styled from 'styled-components';

import { PartnerCard } from '@bumerang-kit/components';
import { Container } from '@bumerang-kit/layouts';
import { Colors } from '@bumerang-kit/foundations';
import { Virtuoso } from 'react-virtuoso';

import { Coords, Partner } from '@/features/shared';
import { useHistory } from 'react-router';

export type PartnerListProps = {
  partners: Partner[];
  onFetchNextPage: () => void;
  currentPosition?: Coords;
};

const StyledContainer = styled(Container)`
  padding: 0;
  background: ${Colors.paleBlue};
`;

const PartnerListTab = (props: PartnerListProps) => {
  const { partners, currentPosition } = props;
  const navigate = useHistory();

  const Row = (index: number) => {
    const partner = partners[index];
    return (
      <div className={'px-4 '}>
        <PartnerCard
          className={'mt-4'}
          onClick={() => navigate.push(`/partner/${partner.id}/details`)}
          key={partner.id}
          partner={partner}
          direction="horizontal"
          currentPosition={currentPosition}
        />
      </div>
    );
  };
  //TODO: implement pagination
  return (
    <StyledContainer>
      <Virtuoso
        style={{ height: '100%' }}
        className={'scrollbar-hide'}
        totalCount={partners.length}
        itemContent={(index) => Row(index)}
      />
    </StyledContainer>
  );
};
export default PartnerListTab;
