import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { IonAlert } from '@ionic/react';

import { Button, Input, Navigation, Text } from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import { Spacing } from '@bumerang-kit/foundations';
import { Toaster } from '@bumerang-kit/helpers';
import { getCurrentSession, removeCurrentSession, setCurrentSession } from '@/helpers/session';

import AuthApi from '../../api/AuthApi';
import ApiCaller from '../../lib/ApiCaller';
import { useQueries } from 'react-query';
import { useTranslation } from 'react-i18next';

const toaster = new Toaster();

const StyledContainer = styled(Container)`
  padding: ${Spacing.l};
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.l};
`;

const RemoveAccount = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${Spacing.l};
  width: 100%;
`;

const EditProfile = () => {
  const currentUser = getCurrentSession();
  const navigate = useHistory();
  const { t } = useTranslation();
  const [showConfirmAlert, setShowConfirmAlert] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>(currentUser.name);
  const authApi = new AuthApi(new ApiCaller(currentUser.token));

  const [editUser, removeUser] = useQueries([
    {
      queryKey: 'editUser',
      queryFn: async () => {
        const updatedUser = await authApi.editUser(name, currentUser.email);

        if (updatedUser.success && updatedUser.data.token)
          setCurrentSession(updatedUser.data.token);
      },
      onSuccess: () => {
        toaster.flashSuccess(t('RestOptionsGenericPositiveFeedback'));
        navigate.push('/profile');
      },
      onError: () => toaster.flashError(t('ResendEmailSomethingWentWrong')),
      enabled: false,
      retry: false,
    },
    {
      queryKey: 'removeUser',
      queryFn: async () => {
        const removedUser = await authApi.removeUser(currentUser.email);

        if (!removedUser.success) {
          throw new Error(removedUser.data.error);
        }

        if (removedUser.success) removeCurrentSession();
      },
      onSuccess: () => {
        toaster.flashSuccess(t('RemovedAccount'));
        navigate.push('/');
      },
      onError: (error: any) => {
        if (error && error.message) {
          toaster.flashError(error.message);
          return;
        }

        toaster.flashError(t('ResendEmailSomethingWentWrong'));
      },
      enabled: false,
      retry: false,
    },
  ]);

  return (
    <Page>
      <Navigation onBack={() => navigate.push('/profile')}>{t('EditName')}</Navigation>
      <StyledContainer>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            editUser.refetch();
          }}
        >
          <Input
            label={t('RegisterNameLabel')}
            name="name"
            id="name"
            type="name"
            value={name}
            onChange={(e: any) => setName(e.detail.value!)}
            required
          />
          <StyledButton type="submit" expand="full" disabled={!name}>
            {t('SaveChanges')}
          </StyledButton>
        </form>
        <RemoveAccount>
          <Text
            align="center"
            config="main-text-link"
            isLink
            onClick={() => setShowConfirmAlert(true)}
          >
            {t('RemoveAccount')}
          </Text>
        </RemoveAccount>
        <IonAlert
          isOpen={showConfirmAlert}
          onDidDismiss={() => {
            setShowConfirmAlert(false);
          }}
          header={t('RemoveAccountTitle')}
          message={t('RemoveAccountDescription')}
          buttons={[
            {
              text: t('Cancel'),
              role: 'cancel',
            },
            {
              cssClass: 'bold',
              text: t('Delete'),
              handler: () => {
                removeUser.refetch();
              },
            },
          ]}
        />
      </StyledContainer>
    </Page>
  );
};

export default EditProfile;
