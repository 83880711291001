import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import ProductApi from '../../apiClient/ProductApi';

import { Container, Page } from '@bumerang-kit/layouts';
import { Toaster, useInfiniteLoading } from '@bumerang-kit/helpers';
import { Navigation } from '@bumerang-kit/components';

import { getCurrentSession } from '@/helpers/session';

import Settings from '../../lib/settings';
import ContainerList from '../../bumerang-kit/components/molecules/ContainerList';

const toaster = new Toaster();

/* eslint-disable camelcase */
interface GetContainerDetailsResponse {
  id: string;
  name: string;
  product_type_id: string;
  status: string;
  current_owner_id: string | null;
  created_at: Date;
  updated_at: Date;
  product_type: {
    id: string;
    name: string;
    code: string;
    picture: string;
    created_at: Date;
    updated_at: Date;
  };
}

/* eslint-enable camelcase */

const RetrieveContainers = ({ lendConfig, setLendConfig }: any) => {
  const navigate = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const user = getCurrentSession();
  const productApi = new ProductApi(user.token);

  const [searchText, setSearchText] = React.useState<string | null>('');
  const [previousSearchText, setPreviousSearchText] = React.useState<string | null>('');
  const [query, setQuery] = React.useState('');
  const [focus, setFocus] = React.useState(false);
  const [selectedContainers, setSelectedContainers] = useState<{
    [key: string]: GetContainerDetailsResponse;
  }>({});
  const [containers, setContainers] = useState<any>([]);
  const [productCount, setProductCount] = useState({});
  const pagination = useInfiniteLoading({
    limit: 20,
    initialEnable: true,
    getItems: async ({ from }: { from: number }) => {
      let userId = null;
      if (lendConfig.adminAction === 'retrieve_containers_final_customer') {
        if (!lendConfig.user)
          return {
            items: [],
            pagination: {
              limit: 20,
              from: 0,
              total: 0,
              length: 0,
            },
          };
        userId = lendConfig.user.id;
      } else {
        if (!lendConfig.restaurant)
          return {
            items: [],
            pagination: {
              limit: 20,
              from: 0,
              total: 0,
              length: 0,
            },
          };
        userId = lendConfig.restaurant.userId;
      }

      return productApi
        .getContainerList(userId, from, 20, searchText !== '' ? searchText : null)
        .then((data: any) => {
          return {
            items: data.data,
            pagination: data.pagination,
          };
        });
    },
  });

  const setLendConfigStorage = (state: any) => {
    Settings.saveStateInSession(state);
    setLendConfig(state);
  };

  useEffect(() => {
    setFocus(false);
    if (location.pathname === '/admin/restaurant/containers/retrieve') {
      if (!lendConfig.restaurant) {
        const state = Settings.getStateInSession();
        if (state?.restaurant) {
          setLendConfigStorage({
            ...lendConfig,
            ...state,
          });
        } else {
          setLendConfigStorage({
            ...lendConfig,
            adminAction: 'retrieve_containers_restaurant',
            restaurant: null,
            user: null,
            containers: [],
          });
          return navigate.push('/admin/restaurantList');
        }
      }
      setFocus(true);
    } else if (location.pathname === '/admin/user/containers/assign') {
      if (!lendConfig.restaurant || !lendConfig.user) {
        const state = Settings.getStateInSession();
        if (state?.restaurant && state?.user) {
          setLendConfigStorage({
            ...lendConfig,
            ...state,
          });
        } else {
          setLendConfigStorage({
            ...lendConfig,
            adminAction: 'assign_containers_final_customer',
            restaurant: null,
            user: null,
            containers: [],
          });
          return navigate.push('/admin/restaurantList');
        }
      }
      setFocus(true);
    } else if (location.pathname === '/admin/user/containers/retrieve') {
      if (!lendConfig.restaurant || !lendConfig.user) {
        const state = Settings.getStateInSession();
        if (state?.restaurant && state?.user) {
          setLendConfigStorage({
            ...lendConfig,
            ...state,
          });
        } else {
          setLendConfigStorage({
            ...lendConfig,
            adminAction: 'retrieve_containers_final_customer',
            restaurant: null,
            user: null,
            containers: [],
          });
          return navigate.push('/admin/restaurantList');
        }
      }
      setFocus(true);
    }
  }, [location]);

  useEffect(() => {
    if (focus) {
      setPreviousSearchText('');
      setSearchText('');
      pagination.resetPagination().then(() => console.log('reset'));
    }
  }, [focus]);

  useEffect(() => {
    if (pagination.isLoading) return;
    if (searchText !== previousSearchText) {
      setPreviousSearchText(searchText);
      pagination.resetPagination().then(() => console.log('reset'));
    }
  }, [searchText, pagination.isLoading]);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchText(query), 250);
    return () => clearTimeout(timeOutId);
  }, [query]);

  useEffect(() => {
    const calculateProductCount: any = {};
    const selectedContainerList = Object.values(selectedContainers);
    for (let i = 0; i < selectedContainerList.length; i += 1) {
      const container = selectedContainerList[i];
      if (calculateProductCount[container.product_type.code] === undefined) {
        calculateProductCount[container.product_type.code] = 1;
      } else {
        calculateProductCount[container.product_type.code] += 1;
      }
    }
    setProductCount(calculateProductCount);
  }, [selectedContainers]);

  useEffect(() => {
    const calculateContainers: any = [];
    for (let i = 0; i < pagination.items.length; i += 1) {
      const container = pagination.items[i];
      calculateContainers.push({
        container,
        selected: !!selectedContainers[container.id],
      });
    }
    setContainers([...calculateContainers]);
  }, [pagination.items]);

  const handleUnselectItem = (id: string) => {
    if (id in selectedContainers) {
      delete selectedContainers[id];
      setSelectedContainers({ ...selectedContainers });
    }
    for (let i = 0; i < containers.length; i += 1) {
      if (containers[i].container.id === id) {
        containers[i].selected = false;
        setContainers([...containers]);
        break;
      }
    }
  };

  const handleSelectItem = (id: string) => {
    for (let i = 0; i < containers.length; i += 1) {
      if (containers[i].container.id === id) {
        selectedContainers[id] = containers[i].container;
        containers[i].selected = true;
        setSelectedContainers({ ...selectedContainers });
        setContainers([...containers]);
        break;
      }
    }
  };

  const resetState = () => {
    setContainers([]);
    setSelectedContainers({});
    setProductCount({});
    setPreviousSearchText('');
    setSearchText('');
    setQuery('');
  };

  const handleConfirm = async () => {
    try {
      setLendConfigStorage({
        ...lendConfig,
        containers: Object.values(selectedContainers),
      });
      switch (lendConfig.adminAction) {
        case 'retrieve_containers_final_customer':
        case 'assign_containers_final_customer':
          navigate.push('/admin/user/summary');
          break;
        default:
          navigate.push('/admin/restaurant/summary');
          break;
      }
      resetState();
    } catch (error) {
      toaster.flashError(t('GenericNegativeFeedbackTryAgain'));
    }
  };

  const onBack = () => {
    resetState();
    switch (lendConfig.adminAction) {
      case 'retrieve_containers_restaurant':
      default:
        navigate.push('/admin/restaurantList');
        break;
      case 'retrieve_containers_final_customer':
        navigate.push('/admin/userList');
        break;
      case 'assign_containers_final_customer':
        navigate.push('/admin/user/containers/lend');
        break;
    }
  };

  return (
    <Page>
      <Navigation onBack={() => onBack()}>{t('SelectedContainersTitle')}</Navigation>
      <Container>
        <ContainerList
          query={query}
          setQuery={setQuery}
          items={containers}
          productCount={productCount}
          onConfirm={handleConfirm}
          onSeletedItem={handleSelectItem}
          onUnseletedItem={handleUnselectItem}
          hasNextPage={pagination.hasNext}
          loadMore={pagination.loadNext}
          loadMoreRef={pagination.loadMoreRef}
          options={
            lendConfig.adminAction !== 'retrieve_containers_final_customer'
              ? {
                  placeholderTitle: t('NoContainersForThatPartnerDescription', {
                    restaurant: lendConfig.restaurant?.name,
                  }),
                  placeholderDescription: query
                    ? t('NoContainersForThatPartnerWithThatSearchParamsTitle', {
                        restaurant: lendConfig.restaurant?.name,
                      })
                    : t('NoContainersForThatPartnerTitle', {
                        restaurant: lendConfig.restaurant?.name,
                      }),
                  confirmSelectionButton: t('Next'),
                }
              : {
                  placeholderTitle: t('NoContainersForThatUserDescription', {
                    user: lendConfig.user?.name,
                  }),
                  placeholderDescription: query
                    ? t('NoContainersForThatUserWithThatSearchParamsTitle', {
                        user: lendConfig.user?.name,
                      })
                    : t('NoContainersForThatUserTitle', {
                        user: lendConfig.user?.name,
                      }),
                  confirmSelectionButton: t('Next'),
                }
          }
        />
      </Container>
    </Page>
  );
};

export default RetrieveContainers;
