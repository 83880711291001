import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { NavigationBar } from '@bumerang-kit/components';

type BottomNavigationProps = {
  active: string;
  type: string;
  onMainAction?: () => void;
};

const BottomNavigation = (props: BottomNavigationProps) => {
  const { active, type, onMainAction } = props;
  const { t } = useTranslation();
  const navigate = useHistory();

  const mainActionConfig = onMainAction && { icon: 'qr', action: onMainAction };

  const navigationConfig: any = {
    admin: [
      {
        icon: 'bowl',
        text: t('TabAdmin'),
        action: () => navigate.push('/admin'),
        isActive: active === 'admin',
      },
      {
        icon: 'bowl',
        text: t('TabAccount'),
        action: () => navigate.push('/admin/profile'),
        isActive: active === 'profile',
      },
    ],
    restaurant: [
      {
        icon: 'bowl',
        text: t('TabScan'),
        action: () => navigate.push('/restaurant'),
        isActive: active === 'scan',
      },
      {
        icon: 'bowl',
        text: t('TabImpact'),
        action: () => navigate.push('/restaurant/impact'),
        isActive: active === 'impact',
      },
      {
        icon: 'bowl',
        text: t('TabAccount'),
        action: () => navigate.push('/restaurant/profile'),
        isActive: active === 'profile',
      },
    ],
    customer: [
      {
        icon: 'bowl',
        text: 'Home',
        action: () => navigate.push('/customer'),
        isActive: active === 'customer',
      },
      {
        icon: 'cutlery',
        text: t('TabExplore'),
        action: () => navigate.push('/map'),
        isActive: active === 'map',
      },
      {
        icon: 'world',
        text: t('TabImpact'),
        action: () => navigate.push('/impact'),
        isActive: active === 'impact',
      },
      {
        icon: 'user',
        text: t('TabAccount'),
        action: () => navigate.push('/profile'),
        isActive: active === 'profile',
      },
    ],
  };

  return <NavigationBar mainAction={mainActionConfig} navigation={navigationConfig[type]} />;
};

export default BottomNavigation;
