import React, { useEffect, useLayoutEffect, useState } from 'react';
import { IonSpinner } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import QrReader from 'react-qr-reader';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ProductApi from '../../apiClient/ProductApi';

import { Container, Page } from '@bumerang-kit/layouts';
import { Toaster } from '@bumerang-kit/helpers';
import { Cart, Navigation } from '@bumerang-kit/components';
import { Colors } from '@bumerang-kit/foundations';

import { getCurrentSession } from '@/helpers/session';

import Settings from '../../lib/settings';

const toaster = new Toaster();

const Scan = styled(QrReader)`
  width: 100%;
  display: block;
  margin: 0;
  height: 324px;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;
`;

interface ScannerDelayState {
  lastScanDetected?: number;
  qrContent?: string;
}

const AssignContainers = ({ setLendConfig, lendConfig }: any) => {
  console.log(lendConfig);
  const navigate = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const user = getCurrentSession();
  const productApi = new ProductApi(user.token);

  const [lastScanDetected, setLastScanDetected] = useState<ScannerDelayState>({});

  const setLendConfigStorage = (state: any) => {
    Settings.saveStateInSession(state);
    setLendConfig(state);
  };

  const matchWithURL = () => {
    const pathname = location?.pathname;
    return (
      pathname === '/admin/restaurant/containers/assign' ||
      pathname === '/admin/user/containers/collect' ||
      pathname === '/admin/user/containers/lend' ||
      pathname === '/customer/self-assign'
    );
  };
  const [active, setActive] = useState(matchWithURL() && lendConfig?.restaurant);

  useLayoutEffect(() => {
    setActive(matchWithURL() && lendConfig?.restaurant);
  }, [location, lendConfig]);

  useEffect(() => {
    const pathname = location?.pathname;
    if (pathname === '/admin/restaurant/containers/assign') {
      if (!lendConfig.restaurant) {
        const state = Settings.getStateInSession();
        if (state?.restaurant) {
          setLendConfigStorage({
            ...lendConfig,
            ...state,
          });
        } else {
          setLendConfigStorage({
            ...lendConfig,
            adminAction: 'assign_containers_restaurant',
            containers: [],
          });
          return navigate.replace('/admin/restaurantList');
        }
      }
    } else if (pathname === '/admin/user/containers/collect') {
      if (!lendConfig.restaurant) {
        const state = Settings.getStateInSession();
        if (state?.restaurant) {
          setLendConfigStorage({
            ...lendConfig,
            ...state,
          });
        } else {
          setLendConfigStorage({
            ...lendConfig,
            adminAction: 'retrieve_containers_final_customer',
            restaurant: null,
            user: null,
            containers: [],
          });
          return navigate.replace('/admin/restaurantList');
        }
      }
    } else if (pathname === '/admin/user/containers/lend') {
      if (!lendConfig.restaurant || !lendConfig.user) {
        const state = Settings.getStateInSession();
        if (state?.restaurant && state?.user) {
          setLendConfigStorage({
            ...lendConfig,
            ...state,
          });
        } else {
          setLendConfigStorage({
            ...lendConfig,
            adminAction: 'assign_containers_final_customer',
            restaurant: null,
            user: null,
            containers: [],
          });
          return navigate.replace('/admin/restaurantList');
        }
      }
    } else if (pathname === '/customer/self-assign') {
      if (!lendConfig.user) {
        const state = Settings.getStateInSession();
        if (state?.user) {
          setLendConfigStorage({
            ...lendConfig,
            ...state,
          });
        } else {
          setLendConfigStorage({
            ...lendConfig,
            adminAction: 'assign_containers_final_customer',
            restaurant: null,
            user: null,
            containers: [],
          });
          return navigate.replace('/');
        }
      }
    }
  }, [location]);

  const removeContainer = (id: string) => {
    const filteredArray = lendConfig.containers.filter((container: any) => container.id !== id);

    setLendConfigStorage({ ...lendConfig, containers: filteredArray });
  };

  const handleConfirm = async () => {
    try {
      switch (lendConfig.adminAction) {
        case 'retrieve_containers_final_customer':
        case 'assign_containers_final_customer':
          navigate.push('/admin/user/summary');
          break;
        default:
          navigate.push('/admin/restaurant/summary');
          break;
      }
      return;
    } catch (error) {
      toaster.flashError(t('GenericNegativeFeedbackTryAgain'));
    }
  };

  const handleScan = async (qrContent: string | null) => {
    if (!qrContent) return;

    const now: number = Date.now();

    if (lastScanDetected?.lastScanDetected !== undefined) {
      if (
        now - lastScanDetected?.lastScanDetected < 3000 &&
        lastScanDetected?.qrContent === qrContent
      ) {
        return;
      }
    }

    setLastScanDetected({ lastScanDetected: now, qrContent });
    console.log('lendConfig', lendConfig);

    const isCodeIncluded =
      lendConfig.containers.filter((container: any) => container.id === qrContent).length !== 0;

    if (!isCodeIncluded) {
      const response = await productApi.getContainerDetails(qrContent);

      if (response && response.success) {
        if (lendConfig.adminAction === 'assign_containers_final_customer') {
          if (response.data.current_owner_id !== lendConfig.restaurant?.userId) {
            toaster.flashError(t('ContainerIsNotAssignedToThatPartnerNegativeFeedback'));
            return;
          }
        } else if (lendConfig.adminAction === 'retrieve_containers_final_customer') {
          if (response.data.status !== 'lent') {
            toaster.flashError(t('ThatContainerIsNotLentNegativeFeedback'));
            return;
          }
        } else {
          if (response.data.current_owner.type === 'restaurant') {
            if (response.data.current_owner_id !== lendConfig.restaurant?.userId) {
              toaster.flashError(t('ContainerIsAlreadyAssignedToAnotherPartnerNegativeFeedback'));
              return;
            }

            toaster.flashError(t('ContainerIsAlreadyAssignedToThisPartnerNegativeFeedback'));
            return;
          }
          if (response.data.current_owner.type === 'customer') {
            toaster.flashError(t('ContainerIsAlreadyAssignedToACustomerNegativeFeedback'));
            return;
          }

          if (response.data.current_owner_id !== user.id) {
            toaster.flashError(t('ContainerIsNotAssignedToThisWarehouseNegativeFeedback'));
            return;
          }
        }
        if (!isCodeIncluded) {
          setLendConfigStorage({
            ...lendConfig,
            containers: [
              ...lendConfig.containers,
              { ...response.data, timestamp: new Date().getTime() },
            ],
          });
        }
      } else {
        if (response?.data?.error) {
          toaster.flashError(response.data.error);
          return;
        }

        toaster.flashError(t('GenericNegativeFeedbackTryAgain'));
      }
    } else {
      toaster.flashError(t('ContainerAlreadyScanned'));
    }
  };

  const handleError = () => {
    toaster.flashError(t('GenericNegativeFeedback'));
    setLendConfigStorage({
      ...lendConfig,
      adminAction: null,
      containers: [],
    });
    navigate.push('/admin');
  };

  const getURLToChoiceContainers = () => {
    if (lendConfig.adminAction === 'assign_containers_final_customer') {
      return '/admin/user/containers/assign';
    }
    return '/admin/userList';
  };

  const goBack = () => {
    setLendConfigStorage({
      ...lendConfig,
      containers: [],
    });
    switch (lendConfig.adminAction) {
      default:
      case 'assign_containers_restaurant':
        navigate.push('/admin/restaurantList');
        break;
      case 'assign_containers_final_customer':
        navigate.push('/admin/userList');
        break;
      case 'retrieve_containers_final_customer':
        navigate.push('/admin/restaurantList');
        break;
    }
  };

  return (
    <Page>
      <Navigation onBack={() => goBack()}>{t('ScanContainerInstructions')}</Navigation>
      {active ? (
        <>
          <Scan showViewFinder={false} delay={300} onError={handleError} onScan={handleScan} />
          <Container>
            <Cart
              key={lendConfig.containers}
              items={lendConfig.containers}
              onConfirm={handleConfirm}
              onRemoveItem={(item: any) => removeContainer(item)}
              options={{
                showName: true,
                placeholderDescription:
                  lendConfig.adminAction !== 'assign_containers_final_customer' &&
                  lendConfig.adminAction !== 'retrieve_containers_final_customer' ? (
                    t('ScanContainerPartnerDescription', {
                      restaurant: lendConfig.restaurant?.name,
                    })
                  ) : (
                    <Link to="#" onClick={() => navigate.push(getURLToChoiceContainers())}>
                      {t('ScanContainerOrChoiceContainersDescription')}
                    </Link>
                  ),

                confirmSelectionButton: t('Next'),
              }}
            />
          </Container>
        </>
      ) : (
        <SpinnerContainer>
          <IonSpinner color={Colors.mainColor} />
        </SpinnerContainer>
      )}
    </Page>
  );
};

export default AssignContainers;
