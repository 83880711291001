import React from 'react';
import { Container } from '@bumerang-kit/layouts';
import { Icon, Text } from '@bumerang-kit/components';
import { Colors } from '@bumerang-kit/foundations';
import AuthApi from '../api/AuthApi';
import ApiCaller from '../lib/ApiCaller';
// import { Toaster } from '@bumerang-kit/helpers';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { getCurrentSession } from '@/helpers/session';
import VerificationCode from '@/components/VerificationCode';

// const toaster = new Toaster();

const ResendEmail = ({ onDismiss }: any) => {
  const { t } = useTranslation();
  const currentUser = getCurrentSession();
  const authApi = new AuthApi(new ApiCaller(currentUser.token));
  const [feedback, setFeedback] = React.useState<string>('');

  const { refetch: resendEmail } = useQuery(
    'resendEmail',
    async () => await authApi.resendVerification(currentUser.email, currentUser.name),
    {
      onSuccess: () => {
        // toaster.flashSuccess(t('ResendEmailPositiveFeedback'));
        setFeedback(t('ResendEmailPositiveFeedback'));
        setTimeout(() => setFeedback(''), 3000);
      },
      onError: () => {
        // toaster.flashError(t('ResendEmailSomethingWentWrong'));
        setFeedback(t('ResendEmailSomethingWentWrong'));
      },
      enabled: false,
      retry: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Container backgroundColor={Colors.backgroundBlue}>
      <div className="flex justify-center mb-4">
        <Icon name={'email-not-validated'} />
      </div>
      <h1 className="text-3xl text-main font-bold text-center mb-6">
        {t('ResendEmailCheckInbox')}
      </h1>
      <p className="text-base text-main text-center mb-10">
        {t('ResendEmailClick')}: <strong>{currentUser.email}</strong>
      </p>
      <VerificationCode className="mb-6" onDismiss={onDismiss} feedback={feedback} />
      <Text config={'main-text-link'} align="center" isLink onClick={resendEmail}>
        {<strong>{t('ResendCode')}</strong>}
      </Text>
    </Container>
  );
};

export default ResendEmail;
