import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@bumerang-kit/components';
import ResendEmail from '@/pages/ResendEmail';
import { IonModal } from '@ionic/react';
import Icon from '@bumerang-kit/components/Icon';
import { useHistory } from 'react-router';

type NoCodeDialogProps = {
  onClose: () => void;
};

const RegisterDialog = (props: NoCodeDialogProps) => {
  const { onClose } = props;
  const [showResendEmailPage, setShowResendEmailPage] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useHistory();

  if (showResendEmailPage)
    return (
      <IonModal isOpen>
        <ResendEmail onDismiss={() => setShowResendEmailPage(false)} />
      </IonModal>
    );

  return (
    <>
      <div onClick={() => onClose()} className="absolute h-full w-full bg-black opacity-50 z-50 " />

      <div className="absolute flex flex-col gap-4 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-auto z-50 w-[90%] bg-white rounded-lg p-3 pt-6">
        <div className="absolute top-3 right-4 cursor-pointer">
          <Icon name={'cross'} onClick={() => onClose()} />
        </div>
        <h3 className="text-main font-montserrat text-xl font-bold leading-9 text-center ">
          {t('RegisterToContinue')}
        </h3>
        <p className="text-center text-m text-main">{t('RegisterToUse')} </p>
        <Button onClick={() => navigate.push('/register')}>{t('WantToRegister')}</Button>
      </div>
    </>
  );
};

export default RegisterDialog;
