import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Navigation, Button, SelectorList } from '@bumerang-kit/components';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import { Page, Container } from '@bumerang-kit/layouts';

const lends = [
  {
    title: 'Pedido nº 123456',
    description: 'de Glovo',
    value: '123456',
  },
  {
    title: 'Pedido nº 654321',
    description: 'de Glovo',
    value: '654321',
  },
  {
    title: 'Pedido nº 7891011',
    description: 'de Uber Eats',
    value: '7891011',
  },
];

const FixedBlock = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${Spacing.m} ${Spacing.s};
  background-color: ${Colors.white};
`;

const SelectLend = () => {
  const navigate = useHistory();
  const { t } = useTranslation();

  const handleNext = () => {
    navigate.push('/delivery/containers/scan');
  };

  return (
    <Page>
      <Navigation onBack={() => navigate.push('/restaurant')}>{t('SelectTheOrder')}</Navigation>
      <SelectorList onSelect={(currentValue: string) => console.log(currentValue)} values={lends} />
      <Container>
        <FixedBlock>
          <Button expand="full" onClick={handleNext}>
            {t('Next')}
          </Button>
        </FixedBlock>
      </Container>
    </Page>
  );
};

export default SelectLend;
