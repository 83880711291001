import React from 'react';
import styled from 'styled-components';
import { BarcodeScanner } from '@ionic-native/barcode-scanner';

import { Button, Text } from '../..';
import { Spacing } from '../../../foundations';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(Button)`
  width: 264px;
  margin: ${Spacing.m} auto;
`;

const options = {
  prompt: 'Pon el código QR dentro de la zona de escaneo',
  formats: 'QR_CODE',
  disableSuccessBeep: true,
};

const QrScanner = ({ onScan }: { onScan: any }) => {
  const { t } = useTranslation();
  const scanCode = async () => {
    try {
      const data = await BarcodeScanner.scan(options);
      onScan(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StyledButton onClick={scanCode}>
      <Text config="button-text" color="white">
        {t('QRScannerTitle')}
      </Text>
    </StyledButton>
  );
};

export default QrScanner;
