import React from 'react';
import styled from 'styled-components';

import { useHistory } from 'react-router';
import './styles/UserQr.css';
import './styles/General.css';
import { useTranslation } from 'react-i18next';
import PaymentApi from '../api/PaymentApi';
import ApiCaller from '../lib/ApiCaller';

import { Text } from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import { Spacing, Colors } from '@bumerang-kit/foundations';
import { getCurrentSession } from '@/helpers/session';

import QRCode from 'qrcode.react';

const Header = styled.header`
  margin-top: ${Spacing.xxxxl};
  margin-bottom: ${Spacing.l};
`;

const Block = styled.div`
  border: 1px solid ${Colors.mainColorDisabled};
  border-radius: 2px;
  padding: ${Spacing.m};
`;

const QR = styled(QRCode)``;

const UserQr: React.FC = () => {
  const currentUser = getCurrentSession();
  const [qrAuth, setQrAuth] = React.useState<string>();
  const navigate = useHistory();
  const { t } = useTranslation();

  React.useEffect(() => {
    const paymentApi = new PaymentApi(new ApiCaller(currentUser.token));
    paymentApi.getPaymentStatus().then((status: string) => {
      if (status === 'configured') {
        setQrAuth(currentUser.username);
      } else {
        navigate.push('/payment', { replace: true });
      }
    });
  }, []);

  return (
    <Page>
      <Container>
        <Header>
          <Text align="center" config="screen-title">
            {currentUser && currentUser.name ? `Hola, ${currentUser.name}` : 'Hola'}
          </Text>
          <Text align="center" config="secondary-text">
            {t('ShowQR')}
          </Text>
        </Header>
        <Block>{qrAuth && <QR value={qrAuth} />}</Block>
      </Container>
    </Page>
  );
};

export default UserQr;
