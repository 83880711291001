import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useDrag } from '../../helpers';

import './hideScrollbar.css';

type HorizontalScrollProps = {
  children: JSX.Element[] | JSX.Element;
};

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const HorizontalScroll = ({ children }: HorizontalScrollProps) => {
  const { dragStart, dragStop, dragMove } = useDrag();

  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff: any) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  return (
    <div className="pl-4" onMouseLeave={dragStop}>
      <ScrollMenu onMouseDown={() => dragStart} onMouseUp={() => dragStop} onMouseMove={handleDrag}>
        {children}
      </ScrollMenu>
    </div>
  );
};

export default HorizontalScroll;
