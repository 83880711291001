import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import './LocationInput.css';
import { Input } from '@bumerang-kit/components';
import { useTranslation } from 'react-i18next';

interface ContainerProps {
  setAddress: any;
  setLat: any;
  setLng: any;
  setPlaceId: any;
}

const LocationInput: React.FC<ContainerProps> = ({ setAddress, setLat, setLng, setPlaceId }) => {
  const [addr, setAddr] = React.useState<string>('');
  const { t } = useTranslation();
  const handleSelect = (address: string) => {
    setAddr(address);
    geocodeByAddress(address).then((results) => {
      setPlaceId(results[0].place_id);
      getLatLng(results[0])
        .then((latLng) => {
          setAddress(address);
          setLat(latLng.lat);
          setLng(latLng.lng);
        })
        .catch((error) => console.error('Error', error));
    });
  };

  return (
    <PlacesAutocomplete value={addr} onChange={setAddr} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
        <div key={'main'}>
          <Input label={t('SearchGooglePlaces')} {...getInputProps({})} />

          <div key={'list'} className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion: any, index: any) => {
              return (
                <div key={`item${index}`} {...getSuggestionItemProps(suggestion)}>
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationInput;
