import * as React from 'react';
import Icon from '../Icon';
import { Colors } from '../../foundations';

interface NavigationConfig {
  icon: string;
  text: string;
  action: () => void;
  isActive?: boolean;
}

interface MainActionConfig {
  icon: string;
  action: () => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type NavigationBarProps = {
  navigation: NavigationConfig[];
  mainAction?: MainActionConfig;
};

const NavigationBarItem = (props: NavigationConfig) => {
  return (
    <button onClick={props.action} className="text-center p-2 flex flex-col items-center">
      <Icon
        name={props.icon}
        color={props.isActive ? Colors.mainColor : Colors.mainColorDisabled}
      />
      <p
        className={`text-xs font-bold mt-1 whitespace-nowrap ${
          props.isActive ? `text-main` : `text-main-disabled`
        }`}
      >
        {props.text}
      </p>
    </button>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NavigationBar = (props: NavigationBarProps) => {
  return (
    <nav className="grid grid-cols-4 pb-safe gap-3 items-center shadow-lg relative border border-t-1 border-main-disabled">
      {props.navigation &&
        props.navigation.map((item: NavigationConfig, index: number) => (
          <NavigationBarItem key={index} {...item} />
        ))}

      {props.mainAction && (
        <button
          onClick={props.mainAction.action}
          className="absolute top-[-15px] left-1/2 -translate-x-1/2 -mt-6"
        >
          <Icon name={props.mainAction.icon} color="white" isRounded type="secondary" />
        </button>
      )}
    </nav>
  );
};

export default NavigationBar;
