import { Button, Cart, Placeholder } from '@bumerang-kit/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ConfirmationAlert from '../features/home/components/QRModal/ConfirmationAlert';
import { Page } from '@bumerang-kit/layouts';

interface ConfirmationProps {
  lendConfig: any;
}

const StyledDiv = styled.div`
  padding: 50px 20px 20px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .spaced-div {
    margin: 50px;
    background-color: #fff;
  }
`;

const ConfirmationSelfAssign = ({ lendConfig }: ConfirmationProps) => {
  const [showAlert1, setShowAlert1] = useState<boolean>(false);

  const { t } = useTranslation();
  return (
    <Page>
      <StyledDiv>
        <Placeholder
          selfAssignConfirmation
          icon="assets/confirmationTick.svg"
          title={t('SelfAssignConfirmation', {
            total: lendConfig.length,
            envases: lendConfig.length > 1 ? 'Bûmerangs' : 'Bûmerang',
          })}
        />
        <Cart confirmationModal key={lendConfig} items={lendConfig} />
        <div className="spaced-div"></div>
        <Button
          onClick={() => {
            setShowAlert1(true);
          }}
          primary
        >
          Cerrar
        </Button>
        <ConfirmationAlert showAlert={showAlert1} setShowAlert1={setShowAlert1} />
      </StyledDiv>
    </Page>
  );
};

export default ConfirmationSelfAssign;
