import { ENV } from '../env/env';

const Settings = {
  saveContainersInSession: (containers: Array<any>): any =>
    localStorage.setItem('containers', JSON.stringify(containers)),

  getContainersInSession: () => localStorage.getItem('containers'),

  removeContainersInSession: () => localStorage.removeItem('containers'),

  saveStateInSession: (state: any): any =>
    localStorage.setItem('lendConfig', JSON.stringify(state)),

  getStateInSession: () => {
    const state = localStorage.getItem('lendConfig');
    return state ? JSON.parse(state) : null;
  },

  hasSeenTutorial: (): any => localStorage.getItem('hasSeenTutorial'),

  markTutorialAsSeen: (): void => localStorage.setItem('hasSeenTutorial', 'true'),

  removeTutorialAsSeen: (): void => localStorage.removeItem('hasSeenTutorial'),

  hasEmailValidated: (): any => localStorage.getItem('hasEmailValidated'),

  removeEmailAsValidated: (): void => localStorage.removeItem('hasEmailValidated'),

  setEmailAsValidated: (): void => localStorage.setItem('hasEmailValidated', 'true'),

  getApiURL: (): string => {
    return `${ENV.ENDPOINT}/api`;
  },

  getLambda: (): string => {
    return 'https://cp6iwu2ba3.execute-api.eu-west-3.amazonaws.com/Prod';
  },

  getPublicURL: (): string => {
    return `${window.location.protocol}//${window.location.hostname}/qr/`;
  },

  getNumDaysToReturn: (): number => {
    return 15;
  },

  getPenaltyPrice: (): number => {
    return 6;
  },

  getResiduesPerUnit: (): number => {
    // grams
    return 30;
  },

  getVersion: (): string => {
    return ENV.VERSION || '1.0';
  },

  getAPIVersion: (): string => {
    return ENV.API_VERSION || '2.0';
  },

  getStripeKey: (): string => {
    return ENV.PUBLIC_STRIPE_KEY || 'pk_test_t1WHSW3dlbCyWaJe8vStOrxf00Gl3m9aWM';
  },
};

export default Settings;
