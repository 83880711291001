import React from 'react';
import { Container } from '@bumerang-kit/layouts';
import { Icon, Text } from '@bumerang-kit/components';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledText = styled(Text)`
  margin: ${Spacing.s} auto;
`;

const IconContainer = styled.div`
  height: 40px;
  width: 40px;
  margin: 160px 50% ${Spacing.l} auto;
`;

const Offline: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <IconContainer>
        <Icon name="wifi" />
      </IconContainer>
      <StyledText config="onboarding-title" color={Colors.mainColor} align="center">
        {t('OfflineTitle')}
      </StyledText>
      <StyledText align="center">{t('OfflineSubtitle')}</StyledText>
    </Container>
  );
};

export default Offline;
