import React from 'react';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { Card, Text } from '../../atoms';
import { Colors, Spacing } from '../../../foundations';
import { Trans } from 'react-i18next';

const StyledCard = styled(Card)`
  display: flex;
  padding: ${Spacing.s};
  align-items: center;
  justify-content: space-between;
  max-height: 64px;
`;
const StyledIcon = styled(IonIcon)`
  width: 32px;
  height: 32px;
`;
const StyledStrong = styled.strong`
  font-family: Montserrat;
`;

const WasteCard = ({ totalLends }: any) => {
  const waste = totalLends * 15;

  return (
    <StyledCard isFull={true}>
      <Text color={Colors.black} config="main-text" align="left">
        <Trans
          i18nKey={'WasteCardTitile'}
          defaults={'Has evitado <bold> {{waste}gr </bold> de <line /> residuos con Bûmerang'}
          values={{ waste: waste }}
          components={{ bold: <StyledStrong />, line: <br /> }}
        />
      </Text>
      <StyledIcon icon="assets/earth.svg" />
    </StyledCard>
  );
};
export default WasteCard;
