import React from 'react';

type CustomerMarker = {
  lat: number;
  lng: number;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomerMarker = (props: CustomerMarker) => {
  return (
    <div className="h-12 w-12 rounded-full grid place-items-center bg-opacity-50 bg-[#A7BFDA] -translate-y-1/2 -translate-x-1/2 ">
      <div className="drop-shadow-md z-999 bg-[#5383EC] rounded-full border-2 border-white	 h-4 w-4" />
    </div>
  );
};
export default CustomerMarker;
