import * as React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Colors, Spacing } from '../../../foundations';
import { Button, Card, Icon, Line, Text } from '../../index';
import { Container } from '../../../layouts';
import QRCode from 'qrcode.react';

const StyledContainer = styled(Container)`
  padding: ${Spacing.s};
`;

const QrHeader = styled.header`
  position: relative;
  color: black;
`;

const QrMain = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QrActions = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 20px;
  right: ${Spacing.s};
  justify-content: center;
  align-items: center;
`;

const StyledQRCode = styled(QRCode)`
  margin: 0 auto;
  margin-top: ${Spacing.m};
  display: block;
  height: auto;
  max-width: 140.1px;
`;

const StyledText = styled(Text)`
  position: relative;
  cursor: pointer;
  background-color: ${Colors.backgroundBlue};
`;

const ActionIcon = styled.div`
  margin-bottom: 8px;
  cursor: pointer;
  color: ${Colors.actionableGrey};
  font-weight: normal;
`;

const Redirect = styled.a`
  color: ${Colors.black};
`;

const StyledButton = styled(Button)`
  font-size: 12px;
  border: solid 1px;
  height: 22px;
  width: fit-content;
  margin-left: ${Spacing.xs};
`;

const CopyDiv = styled.div`
  display: flex;
  margin-top: ${Spacing.s};
  justify-content: center;
  align-items: center;
`;

const QRCard = ({ auth, onSync, onCopy, onChat }: any) => {
  const { t } = useTranslation();
  return (
    <Card isFull>
      <StyledContainer>
        <QrHeader>
          <Text align="center" config="main-text">
            <Trans
              i18nKey="QRCardBanner"
              defaults="<bold>Muestra este QR</bold> en el restaurante cuando necesites un Bûmerang (va cambiando)"
              components={{ bold: <strong /> }}
            >
              <strong>Muestra este QR</strong> en el restaurante cuando necesites un Bûmerang (va
              cambiando)
            </Trans>
          </Text>
        </QrHeader>

        {auth && (
          <div>
            <QrMain>
              <StyledQRCode size={250} value={auth} />
              <QrActions>
                {onSync && (
                  <ActionIcon onClick={() => onSync()}>
                    <Icon name="sync" color={Colors.actionableGrey} />
                  </ActionIcon>
                )}
                {onChat && (
                  <ActionIcon onClick={() => onChat()}>
                    <Icon name="big-chat" color={Colors.actionableGrey} />
                    <Text config="tag-text">{t('Help')}</Text>
                  </ActionIcon>
                )}
              </QrActions>
            </QrMain>
            <Line yMargin={Spacing.m} />
            <Text config="main-text" align="center">
              <Trans
                i18nKey="QRCardCopyExplanation"
                defaults=" <bold>Copia y envía </bold> este código para hacer tu pedido delivery."
                components={{
                  bold: <strong />,
                }}
              />
              <Text isLink config="main-text" align="center">
                <Redirect
                  target="_blank"
                  rel="noopener nofollow"
                  href="https://www.notion.so/C-mo-hacer-un-pedido-delivery-con-B-merang-c32b882630cc4f78924e5e09c667a822"
                >
                  {t('QRCardCopyExplanationLink')}
                </Redirect>
              </Text>
            </Text>
            <CopyDiv>
              <StyledText config="qr-text" onClick={() => onCopy(auth)} align="center">
                {auth}
              </StyledText>{' '}
              <StyledButton onClick={() => onCopy(auth)} secondary>
                {t('Copy')}
              </StyledButton>
            </CopyDiv>
          </div>
        )}
      </StyledContainer>
    </Card>
  );
};

export default QRCard;
