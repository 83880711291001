import Icon from '../Icon';
import DaysToReturnTag from '../DaysToReturnTag';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

export type AccountantProps = {
  total?: any;
  limit?: number;
  setupAction: () => void;
  paymentStatus?: any;
};

type AccountantItemProps = {
  type: 'cup' | 'bowl';
  name: string;
  expirationDate: Date;
};

const AccountantItem = (props: AccountantItemProps) => (
  <div className={`flex justify-between items-center py-3 border-b border-main-disabled`}>
    <div className="flex items-center mr-6">
      {props.type === 'bowl' ? <Icon name="bowl_3d" /> : <Icon name="cup_3d" />}{' '}
      <p className="text-sm text-main font-bold ml-3 whitespace-nowrap">{props.name}</p>
    </div>
    <div>
      <DaysToReturnTag date={props.expirationDate} />
    </div>
  </div>
);

const Accountant = (props: AccountantProps) => {
  const navigation = useHistory();
  const { t } = useTranslation();
  const { total, paymentStatus, setupAction, limit = 3 } = props;

  if (paymentStatus !== 'configured') {
    return (
      <div className="flex items-center justify-center shadow-lg rounded-lg px-4 py-2 max-w-md bg-white mx-auto">
        <p className="mr-2 text-main">{t('AccountantTotalZero')}</p>
        <button onClick={setupAction}>
          <Icon name="qr" color="white" isRounded type="secondary" />
        </button>
      </div>
    );
  }

  if (total && total.length === 0 && paymentStatus === 'configured') {
    return (
      <div
        className="shadow-lg rounded-lg px-4 py-2 max-w-md bg-white mx-auto"
        onClick={() => navigation.push('/mytuppers')}
      >
        <header className="flex items-end justify-between mb-4">
          <p className="font-bold text-xl text-main">{t('RestImpactStock')}</p>
          <p className="text-xs text-main">{t('TotalContainers')} (0)</p>
        </header>

        <div className="flex justify-between items-center">
          <div className="flex items-center pr-4 mr-6 border-r-2 border-main-disabled">
            <Icon name="cup_3d" />
            <Icon name="bowl_3d" />
          </div>
          <p className="text-main">{t('PendingHistoryTitle')}</p>
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={() => navigation.push('/mytuppers')}
      className="shadow-lg rounded-lg px-4 py-2 max-w-md bg-white mx-auto"
    >
      <header className="flex items-end justify-between mb-4">
        <p className="font-bold text-xl text-main">{t('RestImpactStock')}</p>
        <p className="text-xs text-main">
          {t('TotalContainers')} ({total && total.length})
        </p>
      </header>

      {total &&
        total
          .slice(0, limit)
          .map((item: AccountantItemProps, index: number) => (
            <AccountantItem key={index} {...item} />
          ))
          .reverse()}

      <a
        className="text-center block mt-2 text-xs font-bold text-main underline underline-offset-auto cursor-pointer"
        onClick={() => navigation.push('/mytuppers')}
      >
        {t('SeeAll')}
      </a>
    </div>
  );
};

export default Accountant;
