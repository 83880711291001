import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Spacing } from '../../../foundations';
import { Card, Text, TimedTag } from '../../index';
import format from 'date-fns/format';
import Icon from '@bumerang-kit/components/Icon';

const StyledDiv = styled.div`
  padding: ${Spacing.s};
`;

const StyledIconDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-bottom: ${Spacing.xxs};
`;

const HistoryCard = ({ lend, returned }: { lend: any; returned?: boolean }) => {
  const { t } = useTranslation();
  return (
    <Card full listItem>
      <StyledDiv>
        {lend.container && (
          <StyledContainerDiv>
            <StyledIconDiv>
              <Icon name={lend?.container?.product_type?.code === 'Cup' ? 'cup_3d' : 'bowl_3d'} />
              <StyledText config="tag-text">
                <p className="text-sm text-main font-extrabold ml-3 whitespace-nowrap">
                  {lend?.container?.name}
                </p>
                <p className="text-xs text-main ml-3 whitespace-nowrap">
                  {lend?.container?.product_type?.code === 'Cup' ? t('Vaso') : 'Bowl'}
                </p>
              </StyledText>
            </StyledIconDiv>
            <div>{!returned && <TimedTag time={lend?.return_date} />}</div>
          </StyledContainerDiv>
        )}
        <StyledText config="tag-text">
          <Trans
            i18nKey={lend.self_assigned ? 'HistoryCardSelfAssigned' : 'HistoryCardLentAt'}
            values={{
              createdAt: format(new Date(lend?.lent_at), 'dd/MM/yy'),
              lender: lend?.lender?.name,
            }}
            components={{ bold: <strong /> }}
          />
        </StyledText>
        {returned && (
          <StyledText config="tag-text">
            <Trans
              i18nKey="HistoryCardCollectedAt"
              values={{
                collectedAt: format(new Date(lend?.collected_at), 'dd/MM/yy'),
                receiver: lend?.collector?.name,
              }}
              components={{ bold: <strong /> }}
            />
          </StyledText>
        )}
      </StyledDiv>
    </Card>
  );
};
export default HistoryCard;
