import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import './styles/General.css';
import './styles/Login.css';
import { useTranslation } from 'react-i18next';
import { Button, Input, Navigation } from '@bumerang-kit/components';
import { Page, Container } from '@bumerang-kit/layouts';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import { useLoginAs, usePlatform } from '@bumerang-kit/helpers';
import { getCurrentSession } from '@/helpers/session';

const StyledForm = styled.form`
  margin-top: ${Spacing.xs};
  margin-bottom: ${Spacing.xxxxxl};
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.s};
`;

const StyledPage = styled(Page)`
  background-color: ${Colors.backgroundBlue};
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const LoginAs = () => {
  const currentUser = getCurrentSession();
  const navigate = useHistory();
  const { data: platform } = usePlatform();
  const loginAsMutation = useLoginAs();
  const { t } = useTranslation();
  const [email, setEmail] = React.useState<string>();
  const isFormValid = !email;

  const doAuth = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!email) {
      return;
    }

    loginAsMutation.mutate({
      token: currentUser.token,
      data: { email },
      provider: 'Email',
      platform,
    });
  };

  return (
    <StyledPage>
      <Navigation backgroundColor={Colors.backgroundBlue} onBack={() => navigate.replace('/admin')}>
        {t('LoginAsTitle')}
      </Navigation>
      <StyledContainer backgroundColor={Colors.backgroundBlue}>
        <StyledForm onSubmit={(event) => doAuth(event)}>
          <Input
            label="Email"
            name="email"
            id="email"
            type="email"
            value={email}
            onChange={(e: any) => setEmail(e.detail.value!)}
            required
          />
          <StyledButton type="submit" expand="full" disabled={isFormValid}>
            {t('ContinueWithEmail')}
          </StyledButton>
          <input type="submit" className="submit-enter" />
        </StyledForm>
      </StyledContainer>
    </StyledPage>
  );
};

export default LoginAs;
