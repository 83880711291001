import ApiCaller from '../lib/ApiCaller';
import { User } from '../lib/common/models';

class AuthApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  login({
    email,
    password,
    provider,
    platform,
  }: {
    email: string;
    password: string;
    provider: string;
    platform?: string;
  }): Promise<User> {
    return this.apiCaller.call('/v2/auth/login', 'POST', {
      email,
      password,
      platform,
      provider,
    });
  }

  //  socialLogin({
  //    email,
  //    name,
  //    provider,
  //    platform,
  //    appleToken,
  //  }: {
  //    email: string | null;
  //    name: string | null;
  //    provider: string;
  //    platform?: string;
  //    appleToken?: string;
  //  }): Promise<User> {
  //    return this.apiCaller.call('/v1/auth/socialLogin', 'POST', {
  //      email,
  //      name,
  //      appleToken,
  //      provider,
  //      platform,
  //    });
  //  }

  register(
    email: string,
    password: string,
    name: string,
    platform?: string
  ): Promise<User | undefined> {
    return this.apiCaller.call('/v2/auth/register', 'POST', {
      name,
      email,
      password,
      platform,
    });
  }

  getCurrentUser(): Promise<User> {
    return this.apiCaller.call('/v2/auth/profile', 'GET');
  }

  generateQrAuth(): Promise<string> {
    return this.apiCaller.call('/v1/auth/qr/generate', 'GET');
  }

  getTokenById(id: string): Promise<string> {
    return this.apiCaller.call(`/v1/auth/qr/${id}`, 'GET');
  }

  verify(email: string, token: string): Promise<void | undefined> {
    return this.apiCaller.call(`/v1/auth/verify?email=${email}&code=${token}`, 'GET');
  }

  resetPassword(email: string): Promise<void | undefined> {
    return this.apiCaller.call(`/v1/auth/resetPassword/${email}`, 'GET');
  }

  changePassword(
    password: string,
    verifyPassword: string,
    token: string
  ): Promise<void | undefined> {
    return this.apiCaller.call(`/v1/auth/resetPassword`, 'POST', {
      password,
      verifyPassword,
      token,
    });
  }

  async editUser(name: string, email: string, newsletter: boolean): Promise<any> {
    const response = await this.apiCaller.call('/v1/auth/edit', 'PUT', {
      name,
      email,
      newsletter,
    });

    return response;
  }

  async removeUser(email: string): Promise<any> {
    return await this.apiCaller.call('/v1/auth/remove', 'DELETE', {
      email,
    });
  }

  resendVerification(email: string, name: string): Promise<void | undefined> {
    return this.apiCaller.call('/v1/auth/resendverification', 'POST', {
      email,
      name,
    });
  }

  logout(): Promise<void | undefined> {
    return this.apiCaller.call('/v2/auth/logout', 'GET');
  }
}

export default AuthApi;
