import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Navigation, Button, Text, Icon, Line } from '@bumerang-kit/components';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import { Page, Container } from '@bumerang-kit/layouts';
import LogisticsApi from '../../apiClient/LogisticsApi';
import Settings from '../../lib/settings';
import { getCurrentSession } from '@/helpers/session';
import { Toaster } from '@bumerang-kit/helpers';

const toaster = new Toaster();

const StyledIcon = styled(Icon)`
  width: 65px;
  height: 65px;
  margin-bottom: ${Spacing.s};
`;

const StyledLine = styled(Line)`
  margin-top: ${Spacing.l};
  margin-bottom: ${Spacing.l};
`;

const Wrapper = styled.div`
  margin-top: ${Spacing.l};
`;

const Block = styled.div`
  text-align: center;
  max-width: 65%;
  margin: 0 auto;
`;

const FixedBlock = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${Spacing.m} ${Spacing.s};
  background-color: ${Colors.white};
`;

const StyledText = styled(Text)`
  margin-top: ${Spacing.s};
  margin-bottom: ${Spacing.s};
`;

const AdminLendSummary = ({ lendConfig, setLendConfig }: any) => {
  const navigate = useHistory();
  const location = useLocation();

  const currentUser = getCurrentSession();
  const logisticsApi = new LogisticsApi(currentUser.token);
  const [toRetrieve, setToRetrieve] = useState<any>([]);
  const [toAssign, setToAssign] = useState<any>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { t } = useTranslation();

  const setLendConfigStorage = (state: any) => {
    Settings.saveStateInSession(state);
    setLendConfig(state);
  };

  useEffect(() => {
    const pathname = location?.pathname;
    if (pathname === '/admin/user/summary') {
      if (!lendConfig.restaurant && !lendConfig.user) {
        const state = Settings.getStateInSession();
        if (state?.restaurant && state?.user && state.containers?.length > 0) {
          setLendConfigStorage({
            ...lendConfig,
            ...state,
          });
        } else {
          setLendConfigStorage({
            ...lendConfig,
            containers: [],
          });
          navigate.replace('/admin');
        }
      }
    }
  }, [location]);

  useEffect(() => {
    if (!lendConfig.containers) return;

    const retrieve: any = [];
    const assign: any = [];
    lendConfig.containers.forEach((container: any) =>
      container.current_owner?.type === 'restaurant'
        ? assign.push(container.id)
        : retrieve.push(container.id)
    );
    setToRetrieve(retrieve);
    setToAssign(assign);
  }, [lendConfig]);

  const resetState = () => {
    setToRetrieve([]);
    setToAssign([]);
  };

  const handleConfirmation = async () => {
    setIsButtonDisabled(true);
    try {
      const responses = [];
      if (toRetrieve.length > 0) {
        responses.push(await logisticsApi.collect(toRetrieve, lendConfig.restaurant?.userId));
      }
      if (toAssign.length > 0) {
        responses.push(
          await logisticsApi.startLend(lendConfig.user?.id, toAssign, lendConfig.restaurant?.userId)
        );
      }

      if (responses && responses.length) {
        for (let i = 0; i < responses.length; i++) {
          const response = responses[i];
          if (!(response && response.success)) {
            if (response.data?.error) {
              toaster.flashError(response.data.error);
            } else {
              toaster.flashError(t('GenericNegativeFeedbackTryAgain'));
            }
          } else if (response.data?.message) {
            toaster.flashSuccess(response.data.message);
          }
        }
      }
    } catch (e) {
      toaster.flashError(t('GenericNegativeFeedbackTryAgain'));
    }

    resetState();
    setLendConfigStorage({
      ...lendConfig,
      restaurant: null,
      containers: [],
      adminAction: null,
    });
    navigate.replace(`/admin`);
    setIsButtonDisabled(false);
  };

  const goBack = () => {
    resetState();
    switch (lendConfig.adminAction) {
      case 'retrieve_containers_final_customer':
        navigate.replace('/admin/user/containers/collect');
        break;
      case 'assign_containers_final_customer':
        navigate.replace('/admin/user/containers/lend');
        break;
      default:
        navigate.replace('/admin');
        break;
    }
  };
  const isNotValid =
    !lendConfig.containers?.length ||
    !lendConfig.restaurant ||
    (toAssign.length > 0 && !lendConfig.user);
  return (
    <Page>
      <Navigation onBack={() => goBack()}>{t('SummaryTitle')}</Navigation>
      <Container>
        <Wrapper>
          <Block>
            <StyledIcon name="bowl" />

            {toAssign.length + toRetrieve.length === 0 ? (
              <Text align="center" config="screen-title-regular">
                {t('SummaryQRPlaceholder')}
              </Text>
            ) : (
              <>
                <Text align="center" config="secondary-screen-title">
                  {t('ScannedContainers')}
                </Text>
                <br />
                <Text align="center" config="screen-title-regular">
                  {toRetrieve.length !== 0 && `Retira ${toRetrieve.length}`}
                  <br />
                  {toAssign.length !== 0 && `Asigna ${toAssign.length}`}
                </Text>
              </>
            )}
          </Block>
          <StyledLine />
          <Block>
            <Text align="center" config="screen-title-regular">
              Retiras a
            </Text>
            <StyledText align="center" config="secondary-screen-title">
              {toAssign.length !== 0 ? lendConfig.restaurant?.name : lendConfig.user?.name}
            </StyledText>

            <Text align="center" config="screen-title-regular">
              Asignas a
            </Text>
            <StyledText align="center" config="secondary-screen-title">
              {toAssign.length !== 0 ? lendConfig.user?.name : lendConfig.restaurant?.name}
            </StyledText>
          </Block>
        </Wrapper>
        <FixedBlock>
          <Button
            disabled={isNotValid || isButtonDisabled}
            expand="full"
            onClick={handleConfirmation}
          >
            {t('Confirm')}
          </Button>
        </FixedBlock>
      </Container>
    </Page>
  );
};

export default AdminLendSummary;
