import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';

const getPartner = async (id: string): Promise<any> => {
  const response = await axios.get(`/v2/restaurant/${id}`);
  return response.data;
};

export default function useGetPartner(id: string, { config = {} }: any) {
  return useQuery({
    queryKey: ['partner', id],
    queryFn: () => getPartner(id),
    onError: (err: any) => console.log(err),
    ...config,
  });
}
