import * as React from 'react';
import QRCode from 'qrcode.react';

import { Colors } from '../../foundations';
import Icon from '../Icon';

export type QrCardProps = {
  onChat: () => void;
  onShare: () => void;
  title: string;
  qr: string;
};

const QrCard = (props: QrCardProps) => {
  return (
    <div
      className="flex items-center flex-col justify-center mx-auto bg-white shadow-lg px-4 pt-4 pb-8 rounded-xl w-auto"
      style={{ maxWidth: '250px' }}
    >
      <header className="flex items-center justify-between w-full mb-4">
        <button onClick={props.onChat}>
          <Icon color={Colors.mainColor} size="20px" name="chat_bubble" />
        </button>
        <span className="text-main text-md font-montserrat font-bold text-center">
          {props.title}
        </span>
        {/* <button onClick={props.onShare}>
          <Icon size="20px" name="share" />
        </button> */}
        <div className="w-6 h-6" />
      </header>
      <QRCode size={120} value={props.qr} />
    </div>
  );
};

export default QrCard;
