import * as React from 'react';
import { Clipboard } from '@capacitor/clipboard';
import { useLastLocation } from 'react-router-last-location';

import { BottomNavigation } from '@/features/shared';
import { QRModal } from '@/features/home';
import Analyzer from '@/lib/common/helpers';

import { Page } from '@bumerang-kit/layouts';

import { getCurrentSession } from '@/helpers/session';
import { useQRAuth } from '@/features/auth';

import { ENV } from '@/env/env';

import { useGetPaymentStatus } from '../../hooks';
import { useLocation } from 'react-router';
import { Chat } from '@bumerang-kit/helpers';

export type NavigationWrapperProps = {
  children: JSX.Element | JSX.Element[];
  isQRModalOpen: boolean;
  onOpenQRModal: (isOpen: boolean) => void;
  active: string;
  type: string;
};

const analyzer = new Analyzer();

const NavigationWrapper = (props: NavigationWrapperProps) => {
  const { children, isQRModalOpen, onOpenQRModal, active, type } = props;
  const currentUser = getCurrentSession();

  const { getPaymentStatus, paymentStatus } = useGetPaymentStatus(currentUser.status, {});
  const isPaymentConfigured = getPaymentStatus.data === 'configured';
  const generateQRAuth: any = useQRAuth({ enabled: isPaymentConfigured && !!currentUser.username });

  const lastLocation: any = useLastLocation();
  const currentLocation: any = useLocation();

  React.useEffect(() => {
    if (isQRModalOpen) return;

    if (
      currentLocation?.location?.pathname !== '/how-it-works' &&
      (lastLocation?.location?.pathname === '/how-it-works/delivery' ||
        lastLocation?.location?.pathname === '/how-it-works/takeaway')
    ) {
      onOpenQRModal(true);
    }
  }, [lastLocation, currentLocation, isQRModalOpen, onOpenQRModal]);

  const handleCopy = async (string: string, userId: string) => {
    await Clipboard.write({
      string,
    });

    const result = await Clipboard.read();

    if (result.value === string) {
      analyzer.trackCopyQr(userId, string);
    }
  };

  if (generateQRAuth.isLoading || getPaymentStatus.isLoading) return <div />;

  return (
    <Page>
      {children}
      <BottomNavigation
        onMainAction={() => {
          onOpenQRModal(true);
        }}
        active={active}
        type={type}
      />
      <QRModal
        onDismiss={() => {
          onOpenQRModal(false);
        }}
        isOpen={isQRModalOpen}
        currentUser={currentUser}
        paymentStatus={paymentStatus}
        qr={currentUser.username || generateQRAuth.data.id}
        onCopy={handleCopy}
        onChat={() =>
          Chat.open(ENV.CRISP_ID, currentUser.email, currentUser.name, currentUser.token)
        }
      />
    </Page>
  );
};

export default NavigationWrapper;
