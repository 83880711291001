import React, { useState } from 'react';
import { useQueries } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import { EmptyState, LoadingList, MultipleStatCard, Tabs } from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import SingleStatCard from '@bumerang-kit/components/molecules/SingleStatCard';
import ImpactApi from '../api/ImpactApi';
import ApiCaller from '../lib/ApiCaller';
import { getCurrentSession } from '@/helpers/session';

const RestImpact = () => {
  const currentUser = getCurrentSession();
  const impactApi = new ImpactApi(new ApiCaller(currentUser.token));
  const { t } = useTranslation();
  const currentDate = new Date();
  const beginningWeek = new Date().getDate() - currentDate.getDay() + 1;
  const endWeek = beginningWeek + 6;
  const today = new Date().toLocaleDateString();
  const monthString = currentDate.toLocaleDateString('es-ES', {
    month: 'long',
  });
  const month = monthString.charAt(0).toUpperCase() + monthString.slice(1);

  const [lent, setLentByTime] = useState({
    today: 0,
    week: 0,
    month: 0,
    total: 0,
  });

  const [collected, setCollectedByTime] = useState({
    today: 0,
    week: 0,
    month: 0,
    total: 0,
  });

  const [stock, setStock] = useState({
    given: 0,
    retrieved: 0,
    lent: 0,
    collected: 0,
    current_stock: 0,
  });

  const [differentCustomers, setDifferentCustomers] = useState(0);
  const [restaurantFootprint, setRestaurantFootprint] = useState<any>([]);

  const [
    getLentByTime,
    getCollectedByTime,
    getDifferentCustomers,
    getRestaurantFootprint,
    getCurrentStock,
  ] = useQueries([
    {
      queryKey: 'lentByTime',
      queryFn: async () => await impactApi.getLendsByTime(),
      onSuccess: (response: any) => {
        if (response.success) {
          setLentByTime(response.data);
        }
      },
      onError: (err: any) => console.log(err),
    },
    {
      queryKey: 'collectedByTime',
      queryFn: async () => await impactApi.getCollectedByTime(),
      onSuccess: (response: any) => {
        if (response.success) {
          setCollectedByTime(response.data);
        }
      },
      onError: (err: any) => console.log(err),
    },
    {
      queryKey: 'differentCustomers',
      queryFn: async () => await impactApi.getTotalDifferentCustomer(),
      onSuccess: (data: any) => setDifferentCustomers(data.count),
      onError: (err: any) => console.log(err),
      enabled: !!currentUser && currentUser.status === 'confirmed',
    },
    {
      queryKey: 'restaurantFootprint',
      queryFn: async () => await impactApi.getRestaurantFootprint(),
      refetchOnWindowFocus: true,
      onSuccess: (response: any) => {
        if (response.success) {
          setRestaurantFootprint(response.data);
        }
      },
      enabled: !!currentUser && currentUser.status === 'confirmed',
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    },
    {
      queryKey: 'currentStock',
      queryFn: async () => await impactApi.getCurrentStock(),
      onSuccess: (response: any) => {
        if (response.success) {
          setStock(response.data);
        }
      },
      onError: (err: any) => console.log(err),
      enabled: !!currentUser && currentUser.status === 'confirmed',
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    },
  ]);

  if (
    getLentByTime.isLoading ||
    getDifferentCustomers.isLoading ||
    getCurrentStock.isLoading ||
    getRestaurantFootprint.isLoading ||
    getCollectedByTime.isLoading
  )
    return <LoadingList />;

  return (
    <Page>
      <Container>
        {stock.lent === 0 ? (
          <EmptyState
            src="assets/emptyBowl.svg"
            title={t('RestImpactEmptyStateTitle')}
            subtitle={t('RestImpactEmptyStateSubtitle')}
          />
        ) : (
          <>
            <MultipleStatCard
              iconName="bowl"
              title={t('RestImpactStock')}
              stats={[
                {
                  name: t('RestImpactGivenByBumerang'),
                  metric: stock.given,
                  highlighted: false,
                },
                {
                  name: t('RestImpactRetiredByBumerang'),
                  metric: stock.retrieved,
                  highlighted: false,
                },
                {
                  name: t('RestImpactCurrentStock'),
                  metric: stock.current_stock,
                  highlighted: true,
                  danger: stock.current_stock <= 10,
                },
              ]}
            />

            <MultipleStatCard
              iconName="full-bowl"
              title={t('RestImpactServed')}
              stats={[
                {
                  name: t('RestImpactToday', { today }),
                  metric: lent.today,
                  highlighted: false,
                },
                {
                  name: t('RestImpactWeek', {
                    beginningWeek,
                    endWeek,
                  }),
                  metric: lent.week,
                  highlighted: false,
                },
                {
                  name: t('RestImpactMonth', { month }),
                  metric: lent.month,
                  highlighted: false,
                },
                {
                  name: t('Total'),
                  metric: lent.total,
                  highlighted: false,
                },
              ]}
            />
            <MultipleStatCard
              iconName="full-bowl"
              title={t('YouHaveCollected')}
              stats={[
                {
                  name: t('RestImpactToday', { today }),
                  metric: collected.today,
                  highlighted: false,
                },
                {
                  name: t('RestImpactWeek', {
                    beginningWeek,
                    endWeek,
                  }),
                  metric: collected.week,
                  highlighted: false,
                },
                {
                  name: t('RestImpactMonth', { month }),
                  metric: collected.month,
                  highlighted: false,
                },
                {
                  name: t('Total'),
                  metric: collected.total,
                  highlighted: false,
                },
              ]}
            />
            <SingleStatCard
              iconName="earth"
              title={
                <>
                  <Trans
                    i18nKey="RestImpactPreventedWaste"
                    defaults="Has <bold>evitado</bold> con Bûmerang"
                    components={{ bold: <strong /> }}
                  />
                </>
              }
              metric={`${restaurantFootprint.waste}gr`}
              unit={t('RestImpactWasteUnit')}
            />

            <SingleStatCard
              iconName="metrics"
              title={
                <>
                  <Trans
                    i18nKey="RestImpactClients"
                    defaults="Contigo utilizan <newLine /> <bold>Bûmerang</bold>"
                    components={{
                      newLine: <br />,
                      bold: <strong />,
                    }}
                  />
                </>
              }
              metric={differentCustomers}
              unit={t('Clients')}
            />
          </>
        )}
      </Container>
      <Tabs type="restaurant" active="impact" />
    </Page>
  );
};

export default RestImpact;
