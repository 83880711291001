import { useEffect, useState } from 'react';

import ServiceApi from '../../../api/ServiceApi';
import ApiCaller from '../../../lib/ApiCaller';
import Feature from '../../../lib/common/models/feature';

const serviceApi = new ServiceApi(new ApiCaller());

export default function useFeatures(featureName: string): any {
  const [feature, setFeature] = useState<undefined | Feature>();

  useEffect(() => {
    const getFeaturesFromApi = async () => {
      const response = await serviceApi.getFeatures();
      if (response && response.success) {
        const filteredFeature: any = response.data.filter(
          (feature: any) => feature.name === featureName
        );

        if (filteredFeature && filteredFeature.length > 0) {
          setFeature(new Feature(filteredFeature[0]));
        }
      }
    };

    getFeaturesFromApi();
  }, []);

  return feature;
}
