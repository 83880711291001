import { Container, Page } from '@bumerang-kit/layouts';
import { EmptyState, LoadingHome, Navigation } from '@bumerang-kit/components';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';

type NonIntendedResponseProps = {
  isLoading: boolean;
  isError: boolean;
};

const StyledContainer = styled(Container)`
  padding: ${Spacing.s} ${Spacing.m};
  margin-bottom: ${Spacing.xxxxxl};
  position: relative;
`;

const NonIntendedResponse = (props: NonIntendedResponseProps) => {
  const { isLoading, isError } = props;
  const { t } = useTranslation();
  const navigate = useHistory();

  if (isLoading)
    return (
      <Page>
        <LoadingHome />
      </Page>
    );

  if (isError && !isLoading) {
    return (
      <Page>
        <Navigation backgroundColor={Colors.white} onBack={() => navigate.go(-1)}>
          {t('PartnerProfile.NonIntendedResponsePageTitle')}
        </Navigation>
        <StyledContainer>
          <EmptyState
            src="assets/emptyBowlIncorrect.svg"
            title={t('GenericNegativeFeedback')}
            subtitle={t('TryAgainLater')}
          />
        </StyledContainer>
      </Page>
    );
  }
  return null;
};

export default NonIntendedResponse;
