import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router';
import { Container, Page } from '@bumerang-kit/layouts';
import { Button, Line, Tabs, Text } from '@bumerang-kit/components';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import Settings from '../../lib/settings';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 50% ${Spacing.s};
`;

const StyledLine = styled(Line)`
  width: 100%;
`;
const Admin = ({ lendConfig, setLendConfig }: any) => {
  const { t } = useTranslation();
  const navigate = useHistory();

  const setLendConfigStorage = (state: any) => {
    Settings.saveStateInSession(state);
    setLendConfig(state);
  };

  const openRestaurantAction = async (event: any, type: any) => {
    event.preventDefault();

    await setLendConfigStorage({
      ...lendConfig,
      adminAction: type,
      containers: [],
    });

    navigate.replace('/admin/restaurantList');
  };

  const openSelectUserTypeAction = async (event: any, type: any) => {
    event.preventDefault();

    await setLendConfigStorage({
      ...lendConfig,
      adminAction: type,
      containers: [],
    });

    navigate.replace('/admin/selectUserType');
  };

  const openBillingAction = async (event: any) => {
    event.preventDefault();

    await setLendConfigStorage({
      ...lendConfig,
      adminAction: 'billing',
      containers: [],
    });

    navigate.replace('/admin/billing');
  };

  return (
    <Page>
      <StyledContainer>
        <Button link="/restaurant/add">
          <Text config="button-text" color={Colors.white}>
            {t('AdminAddRestaurant')}
          </Text>
        </Button>
        <StyledLine isOption />
        <Button onClick={(event: any) => openRestaurantAction(event, 'edit')}>
          <Text config="button-text" color={Colors.white}>
            {t('AdminEditRestaurant')}
          </Text>
        </Button>
        <StyledLine isOption />
        <Button onClick={(event: any) => openBillingAction(event)}>
          <Text config="button-text" color={Colors.white}>
            {t('BillingTitle')}
          </Text>
        </Button>
        <StyledLine isOption />
        <Button link="/admin/loginAs">
          <Text config="button-text" color={Colors.white}>
            {t('LoginAsTitle')}
          </Text>
        </Button>
        <StyledLine isOption />
        <Button link="/admin/container/info">
          <Text config="button-text" color={Colors.white}>
            {t('GetInfoContainerTitle')}
          </Text>
        </Button>
        <StyledLine isOption />
        <Button onClick={(event: any) => openSelectUserTypeAction(event, 'assign_containers')}>
          <Text config="button-text" color={Colors.white}>
            {t('AssignContainersTitle')}
          </Text>
        </Button>
        <StyledLine isOption />
        <Button onClick={(event: any) => openSelectUserTypeAction(event, 'retrieve_containers')}>
          <Text config="button-text" color={Colors.white}>
            {t('RetrieveContainersTitle')}
          </Text>
        </Button>
        <StyledLine isOption />
        <Button onClick={(event: any) => openRestaurantAction(event, 'movement_history')}>
          <Text config="button-text" color={Colors.white}>
            {t('MovementHistory')}
          </Text>
        </Button>
      </StyledContainer>
      <Tabs type="admin" active="admin" />
    </Page>
  );
};

export default Admin;
