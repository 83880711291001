import React from 'react';
import styled from 'styled-components';
import { IonRouterLink } from '@ionic/react';

import { Colors, Sizes } from '../../../foundations';

const getFontSize = (fontConfig: string): any => {
  switch (fontConfig) {
    case 'app-title':
      return {
        size: `${Sizes.xxxl}`,
        lh: '50px',
        family: "'Montserrat Bold', sans-serif",
      };
    case 'onboarding-title':
      return {
        size: `${Sizes.xxl}`,
        lh: '36px',
        family: "'Montserrat Bold', sans-serif",
      };
    case 'screen-title':
      return {
        size: `${Sizes.xl}`,
        lh: '140%',
        family: "'Montserrat Bold', sans-serif",
      };
    case 'screen-title-regular':
      return {
        size: `${Sizes.xl}`,
        lh: '140%',
        family: "'Montserrat Regular', sans-serif",
      };
    case 'secondary-screen-title':
      return {
        size: `${Sizes.xl}`,
        lh: '140%',
        family: "'Montserrat Bold', sans-serif",
      };
    case 'button-text':
      return {
        size: `${Sizes.l}`,
        lh: '140%',
        family: "'Montserrat Bold', sans-serif",
      };
    case 'main-text-title':
      return {
        size: `${Sizes.m}`,
        lh: '140%',
        family: "'Montserrat Bold', sans-serif",
      };
    case 'main-text-link':
      return {
        size: `${Sizes.m}`,
        lh: '140%',
        family: "'Montserrat Bold', sans-serif",
      };
    case 'main-text':
      return {
        size: `${Sizes.m}`,
        lh: '140%',
        family: "'Montserrat Regular', sans-serif",
      };
    case 'secondary-text-title':
      return {
        size: `${Sizes.s}`,
        lh: '21px',
        family: "'Montserrat Bold', sans-serif",
      };
    case 'secondary-text':
      return {
        size: `${Sizes.s}`,
        lh: '21px',
        family: "'Montserrat Regular', sans-serif",
      };
    case 'small-text-title':
      return {
        size: `${Sizes.xs}`,
        lh: '140%',
        family: "'Montserrat Bold', sans-serif",
      };
    case 'small-text':
      return {
        size: `${Sizes.xs}`,
        lh: '140%',
        family: "'Montserrat Regular', sans-serif",
      };
    case 'tag-text':
      return {
        size: `${Sizes.xs}`,
        lh: '140%',
        family: "'Montserrat Regular', sans-serif",
      };
    case 'qr-text':
      return {
        size: `${Sizes.s}`,
        lh: '140%',
        family: "'Montserrat Regular', sans-serif",
        ls: '4.2px',
      };
    default:
      return {
        size: `${Sizes.m}`,
        lh: '140%',
        family: "'Montserrat Regular', sans-serif",
      };
  }
};

const StyledText = styled.p`
  margin: 0;
  padding: 0;
  ${({ config, isLink, isModalLink, align, color, bold, limit = false }: any) => `
    font-weight: ${bold ? 'bold' : 'normal'};
    font-size: ${getFontSize(config).size};
    line-height: ${getFontSize(config).lh};
    font-family: ${getFontSize(config).family};
    letter-spacing: ${getFontSize(config).ls};
    color: ${isLink || isModalLink ? Colors.mainColor : color || Colors.black};
    text-align: ${align || 'left'};
    text-decoration: ${isLink ? 'underline' : 'none'};
    cursor: ${isLink || isModalLink ? 'pointer' : 'auto'};
    text-transform: ${isModalLink ? 'uppercase' : 'none'};
    ${
      limit
        ? `
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow:  hidden; `
        : ''
    }
  `}
`;

const ConditionalWrapper = ({ condition, wrapper, children }: any): any =>
  condition ? wrapper(children) : children;

const Text = ({
  config,
  isLink,
  isModalLink,
  isActionableLink,
  link,
  action,
  align,
  children,
  color,
  bold,
  limit = false,
  ...props
}: any) => {
  return (
    <ConditionalWrapper
      condition={isLink || isActionableLink || isModalLink}
      wrapper={(children: any) => (
        <IonRouterLink routerLink={link} onClick={action} {...props}>
          {children}
        </IonRouterLink>
      )}
    >
      <StyledText
        config={config}
        align={align}
        isModalLink={isModalLink}
        isLink={isLink}
        isActionableLink={isActionableLink}
        color={color}
        bold={bold}
        limit={limit}
        {...props}
      >
        {children}
      </StyledText>
    </ConditionalWrapper>
  );
};

export default Text;
