import { useQuery } from 'react-query';
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';

import jwt_decode from 'jwt-decode';

const getUserFromApple = async () => {
  const options: SignInWithAppleOptions = {
    clientId: 'com.bumerang.app',
    redirectURI: 'https://app.youbumerang.com/login',
    scopes: 'email name',
    state: '12345',
    nonce: 'nonce',
  };

  const {
    response: { user, givenName, familyName, email, identityToken },
  }: SignInWithAppleResponse = await SignInWithApple.authorize(options);

  if (givenName && familyName && email) {
    const fullName = `${givenName} ${familyName}`;
    return { email, fullName, appleToken: user };
  }

  const { email: identityTokenEmail }: { email: string } = jwt_decode(identityToken);

  return { appleToken: user, email: identityTokenEmail };
};

export default function useApple() {
  return useQuery('Apple', getUserFromApple, {
    enabled: false,
    retry: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
  });
}
