import * as React from 'react';
import { format } from 'date-fns';
import { Trans } from 'react-i18next';

import Settings from '@/lib/settings';
import styled from 'styled-components';
export type TagProps = {
  date: Date;
  getDays?: (lentAt: number) => void;
};

const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  height: 30px;
  width: 190px;
  border-radius: 7px;

  strong {
    margin-left: 3px;
  }
`;

const getTagBackgroundColor = (days: number) => {
  if (days < 1) return `bg-secondary-green`;
  if (days > 0 && days < 7) return `bg-coral`;
  if (days >= 7 && days < 10) return `bg-orange`;
  if (days >= 15) return `bg-cream`;
  return `bg-cream`;
};

const getDaysLeftToReturn = (lentAt: any) => {
  const lentAtDate = new Date(lentAt);
  const now = new Date();
  lentAtDate.setDate(lentAtDate.getDate() + Settings.getNumDaysToReturn());
  return Math.floor((lentAtDate.getTime() - now.getTime()) / (1000 * 3600 * 24));
};

const DaysToReturnTag = (props: TagProps) => {
  const days = getDaysLeftToReturn(props.date);

  return (
    <StyledSpan
      className={`py-1 px-4 flex justify-center text-xxs ${getTagBackgroundColor(
        days
      )} rounded-xl text-center`}
    >
      {days < 1 ? (
        <Trans i18nKey={'MissedReturnDay'} components={{ bold: <strong /> }} />
      ) : (
        <Trans
          i18nKey={'ReturnBefore'}
          components={{ strong: <strong /> }}
          values={{ time: format(props.date, 'dd/MM') }}
        />
      )}
    </StyledSpan>
  );
};

export default DaysToReturnTag;
