import React from 'react';
import Icon from '@bumerang-kit/components/atoms/Icon/Icon';

type ClusterMarkerProps = {
  pointCount: number;
  onClick: () => void;
  lat: number;
  lng: number;
  key: string;
};

const ClusterMarker = (props: ClusterMarkerProps) => {
  const { pointCount, onClick } = props;

  return (
    <div
      className="h-12 w-12 flex flex-col p-3 drop-shadow-md justify-center align-middle items-center bg-[#E2ECF8]  rounded-full h-12 w-12 -translate-y-1/2 -translate-x-1/2"
      onClick={onClick}
    >
      <div className="h-auto w-auto -mb-1">
        <Icon name="bumerang" />
      </div>
      <p className="font-bold text-main text-base ">{pointCount}</p>
    </div>
  );
};

export default ClusterMarker;
