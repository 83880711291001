const Colors = {
  contrastCream: '#eddec2',
  detailsBlue: '#a7bfda',
  mainColorActive: '#221f73',
  mainColor: '#322e8f',
  backgroundBlue: '#F4F9FF',
  white: '#ffffff',
  mainColorDisabled: '#d9d9e3',
  black: '#1d1c1a',
  tagCoral: '#ffa0a0',
  tagOrange: '#fecab4',
  tagCream: '#feead0',
  tagYellow: '#fce15f',
  success: '#59caa8',
  error: '#ff7777',
  actionableGrey: '#5f6368',
  transparent: 'transparent',
  paleBlue: '#F4F9FF',
  secondaryGreen: '#A9D7D5',
  borderSeparation: '#0000005C',
};

export default Colors;
