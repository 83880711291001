import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick-theme.css';
import './Carrousel.css';

const settings = {
  dots: true,
  adaptativeHeight: true,
  arrows: false,
  infinite: false,
};

const Carrousel = ({ children, reference, overrideSettings }: any) => {
  const finalConfiguration = { ...overrideSettings, ...settings };
  return (
    <Slider ref={reference} {...finalConfiguration}>
      {children}
    </Slider>
  );
};

export default Carrousel;
