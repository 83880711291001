import ApiCaller from '../lib/ApiCaller';
import LendsCollection from '../lib/common/models/lendsCollection';

class LendApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getMyLends(): Promise<any> {
    return this.apiCaller.call('/v2/logistics/lend/history', 'GET');
  }

  getTotalLends(): Promise<any> {
    return this.apiCaller.call('/v1/bumerang/me/total', 'GET');
  }

  lend(
    quantity: number,
    token: string,
    inputMethod: string
  ): Promise<void | LendsCollection | undefined> {
    return this.apiCaller.call('/v1/bumerang/lend', 'POST', {
      quantity,
      qrToken: token,
      inputMethod,
    });
  }

  collect(quantity: number, token: string): Promise<void | LendsCollection | undefined> {
    return this.apiCaller.call('/v1/bumerang/collect', 'POST', {
      quantity,
      qrToken: token,
    });
  }

  swap(token: string, inputMethod: string): Promise<void | LendsCollection | undefined> {
    return this.apiCaller.call('/v1/bumerang/swap', 'POST', {
      qrToken: token,
      inputMethod,
    });
  }

  isUsed(token: string): Promise<any> {
    return this.apiCaller.call('/v1/bumerang/isUsed', 'POST', {
      qrToken: token,
    });
  }
}

export default LendApi;
