import React from 'react';
import styled from 'styled-components';

import './styles/General.css';
import './styles/ResetPass.css';

import { useTranslation } from 'react-i18next';
import AuthApi from '../api/AuthApi';
import ApiCaller from '../lib/ApiCaller';

import { Input, Navigation, Button } from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import { Spacing, Colors } from '@bumerang-kit/foundations';
import { Toaster } from '@bumerang-kit/helpers';
import { useHistory, useParams } from 'react-router';

const StyledForm = styled.form`
  margin-top: ${Spacing.l};
  margin-bottom: ${Spacing.xxxl};
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.s};
`;

const StyledPage = styled(Page)`
  background-color: ${Colors.backgroundBlue};
`;

const toaster = new Toaster();
interface ParamsProps {
  token: string;
}

const ResetPass = () => {
  const [password, setPassword] = React.useState<string>('');
  const [verifyPassword, setVerifyPassword] = React.useState<string>('');
  const { t } = useTranslation();
  const { token } = useParams<ParamsProps>();
  const navigate = useHistory();

  const isFormValid = !password || !verifyPassword;

  const sendPass = (e: React.FormEvent) => {
    e.preventDefault();
    const authApi = new AuthApi(new ApiCaller());
    authApi
      .changePassword(password, verifyPassword, token)
      .then((response: any) => {
        const { success, data } = response;

        if (!success) {
          toaster.flashError(data.error);
          return;
        }

        toaster.flashSuccess(t('ResetPassPositiveFeedbak'));

        navigate.push('/login');
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <StyledPage>
      <Navigation backgroundColor={Colors.backgroundBlue} onBack={() => navigate.go(-1)}>
        {t('ResetPassPageTitle')}
      </Navigation>
      <Container backgroundColor={Colors.backgroundBlue}>
        <StyledForm onSubmit={sendPass}>
          <Input
            type="password"
            value={password}
            label={t('PasswordLabel')}
            name="password"
            onChange={(e: any) => setPassword(e.detail.value!)}
          />
          <Input
            type="password"
            value={verifyPassword}
            label={t('RepeatPasswordLabel')}
            name="verifyPassword"
            onChange={(e: any) => setVerifyPassword(e.detail.value!)}
          />
          <StyledButton disabled={isFormValid} type="submit">
            {t('Send')}
          </StyledButton>
        </StyledForm>
      </Container>
    </StyledPage>
  );
};

export default ResetPass;
