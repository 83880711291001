import Tag from '../Tag';
import { Coords, Partner } from '@/features/shared';
import { getDistanceInKmOrM } from '../../../features/shared/utils/calculateDistance';
import ProductIcon from '../../../features/shared/components/ProductIcon/ProductIcon';
import { PartnerSchedule } from '..';
import { useTranslation } from 'react-i18next';

import { truncate } from '@bumerang-kit/helpers/Utils';

export type PartnerCardProps = {
  partner: Partner;
  direction: 'vertical' | 'horizontal';
  currentPosition?: Coords;
  onClick?: () => void;
  className?: string;
  itemId?: any;
};

const PartnerCard = (props: PartnerCardProps) => {
  const { partner, direction, onClick, currentPosition, className } = props;
  const { t } = useTranslation();

  const checkPartnerNameLength = (name: string) => {
    name = partner.name;
    let newFontSize;
    if (name.length > 20) {
      newFontSize = 'text-sm font-bold text-base text-main mt-0 mb-1';
    } else {
      newFontSize = 'font-bold text-base text-main mt-0 mb-1';
    }
    return newFontSize;
  };

  const hasDelivery = partner && Object.keys(partner.delivery).length > 0;
  const emptyImageURL =
    'https://bumerangphotos.s3.eu-west-3.amazonaws.com/assets/big_default_restaurant.png';

  return direction === 'vertical' ? (
    <div
      onClick={onClick}
      {...props}
      className={`h-350 drop-shadow-md min-w-full select-none cursor-pointer ${className}`}
    >
      <header className="relative">
        {hasDelivery && (
          <div className="absolute right-2 top-2">
            <Tag>
              <span>{t('Delivery')}</span>
            </Tag>
          </div>
        )}

        <img
          className="w-full h-32 object-cover pointer-events-none rounded-t"
          src={partner.photoUrl[0]}
          aria-hidden
          alt="image"
        />

        <ProductIcon
          className="absolute right-4 -mt-4 border-4 border-white rounded-full"
          productGroup={partner.productGroupTypeList}
        />
      </header>
      <div className="p-2 bg-white rounded-b" style={{ minHeight: '140px' }}>
        <span className="text-xs text-main">{partner.type && t(partner.type[0])}</span>
        <h2 className={checkPartnerNameLength(partner.name)}>{partner.name}</h2>
        <p className="text-sm mb-1">
          {truncate(partner.address, 50)}{' '}
          {currentPosition && (
            <p className="inline-block text-xs ">
              {`  | ${getDistanceInKmOrM({
                currentLatitude: currentPosition.lat,
                currentLongitude: currentPosition.lng,
                targetLatitude: partner.lat,
                targetLongitude: partner.lng,
              })}`}
            </p>
          )}
        </p>

        <PartnerSchedule hours={partner.hours} />
      </div>
    </div>
  ) : (
    <div
      onClick={onClick}
      {...props}
      className={`h-auto drop-shadow-md select-none cursor-pointer ${className}`}
    >
      <header className="relative">
        {hasDelivery && (
          <div className="absolute right-2 top-2">
            <Tag>
              <span>{t('Delivery')}</span>
            </Tag>
          </div>
        )}

        <img
          className="w-full h-32 object-cover pointer-events-none rounded-t"
          src={partner.photoUrl[0] ? partner.photoUrl[0] : emptyImageURL}
          aria-hidden
          alt="image"
        />
      </header>

      <ProductIcon
        className="absolute right-4 -mt-4 border-4 border-white rounded-full"
        productGroup={partner.productGroupTypeList}
      />

      <div className="p-4 bg-white rounded-b">
        <span className="text-xxs text-main">{partner.type && t(partner.type[0])}</span>
        <h2 className="font-bold text-base text-main mt-0 mb-1">{partner.name}</h2>
        <p className="text-sm mb-1">
          {partner.address}{' '}
          {currentPosition && (
            <p className="inline-block text-xs ">
              {`  | ${getDistanceInKmOrM({
                currentLatitude: currentPosition.lat,
                currentLongitude: currentPosition.lng,
                targetLatitude: partner.lat,
                targetLongitude: partner.lng,
              })}`}
            </p>
          )}
        </p>

        <PartnerSchedule hours={partner.hours} />
      </div>
    </div>
  );
};

export default PartnerCard;
