import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

import { Container } from '../../../../layouts';
import { Colors } from '../../../../foundations';

const StyledContainer = styled(Container)`
  overflow: hidden;
`;

const StyledContentLoader = styled(ContentLoader)`
  background-color: ${Colors.white};
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const LoadingList = ({ props }: any) => (
  <StyledContainer>
    <StyledContentLoader
      speed={2}
      width={100}
      height={100}
      viewBox="0 0 375 500"
      backgroundColor={Colors.backgroundBlue}
      foregroundColor={Colors.mainColorDisabled}
      {...props}
    >
      <rect x="70" y="47" rx="3" ry="3" width="160" height="24" />
      <circle cx="25" cy="56" r="19" />
      <rect x="256" y="46" rx="3" ry="3" width="112" height="24" />
      <rect x="70" y="107" rx="3" ry="3" width="160" height="24" />
      <circle cx="25" cy="116" r="19" />
      <rect x="256" y="106" rx="3" ry="3" width="112" height="24" />
      <rect x="71" y="168" rx="3" ry="3" width="160" height="24" />
      <circle cx="26" cy="177" r="19" />
      <rect x="257" y="167" rx="3" ry="3" width="112" height="24" />
      <rect x="71" y="228" rx="3" ry="3" width="160" height="24" />
      <circle cx="26" cy="237" r="19" />
      <rect x="257" y="227" rx="3" ry="3" width="112" height="24" />
      <rect x="71" y="287" rx="3" ry="3" width="160" height="24" />
      <circle cx="26" cy="296" r="19" />
      <rect x="257" y="286" rx="3" ry="3" width="112" height="24" />
      <rect x="71" y="347" rx="3" ry="3" width="160" height="24" />
      <circle cx="26" cy="356" r="19" />
      <rect x="257" y="346" rx="3" ry="3" width="112" height="24" />
      <rect x="72" y="408" rx="3" ry="3" width="160" height="24" />
      <circle cx="27" cy="417" r="19" />
      <rect x="258" y="407" rx="3" ry="3" width="112" height="24" />
      <rect x="72" y="468" rx="3" ry="3" width="160" height="24" />
      <circle cx="27" cy="477" r="19" />
      <rect x="258" y="467" rx="3" ry="3" width="112" height="24" />
    </StyledContentLoader>
  </StyledContainer>
);

LoadingList.propTypes = {
  items: PropTypes.number,
};

LoadingList.defaultProps = {
  items: 8,
};

export default LoadingList;
