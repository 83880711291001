import React from 'react';
import { Card, Text } from '../../atoms';
import styled from 'styled-components';
import { Spacing } from '../../../foundations';
import RankingStar from './RankingStar';
import { Trans } from 'react-i18next';

const Main = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-content: center;
  margin-bottom: ${Spacing.xs};
`;

const StyledText = styled(Text)`
  margin: ${Spacing.xxs} ${Spacing.xs} ${Spacing.xs} ${Spacing.s};
`;

const getRanking = (numberTuppers: number) => {
  switch (true) {
    case numberTuppers >= 50:
      return 'high';
    case numberTuppers >= 20 && numberTuppers < 50:
      return 'half';
    case numberTuppers < 20:
      return 'low';
    default:
      return 'low';
  }
};

const GlobalPosition = ({
  totalUsers,
  position,
  count,
}: {
  totalUsers: number;
  position: number;
  count: number;
}) => {
  const ranking = getRanking(count);
  return (
    <Card full>
      <StyledText config={'main-text'}>
        <Trans
          i18nKey={'GlobalPositionTitle'}
          defaults={'Ocupas la posición <bold>{{position}}</bold> de {{totalUsers}}'}
          components={{ bold: <strong /> }}
          values={{ position: position, totalUsers: totalUsers }}
        ></Trans>
      </StyledText>
      <Main>
        <RankingStar ranking={'high'} selected={ranking === 'high'} />
        <RankingStar ranking={'half'} selected={ranking === 'half'} />
        <RankingStar ranking={'low'} selected={ranking === 'low'} />
      </Main>
    </Card>
  );
};

export default GlobalPosition;
