import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export type ExpandableContainerProps = {
  children: React.ReactNode;
  className?: string;
  pointer?: React.Ref<any>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BottomSheet = (props: ExpandableContainerProps) => {
  const { children } = props;
  //  const divRef = useRef(null);
  //  const targetRef = useRef(null);
  //const [isSticky, setIsSticky] = useState(false);

  //  useEffect(() => {
  //    const cachedRef: any = divRef.current;
  //    const observer = new IntersectionObserver(
  //      ([e]) => {
  //        setIsSticky(!e.isIntersecting);
  //        if (!e.isIntersecting) {
  //          targetRef.current.style.overflow = 'auto';
  //        }
  //      },
  //      { threshold: [0.5] }
  //    );
  //    observer.observe(cachedRef);
  //    return function () {
  //      observer.unobserve(cachedRef);
  //    };
  //  }, []);

  //<div className="relative h-full z-40 bg-transparent pointer-event-none scrollbar-hide">
  //  <div ref={divRef} className="z-0 pointer-event-none h-[29%] w-full" />
  //  <div
  //    ref={targetRef}
  //    className={`sticky top-20 h-2/3 drop-shadow-lg bg-white overflow-scroll scrollbar-hide rounded-t-xl w-full p-5 items-center justify-center align-middle z-20
  //      `}
  //  >
  //    {children}
  //  </div>
  //</div>
  return (
    <div className="relative -mt-3 bg-white overflow-scroll  h-full rounded-t-xl border-top border-gray-500 p-5 items-center justify-center align-middle z-30 pointer-event-none scrollbar-hide  pb-40 ">
      {children}
    </div>
  );
};
export default BottomSheet;
