/* import { CameraPermissionsContext } from '@/components'; */
import { getCurrentSession } from '@/helpers/session';
import {
  Button,
  Cart,
  Clipboard,
  Divider,
  NewQRCard,
  Placeholder,
  Text,
} from '@bumerang-kit/components';
import {
  NoPermissionsPlaceHolder,
  StyledButton,
} from '@bumerang-kit/components/organism/QRScanner/QRReader';
import { Colors } from '@bumerang-kit/foundations';
import {
  IonContent,
  IonSlide,
  IonSlides,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QrReader from 'react-qr-reader';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Toaster } from '@bumerang-kit/helpers';
import ProductApi from '@/apiClient/ProductApi';
import LogisticsApi from '@/apiClient/LogisticsApi';
import QrScanner from 'qr-scanner';

QrScanner.WORKER_PATH = 'qr-scanner/qr-scanner-worker.min.js';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
};

const Scan = styled(QrReader)`
  width: 100%;
  display: block;
  position: relative;
  top: -20px;
`;

const ScannedStatus = styled.img`
  z-index: 1000000;
  position: absolute;
  top: 150px;
`;

const StyledQrDiv = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const NoPermissionStyledQrDiv = styled(StyledQrDiv)`
  height: 550px;
`;
interface ContainerDetailsData {
  id: string;
  current_owner: {
    id: string;
    name: string;
    username: string;
    type: string;
  } | null;
  name: string;
  product_type_id: string;
  status: string;
  current_owner_id: string | null;
  created_at: Date;
  updated_at: Date;
  model: {
    id: string;
    name: string;
    code: string;
    picture: string;
    created_at: Date;
    updated_at: Date;
  };
  timestamp?: Date;
}

interface QrScannerState {
  time?: number;
  text?: string | null;
}

const toaster = new Toaster();

const QRModalSliders = ({ currentUser, qr, onChat, onCopy, onDismiss, setLendConfig }: any) => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const [video, setVideo] = React.useState<HTMLVideoElement | null>(null);
  const user = getCurrentSession();
  const productApi = new ProductApi(user.token);
  const logisticsApi = new LogisticsApi(user.token);
  const [containers, updateContainers] = useState<ContainerDetailsData[]>([]);
  const [toScan, setToScan] = useState<QrScannerState>({});
  const [alreadyScanned, setAlreadyScanned] = useState<QrScannerState>({});
  const [scanner, setScanner] = useState<QrScanner | null>(null);
  const [isScanning, updateScanning] = useState(true);
  const [isContainerScan, updateIsContainerScan] = useState<boolean>(true);
  const [isScanOkay, setScanOkay] = useState<boolean>();
  const [isScanWrong, setScanWrong] = useState<boolean>();
  const [hasPermissions, setHasPermissions] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (video && hasPermissions) {
      try {
        const qrScanner = new QrScanner(
          video,
          (qrText: string | null) =>
            qrText ? setToScan({ time: Date.now(), text: qrText }) : null,
          300
        );

        setScanner(qrScanner);
      } catch (e) {
        console.log(e);
      }
    }
  }, [video, hasPermissions]);

  React.useEffect(() => {
    if (scanner) {
      scanner.start();
    }

    return () => {
      if (scanner) scanner.stop();
    };
  }, [scanner]);

  React.useEffect(() => {
    try {
      if (
        alreadyScanned.time &&
        Date.now() - alreadyScanned.time < 2000 &&
        toScan.text === alreadyScanned.text
      ) {
        return;
      }
      if (toScan.text) {
        setAlreadyScanned(toScan);
      }
    } catch (e) {
      console.log(e);
    }
  }, [toScan]);

  const removeContainer = async (id: string) => {
    const removedItem = containers.filter((container) => container.id === id);
    const filteredArray = containers.filter((container) => container.id !== id);

    updateContainers([...filteredArray]);
    toaster.flashSuccess(
      t('SuccessfullyRemoved', {
        containerName: removedItem[0].name,
      })
    );
  };

  const handleContainerScan = async (qrContent: string | null) => {
    if (!qrContent) return;
    isContainerScan;
    const isClientId = qrContent.includes('B-');

    if (isClientId) {
      toaster.flashError(t('IsNotAValidContainer'));
      return;
    }

    const isAlreadyScanned = containers.some((container) => container.id === qrContent);

    if (isAlreadyScanned) return;

    if (!isAlreadyScanned) {
      const response = await productApi.getContainerDetails(qrContent);

      if (response && response.success) {
        if (
          response.data.current_owner.type !== 'restaurant' &&
          response.data.current_owner.type !== 'customer'
        ) {
          toaster.flashError(t('UserIsNotRestaurantOrCustomer'));
          setScanWrong(true);
          setTimeout(() => {
            setScanWrong(false);
          }, 2000);
          return;
        }

        if (
          response.data.current_owner.type === 'customer' &&
          response.data.status === 'not_refundable'
        ) {
          toaster.flashError(t('ContainerCouldNotBeCollected'));
          setScanWrong(true);
          setTimeout(() => {
            setScanWrong(false);
          }, 2000);
          return;
        }

        if (response.data.current_owner.type === 'customer') {
          setScanWrong(true);
          setTimeout(() => {
            setScanWrong(false);
          }, 2000);
          toaster.flashError(t('ContainerIsAlreadyAssignedToACustomerNegativeFeedback'));
          return;
        }

        const finalArray: any = containers.concat([
          { ...response.data, timestamp: new Date().getTime() },
        ]);
        updateContainers(finalArray);
        setScanOkay(true);
        setTimeout(() => {
          setScanOkay(false);
        }, 2000);
        toaster.flashSuccess(
          t('SuccessfullyAdded', {
            containerName: response.data.name,
          })
        );
      } else {
        if (response?.data?.error) {
          toaster.flashError(response.data.error);
          setScanWrong(true);
          setTimeout(() => {
            setScanWrong(false);
          }, 2000);
          return;
        } else if (!response.success) {
          toaster.flashError(response.data.error);
          setScanWrong(true);
          setTimeout(() => {
            setScanWrong(false);
          }, 2000);
          return;
        }

        toaster.flashError(t('ContainerIsIncorrect'));
      }
    } else {
      toaster.flashError(t('ContainerAlreadyScanned'));
    }
  };

  const handleError = () => {
    toaster.flashError(t('QrScanSomethingWentWrongFeedback'));
    updateScanning(false);
    navigate.replace('/customer');
  };

  useIonViewWillLeave(() => updateScanning(false));
  useIonViewWillEnter(() => updateScanning(true));

  const handleClick = async () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream) => {
        const newVideo = document.createElement('video');
        newVideo.srcObject = stream;
        setVideo(newVideo);
        setHasPermissions(true);
      })
      .catch((err) => {
        toast.error('Something went wrong.');
        history.go(0);
        console.log('el err', err);
      });
  };

  const slidesRef = React.useRef<HTMLIonSlidesElement>(null);
  const handleNext = () => slidesRef.current?.slideNext();

  const handleConfirm = async () => {
    const containerList: any = [];
    try {
      containers.map((container) => {
        containerList.push(container.id);
      });
      const response = await logisticsApi.selfAssign(containerList);

      if (response.success) {
        updateIsContainerScan(false);
        setLendConfig(containers);
        onDismiss();
        navigate.push('/self-assign');
        toaster.flashSuccess(t('SuccessfullyAdded'));
      } else {
        if (!response.success) {
          toaster.flashError(response.data.error);
          onDismiss();
        } else {
          toaster.flashError(t('GeneralError'));
          onDismiss();
        }
      }
      return;
    } catch (error) {
      updateScanning(true);
      toaster.flashError(t('GeneralError'));
    }
  };

  return (
    <>
      <IonContent>
        <IonSlides pager={true} options={slideOpts} ref={slidesRef}>
          <IonSlide>
            <div className="bg-background-blue h-full overflow-scroll scrollbar-hide py-4">
              <div className="px-4 mx-auto">
                <header className="mb-4">
                  <h2 className="text-xl text-center text-main font-montserrat font-bold">
                    {t('UserHasPaymentConfiguredTitle')}
                  </h2>
                  <h3 className="text-l text-center text-main">{t('ShowYourCodeAndOrder')}</h3>
                  <a
                    className="text-main underline font-bold text-xs block text-center cursor-pointer mt-xs"
                    onClick={() => {
                      navigate.push('how-it-works/takeaway');
                      onDismiss();
                    }}
                  >
                    {t('HowItWorksGuide')}
                  </a>
                </header>
                <NewQRCard
                  title={currentUser.name}
                  qr={qr}
                  onShare={() => console.log('share')}
                  onChat={onChat}
                />
                <Clipboard text={qr} onCopy={onCopy} />
                <Divider />
                <section className="mb-12">
                  <header className="mb-4">
                    <h2 className="text-xl text-center text-main font-montserrat font-bold">
                      {t('SelfAssignScan')}
                    </h2>
                    <Button className="mt-xs" onClick={() => handleNext()} secondary>
                      {t('ScanContainerInstructions')}
                    </Button>
                  </header>
                </section>
              </div>
            </div>
          </IonSlide>
          <IonSlide>
            {hasPermissions === false ? (
              <NoPermissionStyledQrDiv>
                <NoPermissionsPlaceHolder>
                  <Text color={Colors.white} align="center" config="small-screen-title">
                    {t('VideoStream.CameraPermissionsDenied')}
                  </Text>
                  <StyledButton secondary color={Colors.white} onClick={handleClick}>
                    {t('Settings')}
                  </StyledButton>
                </NoPermissionsPlaceHolder>
                <Placeholder
                  icon="assets/self-assign.svg"
                  title={t('SelfAssignTextTitle')}
                  description={t('ShowConfirmation')}
                />
              </NoPermissionStyledQrDiv>
            ) : (
              <StyledQrDiv
                id="qrscan"
                className="bg-background-blue h-full overflow-scroll scrollbar-hide py-4"
              >
                {isScanning && (
                  <>
                    {isScanOkay && (
                      <ScannedStatus src="assets/confirmationTick.svg" alt="scan-okay" />
                    )}
                    {isScanWrong && <ScannedStatus src="assets/alert.svg" alt="scan-wrong" />}
                    <Scan onError={() => handleError()} onScan={handleContainerScan} />
                  </>
                )}
                {isScanning ? (
                  <Cart
                    selfAssign
                    key={containers}
                    items={containers}
                    onConfirm={() => {
                      handleConfirm();
                    }}
                    onRemoveItem={(item: any) => removeContainer(item)}
                    options={{
                      placeholderDescription: t('SelfAssignTextTitle', {
                        total: containers.length,
                      }),
                      confirmSelectionButton: t('AssignContainersTitle'),
                    }}
                  />
                ) : (
                  <Placeholder
                    icon="assets/self-assign.svg"
                    title={t('SelfAssignTextTitle')}
                    description={t('ShowConfirmation')}
                  />
                )}
              </StyledQrDiv>
            )}
          </IonSlide>
        </IonSlides>
      </IonContent>
    </>
  );
};

export default QRModalSliders;
