import React, { useState } from 'react';
import styled from 'styled-components';
import { IonCheckbox, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import Text from '../../atoms/Text';
import { Spacing } from '../../../foundations';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  margin-top: ${Spacing.s};
  cursor: pointer;
  outline: 0;
`;

const StyledButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCheckbox = styled(IonCheckbox)`
  margin-right: ${Spacing.xxs};
`;

const StyledText = styled(Text)`
  display: flex;
  align-items: baseline;
`;

const LegalLink = styled(Text)`
  margin-left: ${Spacing.xxs};
`;

const StyledLabel = styled(IonLabel)`
  p {
    margin: 0;
    font-size: 12px;
  }
`;

const Checkbox = ({ name, isChecked, onCheck, ...props }: any) => {
  const [checked, setIsChecked] = useState(isChecked);
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StyledButton
        onClick={(event: any) => {
          event.preventDefault();
          setIsChecked(!checked);
          onCheck(!checked);
        }}
      >
        <StyledCheckbox name={name} checked={checked} {...props} />
      </StyledButton>
      <StyledLabel>
        <StyledText config="main-text">
          {t('Accept')}
          <LegalLink
            config="main-text-link"
            isLink
            target="_blank"
            rel="noopener noreferrer"
            href={t('PrivacyPolicyUrl')}
          >
            {t('Terms&Conditions')}
          </LegalLink>
        </StyledText>
      </StyledLabel>
    </Wrapper>
  );
};

export default Checkbox;
