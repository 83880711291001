import { IonImg, useIonViewWillLeave } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';

import { NumberSelect, Button, Navigation, Text } from '@bumerang-kit/components';
import ApiCaller from '../lib/ApiCaller';
import LendApi from '../api/LendApi';
import Toaster from '@bumerang-kit/helpers/Toaster/index';
import { Container, Page } from '@bumerang-kit/layouts';
import { Colors } from '@bumerang-kit/foundations';
import Analyzer from '../lib/common/helpers/Analyzer';
import { getCurrentSession, decodeJWT } from '../helpers/session';

const analyzer = new Analyzer();

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`;

const StyledImg = styled(IonImg)`
  height: 46px;
  width: 64px;
  min-height: 46px;
  min-width: 64px;
  margin-right: 10px;
  margin-left: 20px;
`;

const StyledSelect = styled(NumberSelect)`
  margin: 25px;
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
  width: 80%;
`;

const toaster = new Toaster();
const RestOptions: React.FC = () => {
  const [qrAuth, setQrAuth] = React.useState<any>(undefined);
  const [lendQuantity, setLendQuantity] = React.useState<number>(0);
  const [collectQuantity, setCollectQuantity] = React.useState<number>(0);
  const [inputMethod, setInputMethod] = React.useState<string>('');
  const { t } = useTranslation();
  const location = useLocation() as any;
  const navigate = useHistory();
  const currentUser = getCurrentSession();

  if (!qrAuth && location.state && location.state.token) {
    const qrUser = decodeJWT(location.state.token.token);
    setQrAuth(qrUser);
  }

  if (!inputMethod && location.state && location.state.inputMethod) {
    setInputMethod(location.state.inputMethod);
  }

  const correctLend = lendQuantity > 0 || collectQuantity > 0;

  useIonViewWillLeave(() => {
    resetValues();
  });

  const swap = () => {
    if (!qrAuth) {
      return;
    }

    const lendApi = new LendApi(new ApiCaller(currentUser.token));

    if (lendQuantity > 0) {
      lendApi
        .lend(lendQuantity, qrAuth.token, inputMethod)
        .then(() => {
          if (collectQuantity > 0) {
            lendApi
              .collect(collectQuantity, qrAuth.token)
              .then(() => {
                finish(true);
              })
              .catch((err) => doError(err));
          } else {
            finish(true);
          }
        })
        .catch((err) => doError(err));
    } else if (collectQuantity > 0) {
      lendApi
        .collect(collectQuantity, qrAuth.token)
        .then((result: any) => {
          if (result.error) doError(result.error);
          else finish(true);
        })
        .catch((err) => doError(err));
    }
  };

  const finish = (isSuccess: boolean) => {
    analyzer.trackUsedQR(qrAuth.id, inputMethod, currentUser.name, lendQuantity, collectQuantity);
    navigate.push('/restaurant');
    isSuccess
      ? toaster.flashSuccess(t('RestOptionsGenericPositiveFeedback'))
      : toaster.flashError(t('RestOptionsExpiredCodeFeedback'));
  };

  const resetValues = () => {
    setLendQuantity(0);
    setCollectQuantity(0);
    setQrAuth(undefined);
    if (location.state && location.state.token) location.state.token.token = '';
    if (location.state && location.state.inputMethod) location.state.inputMethod = '';
  };

  const doError = (err: any) => {
    if (err === 'TypeError: Failed to fetch')
      toaster.flashError(t('RestOptionsNoResponseFeedback'));
    else if (err === 'REFUND_ERROR') toaster.flashError(t('ReturnError'));
    else if (err === 'NOT_ENOUGH_LENDS') toaster.flashError(t('RestOptionsNotEnoughLends'));
    else toaster.flashError(t('RestOptionsGenericNegativeFeedback'));
  };

  return (
    <Page>
      <Navigation
        backgroundColor={Colors.white}
        onBack={() => navigate.go(-1)}
        onClose={() => {
          location.state.token.token = '';
          navigate.push('/restaurant');
        }}
      >
        <Text align="center" config="secondary-text-title">
          {t('RestOptionsQRFrom')} {qrAuth ? qrAuth.name : ''}
        </Text>
      </Navigation>
      <StyledContainer>
        <section>
          <StyledDiv>
            <StyledImg src={'assets/bowlWithFood.svg'} />
            <Text config="main-text">
              <Trans
                i18nKey={'RestOptionsHowManyLending'}
                defaults={'¿Cuántos Bûmerangs se está <bold>llevando?</bold>'}
                components={{ bold: <strong /> }}
              ></Trans>
            </Text>
          </StyledDiv>
          <StyledSelect quantity={lendQuantity} setQuantity={setLendQuantity} />
        </section>
        <section>
          <StyledDiv>
            <StyledImg src={'assets/fullBowl.svg'} />
            <Text config="main-text">
              <Trans
                i18nKey={'RestOptionsHowManyCollecting'}
                defaults={'¿Cuántos Bûmerangs está <bold>devolviendo?</bold>'}
                components={{ bold: <strong /> }}
              ></Trans>
            </Text>
          </StyledDiv>
          <StyledSelect quantity={collectQuantity} setQuantity={setCollectQuantity} />
        </section>
        <StyledButton disabled={!correctLend} onClick={() => swap()} type="submit" expand="full">
          <Text config="button-text" color="white">
            {t('Confirm')}
          </Text>
        </StyledButton>
      </StyledContainer>
    </Page>
  );
};

export default RestOptions;
