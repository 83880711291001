import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ENV } from '../env/env';

import { Container } from '@bumerang-kit/layouts';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import { Icon, Text } from '@bumerang-kit/components';
import { Chat } from '@bumerang-kit/helpers';

import AuthApi from '../api/AuthApi';
import ApiCaller from '../lib/ApiCaller';

import { getCurrentSession, removeCurrentSession } from '../helpers/session';
import { UserInfo } from '../lib/common/models/user';

import List from '@bumerang-kit/components/List';
import ListItem from '@bumerang-kit/components/ListItem';
import { useHistory } from 'react-router';
import { NavigationWrapper } from '@/features/shared';
import useUserEdit from '../bumerang-kit/helpers/Hooks/useEditUser';

const RightIcon = styled.div``;
const Separator = styled.div`
  height: 1px;
  background-color: ${Colors.mainColorDisabled};
  margin: ${Spacing.m} 0;
`;

const Header = styled(Text)`
  margin-bottom: ${Spacing.m};
`;

const Redirect = styled(Text)`
  margin-left: ${Spacing.xxs};
`;

const Profile: React.FC = () => {
  const currentUser: UserInfo = getCurrentSession();
  const { token, name, email } = currentUser;
  const navigate = useHistory();
  const [newsLetter, setNewsLetter] = useState<boolean>();
  const [previousNewsLetter, setPreviousNewsLetter] = useState<boolean>();
  const [isQRModalOpen, setIsQRModalOpen] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const editUserMutation = useUserEdit();
  const getUser = new AuthApi(new ApiCaller(token));
  const getUserNewsLetterCheck = async () => {
    try {
      const user: any = await getUser.getCurrentUser();
      if (user) {
        setNewsLetter(user.data.newsletter);
        setPreviousNewsLetter(user.data.newsletter);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(newsLetter, previousNewsLetter);
    if (newsLetter !== previousNewsLetter && previousNewsLetter !== undefined) {
      editUserMutation.mutate({ token, name, email, newsletter: newsLetter });
    }
    getUserNewsLetterCheck();
  }, [email, name, newsLetter, previousNewsLetter]);

  const logout = () => {
    const authApi = new AuthApi(new ApiCaller());
    authApi
      .logout()
      .then((e) => {
        console.log(e);
      })
      .catch((e) => console.log(e));

    removeCurrentSession();
    document.location.href = '/';
  };

  return (
    <NavigationWrapper
      onOpenQRModal={(isOpen: boolean) => setIsQRModalOpen(isOpen)}
      isQRModalOpen={isQRModalOpen}
      type="customer"
      active="profile"
    >
      <Container>
        <Header align="center" config="secondary-screen-title">
          {t('ProfileMyAccount')}
        </Header>

        <List>
          <ListItem
            label={currentUser?.name}
            icon="user"
            onClick={() => navigate.push('/profile/edit')}
            rightElement={
              <Redirect config="main-text-link" isLink>
                {t('Edit')}
              </Redirect>
            }
          />
          <ListItem label={currentUser?.email} icon="email" />
          {currentUser.status === 'confirmed' && (
            <ListItem
              onClick={() => navigate.push('/changePayment')}
              label={t('ProfilePayment')}
              icon="card"
              rightElement={
                <RightIcon>
                  <Icon name={'arrow'} />
                </RightIcon>
              }
            />
          )}
          <ListItem
            label={t('ProfileHistory')}
            onClick={() => navigate.push('/mytuppers')}
            icon="list"
          />
          <Separator />
          <ListItem
            label={t('ProfileHowDoesItWork')}
            onClick={() => navigate.push('/how-it-works')}
            icon="info"
          />
          <ListItem
            label={t('ProfileFAQ')}
            icon="question"
            onClick={() =>
              window.open(
                i18n.language === 'en'
                  ? 'https://en.youbumerang.com/faqs '
                  : 'https://www.youbumerang.com/faqs',
                '_blank',
                'noopener'
              )
            }
          />
          <ListItem
            label={t('ProfileContactUs')}
            icon="chat"
            onClick={() =>
              Chat.open(ENV.CRISP_ID, currentUser.email, currentUser.name, currentUser.token)
            }
          />
          <ListItem
            label={t('ProfileFollow')}
            icon="instagram"
            onClick={() =>
              window.open('http://www.instagram.com/youbumerang', '_blank', 'noopener')
            }
          />
          <ListItem
            label="Newsletter"
            icon="newsletter"
            showCheck
            newsLetterCheck={newsLetter}
            setNewsLetter={setNewsLetter}
          />
          <Separator />
          <ListItem label={t('ProfileCloseSession')} icon="leave" onClick={() => logout()} />
        </List>
      </Container>
    </NavigationWrapper>
  );
};

export default Profile;
