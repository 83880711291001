import React from 'react';
import styled from 'styled-components';

import { Text } from '@bumerang-kit/components';
import { Colors } from '@bumerang-kit/foundations';

const Block = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  background-color: ${Colors.backgroundBlue};
`;

const ApiHome = () => (
  <Block>
    <Text color={Colors.mainColor} config="app-title" align="center">
      Bûmerang API
    </Text>
  </Block>
);

export default ApiHome;
