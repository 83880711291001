import { Partner } from '@/features/shared';

const EARTH_RADIUS_IN_KM = 6371;

const deg2rad = (deg: number): number => {
  return deg * (Math.PI / 180);
};

type CalculateDistanceParams = {
  currentLatitude: number;
  currentLongitude: number;
  targetLatitude: number;
  targetLongitude: number;
};

export const calculateDistanceInKm = ({
  currentLatitude,
  currentLongitude,
  targetLatitude,
  targetLongitude,
}: CalculateDistanceParams) => {
  const dLat = deg2rad(targetLatitude - currentLatitude);
  const dLon = deg2rad(targetLongitude - currentLongitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(currentLatitude)) *
      Math.cos(deg2rad(targetLatitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return EARTH_RADIUS_IN_KM * c;
};

type Coords = {
  lat: number;
  lng: number;
};

export const sortPartnersByDistance = (position: Coords, partners: Partner[]) => {
  if (!partners || !partners.length) {
    return [];
  }

  return partners.sort((previousPartner: Partner, nextPartner: Partner) => {
    const distanceA = calculateDistanceInKm({
      currentLatitude: position.lat,
      currentLongitude: position.lng,
      targetLatitude: previousPartner.lat,
      targetLongitude: previousPartner.lng,
    });
    const distanceB = calculateDistanceInKm({
      currentLatitude: position.lat,
      currentLongitude: position.lng,
      targetLatitude: nextPartner.lat,
      targetLongitude: nextPartner.lng,
    });
    return distanceA - distanceB;
  });
};

export const getDistanceInKmOrM = (props: CalculateDistanceParams) => {
  const { currentLatitude, currentLongitude, targetLatitude, targetLongitude } = props;

  const distance = calculateDistanceInKm({
    currentLatitude,
    currentLongitude,
    targetLatitude,
    targetLongitude,
  });
  return Math.round(distance) > 0 ? distance.toFixed(2) + 'km' : Math.round(distance * 1000) + 'm';
};
