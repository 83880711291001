import React from 'react';
import styled from 'styled-components';
import { Card, Icon, Line, Text } from '../../index';
import { Colors, Spacing } from '../../../foundations';

export interface Stat {
  name: string;
  metric: number | string;
  unit?: string;
  highlighted: boolean;
  nameTextConfig?: string;
  unitTextConfig?: string;
  metricTextConfig?: string;
  danger?: boolean;
}

const Metric = styled.div`
  white-space: nowrap;
`;

const LimitMetric = styled(Metric)`
  width: calc(90%);
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.m};
`;

const Item = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RightItem = styled(Item)`
  justify-content: right;
`;

const List = styled.ul`
  width: 100%;
  padding: 0;
  list-style: none;
  margin: 0;
`;

const HeaderMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 0 ${Spacing.s} ${Spacing.xxs} 0;
`;

const IconContainer = styled.div`
  margin-right: ${Spacing.xs};
`;

const MultipleStatCard = ({
  iconName = undefined,
  title = undefined,
  stats,
  sameLine = true,
}: {
  iconName?: string;
  title?: string;
  stats: Stat[];
  sameLine?: boolean;
}) => {
  return (
    <StyledCard full>
      <List>
        {title && (
          <HeaderMain>
            {iconName && (
              <IconContainer>
                <Icon name={iconName} />
              </IconContainer>
            )}
            <Text config="main-text-title" bold align="left">
              {title}
            </Text>
          </HeaderMain>
        )}
        {stats.map((stat: Stat, index) => {
          return (
            <>
              <Item>
                <Text
                  config={stat.nameTextConfig ? stat.nameTextConfig : 'main-text'}
                  bold={stat.highlighted}
                  align="left"
                >
                  {stat.name}
                </Text>
                {sameLine && (
                  <Metric>
                    <Text
                      config={
                        stat.metricTextConfig ? stat.metricTextConfig : 'secondary-screen-title'
                      }
                      bold={stat.highlighted}
                      align="right"
                      color={stat.danger ? Colors.error : Colors.black}
                    >
                      {stat.metric}
                    </Text>
                    {stat.unit && (
                      <Text
                        config={stat.unitTextConfig ? stat.unitTextConfig : 'small-text'}
                        align="right"
                      >
                        {stat.unit}
                      </Text>
                    )}
                  </Metric>
                )}
              </Item>
              {!sameLine && (
                <>
                  <RightItem>
                    <LimitMetric>
                      <Text
                        config={
                          stat.metricTextConfig ? stat.metricTextConfig : 'secondary-screen-title'
                        }
                        bold={stat.highlighted}
                        align="right"
                        color={stat.danger ? Colors.error : Colors.black}
                        limit
                      >
                        {stat.metric}
                      </Text>
                      {stat.unit && (
                        <Text
                          config={stat.unitTextConfig ? stat.unitTextConfig : 'small-text'}
                          align="right"
                        >
                          {stat.unit}
                        </Text>
                      )}
                    </LimitMetric>
                  </RightItem>
                </>
              )}

              {index !== stats.length - 1 && <Line yMargin={Spacing.xxs} />}
            </>
          );
        })}
      </List>
    </StyledCard>
  );
};

export default MultipleStatCard;
