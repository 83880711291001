import React from 'react';
import styled from 'styled-components';

interface WrapperProps {
  url: string;
}

const StyledDiv = styled.div`
  max-height: 250px;
  min-height: 250px;
  position: relative;
  background-image: url(${({ url }: WrapperProps) => url});
  background-position: center;
  background-size: cover;
`;

const ImgSlide = ({ imgUrl, children }: any) => {
  return <StyledDiv url={imgUrl}>{children}</StyledDiv>;
};

export default ImgSlide;
