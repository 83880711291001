import Icon from '@bumerang-kit/components/Icon';
import React from 'react';
import { ProductTypeGroup } from '@/features/shared';

const getProperties = (productGroup: Array<ProductTypeGroup>): any => {
  if (productGroup.length > 1) {
    return {
      iconType: 'none',
      name: 'mix_marker',
    };
  }
  const type = productGroup[0].toLowerCase();
  if (type === 'bowl') {
    return {
      iconType: 'primary',
      name: 'bowl_marker',
    };
  }

  if (type === 'cup') {
    return {
      iconType: 'secondary',
      name: 'cup_marker',
    };
  }
};

type MapMarkerProps = {
  productGroup?: Array<ProductTypeGroup>;
  onClick?: (event: any) => void;
  lat: number;
  lng: number;
  selected?: boolean;
};

const MapMarker = (props: MapMarkerProps) => {
  const { productGroup, onClick, selected } = props;
  if (!productGroup || productGroup.length === 0) return null;

  const { iconType, name } = getProperties(productGroup);

  return (
    <div className="h-12 w-12 -translate-y-1/2 -translate-x-1/2">
      <Icon
        size={selected ? '70px' : undefined}
        onClick={(event: any) => onClick && onClick(event)}
        name={name}
        type={iconType}
      />
    </div>
  );
};

export default MapMarker;
