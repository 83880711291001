import Axios, { AxiosRequestConfig } from 'axios';

import { useNotificationStore } from '../stores/notifications';
import storage from '../utils/storage';
import Settings from './settings';

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();
  const apiVersion = Settings.getAPIVersion();

  if (token) {
    if (config.headers) config.headers.Authorization = `Bearer ${token}`;
  }

  if (config.headers) config.headers.Accept = 'application/json';

  config.params = {
    ...config.params,
    version: apiVersion,
    token,
  };

  return config;
}

export const axios = Axios.create({
  baseURL: Settings.getApiURL(),
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;
    useNotificationStore.getState().addNotification({
      type: 'error',
      title: 'Error',
      message,
    });

    return Promise.reject(error);
  }
);
