import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationES from './es/translationES.json';
import translationEN from './en/translationEN.json';

export const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
};

export const language: string = navigator.language;

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources,
  lng: language,
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false,
  },
});
