import * as React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

import { Colors } from '@bumerang-kit/foundations';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      background: Colors.white,
      color: Colors.mainColor,
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: Colors.mainColorDisabled,
      },
      '::hover': {
        border: '0px',
      },
    },
    invalid: {
      color: Colors.error,
      iconColor: Colors.error,
    },
  },
};

interface CardSectionProps {
  setCard: React.Dispatch<React.SetStateAction<boolean>>;
}

function CardSection({ setCard }: CardSectionProps) {
  const checkIfCardComplete = (cardElementEvent: StripeCardElementChangeEvent) => {
    if (cardElementEvent.complete) {
      setCard(true);
    } else {
      setCard(false);
    }
  };

  return (
    <>
      <div className="text-xs text-gray-500 mb-2"> Añadir método de pago</div>
      <div className="rounded-md bg-white border border-main-disabled p-2">
        <CardElement options={CARD_ELEMENT_OPTIONS} onChange={checkIfCardComplete} />
      </div>
    </>
  );
}

export default CardSection;
