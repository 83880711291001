import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import PendingHistory from './PendingHistory';
import ReturnedHistory from './ReturnedHistory';
import { Tabs } from '../../molecules';
import { Container } from '../../../layouts';

const StyledTabs = styled(Tabs)`
  position: absolute;
  top: var(--ion-safe-area-top, 0);
  margin-top: 10px;
  border: 1px solid red;
`;

const StyledDiv = styled.div`
  position: relative;
  height: calc(100% - 60px);
`;

const StyledContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
`;
const TuppersHistory = ({ lends }: { lends: { finished: any; notFinished: any } }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [activeTab, setActiveTab] = useState('pending');
  const { notFinished, finished } = lends;

  const handleChange = (tabIndex: number) => {
    tabIndex === 0 ? setActiveTab('pending') : setActiveTab('returned');
    window.scroll(0, 0);
    setTabIndex(tabIndex);
  };

  const handleClick = (tabName: string) => {
    tabName === 'pending' ? setTabIndex(0) : setTabIndex(1);
    window.scroll(0, 0);
    setActiveTab(tabName);
  };

  return (
    <StyledDiv>
      <StyledTabs
        onClick={(tabName: string) => handleClick(tabName)}
        type="tuppers"
        active={activeTab}
      />
      <StyledContainer>
        <SwipeableViews
          animateTransitions={false}
          disableLazyLoading
          index={tabIndex}
          animateHeight={false}
          containerStyle={{ height: 'auto' }}
          onChangeIndex={handleChange}
          springConfig={{ duration: '0s', easeFunction: '...', delay: '0s' }}
        >
          {activeTab === 'pending' && <PendingHistory list={notFinished} />}
          {activeTab === 'returned' && <ReturnedHistory list={finished} />}
        </SwipeableViews>
      </StyledContainer>
    </StyledDiv>
  );
};

export default TuppersHistory;
