import { useInfiniteQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { Coords } from '@/features/shared';

type RequestParams = {
  distance?: Coords;
  limit?: number;
  from?: number;
  lends?: boolean;
  order?: string;
};

type UseActivePartnerParams = {
  requestParams?: RequestParams;
  config?: any;
};

const mapPositionToString = (position: Coords) => {
  return `${position.lat},${position.lng}`;
};

export const getActivePartners = (params?: RequestParams): Promise<any> => {
  const restaurantParams = {
    params: params
      ? {
          distance: params.distance ? mapPositionToString(params.distance) : undefined,
          limit: params.limit ? params.limit : 10,
          from: params.from ? params.from : 0,
          lends: 'lends' in params ? params.lends : undefined,
          order: 'order' in params ? params.order : undefined,
        }
      : {},
  };

  return axios.get('/v2/restaurant', restaurantParams);
};

export default function useActivePartners(props: UseActivePartnerParams) {
  const { requestParams, config } = props;

  return useInfiniteQuery({
    queryKey: ['activePartners', requestParams?.distance],
    queryFn: () => getActivePartners(requestParams),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    ...config,
  });
}
