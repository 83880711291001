import React from 'react';
import styled from 'styled-components';
import { Button, Text } from '../../index';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Counter = styled.div`
  position: relative;
  width: 76px;
  height: 76px;
  margin: 20px;
  border: solid 2px #000000;
  border-radius: 50%;
`;

const Count = styled(Text)`
  display: block;
  font-size: 32px;
  font-family: 'Montserrat Regular', sans-serif;
  color: #000000;
  margin-top: 15px;
`;

const NumberSelect = ({ quantity, setQuantity }: any) => {
  const addTupper = () => {
    const lendsLimit = 100;
    if (quantity < lendsLimit) {
      setQuantity(quantity + 1);
    }
  };

  return (
    <StyledDiv>
      <Button fab onClick={() => setQuantity(Math.max(quantity - 1, 0))}>
        <Text config="onboarding-title" size="large" color="white">
          -
        </Text>
      </Button>
      <Counter>
        <Count align="center">{quantity}</Count>
      </Counter>
      <Button fab onClick={() => addTupper()}>
        <Text config="onboarding-title" color="white">
          +
        </Text>
      </Button>
    </StyledDiv>
  );
};

export default NumberSelect;
