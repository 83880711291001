import { Button } from '@bumerang-kit/components';
import { Colors } from '@bumerang-kit/foundations';
import { IonModal } from '@ionic/react';
import styled from 'styled-components';

interface HomeModalProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  showUpdate: boolean;
  showButton: boolean;
  modalText: string;
  modalTitle: string;
  modalImage: string;
}

const HomeModalStyle = styled(IonModal)`
  .ion-page {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  p {
    color: ${Colors.mainColor};
    text-align: center;
    margin: 15px;
  }

  img {
    width: 95px;
    height: 95px;
    margin: 15px;
  }

  div .container-modal {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: ${Colors.paleBlue};
    border-radius: 20px;
  }
`;

const HomeModal = ({
  isOpen,
  setIsOpen,
  showButton,
  showUpdate,
  modalImage,
  modalText,
  modalTitle,
}: HomeModalProps) => {
  return (
    <HomeModalStyle isOpen={isOpen} cssClass="my-custom-class">
      {showUpdate ? (
        <div className="container-modal px-3">
          <img src={modalImage} alt="icon" />
          <h1 className="text-[28px] text-center text-main font-montserrat font-bold">
            {modalTitle}
          </h1>
          <p>{modalText}</p>
        </div>
      ) : (
        <div className="container-modal px-3">
          <img alt="alert-icon" src={modalImage} />
          <h1 className="text-[28px] text-center text-main font-montserrat font-bold">
            {modalTitle}
          </h1>
          <p>{modalText}</p>
          {showButton && (
            <Button onClick={() => setIsOpen(false)} primary>
              Cerrar
            </Button>
          )}
        </div>
      )}
    </HomeModalStyle>
  );
};

export default HomeModal;
