import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { IonFooter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Keyboard } from '@capacitor/keyboard';

import { Button, Input, Line, LoginButtons, Navigation, Text } from '@bumerang-kit/components';
import { Page, Container } from '@bumerang-kit/layouts';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import { useApple, useGoogle, useLogin, usePlatform } from '@bumerang-kit/helpers';

import './styles/General.css';
import './styles/Login.css';

const StyledForm = styled.form`
  margin-top: ${Spacing.xs};
  margin-bottom: ${Spacing.xxxxxl};
`;

const CreateAccountWrapper = styled(IonFooter)`
  display: flex;
  width: 95%;
  justify-content: center;
  margin-top: ${Spacing.m};
  margin-bottom: 30px;
  position: absolute;
  bottom: ${Spacing.xs};
`;

const StyledButton = styled(Button)`
  margin-top: ${Spacing.m};
  margin-bottom: ${Spacing.s};
`;

const StyledText = styled(Text)`
  align-items: center;
  display: flex;
`;

const StyledPage = styled(Page)`
  background-color: ${Colors.backgroundBlue};
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const RegisterLink = styled(Text)`
  margin-left: ${Spacing.xxs};
`;

const Login = () => {
  const navigate = useHistory();
  const { data: platform } = usePlatform();
  const loginMutation = useLogin();
  const { refetch: getGoogle } = useGoogle();
  const { refetch: getApple } = useApple();
  const { t } = useTranslation();
  const [email, setEmail] = React.useState<string>();
  const [isFooterShown, setIsFooterShown] = React.useState(true);
  const [password, setPassword] = React.useState<string>();
  const isFormValid = !email || !password;

  const login = async (provider: string) => {
    const { data } = provider === 'Google' ? await getGoogle() : await getApple();

    loginMutation.mutate({ data, provider, platform });
  };

  const doAuth = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!email || !password) {
      return;
    }

    loginMutation.mutate({
      data: { email, password },
      provider: 'Email',
      platform,
    });
  };

  React.useEffect(() => {
    if (Keyboard) {
      Keyboard.addListener('keyboardWillShow', function () {
        setIsFooterShown(false);
      });

      Keyboard.addListener('keyboardWillHide', function () {
        setIsFooterShown(true);
      });
      return () => {
        Keyboard.removeAllListeners();
      };
    }
  });

  return (
    <StyledPage>
      <Navigation backgroundColor={Colors.backgroundBlue} onBack={() => navigate.go(-1)}>
        {t('LoginTitle')}
      </Navigation>
      <StyledContainer backgroundColor={Colors.backgroundBlue}>
        <LoginButtons onGoogle={() => login('Google')} onApple={() => login('Apple')}>
          <Line
            isOption
            backgroundColor={Colors.backgroundBlue}
            color={Colors.black}
            yMargin={Spacing.l}
          />
        </LoginButtons>

        <StyledForm onSubmit={(event) => doAuth(event)}>
          <Input
            label="Email"
            name="email"
            id="email"
            type="email"
            value={email}
            onChange={(e: any) => setEmail(e.detail.value!)}
            required
          />
          <Input
            label={t('Password')}
            id="password"
            name="password"
            required
            type="password"
            value={password}
            onChange={(e: any) => setPassword(e.detail.value!)}
            clearInput
          />
          <StyledButton type="submit" expand="full" disabled={isFormValid}>
            {t('ContinueWithEmail')}
          </StyledButton>
          <Text align="center" config="main-text-link" link="/forgotpassword" isLink>
            {t('LoginForgotPassword')}
          </Text>
          <input type="submit" className="submit-enter" />
        </StyledForm>
        {isFooterShown && (
          <CreateAccountWrapper className="ion-no-border">
            <StyledText config="main-text" align="center">
              {t('LoginSignUpSuggestion')}
              <RegisterLink config="main-text-link" link="/register" isLink>
                {t('CreateAccount')}
              </RegisterLink>
            </StyledText>
          </CreateAccountWrapper>
        )}
      </StyledContainer>
    </StyledPage>
  );
};

export default Login;
