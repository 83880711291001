import { Colors } from '@bumerang-kit/foundations';
import * as React from 'react';
import styled from 'styled-components';

import Icon from '../atoms/Icon';

export type ListItemProps = {
  label: string;
  icon: string;
  onClick?: () => void;
  rightElement?: React.ReactNode;
  showCheck?: boolean;
  newsLetterCheck?: any;
  setNewsLetter?: any;
};

const StyledInput = styled.input`
  position: absolute;
  right: 40px;
  margin: 0;
`;

const ListItem = (props: ListItemProps) => {
  const { label, icon, onClick, rightElement, showCheck, newsLetterCheck, setNewsLetter } = props;
  const onClickClasses = onClick && `cursor-pointer`;
  return (
    <a
      onClick={onClick}
      className={`flex items-center decoration-none justify-between px-2 py-4 ${onClickClasses}`}
    >
      <div className="flex items-center m-0">
        <Icon name={icon} color={Colors.actionableGrey} />
        <span className="text-base text-black ml-4">{label}</span>
        {showCheck && (
          <StyledInput
            type="checkbox"
            checked={newsLetterCheck}
            onClick={(e: any) => {
              setNewsLetter(e.target.checked);
            }}
          />
        )}
      </div>
      {rightElement}
    </a>
  );
};

export default ListItem;
