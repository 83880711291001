import { IonGrid, IonContent, IonRow, IonButton, IonTitle, IonText } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '@bumerang-kit/layouts';

import './styles/Registered.css';

const Registered: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <IonContent scrollY={false}>
        <IonGrid className="payment-grid">
          <IonRow>
            <IonTitle className="center title-card">{t('AccountCreatedTitle')}</IonTitle>
            <IonText className="explanation center">
              <p className="text-in">{t('AccountCreatedDescription')}</p>
            </IonText>
          </IonRow>
        </IonGrid>
        <IonButton class="button-round reg-button center" expand="full" routerLink="/">
          {t('Back')}
        </IonButton>
      </IonContent>
    </Page>
  );
};

export default Registered;
