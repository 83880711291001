import ApiCaller from '../lib/ApiCaller';

class CustomerApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getCustomerByUsername(username: string): Promise<any> {
    return this.apiCaller.call(`/v2/customer/${username}`, 'GET');
  }
}

export default CustomerApi;
