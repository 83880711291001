import React from 'react';
import { Redirect, Route, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { isLogin } from '../helpers/session';
import { generateOAuthErrorFromUrl, getOauthQueryAttributes, isValidOauth } from '../helpers/oauth';

const PrivateOauthRoute = ({ component: Component, as, ...rest }: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isValidOauthResult = isValidOauth(location.search, as);

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (isLogin() && isValidOauthResult) {
          return <Component {...props} />;
        }

        if (isValidOauthResult) {
          return <Redirect to={`/oauth?${getOauthQueryAttributes(location.search)}`} />;
        }

        return (
          <Redirect
            to={`/oauth/error?authError=${generateOAuthErrorFromUrl(t, location.search, as)}`}
          />
        );
      }}
    />
  );
};

export default React.memo(PrivateOauthRoute);
