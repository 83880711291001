import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { Text } from '../../atoms';
import { Spacing, Colors } from '../../../foundations';
import { use100vh } from 'react-div-100vh';
import { useTranslation } from 'react-i18next';

interface BackgroundImageProps {
  url: string;
  isSmallImage: boolean;
}

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: ${(props: { isMobile: boolean; height: any }) =>
    props.isMobile ? `${props.height}px` : '670px'};
  min-height: ${(props: { isMobile: boolean; height: any }) =>
    props.isMobile ? `${props.height}px` : '670px'};
  position: relative;
  overflow-y: auto;
`;

const Header = styled.header`
  display: flex;
  justify-content: flex-end;
  padding: ${Spacing.m};
`;

const BackgroundImage = styled.div`
  flex-grow: 3;
  background-image: url(${({ url }: BackgroundImageProps) => url});
  background-position: center;
  background-size: cover;
  min-height: ${({ isSmallImage }: BackgroundImageProps) => (isSmallImage ? '250px' : '350px')};
  max-height: ${({ isSmallImage }: BackgroundImageProps) => (isSmallImage ? '250px' : '350px')};
`;

const Wrapper = styled.div`
  flex-grow: 1;
  position: relative;
  padding: ${Spacing.m};
  background-color: ${Colors.white};
`;

const Slide = ({ onSkip, onSkipLink, children, imgUrl, isSmallImage }: any) => {
  const isSmallMobile = useMediaQuery({ query: '(max-width: 320px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 684px)' });
  const height = use100vh();
  const { t } = useTranslation();
  if (isSmallImage == null) isSmallImage = isSmallMobile;
  return (
    <Container isMobile={isMobile} height={height}>
      <BackgroundImage url={imgUrl} isSmallImage={isSmallImage}>
        {onSkip && (
          <Header>
            <Text config="main-text-link" isLink link={onSkipLink} action={onSkip}>
              {t('SlideNext')}
            </Text>
          </Header>
        )}
      </BackgroundImage>
      {children && <Wrapper>{children}</Wrapper>}
    </Container>
  );
};

export default Slide;
