import ApiCaller from '../lib/ApiCaller';

class ImpactApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getTotalLends(): Promise<any> {
    return this.apiCaller.call('/v1/impact/total');
  }

  getTopThreeRestaurants(): Promise<any> {
    return this.apiCaller.call('/v2/logistics/impact/bumerang/restaurant_top');
  }

  getGlobalPosition(): Promise<any> {
    return this.apiCaller.call('/v1/impact/globalPosition');
  }

  getLendsByTime(): Promise<any> {
    return this.apiCaller.call('/v2/logistics/impact/restaurant/lent', 'GET');
  }

  getCollectedByTime(): Promise<any> {
    return this.apiCaller.call('/v2/logistics/impact/restaurant/collected', 'GET');
  }

  getTotalDifferentCustomer(): Promise<any> {
    return this.apiCaller.call('/v1/impact/getTotalDifferentCustomers', 'GET');
  }

  getCurrentStock(): Promise<any> {
    return this.apiCaller.call('/v2/logistics/impact/stock', 'GET');
  }

  getCustomerFootprint(): Promise<any> {
    return this.apiCaller.call('/v2/logistics/impact/customer/footprint', 'GET');
  }

  getRestaurantFootprint(): Promise<any> {
    return this.apiCaller.call('/v2/logistics/impact/restaurant/footprint', 'GET');
  }
}

export default ImpactApi;
