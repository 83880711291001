import {
  IonContent,
  IonButton,
  IonGrid,
  IonRow,
  IonText,
  IonBackButton,
  IonCol,
} from '@ionic/react';

import React from 'react';
import './styles/AddCard.css';
import './styles/ShareQr.css';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Settings from '../lib/settings';
import ApiCaller from '../lib/ApiCaller';
import PaymentApi from '../api/PaymentApi';
import { Page } from '@bumerang-kit/layouts';
import { getCurrentSession } from '@/helpers/session';

import { Clipboard } from '@capacitor/clipboard';

const ShareQr: React.FC = () => {
  const [link, setLink] = React.useState<string>('');
  const [status, setStatus] = React.useState<string>();
  const currentUser = getCurrentSession();
  const { t } = useTranslation();

  React.useEffect(() => {
    const paymentApi = new PaymentApi(new ApiCaller(currentUser.token));
    paymentApi.getPaymentStatus().then((status: string) => {
      setStatus(status);
      if (status === 'configured') {
        setLink(Settings.getPublicURL() + currentUser.username);
      }
    });
  }, []);

  const copyToClipBoard = () => {
    Clipboard.write({
      string: link,
    });
  };
  return (
    <Page>
      <IonContent scrollY={false}>
        <IonBackButton class="pay-backbutton" defaultHref="/" />
        <IonGrid className="shareqr-grid">
          <IonRow>
            <IonCol class="textCenter">
              <a target="_blank" rel="noopener noreferrer" href={link}>
                {link}
              </a>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="textCenter">
              <IonButton onClick={copyToClipBoard}>{t('CopyLink')}</IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText className="explanation center">
                <p className="text-in">{t('CopyLinkExplanation')}</p>
                <p className="text-in">{t('CheckTheFAQS')}</p>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        {status && status !== 'configured' ? <Redirect to="/payment" /> : false}
      </IonContent>
    </Page>
  );
};

export default ShareQr;
