import useCurrentLocation from '../../../hooks/useCurrentLocation';
import { Coords } from '@/features/shared';

type Response = {
  position?: Coords;
  isLoading: boolean;
  status: string;
};

export const useGetCurrentLocation = (): Response => {
  const { data, status, isLoading }: any = useCurrentLocation({});

  return {
    position: data ? { lat: data.coords.latitude, lng: data.coords.longitude } : undefined,
    isLoading,
    status,
  };
};
