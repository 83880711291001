import jwt_decode from 'jwt-decode';
const USER_KEY = 'currentSession';
const USER_KEY2 = 'currentSessionV2';
const FALLBACK_KEY = '_cap_user';

const setCurrentSession = (token: string): void => {
  localStorage.setItem(USER_KEY, token);
};

const setCurrentSession2 = (token: string): void => {
  localStorage.setItem(USER_KEY2, token);
};

const getCurrentSession2 = (): any => {
  const currentSession = localStorage.getItem(USER_KEY2);
  const fallbackUser = localStorage.getItem(FALLBACK_KEY);

  if (currentSession) {
    localStorage.removeItem(FALLBACK_KEY);
    const v2User: any = jwt_decode(currentSession);

    return {
      ...v2User,
      type: v2User.user_type,
      stripeId: v2User.stripe_id,
      token: currentSession,
    };
  }

  // TODO: Fallback para que no se les cierre la sesión a la gente que esté ya logueada. En unos meses se podría quitar.
  if (fallbackUser) {
    const fallbackUserJson = JSON.parse(fallbackUser);
    setCurrentSession(fallbackUserJson.token);
    return fallbackUserJson;
  }

  return {
    id: '',
    name: 'Invitado',
    email: '',
    token: 'GUEST_TOKEN',
    type: 'guest',
    status: 'pending',
    stripeId: '',
    provider: 'Email',
    username: '',
  };
};

const getCurrentSession = (): any => {
  const currentSession = localStorage.getItem(USER_KEY);
  const fallbackUser = localStorage.getItem(FALLBACK_KEY);

  try {
    if (currentSession) {
      localStorage.removeItem(FALLBACK_KEY);
      return {
        ...jwt_decode(currentSession),
        token: currentSession,
      };
    }
  } catch (e) {
    console.log('Error when trying to get session', e);
  }

  // TODO: Fallback para que no se les cierre la sesión a la gente que esté ya logueada. En unos meses se podría quitar.
  if (fallbackUser) {
    const fallbackUserJson = JSON.parse(fallbackUser);
    setCurrentSession(fallbackUserJson.token);
    return fallbackUserJson;
  }

  return {
    id: '',
    name: 'Invitado',
    email: '',
    token: 'GUEST_TOKEN',
    type: 'guest',
    status: 'pending',
    stripeId: '',
    provider: 'Email',
    username: '',
  };
};

const decodeJWT = (jwt: string): any => {
  return {
    ...jwt_decode(jwt),
    token: jwt,
  };
};

const isLogin = (): boolean => {
  const currentSession = getCurrentSession();
  return currentSession.id !== '' && currentSession.token !== 'GUEST_TOKEN';
};

const removeCurrentSession = (): void => {
  localStorage.removeItem(USER_KEY);
};

export {
  setCurrentSession,
  setCurrentSession2,
  getCurrentSession,
  getCurrentSession2,
  removeCurrentSession,
  isLogin,
  decodeJWT,
};
