import * as React from 'react';
import { Coords, getDistanceInKmOrM, Partner } from '@/features/shared';
import { PartnerSchedule } from '@bumerang-kit/components';

type HeaderProps = {
  partner: Partner;
  onDirections: () => void;
  position?: Coords;
};
const Header = (props: HeaderProps) => {
  const { partner, position, onDirections } = props;

  return (
    <div>
      <span className="text-xxs text-main">
        {partner.type && partner.type[0].charAt(0).toUpperCase() + partner.type[0].slice(1)}
      </span>
      <h1 className="text-3xl font-bold font-montserrat text-main leading-7 my-2">
        {partner.name}
      </h1>
      <div className="inline-block leading-0	">
        <a className="text-sm leading-0  text-black underline" onClick={() => onDirections()}>
          {`${partner.address}`}
        </a>

        {position && (
          <p className="inline-block text-sm leading-0 text-black mb-2">
            &nbsp;
            {`|
            ${getDistanceInKmOrM({
              currentLatitude: position.lat,
              currentLongitude: position.lng,
              targetLatitude: partner.lat,
              targetLongitude: partner.lng,
            })}`}
          </p>
        )}

        <PartnerSchedule hours={partner.hours} />
      </div>
    </div>
  );
};

export default Header;
