import styled from 'styled-components';
import { IonCol, IonImg, IonRow } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Trans, useTranslation } from 'react-i18next';
import HomeModal from '../features/home/components/HomeModal/HomeModal';
import { LoginButtons, Button, Text } from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import { useApple, useGoogle, useLogin, usePlatform } from '@bumerang-kit/helpers';
import './styles/General.css';
import './styles/Splash.css';
import { language } from '@/locales/languageDetector';
import React, { useState } from 'react';
import ApiLambda from '@/lib/ApiLambda';

const FindUsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${Spacing.m};
`;

const StyledText = styled(Text)`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
`;

const Redirect = styled(Text)`
  margin-left: ${Spacing.xxs};
`;

const ButtonsWrapper = styled.div`
  margin-top: ${Spacing.xxxxl};
`;

const Logo = styled(IonImg)`
  margin-bottom: ${Spacing.l};
`;

const StyledTitle = styled(Text)`
  margin-bottom: ${Spacing.xs};
`;

const StyledPage = styled(Page)`
  background-color: ${Colors.backgroundBlue};
`;

const Splash = () => {
  const { data: platform } = usePlatform();
  const { refetch: getGoogle } = useGoogle();
  const { refetch: getApple } = useApple();
  const { t } = useTranslation();
  const productApi = new ApiLambda();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalImage, setModalImage] = useState<string>('');
  const [checkStatus, setCheckStatus] = useState<boolean>(false);

  if (Capacitor.isPluginAvailable('StatusBar')) {
    StatusBar.setStyle({ style: Style.Light });
    StatusBar.setBackgroundColor({ color: Colors.backgroundBlue });
  }

  async function checkAppStatus() {
    try {
      const response = await productApi.call(
        'https://cp6iwu2ba3.execute-api.eu-west-3.amazonaws.com/Prod'
      );
      const data = response[0];
      console.log(data);
      const theTitle = (): string => {
        switch (language) {
          case 'en':
            return data.titleEN;
          case 'es':
            return data.titleES;
          default:
            return data.title;
        }
      };

      const theText = (): string => {
        switch (language) {
          case 'en':
            return data.textEN;
          case 'es':
            return data.textES;
          default:
            return data.text;
        }
      };
      setModalImage(data.icon);
      setModalText(theText);
      setModalTitle(theTitle);
      if ((data.platform === platform || data.platform === 'all') && data.app === 'all') {
        setShowModal(true);
        if (
          data.flag === 'warning_custom' ||
          data.flag === 'warning_maintenance' ||
          data.flag === 'warning_update'
        ) {
          setShowButton(true);
        }
        if (data.flag === 'warning_update' || data.flag === 'update') {
          setShowUpdate(true);
        }
      }
      setCheckStatus(true);
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    if (!checkStatus) checkAppStatus();
  });

  const loginMutation = useLogin();

  const login = async (provider: string) => {
    const { data } = provider === 'Google' ? await getGoogle() : await getApple();
    loginMutation.mutate({ data: data, provider, platform });
  };

  return (
    <>
      {checkStatus && (
        <HomeModal
          isOpen={showModal}
          setIsOpen={setShowModal}
          modalImage={modalImage}
          modalText={modalText}
          modalTitle={modalTitle}
          showButton={showButton}
          showUpdate={showUpdate}
        />
      )}
      <StyledPage>
        <Container backgroundColor={Colors.backgroundBlue}>
          <IonRow>
            <IonCol size="12">
              <Logo className="centered-image splash-logo" src="assets/logo-con-nombre.svg" />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <StyledTitle align="center" config="app-title" color={Colors.mainColor}>
                {t('SplashTitle')}
              </StyledTitle>
              <Text align="center" config="main-text" color={Colors.mainColor}>
                <Trans
                  i18nKey={'SplashSubtitle'}
                  defaults={
                    'Envases reutilizables para tu comida takeaway o delivery.<bold> 100% gratuito.</bold>'
                  }
                  components={{ bold: <strong /> }}
                />
              </Text>
            </IonCol>
          </IonRow>
          <ButtonsWrapper>
            <LoginButtons onGoogle={() => login('Google')} onApple={() => login('Apple')}>
              <Button secondary expand="full" routerLink="/login">
                {t('ContinueWithEmail')}
              </Button>
            </LoginButtons>
            <FindUsWrapper>
              <StyledText config="main-text">
                <Redirect config="main-text-link" link="/guestMap" isLink>
                  {t('SplashLookRestaurantsUp')}
                </Redirect>
              </StyledText>
            </FindUsWrapper>
          </ButtonsWrapper>
        </Container>
      </StyledPage>
    </>
  );
};

export default Splash;
