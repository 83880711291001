import React from 'react';
import { IonModal } from '@ionic/react';
import styled from 'styled-components';

import Navigation from '../../molecules/Navigation';
import { Spacing, Colors } from '../../../foundations';

import './Modal.css';

interface ModalProps {
  children: React.ReactNode;
  title?: string;
  isVisible?: boolean;
  onClose: (currentStatus: boolean) => void;
  size?: string;
  noPadding?: boolean;
  iconAction?: () => void;
  iconName?: string;
}

const StyledModal = styled(IonModal)`
  padding-top: var(--ion-safe-area-top, 24px);
`;

const Wrapper: any = styled.div`
  padding: ${(props: { size: string; noPadding: boolean }) => {
    if (props.noPadding) return `0`;
    return props.size === 'small' ? `0 ${Spacing.m}` : `${Spacing.l} ${Spacing.m}`;
  }};
  overflow-y: auto;
  background-color: ${Colors.backgroundBlue};
  height: 100%;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Modal = ({
  children,
  title = '',
  isVisible = false,
  onClose,
  size = 'default',
  noPadding = false,
  iconAction,
  iconName,
}: ModalProps) => {
  const close = () => onClose(isVisible);

  return (
    <StyledModal swipeToClose onDidDismiss={close} isOpen={isVisible}>
      <div className="h-full">
        {size !== 'small' && (
          <Navigation onClose={close} iconAction={iconAction} iconName={iconName}>
            {title}
          </Navigation>
        )}
        <Wrapper noPadding={noPadding} size={size}>
          {children}
        </Wrapper>
      </div>
    </StyledModal>
  );
};

export default Modal;
