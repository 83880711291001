import { AppProvider } from '@/providers/app';
import AppRouter from './routes/AppRouter';

const App = (props: any) => {
  return (
    <AppProvider {...props}>
      <AppRouter />
    </AppProvider>
  );
};

export default App;
