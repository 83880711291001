import { useQuery } from 'react-query';

import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { useEffect } from 'react';

const getUserFromGoogle = async () => {
  const { name, givenName, familyName, email, authentication, serverAuthCode }: any =
    await GoogleAuth.signIn();

  const fullName = name || `${givenName} ${familyName}`;
  console.log('el data', name, givenName, familyName, email, authentication, serverAuthCode);
  return {
    name,
    givenName,
    familyName,
    email,
    fullName,
    authentication: { ...authentication, serverAuthCode },
  };
};

export default function useGoogle() {
  useEffect(() => {
    try {
      GoogleAuth.initialize();
    } catch (e) {
      console.log(e, 'el error en el init');
    }
  }, []);

  return useQuery('Google', getUserFromGoogle, {
    enabled: false,
    retry: false,
    onError: (err) => {
      console.log(err, 'el error en el useGoogle');
      history.go(0);
    },
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
  });
}
