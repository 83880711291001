import React, { useState } from 'react';
import { useQueries } from 'react-query';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Container } from '@bumerang-kit/layouts';
import {
  EmptyState,
  GlobalPosition,
  LoadingHome,
  MultipleStatCard,
  Text,
} from '@bumerang-kit/components';
import SingleStatCard from '@bumerang-kit/components/molecules/SingleStatCard/SingleStatCard';
import ApiCaller from '../lib/ApiCaller';
import ImpactApi from '../api/ImpactApi';
import { Spacing } from '@bumerang-kit/foundations';
import { getCurrentSession } from '@/helpers/session';

import { NavigationWrapper } from '@/features/shared';

const StyledText = styled(Text)`
  margin-top: ${Spacing.m};
`;

const Impact: React.FC = () => {
  const currentUser = getCurrentSession();
  const impactApi = new ImpactApi(new ApiCaller(currentUser.token));
  const { t } = useTranslation();
  const [customerFootprint, setCustomerFootprint] = useState<any>([]);
  const [tuppers, setTuppers] = useState<number>(0);
  const [topThreeRestaurants, setTopThree] = useState<any>([]);
  const [isQRModalOpen, setIsQRModalOpen] = useState<boolean>(false);

  const navigate = useHistory();

  const [getCustomerFootprint, getMyLends, getTopThree]: any[] = useQueries([
    {
      queryKey: 'customerFootprint',
      queryFn: async () => await impactApi.getCustomerFootprint(),
      refetchOnWindowFocus: true,
      onSuccess: (response: any) => {
        if (response.success) {
          setCustomerFootprint(response.data);
        }
      },
      enabled: !!currentUser && currentUser.status === 'confirmed',
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    },
    {
      queryKey: 'myLends',
      queryFn: async () => await impactApi.getTotalLends(),
      refetchOnWindowFocus: true,
      onSuccess: (data: any) => setTuppers(data.tuppers),
      enabled: !!currentUser && currentUser.status === 'confirmed',
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    },
    {
      queryKey: 'topThreeRestaurants',
      queryFn: async () => await impactApi.getTopThreeRestaurants(),
      onSuccess: (response: any) => {
        if (response.success) {
          setTopThree(response.data);
        }
      },
      enabled: !!currentUser && currentUser.status === 'confirmed',
    },
  ]);

  if (getCustomerFootprint.isLoading || getMyLends.isLoading || getTopThree.isLoading)
    return (
      <NavigationWrapper
        onOpenQRModal={(isOpen: boolean) => setIsQRModalOpen(isOpen)}
        isQRModalOpen={isQRModalOpen}
        type="customer"
        active="impact"
      >
        <LoadingHome />
      </NavigationWrapper>
    );

  return (
    <NavigationWrapper
      onOpenQRModal={(isOpen: boolean) => setIsQRModalOpen(isOpen)}
      isQRModalOpen={isQRModalOpen}
      type="customer"
      active="impact"
    >
      <Container>
        {tuppers === 0 || currentUser.status === 'pending' ? (
          <EmptyState
            src="assets/emptyBowl.svg"
            title={t('ImpactNeverUsedEmptyStateTitle')}
            subtitle={t('ImpactNeverUsedEmptyStateSubtitle')}
            buttonText={t('ImpactNeverUsedEmptyStateBottom')}
            onClick={() => navigate.push('/map')}
          />
        ) : (
          <>
            <Text config="screen-title" align="center">
              <strong>
                {t('ImpactThanks')} {customerFootprint.name}
              </strong>
            </Text>
            <Text config="main-text" align="center">
              {t('ImpactHeader')}
            </Text>
            <hr />

            <SingleStatCard
              iconName="earth"
              title={
                <Trans
                  i18nKey="ImpactTotalUsedTitle"
                  components={{ bold: <strong />, line: <br /> }}
                />
              }
              metric={customerFootprint.count}
              unit={t('ImpactContainersUnit')}
            />

            <SingleStatCard
              iconName="trash"
              title={t('ImpactTotalCollectedTitle')}
              metric={`${customerFootprint.waste}gr`}
              unit={t('ImpactWasteUnit')}
            />

            <GlobalPosition
              totalUsers={customerFootprint.total}
              position={customerFootprint.position}
              count={customerFootprint.count}
            />

            <StyledText config="screen-title">{t('ImpactTopThreeTitle')}</StyledText>

            {topThreeRestaurants[2] !== undefined ? (
              <MultipleStatCard
                stats={[
                  {
                    name: topThreeRestaurants[0].name,
                    metric: topThreeRestaurants[0].count,
                    highlighted: false,
                  },
                  {
                    name: topThreeRestaurants[1].name,
                    metric: topThreeRestaurants[1].count,
                    highlighted: false,
                  },
                  {
                    name: topThreeRestaurants[2].name,
                    metric: topThreeRestaurants[2].count,
                    highlighted: false,
                  },
                ]}
              />
            ) : (
              <div>
                <EmptyState
                  src="assets/icono_cubiertos.svg"
                  subtitle={t('ImpactTopThreeEmptyStateTitle')}
                />
              </div>
            )}
          </>
        )}
      </Container>
    </NavigationWrapper>
  );
};

export default Impact;
