import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { Container } from '../../../../layouts';

const StyledContainer = styled(Container)`
  overflow: hidden;
`;
const LoadingHome = ({ props }: any) => (
  <StyledContainer>
    <ContentLoader
      speed={2}
      width={100}
      height={100}
      viewBox="0 0 343 568.333"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
      style={{ height: '100%', width: '100%' }}
    >
      <rect x="10" y="81" rx="6" ry="6" width="325" height="283" />
      <rect x="11" y="373" rx="6" ry="6" width="325" height="39" />
      <rect x="11" y="421" rx="6" ry="6" width="325" height="39" />
      <rect x="47" y="51" rx="0" ry="0" width="240" height="5" />
      <rect x="96" y="31" rx="0" ry="0" width="139" height="11" />
    </ContentLoader>
  </StyledContainer>
);

export default LoadingHome;
