import React from 'react';
import styled from 'styled-components';
import QRReader from './QRReader';

const StyledContainer = styled.div`
  min-width: 100%;
  max-height: 50%;
  width: auto;
  height: 100%;
  z-index: -100;
  position: relative;
  overflow: hidden;
`;

interface QrReaderProps {
  onScan: (data: string | null) => void;
  onError: (err: any) => void;
  video: HTMLVideoElement;
}

type QrScannerProps = Omit<QrReaderProps, 'video'>;

const QRScanner = (props: QrScannerProps) => {
  const { onScan, onError } = props;

  return (
    <StyledContainer>
      <QRReader onScan={onScan} onError={onError} />
    </StyledContainer>
  );
};

export default QRScanner;
