import * as React from 'react';
import styled from 'styled-components';

import { Colors, Spacing } from '../../../foundations';
import { Text } from '../..';
import { Icon } from '../../atoms';

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  width: 100%;
  height: 60px;
  ${({ backgroundColor }: { backgroundColor?: string }) =>
    `
      background-color: ${backgroundColor || 'white'};
      border-bottom: 1px solid ${
        backgroundColor ? Colors.mainColorDisabled : Colors.backgroundBlue
      };
    `}
`;

const FakeDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${Spacing.xs};
  padding-right: ${Spacing.s};
  width: 48px;
  height: 48px;
`;

const IconContainer = styled.div`
  padding-left: ${Spacing.s};
  margin-right: ${Spacing.s};
  width: 24px;
  height: 24px;
`;

const getLeftElement = (onClose: any, onBack: any) => {
  if (onClose) {
    return (
      <IconContainer onClick={onClose}>
        <Icon name="arrow-back" color={Colors.mainColor} />
      </IconContainer>
    );
  }

  if (onBack) {
    return (
      <IconContainer onClick={onBack}>
        <Icon name="arrow-back" color={Colors.mainColor} />
      </IconContainer>
    );
  }

  return <FakeDiv />;
};

const Navigation = ({
  onBack,
  onClose,
  children,
  iconAction,
  iconName,
  backgroundColor = 'white',
}: any) => (
  <NavigationWrapper backgroundColor={backgroundColor}>
    {getLeftElement(onClose, onBack)}
    <Text config="screen-title-regular" color={Colors.mainColor} align="center">
      {children}
    </Text>
    <FakeDiv onClick={() => (iconAction ? iconAction() : null)}>
      {iconName && <Icon size="25" color={Colors.mainColor} name={iconName} />}
    </FakeDiv>
  </NavigationWrapper>
);

export default Navigation;
