import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Card, Text } from '../../index';
import { Spacing } from '../../../foundations';

const StyledButton = styled(Button)`
  font-weight: 100;
  margin: ${Spacing.s} auto 0 auto;
`;

const Main = styled(Card)`
  margin: ${Spacing.s} 0;
  padding: ${Spacing.s} ${Spacing.xl};
`;

const NeverUsedCard = ({ goTo }: { goTo: void }) => {
  const { t } = useTranslation();

  return (
    <Main>
      <Text config="main-text-title" align="center">
        <strong>
          <Trans
            i18nKey={'NeverUsedCardTitle'}
            defaults={'Use Bûmerang and start avoiding waste'}
            components={{ bold: <strong /> }}
          >
            Utiliza Bûmerang <br />y empieza a evitar residuos
          </Trans>
        </strong>
      </Text>
      <StyledButton onClick={goTo}>{t('NeverUsedCardButton')}</StyledButton>
    </Main>
  );
};

export default NeverUsedCard;
