import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { app } from '@/lib/firebase';
import { removeCurrentSession } from '@/helpers/session';

const getNotificationToken = async (): Promise<any> => {
  const response = await axios.get(`/v2/notify/token`);
  const auth = getAuth(app);
  await signInWithCustomToken(auth, response.data.token);

  return response.data.token;
};

export default function useGetNotificationToken(config?: any) {
  return useQuery({
    queryKey: ['notificationToken'],
    queryFn: () => getNotificationToken(),
    onError: (err: any) => {
      removeCurrentSession();
      history.go(0);
      console.log(err);
    },

    onSuccess: (response: any) => localStorage.setItem('token', response),
    ...config,
  });
}
