import { Redirect, Route, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { isLogin } from '../helpers/session';
import { isValidOauth, generateOAuthErrorFromUrl, getOauthQueryAttributes } from '../helpers/oauth';

const PublicOauthRoute = ({ component: Component, as, ...rest }: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isValidOauthResult = isValidOauth(location.search, as);

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (isLogin() && isValidOauthResult) {
          return <Redirect to={`/oauth/authorize?${getOauthQueryAttributes(location.search)}`} />;
        }
        if (isValidOauthResult) {
          return <Component {...props} />;
        }

        return (
          <Redirect
            to={`/oauth/error?authError=${generateOAuthErrorFromUrl(t, location.search, as)}`}
          />
        );
      }}
    />
  );
};

export default PublicOauthRoute;
