import { useEffect, useState } from 'react';
import { IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useHistory, useLocation } from 'react-router';

import { useTranslation } from 'react-i18next';
import { LoadingList, Navigation, Text } from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import PaymentApi from '../../api/PaymentApi';
import ApiCaller from '../../lib/ApiCaller';
import { Toaster } from '@bumerang-kit/helpers';

import IbanForm from './IbanForm';
import Settings from '../../lib/settings';
import { getCurrentSession } from '@/helpers/session';
import UserApi from '../../api/UserApi';

const toaster = new Toaster();

const AddSepa = ({ lendConfig, setLendConfig }: any) => {
  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const navigate = useHistory();
  const location = useLocation();
  const currentUser = getCurrentSession();

  const paymentApi = new PaymentApi(new ApiCaller());
  const userApi = new UserApi(new ApiCaller(currentUser.token));
  const [isLoading, setLoading] = useState(false);

  const setLendConfigStorage = (state: any) => {
    Settings.saveStateInSession(state);
    setLendConfig(state);
  };

  const loadUser = async () => {
    if (!lendConfig?.restaurant?.userId) return;
    setLoading(true);

    await userApi.getUserInfo(lendConfig?.restaurant?.userId).then((data: any) => {
      setLendConfigStorage({
        ...lendConfig,
        user: data.data,
      });
    });

    setLoading(false);
  };
  const resetStripeUser = async () => {
    if (!lendConfig?.restaurant?.userId) return;
    setLoading(true);

    await userApi.resetStripe(lendConfig?.restaurant?.userId).then((data: any) => {
      setLendConfigStorage({
        ...lendConfig,
        user: data.data,
      });
    });

    setLoading(false);
  };

  useEffect(() => {
    const pathname = location?.pathname;

    if (pathname === '/admin/billing/sepa') {
      const state = Settings.getStateInSession();
      if (state?.restaurant) {
        setLendConfigStorage({
          ...lendConfig,
          ...state,
        });
        loadUser();
      } else {
        setLendConfigStorage({
          ...lendConfig,
          restaurant: null,
        });
        navigate.replace('/admin', { replace: true });
      }
    }
  }, [location]);

  useEffect(() => {
    const pathname = location?.pathname;

    if (pathname === '/admin/billing/sepa') {
      if (lendConfig?.restaurant && !lendConfig?.user) {
        loadUser();
      }
    }
  }, [lendConfig]);

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      const iban = elements.getElement(IbanElement);

      const customerId = event.target['accountholder-id'];

      const response = await paymentApi.getClientSecretWithoutUser(customerId.value);

      if (response.success) {
        const paymentMethod: any = {
          sepa_debit: iban,
          billing_details: {
            name: response.data.name || lendConfig?.user?.name,
            email: response.data.email || lendConfig?.user?.email,
          },
        };

        const result = await stripe.confirmSepaDebitSetup(response.data.client_secret, {
          payment_method: paymentMethod,
        });

        if (result.error) {
          toaster.flashError(result.error.message!);
        } else {
          toaster.flashSuccess(t('PaymentMethodAddedSuccessfullyMessage'));
        }
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const onBack = () => {
    setLendConfigStorage({
      ...lendConfig,
      restaurant: null,
    });
    navigate.replace('/admin/restaurantList');
  };

  if (isLoading) {
    return (
      <Page>
        <Navigation
          onBack={() => onBack()}
          iconAction={async () => resetStripeUser()}
          iconName="sync"
        >
          <Text align="center" config="secondary-screen-title">
            {t('AddSepaTitle')}
          </Text>
        </Navigation>
        <LoadingList items={2} />
      </Page>
    );
  }

  return (
    <Page>
      <>
        <Navigation
          onBack={() => onBack()}
          iconAction={async () => resetStripeUser()}
          iconName="sync"
        >
          <Text align="center" config="secondary-screen-title">
            {t('AddSepaTitle')}
          </Text>
        </Navigation>
        <Container>
          <IbanForm onSubmit={handleSubmit} disabled={!stripe} value={lendConfig?.user?.stripeId} />
        </Container>
      </>
    </Page>
  );
};

export default AddSepa;
