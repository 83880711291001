import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';

export const getLendsHistory = (): Promise<any> => {
  return axios.get('/v2/logistics/lend/history');
};

export const useLendsHistory = (config: any | undefined) => {
  return useQuery({
    queryKey: ['lendsHistory'],
    queryFn: () => getLendsHistory(),
    ...config,
  });
};
