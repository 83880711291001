import ApiCaller from '../lib/ApiCaller';

class UserApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getUserInfo(id: string): Promise<any> {
    return this.apiCaller.call(`/v1/user/${id}`, 'GET');
  }

  getUsers(
    type?: string | null,
    from = 0,
    limit = 10,
    query: string | null = null,
    containers?: boolean
  ): Promise<any> {
    const params = {
      ...(from ? { from } : {}),
      ...(limit ? { limit } : {}),
      ...(type ? { type } : {}),
      ...(query ? { query } : {}),
      ...(containers === true || containers === false ? { containers } : {}),
    };
    return this.apiCaller.call(`/v2/user`, 'GET', params);
  }

  resetStripe(userId: string): Promise<any> {
    return this.apiCaller.call(`/v1/user/${userId}/resetStripe`, 'PUT', {});
  }
}

export default UserApi;
