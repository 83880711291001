import React from 'react';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { eyeOff } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { Card, FormattedDistance, Icon, Text } from '../../atoms';
import { Colors, Spacing } from '../../../foundations';
import Tag from '../../atoms/Tag';
import Rating from '../Rating';

const FramedImage = styled.div`
  width: 105px;
  height: 105px;
  min-width: 105px;
  min-height: 105px;
  border-radius: 6px;
  margin: ${Spacing.xs};
  background-image: url(${(props: { url: any }) => props.url});
  background-position: center;
  background-size: cover;
`;

const StyledCard = styled(Card)`
  display: flex;
  position: relative;
  max-width: 100%;
`;

const StyledRating = styled(Rating)`
  margin-bottom: 10px;
`;

const StyledDiv = styled.div`
  padding: ${Spacing.xs};
  flex-grow: 2;
`;

const DistanceContainer = styled.div`
  position: absolute;
  right: ${Spacing.xs};
  bottom: ${Spacing.xs};
`;

const PropertiesContainer = styled.div`
  bottom: ${Spacing.xs};
  width: 160px;
  display: flex;
  justify-content: space-between;
  margin-left: 0;
`;

const ArrowContainer = styled.div`
  padding: ${Spacing.xs};
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const ContainerCount = styled.div`
  padding: ${Spacing.xs};
  position: absolute;
  right: 0;
  bottom: 0;
`;

const ListCard = ({ restaurant, showTags = true, onClick, distance }: any) => {
  const itHasDeliveryOptions = restaurant.delivery && Object.keys(restaurant.delivery).length > 0;
  const { t } = useTranslation();

  return (
    <StyledCard onClick={() => onClick(restaurant)} full listItem>
      <FramedImage
        url={
          restaurant.photoUrl.length !== 0
            ? restaurant.photoUrl[0]
            : 'https://bumerangphotos.s3.eu-west-3.amazonaws.com/assets/default_restaurant.png'
        }
      />
      <StyledDiv>
        <Text config="main-text-link">{restaurant.name}</Text>
        <Text config="secondary-text-title">
          <StyledRating starsColor="black" rating={restaurant.rating} />
          {!restaurant.active && <IonIcon id="eyeicon" icon={eyeOff} />}
        </Text>
        {showTags && (
          <PropertiesContainer>
            {restaurant.discount && <Tag title={t('Discount')} color={Colors.tagCream} />}
            {itHasDeliveryOptions && <Tag title="Delivery" color={Colors.tagYellow} />}
          </PropertiesContainer>
        )}
        <DistanceContainer>
          {distance && <FormattedDistance distance={distance} />}
        </DistanceContainer>
      </StyledDiv>
      <ArrowContainer>
        <Icon name="arrow" />
      </ArrowContainer>

      {restaurant.containersAssigned && restaurant.containersAssigned[0] !== null && (
        <ContainerCount>
          {restaurant.containersAssigned.map((product: any, index: number) => (
            <Text key={index} limit config="small-text-title" align="center">
              {`${product.code}: ${product.count}`}
            </Text>
          ))}
        </ContainerCount>
      )}
    </StyledCard>
  );
};

export default ListCard;
