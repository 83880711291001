import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';

class Analyzer {
  analytics: any;

  constructor() {
    this.analytics = Analytics({
      app: 'bumerang-app',
      plugins: [
        segmentPlugin({
          writeKey: 'P3eMtNK2hnQQz6lvUKsqvCbXNZwyoOLB',
        }),
      ],
    });
  }

  identifyUser = (id: string, name: string, email: string) => {
    this.analytics.identify(id.toString(), {
      name: name,
      email: email,
    });
  };

  trackSignUpAndIdentify = (user: any, provider: string, platform: string) => {
    this.identifyUser(user.id, user.name, user.email);
    this.analytics.track('user_signup', {
      user_id: user.id,
      platform: platform,
      provider: provider,
    });
  };

  trackMailValidation = (id: string, email: string) => {
    this.analytics.track('mail_validation', {
      user_id: id,
      email: email,
    });
  };

  trackCopyQr = (id: string, qr: string) => {
    this.analytics.track('user_copycode', {
      user_id: id,
      qrCode: qr,
    });
  };

  trackAddedCard = (id: string) => {
    this.analytics.track('payment_added', {
      user_id: id,
      card: true,
    });
  };

  trackSkippedOnboarding = (id: string, slideNumber: number) => {
    this.analytics.track('user_onboarding', {
      user_id: id,
      skipped_on: slideNumber,
    });
  };

  trackLoginAndIdentify = (user: any, provider: string, platform: string) => {
    this.identifyUser(user.id, user.name, user.email);

    this.analytics.track('user_login', {
      user_id: user.id,
      platform,
      provider,
    });
  };

  trackRestaurantLogin = (id: string, platform: string, provider: string) => {
    this.analytics.track('partner_login', {
      user_id: id,
      platform,
      provider,
    });
  };

  trackUsedQR = (
    id: string,
    inputMethod: string,
    restaurant: string,
    lendQuantity: number,
    collectQuantity: number
  ) => {
    this.analytics.track('qr_used', {
      user_id: id,
      inputMethod: inputMethod,
      restaurant: restaurant,
      lendQuantity: lendQuantity,
      collectQuantity: collectQuantity,
      date: new Date().toString(),
    });
  };

  trackOpenMap = (id: string, platform?: string) => {
    this.analytics.track('map_visited', {
      id: id,
      platform: platform,
      date: new Date().toString(),
    });
  };

  trackOpenMapCard = (id: string, restaurantName: string) => {
    this.analytics.track('card_restaurant_visited', {
      user_id: id,
      restaurant: restaurantName,
    });
  };

  trackRestaurantVisited = (id: string, restaurantName: string) => {
    this.analytics.track('restaurant_visited', {
      user_id: id,
      restaurant: restaurantName,
      date: new Date().toString(),
    });
  };

  trackOpenRestaurantSchedule = (id: string, restaurantName: string) => {
    this.analytics.track('restaurant_schedule_visited', {
      user_id: id,
      restaurant: restaurantName,
      date: new Date().toString(),
    });
  };

  trackGoButtonSubmit = (id: string, restaurantName: string) => {
    this.analytics.track('restaurant_gobutton_submited', {
      user_id: id,
      restaurant: restaurantName,
      date: new Date().toString(),
    });
  };

  trackDeliveryButton = (
    id: string,
    qrCode: string,
    restaurantName: string,
    deliveryPlatform: string,
    email: string
  ) => {
    this.analytics.track('delivery_button', {
      user_id: id,
      user_email: email,
      code_id: qrCode,
      restaurant: restaurantName,
      delivery_platform: deliveryPlatform,
      date: new Date().toString(),
    });
  };
}
export default Analyzer;
