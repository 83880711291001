import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Placeholder, Text } from '../../../components';
import { Colors, Spacing } from '../../../foundations';
import { IonIcon } from '@ionic/react';

const Section = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 98px;
  height: 100%;
  padding: 0 15px;

  .conditional-p {
    text-align: left;
  }
`;

const Count = styled(Text)`
  margin-bottom: ${Spacing.m};
`;

const Containers = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: ${Spacing.m} 0;
  height: 120px;
`;

const StyledConfirmation = styled.p`
  margin: ${Spacing.m};
  text-align: center;
  color: ${Colors.mainColor};
`;

const CartItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.borderSeparation};
  padding: ${Spacing.xxs} 0;
  margin-bottom: ${Spacing.xxs};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(IonIcon)`
  margin-right: ${Spacing.xxs};
  height: 25px;
  width: 50px;
`;
const FixedBlock = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  padding: ${Spacing.s} ${Spacing.s};
`;

const StyledText = styled(Text)`
  margin-left: ${Spacing.s};
`;
const RevStyledText = styled(Text)`
  margin-right: ${Spacing.s};
`;
const withHeight = { height: '100%' };
const withoutHeight = { height: 0 };

const Cart = ({ items, onConfirm, onRemoveItem, options, selfAssign, confirmationModal }: any) => {
  const { t } = useTranslation();
  const today = new Date();
  const [containers, setContainers] = useState<any>([]);

  const placeholderDescription: string = options?.placeholderDescription
    ? options.placeholderDescription
    : t('ScanContainerDescription');
  const placeholderTitle: string = options?.placeholderTitle
    ? options.placeholderTitle
    : t('ScanContainerInstructions');
  const confirmSelectionButton: string = options?.confirmSelectionButton
    ? options.confirmSelectionButton
    : t('ConfirmSelection');
  const scannedContainersTitle: string = options?.scannedContainersTitle
    ? options.scannedContainersTitle
    : t('ScannedContainers');
  const showName: boolean = options?.showName === true || options?.showName === 'true';
  useEffect(() => {
    if (items && items.length > 0) {
      const orderContainersByTimestamp = items.sort((a: any, b: any) => b.timestamp - a.timestamp);
      setContainers(orderContainersByTimestamp);
    } else {
      setContainers([]);
    }
  }, [items]);

  return (
    <>
      {containers && containers.length > 0 ? (
        <Section style={confirmationModal ? withoutHeight : withHeight}>
          {!confirmationModal && (
            <Count
              color={Colors.mainColor}
              bold="extra-bold"
              config="secondary-screen-title"
              align="center"
            >
              {scannedContainersTitle}: {containers.length}
            </Count>
          )}
          {selfAssign && (
            <p className={`conditional-p text-main font-black font-montserrat`}>
              {t('SelfScannedContainers')}
            </p>
          )}
          <Containers>
            {containers &&
              containers.map((item: any) => (
                <CartItem key={item.id}>
                  <IconContainer>
                    <StyledIcon
                      icon={
                        item.current_owner.type === 'customer'
                          ? item.product_type.group === 'Bowl'
                            ? 'assets/return-bowl.svg'
                            : 'assets/return-cup.svg'
                          : item.product_type.group === 'Bowl'
                          ? 'assets/takeaway-bowl.svg'
                          : 'assets/takeaway-cup.svg'
                      }
                    />
                    <StyledText>
                      {item.product_type.code} {item.name}
                    </StyledText>
                  </IconContainer>
                  {showName ? (
                    <IconContainer>
                      <RevStyledText>{item.current_owner.name}</RevStyledText>

                      <div onClick={() => onRemoveItem(item.id)}>
                        <Icon name="close" />
                      </div>
                    </IconContainer>
                  ) : (
                    <div onClick={() => onRemoveItem(item.id)}>
                      {!confirmationModal && <Icon name="close" />}
                    </div>
                  )}
                </CartItem>
              ))}
          </Containers>
          {confirmationModal && (
            <StyledConfirmation>
              <strong>
                {today.getDate() >= 10 ? today.getDate() : '0' + today.getDate()}/
                {'0' + (today.getMonth() + 1)}
              </strong>{' '}
              a las{' '}
              <strong>
                {today.getHours() >= 10 ? today.getHours() : '0' + today.getHours()}:
                {today.getMinutes() >= 10 ? today.getMinutes() : '0' + today.getMinutes()}h{' '}
              </strong>
              en <strong>{items[0].current_owner.name}</strong>
            </StyledConfirmation>
          )}
          <FixedBlock>
            {!confirmationModal && (
              <Button expand="full" onClick={onConfirm}>
                {confirmSelectionButton}
              </Button>
            )}
          </FixedBlock>
        </Section>
      ) : selfAssign ? (
        <Placeholder
          icon="assets/self-assign.svg"
          title={t('SelfAssignTextTitle')}
          description={t('ShowConfirmation')}
        />
      ) : (
        <Placeholder
          icon="assets/scanCode.svg"
          title={placeholderTitle}
          description={placeholderDescription}
        />
      )}
    </>
  );
};

export default Cart;
