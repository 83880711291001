import Restaurant from '../models/restaurant';

interface LendInfo {
  id: string;
  finished: boolean;
  lendeeId: string;
  lenderId: string;
  receiverId?: string;
  status?: string;
  paymentId?: string;
  createdAt: string;
  updatedAt: string;
  lendedAt: string;
  collectedAt: string;
  Lender?: Restaurant;
  Receiver?: Restaurant;
}

class Lend {
  id: string;
  finished: boolean;
  lendeeId: string;
  lenderId: string;
  receiverId?: string;
  status?: string;
  paymentId?: string;
  createdAt: string;
  lendedAt: string;
  collectedAt: string;
  updatedAt: string;
  Lender?: Restaurant;
  Receiver?: Restaurant;

  constructor(lendInfo: LendInfo) {
    this.id = lendInfo.id;
    this.finished = lendInfo.finished;
    this.lendeeId = lendInfo.lendeeId;
    this.lenderId = lendInfo.lenderId;
    this.receiverId = lendInfo.receiverId;
    this.status = lendInfo.status;
    this.paymentId = lendInfo.paymentId;
    this.createdAt = lendInfo.createdAt;
    this.updatedAt = lendInfo.updatedAt;
    this.lendedAt = lendInfo.lendedAt;
    this.collectedAt = lendInfo.collectedAt;
    this.Lender = lendInfo.Lender;
    this.Receiver = lendInfo.Receiver;
  }

  getLeftDaysToReturn(lendDuration: number): number {
    if (!this.lendedAt) return 0;

    const lendedAtDate = new Date(Date.parse(this.lendedAt));
    const now = new Date();

    lendedAtDate.setDate(lendedAtDate.getDate() + lendDuration);

    return Math.floor(
      (lendedAtDate.getTime() - now.getTime()) / (1000 * 3600 * 24)
    );
  }
}

export default Lend;
