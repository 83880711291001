const storage = {
  getToken: () => {
    return window.localStorage.getItem('currentSession') as string;
  },
  setToken: (token: string) => {
    window.localStorage.setItem('currentSession', token);
  },
  clearToken: () => {
    window.localStorage.removeItem('currentSession');
  },
};

export default storage;
