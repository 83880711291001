import ContainerV2 from './containerV2';

class ContainerV2Collection {
  containers: ContainerV2[];

  constructor(containerV2Array: ContainerV2[]) {
    this.containers = containerV2Array;
  }

  getCountByProductType(): any {
    const productCount: any = {};
    if (
      this.containers.length > 0 &&
      this.containers.every(
        (container: any) => container.product_type !== undefined
      )
    )
      this.containers.map((container: any) => {
        if (container !== undefined && container.product_type !== undefined)
          productCount[container.product_type.code] =
            productCount[container.product_type.code] === undefined
              ? 1
              : (productCount[container.product_type.code] += 1);
      });

    return productCount;
  }
}

export default ContainerV2Collection;
