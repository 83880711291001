import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { StatusBar } from '@capacitor/status-bar';
import { Network } from '@capacitor/network';
import { Capacitor } from '@capacitor/core';

import { Chat, usePlatform } from '@bumerang-kit/helpers';
import { Button, Tabs, Text, Icon } from '@bumerang-kit/components';
import { Container, Page } from '@bumerang-kit/layouts';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import { useHistory } from 'react-router';
import { useQuery } from 'react-query';
import Offline from '../Offline';
import { ENV } from '@/env/env';
import { getCurrentSession } from '@/helpers/session';
import ApiLambda from '@/lib/ApiLambda';
import HomeModal from '../../features/home/components/HomeModal/HomeModal';
import { language } from '@/locales/languageDetector';

const StyledContainer = styled(Container)`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const Title = styled(Text)`
  margin: ${Spacing.s} ${Spacing.l};
`;
const StyledButton = styled(Button)`
  width: 264px;
  margin: ${Spacing.m} auto;
`;

const ChatIcon = styled(Button)`
  position: absolute;
  background-color: ${Colors.white};
  top: max(25px, var(--ion-safe-area-top));
  right: ${Spacing.m};
`;

const Restaurant: React.FC = () => {
  const { t } = useTranslation();
  const { data: platform } = usePlatform();
  const navigate = useHistory();
  const productApi = new ApiLambda();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalImage, setModalImage] = useState<string>('');
  const [checkStatus, setCheckStatus] = useState<boolean>(false);

  if (Capacitor.isPluginAvailable('StatusBar'))
    StatusBar.setBackgroundColor({ color: Colors.white });

  const [hasConnection, setHasConnection] = useState<boolean>(true);

  const { data: currentUser }: { data: any } = useQuery({
    queryKey: 'getCurrentSessionQuery',
    queryFn: getCurrentSession,
  });

  async function checkAppStatus() {
    try {
      const response = await productApi.call(
        'https://cp6iwu2ba3.execute-api.eu-west-3.amazonaws.com/Prod'
      );
      const data = response[0];
      console.log(data);
      const theTitle = (): string => {
        switch (language) {
          case 'en':
            return data.titleEN;
          case 'es':
            return data.titleES;
          default:
            return data.title;
        }
      };

      const theText = (): string => {
        switch (language) {
          case 'en':
            return data.textEN;
          case 'es':
            return data.textES;
          default:
            return data.text;
        }
      };
      setModalImage(data.icon);
      setModalText(theText);
      setModalTitle(theTitle);
      if (
        (data.platform === platform || data.platform === 'all') &&
        (data.app === 'partner' || data.app === 'all')
      ) {
        setShowModal(true);
        if (
          data.flag === 'warning_custom' ||
          data.flag === 'warning_maintenance' ||
          data.flag === 'warning_update'
        ) {
          setShowButton(true);
        }
        if (data.flag === 'warning_update' || data.flag === 'update') {
          setShowUpdate(true);
        }
      }
      setCheckStatus(true);
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    if (!checkStatus) checkAppStatus();
  });

  React.useEffect(() => {
    const getCurrentNetworkStatus = async () => {
      const currentStatus = await Network.getStatus();
      setHasConnection(currentStatus.connected);
    };

    Network.addListener('networkStatusChange', (status: any) => {
      setHasConnection(status.connected);
    });

    getCurrentNetworkStatus();

    return () => {
      Network.removeAllListeners();
    };
  }, [hasConnection, setHasConnection]);

  return (
    <>
      {checkStatus && (
        <HomeModal
          isOpen={showModal}
          setIsOpen={setShowModal}
          modalImage={modalImage}
          modalText={modalText}
          modalTitle={modalTitle}
          showButton={showButton}
          showUpdate={showUpdate}
        />
      )}
      <Page>
        <StyledContainer>
          {!hasConnection ? (
            <Offline />
          ) : (
            <>
              <Title config="screen-title" align="center">
                {t('Greet')}, {currentUser?.name}
              </Title>
              <main>
                <StyledButton onClick={() => navigate.replace('/takeaway/scan')}>
                  <Text config="button-text" color="white">
                    {t('ScanContainerInstructions')}
                  </Text>
                </StyledButton>
              </main>
              <div />
            </>
          )}
        </StyledContainer>
        <ChatIcon
          fab
          onClick={() =>
            Chat.open(ENV.CRISP_ID, currentUser?.email, currentUser?.name, currentUser?.token)
          }
        >
          <Icon name="chat" color={Colors.mainColor} size="large" />
        </ChatIcon>
        <Tabs type="restaurant" active="scan" />
      </Page>
      );
      <Page>
        <StyledContainer>
          {!hasConnection ? (
            <Offline />
          ) : (
            <>
              <Title config="screen-title" align="center">
                {t('Greet')}, {currentUser?.name}
              </Title>
              <main>
                <StyledButton onClick={() => navigate.replace('/takeaway/scan')}>
                  <Text config="button-text" color="white">
                    {t('ScanContainerInstructions')}
                  </Text>
                </StyledButton>
              </main>
              <div />
            </>
          )}
        </StyledContainer>
        <ChatIcon
          fab
          onClick={() =>
            Chat.open(ENV.CRISP_ID, currentUser?.email, currentUser?.name, currentUser?.token)
          }
        >
          <Icon name="chat" color={Colors.mainColor} size="large" />
        </ChatIcon>
        <Tabs type="restaurant" active="scan" />
      </Page>
    </>
  );
};

export default Restaurant;
