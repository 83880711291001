import React from 'react';
import { Card, Rating, Text } from '../../index';
import styled from 'styled-components';
import { Colors, Sizes, Spacing } from '../../../foundations';
import { FormattedDistance, Icon } from '../../atoms';
import Tag from '../../atoms/Tag';

import { useTranslation } from 'react-i18next';

const FramedImage = styled.div`
  height: auto;
  width: 90px;
  border-radius: 6px;
  margin-right: ${Spacing.s};
  background-image: url(${(props: { url: any }) => props.url});
  background-position: center;
  background-size: cover;
`;

const StyledCard = styled(Card)`
  position: relative;
  display: flex;
  width: 85%;
  height: 134px;
`;

const Arrow = styled.div`
  position: absolute;
  right: ${Spacing.xs};
  top: ${Spacing.xs};
  font-size: ${Sizes.xl};
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
  background-color: rgba(109, 109, 109, 0.3);
`;

const StyledMain = styled.main`
  max-width: 60%;
`;

const PropertiesContainer = styled.div`
  margin-top: ${Spacing.xs};
  width: 170px;
  display: flex;
  justify-content: space-between;
  margin-left: 0;
`;

const StyledDistance = styled(FormattedDistance)`
  display: block;
  position: relative;
  right: ${Spacing.xs};
  bottom: ${Spacing.xs};
`;

const DistanceContainer = styled.div`
  position: absolute;
  right: ${Spacing.xs};
  bottom: ${Spacing.xs};
`;

const MapRestaurantCard = ({
  onDismiss,
  onOpen,
  img,
  name,
  rating,
  isDiscounted,
  isHasDeliveryOptions,
  disabled,
  distance,
}: any) => {
  const { t } = useTranslation();

  return (
    <Background onClick={onDismiss}>
      <StyledCard full onClick={!disabled && onOpen}>
        <FramedImage url={img} />
        <StyledMain>
          {!disabled && (
            <Arrow>
              <Icon name="arrow" color={Colors.black} />
            </Arrow>
          )}
          <Text config="secondary-text-title">
            <strong>{name}</strong>
          </Text>
          <Rating starsColor="black" rating={rating} />
          <PropertiesContainer>
            {isDiscounted && <Tag title={t('Discount')} color={Colors.tagCream} />}
            {isHasDeliveryOptions && <Tag title="Delivery" color={Colors.tagYellow} />}
          </PropertiesContainer>
        </StyledMain>
        <DistanceContainer>{distance && <StyledDistance distance={distance} />}</DistanceContainer>
      </StyledCard>
    </Background>
  );
};

export default MapRestaurantCard;
