import { IonContent, IonGrid, IonRow, IonCol } from '@ionic/react';

import React from 'react';
import './styles/UserQr.css';
import './styles/General.css';
import { Page } from '@bumerang-kit/layouts';
import { getCurrentSession } from '@/helpers/session';
import QRCode from 'qrcode.react';

const ViewQr: React.FC = () => {
  const currentUser = getCurrentSession();

  return (
    <Page>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <QRCode includeMargin className="qrcode" value={currentUser.username} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </Page>
  );
};

export default ViewQr;
