import React from 'react';
import styled from 'styled-components';
import { Colors, Spacing, Sizes } from '../../../foundations';

const StyledDiv: any = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: ${(props: any) => props.yMargin} auto;
  border-top: 1px solid ${(props) => props.color};
  justify-content: center;
`;

const Circle = styled.div`
  border: 1px solid ${(props) => props.color};
  border-radius: 50%;
  height: ${Sizes.xxs};
  width: ${Sizes.xxs};
  background-color: transparent;
`;

const Wrapper = styled.div`
  position: absolute;
  width: 20px;
  height: 10px;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Line = ({
  isOption,
  yMargin = Spacing.s,
  backgroundColor = Colors.white,
  color = Colors.mainColorDisabled,
  ...props
}: any) => {
  return (
    <StyledDiv {...props} color={color} yMargin={yMargin}>
      {isOption && (
        <Wrapper color={backgroundColor}>
          <Circle color={color} />
        </Wrapper>
      )}
    </StyledDiv>
  );
};

export default Line;
