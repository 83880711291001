import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Page, Container } from '@bumerang-kit/layouts';
import { Button, Navigation } from '@bumerang-kit/components';

import Clean from './gifs/clean_bumerang.gif';
import Delivery from './gifs/delivery.gif';
import Eat from './gifs/eat.gif';
import FindRestaurant from './gifs/find_restaurant.gif';
import OrderDelivery from './gifs/order_delivery.gif';
import TakeawayScanner from './gifs/takeaway_scanner.gif';
import { useHistory, useParams } from 'react-router';
import { getCurrentSession } from '@/helpers/session';
import { ENV } from '@/env/env';

import Settings from '@/lib/settings';

const HowItWorksModal = () => {
  const { t } = useTranslation();
  const currentUser = getCurrentSession();
  const { section }: any = useParams();

  const navigate = useHistory();

  if (section === 'takeaway') {
    return (
      <Page>
        <Navigation onClose={() => navigate.go(-1)}>{t('TakeawayOrder')}</Navigation>
        <Container>
          <ul className="mb-12">
            <li className="text-center mb-8">
              <span className="rounded-full bg-main w-10 h-10 mx-auto flex justify-center items-center text-white font-bold mb-6 text-3xl">
                1
              </span>
              <h2 className="text-xl text-main">
                <Trans i18nKey="TakeawayStepOne" components={{ strong: <strong /> }} />
              </h2>
              <img
                className="mt-6 mx-auto"
                src={FindRestaurant}
                alt={'Localiza un restaurante de la red Bûmerang'}
              />
            </li>
            <li className="text-center mb-8">
              <span className="rounded-full bg-main w-10 h-10 mx-auto flex justify-center items-center text-white font-bold mb-6 text-3xl">
                2
              </span>
              <h2 className="text-xl text-main mb-2">
                <Trans i18nKey="TakeawayStepTwo" components={{ strong: <strong /> }} />
              </h2>
              <p className="text-sm text-main">
                <Trans i18nKey="TakeawayStepTwoDescription" />
              </p>
              <img
                className="mt-6 mx-auto"
                src={TakeawayScanner}
                alt="Pide tu comida en un Bûmerang"
              />
            </li>
            <li className="text-center mb-8">
              <span className="rounded-full bg-main w-10 h-10 mx-auto flex justify-center items-center text-white font-bold mb-6 text-3xl">
                3
              </span>
              <h2 className="text-xl text-main mb-2">
                <Trans i18nKey="TakeawayStepThree" components={{ strong: <strong /> }} />
              </h2>
              <p className="text-sm text-main">{t('TakeawayStepThreeDescription')}</p>
              <img
                className="mt-6 mx-auto"
                src={Eat}
                alt="Disfruta de tu comida sin generar residuos"
              />
            </li>
            <li className="text-center mb-8">
              <span className="rounded-full bg-main w-10 h-10 mx-auto flex justify-center items-center text-white font-bold mb-6 text-3xl">
                4
              </span>
              <h2 className="text-xl text-main mb-2">
                <Trans
                  i18nKey="TakeawayStepFour"
                  components={{ strong: <strong /> }}
                  values={{
                    daysLeft: Settings.getNumDaysToReturn(),
                  }}
                />
              </h2>
              <p className="text-sm text-main mb-4">{t('TakeawayStepFourDescription')}</p>
              <p className="text-sm text-main">
                <Trans
                  i18nKey="TakeawayStepFourDescriptionTwo"
                  components={{ strong: <strong /> }}
                  values={{
                    daysLeft: Settings.getNumDaysToReturn(),
                    penaltyPrice: `${Settings.getPenaltyPrice()} €`,
                  }}
                />
              </p>
              <img
                className="mt-6 mx-auto"
                src={Clean}
                alt="Devuelvelos antes de 15 días en cualquier punto de la red"
              />
            </li>
            <li className="text-center mb-8">
              <span className="rounded-full bg-main w-10 h-10 mx-auto flex justify-center items-center text-white font-bold mb-6 text-3xl">
                5
              </span>
              <h2 className="text-xl text-main mb-2">
                <Trans i18nKey="TakeawayStepFive" components={{ strong: <strong /> }} />
              </h2>
              <p className="text-sm text-main">{t('TakeawayStepFiveDescription')}</p>
            </li>
          </ul>
          <div className="mb-12">
            <Button
              onClick={() => {
                navigate.push('/map');
              }}
            >
              {t('FindARestaurant')}
            </Button>
            <p className="mt-4 text-sm text-center text-black">
              <Trans i18nKey={'AddCardFAQInvitation'}>
                If you have further questions,
                <a
                  className="underline cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youbumerang.com/faqs"
                >
                  check our FAQ
                </a>
                or{' '}
                <a
                  className="underline cursor-pointer"
                  rel="noopener noreferrer"
                  onClick={() =>
                    window.open(
                      `https://go.crisp.chat/chat/embed/?website_id=${
                        ENV.CRISP_ID
                      }&user_email=${encodeURI(currentUser.email)}&user_nickname=${encodeURI(
                        currentUser.name
                      )}&token_id=${encodeURI(currentUser.token)}`,
                      '_blank'
                    )
                  }
                >
                  write to us via chat
                </a>
                .
              </Trans>
            </p>
          </div>
        </Container>
      </Page>
    );
  }

  if (section === 'delivery') {
    return (
      <Page>
        <Navigation onClose={() => navigate.go(-1)}>{t('DeliveryOrder')}</Navigation>
        <Container>
          <ul className="mb-12">
            <li className="text-center mb-8">
              <span className="rounded-full bg-main w-10 h-10 mx-auto flex justify-center items-center text-white font-bold mb-6 text-3xl">
                1
              </span>
              <h2 className="text-xl text-main mb-2">
                <Trans i18nKey="DeliveryStepOne" components={{ strong: <strong /> }} />
              </h2>
              <p className="text-sm text-main">{t('DeliveryStepOneDescription')}</p>
              <img
                className="mt-6 mx-auto"
                src={FindRestaurant}
                alt="Localiza un restaurante de la red Bûmerang"
              />
            </li>
            <li className="text-center mb-8">
              <span className="rounded-full bg-main w-10 h-10 mx-auto flex justify-center items-center text-white font-bold mb-6 text-3xl">
                2
              </span>
              <h2 className="text-xl text-main mb-2">
                <Trans i18nKey="DeliveryStepTwo" components={{ strong: <strong /> }} />
              </h2>
              <p className="text-sm text-main">{t('DeliveryStepTwoDescription')}</p>
              <img
                className="mt-6 mx-auto"
                src={OrderDelivery}
                alt="Haz tu pedido en la plataforma delivery y pega tu código Bûmerang"
              />
            </li>
            <li className="text-center mb-8">
              <span className="rounded-full bg-main w-10 h-10 mx-auto flex justify-center items-center text-white font-bold mb-6 text-3xl">
                3
              </span>
              <h2 className="text-xl text-main mb-2">
                <Trans i18nKey="DeliveryStepThree" components={{ strong: <strong /> }} />
              </h2>
              <p className="text-sm text-main">{t('DeliveryStepThreeDescription')}</p>
              <img
                className="mt-6 mx-auto"
                src={Delivery}
                alt="Recibe tu Bûmerang y disfruta de tu comida sin generar residuos"
              />
            </li>
            <li className="text-center mb-8">
              <span className="rounded-full bg-main w-10 h-10 mx-auto flex justify-center items-center text-white font-bold mb-6 text-3xl">
                4
              </span>
              <h2 className="text-xl text-main mb-2">
                <Trans
                  i18nKey="DeliveryStepFour"
                  components={{ strong: <strong /> }}
                  values={{
                    daysLeft: Settings.getNumDaysToReturn(),
                  }}
                />
              </h2>
              <p className="text-sm text-main mb-4">{t('DeliveryStepFourDescription')}</p>
              <p className="text-sm text-main">
                <Trans
                  i18nKey="DeliveryStepFourDescriptionTwo"
                  components={{ strong: <strong /> }}
                  values={{
                    daysLeft: Settings.getNumDaysToReturn(),
                    penaltyPrice: `${Settings.getPenaltyPrice()} €`,
                  }}
                />
              </p>
              <img
                className="mt-6 mx-auto"
                src={Clean}
                alt="Devuelvelos antes de 15 días en cualquier punto de la red"
              />
            </li>
            <li className="text-center mb-8">
              <span className="rounded-full bg-main w-10 h-10 mx-auto flex justify-center items-center text-white font-bold mb-6 text-3xl">
                5
              </span>
              <h2 className="text-xl text-main mb-2">
                <Trans i18nKey="DeliveryStepFive" components={{ strong: <strong /> }} />
              </h2>
              <p className="text-sm text-main">{t('DeliveryStepFiveDescription')}</p>
            </li>
          </ul>
          <div className="mb-12">
            <Button
              onClick={() => {
                navigate.push('/map');
              }}
            >
              {t('FindARestaurant')}
            </Button>
            <p className="mt-4 text-sm text-center text-black">
              <Trans i18nKey={'AddCardFAQInvitation'}>
                If you have further questions,
                <a
                  className="underline cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youbumerang.com/faqs"
                >
                  check our FAQ
                </a>
                or{' '}
                <a
                  className="underline cursor-pointer"
                  rel="noopener noreferrer"
                  onClick={() =>
                    window.open(
                      `https://go.crisp.chat/chat/embed/?website_id=${
                        ENV.CRISP_ID
                      }&user_email=${encodeURI(currentUser.email)}&user_nickname=${encodeURI(
                        currentUser.name
                      )}&token_id=${encodeURI(currentUser.token)}`,
                      '_blank'
                    )
                  }
                >
                  write to us via chat
                </a>
              </Trans>
            </p>
          </div>
        </Container>
      </Page>
    );
  }

  return (
    <Page>
      <Navigation
        onClose={() => navigate.replace('/customer')}
        iconAction={() =>
          window.open(
            `https://go.crisp.chat/chat/embed/?website_id=${ENV.CRISP_ID}&user_email=${encodeURI(
              currentUser.email
            )}&user_nickname=${encodeURI(currentUser.name)}&token_id=${encodeURI(
              currentUser.token
            )}`,
            '_blank'
          )
        }
        iconName="chat"
      >
        {t('HowDoesItWork')}
      </Navigation>
      <Container noPadding>
        <div className="flex justify-between flex-col h-full">
          <section className="py-6 px-4">
            <h1 className="text-main font-bold text-xl text-center mb-2">{t('HowItWorksTitle')}</h1>
            <p className="text-base text-black text-center">{t('HowItWorksDescription')}</p>

            <div className="my-6 grid grid-cols-1 gap-4">
              <Button onClick={() => navigate.push('/how-it-works/takeaway')}>
                {t('TakeawayOrdersGuide')}
              </Button>
              <Button onClick={() => navigate.push('/how-it-works/delivery')} secondary>
                {t('DeliveryOrdersGuide')}
              </Button>
            </div>

            <p className="text-sm text-center text-black">
              <Trans i18nKey={'AddCardFAQInvitation'}>
                If you have further questions,
                <a
                  className="underline cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youbumerang.com/faqs"
                >
                  check our FAQ
                </a>
                or{' '}
                <a
                  className="underline cursor-pointer"
                  rel="noopener noreferrer"
                  onClick={() =>
                    window.open(
                      `https://go.crisp.chat/chat/embed/?website_id=${
                        ENV.CRISP_ID
                      }&user_email=${encodeURI(currentUser.email)}&user_nickname=${encodeURI(
                        currentUser.name
                      )}&token_id=${encodeURI(currentUser.token)}`,
                      '_blank'
                    )
                  }
                >
                  write to us via chat
                </a>
              </Trans>
            </p>
          </section>
          <div className="h-[300px] w-full bg-[url('https://bumerangphotos.s3.eu-west-3.amazonaws.com/assets/como_funciona_guias_bumerang.png')] bg-center bg-cover bg-no-repeat" />
        </div>
      </Container>
    </Page>
  );
};

export default HowItWorksModal;
