import React, { useState } from 'react';
import { IonContent, IonGrid, IonRow, IonCol, IonSpinner, IonBackButton } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import QrReader from 'react-qr-reader';

import styled from 'styled-components';
import AuthApi from '../api/AuthApi';
import ApiCaller from '../lib/ApiCaller';

import { Page } from '@bumerang-kit/layouts';
import { Toaster } from '@bumerang-kit/helpers';

import LendApi from '../api/LendApi';
import { Colors } from '@bumerang-kit/foundations';
import { getCurrentSession } from '@/helpers/session';

import { useTranslation } from 'react-i18next';

const toaster = new Toaster();

const Scan = styled(QrReader)`
  width: 100%;
  max-width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  height: auto !important;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;
`;

const QrScan: React.FC = () => {
  const navigate = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const user = getCurrentSession();
  const [hasScanned, setHasScanned] = useState(false);

  const handleScan = (qrContent: string | null) => {
    if (!qrContent) return;
    setHasScanned(true);
    const lendApi = new LendApi(new ApiCaller(user.token));
    const authApi = new AuthApi(new ApiCaller(user.token));
    const pathname = location?.pathname;

    authApi
      .getTokenById(qrContent)
      .then((token: any) => {
        lendApi
          .isUsed(token.token)
          .then((e) => {
            if (e.result === null && pathname === '/qrscan') {
              navigate.push('/restoptions', {
                state: {
                  token,
                  inputMethod: 'scanned',
                },
              });
            } else {
              toaster.flashError(t('QrScanDefaultNegativeFeedback'));

              navigate.push('/restaurant', { state: { isSuccess: false } });
            }
          })
          .catch((e) => {
            console.log(e);
            toaster.flashError(t('QrScanDefaultNegativeFeedback'));
            navigate.push('/restaurant', { state: { isSuccess: false } });
          });
      })
      .catch((err) => handleError(err));
  };

  const handleError = (error: string) => {
    toaster.flashError(t('QrScanSomethingWentWrongFeedback'));
    console.log(error);
    navigate.push('/restaurant');
  };

  return (
    <Page>
      <IonContent>
        <IonBackButton class="backbutton" defaultHref="restaurant" />
        {hasScanned ? (
          <SpinnerContainer>
            <IonSpinner color={Colors.mainColor} />
          </SpinnerContainer>
        ) : (
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <div id="qrscan">
                  <Scan delay={300} onError={handleError} onScan={handleScan} />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </Page>
  );
};

export default QrScan;
