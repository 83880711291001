import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useHistory, useLocation } from 'react-router';
import { Container, Page } from '@bumerang-kit/layouts';
import { Button, Line, Navigation, Text } from '@bumerang-kit/components';
import { Colors, Spacing } from '@bumerang-kit/foundations';
import Settings from '../../lib/settings';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 60% ${Spacing.s};
`;

const StyledLine = styled(Line)`
  width: 100%;
`;

const SelectUserTypeAdmin = ({ lendConfig, setLendConfig }: any) => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const location = useLocation();

  const setLendConfigStorage = (state: any) => {
    Settings.saveStateInSession(state);
    setLendConfig(state);
  };

  useEffect(() => {
    const pathname = location?.pathname;
    if (pathname === '/admin/selectUserType') {
      if (!lendConfig.adminAction) {
        const state = Settings.getStateInSession();
        if (state?.adminAction) {
          setLendConfigStorage({
            ...lendConfig,
            ...state,
          });
        } else {
          navigate.push('/admin');
        }
      }
    }
  }, [location]);

  const openAction = async (event: any, type: any) => {
    event.preventDefault();

    await setLendConfigStorage({
      ...lendConfig,
      adminAction: `${lendConfig.adminAction}_${type}`,
    });
    navigate.push('/admin/restaurantList');
  };

  const goBack = async () => {
    setLendConfigStorage({
      ...lendConfig,
      adminAction: null,
    });
    navigate.push('/admin');
  };
  return (
    <Page>
      <Navigation onBack={() => goBack()}>
        <Text align="center" config="secondary-screen-title">
          {t('SelectUserType')}
        </Text>
      </Navigation>
      <StyledContainer>
        <Button onClick={(event: any) => openAction(event, 'restaurant')}>
          <Text config="button-text" color={Colors.white}>
            {t('Restaurant')}
          </Text>
        </Button>
        <StyledLine isOption />
        <Button onClick={(event: any) => openAction(event, 'final_customer')}>
          <Text config="button-text" color={Colors.white}>
            {t('FinalCustomer')}
          </Text>
        </Button>
      </StyledContainer>
    </Page>
  );
};

export default SelectUserTypeAdmin;
