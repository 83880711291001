import React from 'react';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Settings from '../lib/settings';
import AuthApi from '../api/AuthApi';
import ApiCaller from '../lib/ApiCaller';

import { Page } from '@bumerang-kit/layouts';
import { LoadingHome } from '@bumerang-kit/components';
import { setCurrentSession } from '../helpers/session';
import { Toaster } from '@bumerang-kit/helpers';

interface VerifyProps {
  code: string;
}

const toaster = new Toaster();

const Verify: React.FC<VerifyProps> = ({ code }) => {
  const authApi = new AuthApi(new ApiCaller());
  const history = useHistory();
  const { t } = useTranslation();

  React.useEffect(() => {
    authApi
      .verify(code)
      .then((response: any) => {
        if (response.success) {
          setCurrentSession(response.data.token);
          Settings.setEmailAsValidated();
          history.push(`/${response.type}`);
          toaster.flashSuccess(t('EmailVerifiedPositiveFeedback'));
        } else {
          throw new Error(response.data.error);
        }
      })
      .catch((error) => {
        if (error.message) {
          toaster.flashError(error.message);
        } else {
          toaster.flashError(t('GeneralError'));
        }
        history.push('/');
      });
  }, []);

  return (
    <Page>
      <LoadingHome />
    </Page>
  );
};

export default Verify;
