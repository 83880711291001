import React, { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import {
  IonImg,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTextarea,
  IonToggle,
} from '@ionic/react';

import { ItemReorderEventDetail } from '@ionic/core';
import { useTranslation } from 'react-i18next';
import Restaurant from '../../lib/common/models/restaurant';
import RestaurantApi from '../../api/RestaurantApi';
import ApiCaller from '../../lib/ApiCaller';
import { Container, Page } from '@bumerang-kit/layouts';
import { Toaster } from '@bumerang-kit/helpers';
import {
  Button,
  Input,
  Navigation,
  LoadingModal,
  RestaurantCarrousel,
  Text,
} from '@bumerang-kit/components';
import { Colors, Spacing } from '@bumerang-kit/foundations';

import { getCurrentSession } from '@/helpers/session';
import Settings from '../../lib/settings';
import ProductApi from '../../apiClient/ProductApi';
import ReorderModal from '@bumerang-kit/components/molecules/ReorderModal';

const toaster = new Toaster();

const StyledButton = styled(Button)`
  margin-bottom: ${Spacing.xs};
`;

const SubmitButton = styled(Button)`
  margin-bottom: ${Spacing.xs};
`;

const ButtonsWrapper = styled.div`
  margin-top: ${Spacing.xl};
`;

const StyledSpinner = styled(IonSpinner)`
  margin: 50% auto;
`;

/* eslint-disable camelcase */
interface ProductType {
  id: string;
  name: string;
  code: string;
  group: string;
  picture: string;
  created_at: Date;
  updated_at: Date;
}
interface BusinessType {
  name: string;
  code: string;
}
/* eslint-enable camelcase */

const EditRestaurant = ({ lendConfig, setLendConfig }: any) => {
  const currentUser = getCurrentSession();
  const restaurantApi = new RestaurantApi(new ApiCaller(currentUser.token));
  const navigate = useHistory();
  const location = useLocation();
  const productApi = new ProductApi(currentUser.token);
  const [messageLoading, setMessageLoading] = React.useState('');
  const [showLoadingToUploadPhotos, setShowLoadingToUploadPhotos] = React.useState(false);
  const [showLoadingToUpdateRestaurant, setShowLoadingToUpdateRestaurant] = React.useState(false);
  const [totalPhotos, setTotalPhotos] = React.useState(0);
  const [currentPhoto, setCurrentPhoto] = React.useState(0);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const [restaurant, setRestaurant] = useState<Restaurant>(lendConfig.restaurant);
  const [allProductGroupTypes, setAllProductGroupTypes] = useState<string[]>([]);
  const [allBusinessTypes] = useState<BusinessType[]>([
    {
      name: 'Restaurant',
      code: 'restaurant',
    },
    {
      name: 'Delivery Service',
      code: 'delivery_service',
    },
    {
      name: 'Supermarket',
      code: 'supermarket',
    },
    {
      name: 'Canteen',
      code: 'canteen',
    },
    {
      name: 'Cafeteria',
      code: 'cafeteria',
    },
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setLendConfigStorage = (state: any) => {
    Settings.saveStateInSession(state);
    setLendConfig(state);
  };
  const setRestaurantInStorage = (restaurant: Restaurant) => {
    setLendConfigStorage({
      ...lendConfig,
      restaurant,
    });
    setRestaurant(restaurant);
  };

  useEffect(() => {
    const pathname = location?.location?.pathname || location?.pathname;
    if (pathname === '/admin/restaurant/edit') {
      if (!lendConfig?.restaurant) {
        const state = Settings.getStateInSession();
        if (state?.restaurant) {
          setLendConfigStorage({
            ...lendConfig,
            ...state,
          });
          setRestaurant(state.restaurant);
        } else {
          setLendConfigStorage({
            ...lendConfig,
            restaurant: null,
          });
          navigate.replace('/admin', { replace: true });
        }
      } else {
        setRestaurant(lendConfig?.restaurant);
      }
    }
  }, [location]);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      try {
        const response = await productApi.getProductTypeList();
        if (!(response && response?.success)) {
          throw new Error(response.data.error);
        }
        setAllProductGroupTypes(
          response.data?.product_types
            .map((productType: ProductType) => {
              return productType.group;
            })
            .reduce((acc: string[], curr: string) => {
              if (!acc.includes(curr)) {
                acc.push(curr);
              }
              return acc;
            }, [])
        );
      } catch (error: any) {
        if (error?.message) {
          toaster.flashError(error.message);
        } else toaster.flashError(t('GenericNegativeFeedbackTryAgain'));
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const setPropertyValue = (propertyName: string, value: any) => {
    setRestaurantInStorage({ ...lendConfig.restaurant, [propertyName]: value });
  };

  const uploadPictures = () => {
    Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      source: CameraSource.Photos,
    }).then((data: any) => {
      setTotalPhotos(1);
      setShowLoadingToUploadPhotos(true);
      restaurantApi
        .uploadPictures({
          restaurantName: restaurant?.name,
          base64: data.base64String,
          format: data.format,
        })
        .then((imageUrl: any) => {
          const newPhotosList = restaurant?.photoUrl!.concat(imageUrl.Location);
          setPropertyValue('photoUrl', newPhotosList);
          setShowLoadingToUploadPhotos(false);
        })
        .catch((e: any) => {
          setShowLoadingToUploadPhotos(false);
          console.log(e);
        });
    });
  };

  const removePicture = (selectedIndex: number) => {
    setPropertyValue(
      'photoUrl',
      restaurant?.photoUrl?.filter((url: string, index: number) => index !== selectedIndex)
    );
  };

  const doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    const element = restaurant?.photoUrl![event.detail.from];
    restaurant?.photoUrl!.splice(event.detail.from, 1);
    restaurant?.photoUrl!.splice(event.detail.to, 0, element);
    event.detail.complete();
  };

  const updateRestaurant = async (e: any) => {
    e.preventDefault();

    const restaurantToUpdate = new Restaurant(restaurant);

    try {
      setShowLoadingToUpdateRestaurant(true);
      const response = await restaurantApi.updateData(restaurantToUpdate);
      if (!(response && response?.success)) {
        throw new Error(response.data.error);
      }
      toaster.flashSuccess(t('EditRestaurantDataSaved'));
      navigate.replace('/admin');
    } catch (error: any) {
      if (error?.message) {
        toaster.flashError(error.message);
      } else toaster.flashError(t('GenericNegativeFeedbackTryAgain'));
    } finally {
      setShowLoadingToUpdateRestaurant(false);
    }
  };

  const getGooglePlacesData = () => {
    const spain = new google.maps.LatLng(40.416775, -3.70379);
    const map = new google.maps.Map(document.createElement('div'), {
      center: spain,
      zoom: 15,
    });
    const service = new google.maps.places.PlacesService(map);

    const request: any = {
      language: 'es',
      placeId: restaurant?.placeid,
      fields: [
        'name',
        'rating',
        'formatted_phone_number',
        'opening_hours',
        'website',
        'price_level',
        'photo',
      ],
    };

    service.getDetails(request, async (place: any, status: any) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        if (place.photos) {
          setTotalPhotos(place.photos.length);
          setShowLoadingToUploadPhotos(true);
          try {
            for (let x = 0; x < place.photos.length; x += 1) {
              setCurrentPhoto(x);
              const imageUrl = await restaurantApi.uploadPictures({
                restaurantName: restaurant?.name,
                url: place.photos[x].getUrl(),
              });
              restaurant?.photoUrl.push(imageUrl.Location);
              const updatedRestaurant = new Restaurant({
                ...restaurant,
              });
              setRestaurantInStorage(updatedRestaurant);
            }
            setCurrentPhoto(place.photos.length);
          } catch (error) {
            console.log(error);
          } finally {
            setShowLoadingToUploadPhotos(false);
          }
        }
        const hoursFormatted = place.opening_hours?.weekday_text;
        const updatedRestaurant = new Restaurant({
          ...restaurant,
          phone: place.formatted_phone_number,
          hours: hoursFormatted,
          rating: place.rating,
          website: place.website,
        });
        setRestaurantInStorage(updatedRestaurant);
        toaster.flashSuccess(t('EditRestaurantDataFeedback'));
      }
    });
  };
  const onBack = () => {
    setLendConfigStorage({
      ...lendConfig,
      restaurant: null,
    });
    navigate.replace('/admin/restaurantList');
  };

  useEffect(() => {
    if (totalPhotos == 1) {
      setMessageLoading(t('EditRestaurantUploading'));
    } else {
      setMessageLoading(
        t('EditRestaurantUploadingMultiple', {
          current: currentPhoto,
          total: totalPhotos,
        })
      );
    }
  }, [currentPhoto, totalPhotos]);

  if (isLoading) {
    return (
      <Page>
        <Navigation onBack={() => onBack()} iconName="gallery">
          <Text align="center" config="main-text-title">
            {restaurant?.name}
          </Text>
        </Navigation>
        <Container>
          <StyledSpinner color={Colors.mainColor} />
        </Container>
      </Page>
    );
  }

  return (
    <Page>
      <Navigation onBack={() => onBack()} iconName="gallery" iconAction={uploadPictures}>
        <Text align="center" config="main-text-title">
          {restaurant?.name}
        </Text>
      </Navigation>

      <RestaurantCarrousel
        admin
        imgList={restaurant?.photoUrl}
        onArrowClick={() => setShowModal(true)}
      />
      <LoadingModal isOpen={showLoadingToUploadPhotos} message={messageLoading} />
      <LoadingModal isOpen={showLoadingToUpdateRestaurant} message={t('EditRestaurantUpdating')} />
      <ReorderModal
        itemList={restaurant?.photoUrl}
        isVisible={showModal}
        onReorder={doReorder}
        onClose={() => setShowModal(false)}
        onRemove={(index: number) => removePicture(index)}
      />
      <Container>
        <form onSubmit={updateRestaurant}>
          <IonItem>
            <IonLabel>{t('Discount')}</IonLabel>
            <IonToggle
              checked={restaurant?.discount}
              onIonChange={(e) => setPropertyValue('discount', e.detail.checked)}
            />
          </IonItem>
          <IonItem>
            <IonLabel>{t('Active')}</IonLabel>
            <IonToggle
              checked={restaurant?.active}
              onIonChange={(e) => setPropertyValue('active', e.detail.checked)}
            />
          </IonItem>
          <IonItem>
            <IonLabel>{t('Displayed')}</IonLabel>
            <IonToggle
              checked={restaurant?.displayed}
              onIonChange={(e) => setPropertyValue('displayed', e.detail.checked)}
            />
          </IonItem>
          <Input
            label={t('Phone')}
            name="phone"
            id="phone"
            type="tel"
            value={restaurant?.phone}
            onChange={(e: any) => setPropertyValue(e.target.name, e.detail.value)}
          />{' '}
          <Input
            label={t('Website')}
            name="website"
            id="website"
            type="website"
            value={restaurant?.website}
            onChange={(e: any) => setPropertyValue(e.target.name, e.detail.value)}
          />
          <IonItem color="white">
            <IonLabel position="floating">{t('BusinessType')}</IonLabel>
            <IonSelect
              color="dark"
              value={restaurant?.type}
              multiple
              cancelText={t('Cancel')}
              okText={t('Confirm')}
              onIonChange={(e) => setPropertyValue('type', e.detail.value)}
            >
              {allBusinessTypes.map((business: any) => {
                return (
                  <IonSelectOption key={business.code} value={business.code}>
                    {business.name}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <IonItem color="white">
            <IonLabel position="floating">{t('ProductGroupType')}</IonLabel>
            <IonSelect
              color="dark"
              value={restaurant?.productGroupTypeList}
              multiple
              cancelText={t('Cancel')}
              okText={t('Confirm')}
              onIonChange={(e) => setPropertyValue('productGroupTypeList', e.detail.value)}
            >
              {allProductGroupTypes.map((group: string) => {
                return (
                  <IonSelectOption key={group} value={group}>
                    {group}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">{t('Description')}</IonLabel>
            <IonTextarea
              autoGrow
              onIonChange={(e: any) => setPropertyValue('description', e.detail.value!)}
              value={restaurant?.description}
            />
          </IonItem>
          <ButtonsWrapper>
            <StyledButton
              type="button"
              onClick={() => getGooglePlacesData()}
              social
              disabled={showLoadingToUpdateRestaurant || showLoadingToUploadPhotos}
            >
              <IonImg
                slot="start"
                className="social-login-button-logo"
                src="assets/google-logo-social-login.png"
              />
              {t('EditRestaurantUpdate')}
            </StyledButton>
            <SubmitButton
              type="submit"
              expand="full"
              disabled={showLoadingToUpdateRestaurant || showLoadingToUploadPhotos}
            >
              {t('Save')}
            </SubmitButton>
          </ButtonsWrapper>
        </form>
      </Container>
    </Page>
  );
};

export default EditRestaurant;
