import * as React from 'react';
import { Redirect, Route } from 'react-router';
import { getCurrentSession, isLogin } from '../helpers/session';

const PublicRoute = ({ component: Component, ...rest }: any) => {
  const currentUser = getCurrentSession();

  return (
    <Route {...rest}>{isLogin() ? <Redirect to={`/${currentUser.type}`} /> : <Component />}</Route>
  );
};

export default React.memo(PublicRoute);
