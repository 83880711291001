import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Colors } from '../../../foundations';

import Tab from '../Tabs/Tab';

const TabBar = styled.div`
  position: static;
  bottom: 0;
  width: 100%;
  padding: 7px;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  border-top: 1px solid ${Colors.mainColorDisabled};
  background-color: ${Colors.white};
  padding-bottom: var(--ion-safe-area-bottom);
`;

const ResTabs = ({ active }: { active: string }) => {
  const { t } = useTranslation();
  return (
    <TabBar>
      <Tab icon="my-bumerang" isActive={active === 'scan'} link="/restaurant">
        {t('TabScan')}
      </Tab>
      <Tab icon="my-account" isActive={active === 'profile'} link="/restaurant/profile">
        {t('TabAccount')}
      </Tab>
    </TabBar>
  );
};

export default ResTabs;
